import { createAsyncThunk } from "@reduxjs/toolkit"
import { LoadingState } from "types/AppNav"
import { ErrorResponse } from "services/SuccessResponse"
import notify from "devextreme/ui/notify"
import { setLoading } from "./estimatorsRoleSlice"
import {
  fetchAssignedAdvanceUserActionAsync,
  fetchUnassignedToAssignedUserActionAsync,
} from "./services"
import { AdvanceUserList, AssignedPermission } from "./types"

export const fetchAssignedAdvanceUserAction = createAsyncThunk<any>(
  "fetchAssignedAdvanceUserActionAction",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      const response: any | ErrorResponse = await fetchAssignedAdvanceUserActionAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data as AdvanceUserList[]
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// AssignedPermission
export const fetchUnassignedToAssignedUserAction = createAsyncThunk<any, AssignedPermission>(
  "fetchUnassignedToAssignedUserAction",
  async (request: AssignedPermission, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      const response: any | ErrorResponse = await fetchUnassignedToAssignedUserActionAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return dispatch(fetchAssignedAdvanceUserAction())
      //  response.data as AssignedPermission[]
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
