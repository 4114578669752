/* eslint-disable no-plusplus */
import { get } from "lodash"
import { BorderStyle, Workbook } from "exceljs"
import { TFunction } from "i18next"
import { saveAs } from "file-saver-es"
import { addClass } from "utils/common-utils"
import { formatDate, formatNumber } from "devextreme/localization"
import { ExpenditureProfileProjectData } from "redux/expenditure-profile/types"
import { config } from "utils/config"

export const costSummaryReportTableHeight = (windowHeight: number) => {
  if (windowHeight <= 972) {
    if (window.innerWidth < 1062) return windowHeight - 380
    return windowHeight - 280
  }
  if (windowHeight > 1094) return "min-content"

  return windowHeight - 320
}

export const onCostSummaryReportRowPrepared = (e: any) => {
  const { rowElement, level } = e
  switch (level) {
    case 0:
      addClass(rowElement, "bg-summary")
      break
    case 1:
      addClass(rowElement, "bg-org")
      break
    case 2:
      addClass(rowElement, "bg-section")
      break
    // case 3:
    //   addClass(rowElement, "bg-composite-total")
    //   break
    default:
      break
  }
}

export const totColCaptionDatafieldGenerator = (
  t: TFunction<"translation", undefined>,
  showPortfolioRisk: boolean,
  showInflation: boolean
) => {
  let obj = {
    costCaption: t("costEstimateSummary.totalCostProj"),
    costDataField: "TOTAL_ML_COST",
    costWidth: 228,
    carbCaption: t("costEstimateSummary.totalCarbProj"),
    carbDataField: "TOTAL_ML_CARBON",
    carbWidth: 240,
  }
  if (showPortfolioRisk && !showInflation) {
    obj = {
      costCaption: t("costEstimateSummary.totalCostProjAndPortfolio"),
      costDataField: "TOTAL_COST_PROJECTRISK_PORTFOLIO",
      costWidth: 332,
      carbCaption: t("costEstimateSummary.totalCarbProjAndPortfolio"),
      carbDataField: "TOTAL_CARBON_PROJECTRISK_PORTFOLIO",
      carbWidth: 344,
    }
  }
  if (!showPortfolioRisk && showInflation) {
    obj = {
      costCaption: t("costEstimateSummary.totalCostProjAndInflation"),
      costDataField: "TOTAL_COST_PROJECTRISK_INFLATION",
      costWidth: 300,
      carbCaption: t("costEstimateSummary.totalCarbProjAndInflation"),
      carbDataField: "TOTAL_CARBON_PROJECTRISK_INFLATION",
      carbWidth: 312,
    }
  }
  if (showPortfolioRisk && showInflation) {
    obj = {
      costCaption: t("costEstimateSummary.totalCost"),
      costDataField: "TOTAL_COST",
      costWidth: 380,
      carbCaption: t("costEstimateSummary.totalCarb"),
      carbDataField: "TOTAL_CARBON",
      carbWidth: 396,
    }
  }

  return obj
}

function getHeader(columns: any[]): string[] {
  return columns.map((column) => column.caption)
}

function getRow(node: any, columns: any[]): any[] {
  return columns.map((column, index) => {
    switch (index) {
      case 0:
        return "   ".repeat((node.level || 0) - 1) + node.data[column.dataField]
      case 29:
        return node.data.TOTAL_COST
      case 30:
        return node.data.TOTAL_CARBON
      default:
        return node.data[column.dataField] === null ? "" : node.data[column.dataField]
    }
  })
}

function getData(node: { level: number; children: any[] }, columns: any[]): any[] {
  return node.children.reduce(
    (rows, child) => {
      const childRows = getData(child, columns)
      return [...rows, ...childRows]
    },
    node.level >= 1 ? [getRow(node, columns)] : []
  )
}

function getColumnCount(cellAddress: string): number {
  const columnLetters = cellAddress.replace(/[^A-Za-z]/g, "")
  return columnLetters.split("").reduce((acc, letter) => {
    const code = letter.toUpperCase().charCodeAt(0) - 64 // Convert letter to uppercase and get ASCII code
    return acc * 26 + code
  }, 0)
}

function getTotalRow(row: any, columns: any[]) {
  const fields = (columns || []).map((item) => item?.dataField)
  fields[29] = "TOTAL_COST"
  fields[30] = "TOTAL_CARBON"
  const totalRow = fields.map((item) => get(row, `[0][${item}]`))
  return totalRow
}

const colWidths = [
  70, 26, 16, 16, 16, 16, 22, 24, 42, 22, 42, 18, 16, 16, 16, 62, 26, 16, 16, 16, 16, 46, 16, 26,
  16, 16, 16, 16, 26, 56, 58,
]
const currencyColIndexes = [1, 7, 8, 9, 10, 16, 23, 29]
export function ExportCCESSReport(
  tree: any,
  selectedProj: ExpenditureProfileProjectData | undefined
) {
  const fileName = `${selectedProj?.PROJECT_TITLE || ""}(${
    selectedProj?.ESTIMATOR_NAME || ""
  })-CCESS`
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("CCESS")

  const descCols = new Array(31).fill("")
  const descCols1 = [...descCols]
  descCols1[0] = "Cost and Carbon Estimate Summary Sheet"

  const descCols2 = [...descCols]
  descCols2[2] = "PROJECT NAME & OPTION:"
  descCols2[5] = `${selectedProj?.PROJECT_TITLE || ""}, ${selectedProj?.PROJ_COMP_DESC || ""} ${
    selectedProj?.PROJECT_QUOTE_NO || ""
  }`
  descCols2[10] = "ESTIMATE TYPE:"
  descCols2[11] = `${selectedProj?.LEVEL_OF_ESTIMATE_DESCRIPTION || ""}`
  descCols2[16] = "PROJECT MANAGER:"
  descCols2[18] = `${selectedProj?.PROJECT_MANAGER || ""}`

  const descCols3 = [...descCols]
  descCols3[10] = "BASE DATE OF ESTIMATE:"
  descCols3[11] = formatDate(new Date(get(selectedProj, "COST_BASE_DATE") || ""), "dd/MM/yyyy")
  descCols3[16] = "LEAD COST ENGINEER:"
  descCols3[18] = `${selectedProj?.ESTIMATOR_NAME || ""}`

  const descCols4 = [...descCols]
  descCols4[2] = "SCHEME TYPE:"
  descCols4[5] = `${selectedProj?.SCHEME_TYPE || ""}`
  descCols4[10] = "CURRENT PCF STAGE:"
  descCols4[11] = `${selectedProj?.PROJECT_EST_STAGE || ""}`
  descCols4[16] = "SIGN OFF DATE:"
  descCols4[18] = get(selectedProj, "ESTIMATE_RELEASE_DATE")
    ? formatDate(new Date(get(selectedProj, "ESTIMATE_RELEASE_DATE") || ""), "dd-MMMM-yyyy")
    : ""

  worksheet.addRow(descCols1)
  worksheet.addRow(descCols2)
  worksheet.addRow(descCols3)
  worksheet.addRow(descCols4)

  const formatCols = (cols: any[], rowId: number, changeWidth?: boolean) => {
    cols.forEach((text: any, index: number) => {
      worksheet.getCell(rowId, index + 1).font = {
        color: { argb: "FFFFFF" },
        bold: true,
      }
      worksheet.getCell(rowId, index + 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "30363D" },
      }
      if (changeWidth) worksheet.getColumn(index + 1).width = colWidths[index]
    })
  }

  const topCols = new Array(31).fill("")
  topCols[3] = "Total Base Estimate (Including UIA)"
  topCols[8] = "Project Risk, Opportunity & Uncertainty Cost"
  topCols[13] = "Project Risk, Opportunity & Uncertainty Carbon"
  topCols[16] = "Portfolio Risk - Cost"
  topCols[19] = "Portfolio Risk - Carbon"
  topCols[23] = "Total Cost Inflation"
  topCols[26] = "Carbon Inflation"

  worksheet.addRow(topCols)
  formatCols(topCols, 5)

  const secondtopCols = new Array(31).fill("")
  secondtopCols[8] = "Project Risk"
  secondtopCols[9] = "Uncertainty"
  secondtopCols[10] = "Total Project Risk and Uncertainty"

  worksheet.addRow(secondtopCols)
  formatCols(secondtopCols, 6)

  const columnParent = get(tree, "_controllers.columns._columns") || []
  const columns = columnParent.filter((c: { dataField: string }) => c.dataField)
  const header = getHeader(columns)
  header[29] = "Total Base, Project Risk, Portfolio Risk & Inflation - Cost"
  header[30] = "Total Base, Project Risk, Portfolio Risk & Inflation - Carbon"
  worksheet.addRow(header)
  formatCols(header, 7, true)

  let tableData = tree.option("dataSource") || []
  tableData = tableData.filter((item: any) => item?.ID !== 99999)
  let data = getData(tree.getRootNode(), columns)
  const totalRow = (tree.option("dataSource") || []).filter((item: any) => item?.ID === 99999)
  const resTotalRow = getTotalRow(totalRow, columns) || []
  data = resTotalRow.length ? [...data, resTotalRow] : data

  data.forEach((rowData) => {
    currencyColIndexes.forEach((item) => {
      rowData[item] = formatNumber(rowData[item], {
        currency: config.clientName === "HE" ? "GBP" : "USD",
        precision: 2,
        type: "currency",
      })
    })
    const rowDesc = (rowData[0] || "").trim()
    const found = tableData.find((item: any) => item.DESCRIPTION === rowDesc)
    const row = worksheet.addRow(rowData)

    row.eachCell((cell) => {
      if (currencyColIndexes.includes(getColumnCount(cell.address) - 1)) {
        cell.alignment = { horizontal: "right" }
      }

      if (found && found.PARENT_ID === 99999) {
        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A9A9A9" } }
        cell.font = { bold: true }
      }

      if (found && tree?.getNodeByKey(found?.ID)?.level === 2) {
        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } }
        cell.font = { bold: true }
      }

      if (rowDesc === "Estimate Total") {
        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "8C8C8C" } }
        cell.font = { bold: true, size: 14 }
      }
    })
  })

  const rangesToMerge = [
    { range: "A1:B1", value: "A1" },
    { range: "C2:E2", value: "C2" },
    { range: "F2:I2", value: "F2" },
    { range: "L2:O2", value: "L2" },
    { range: "Q2:R2", value: "Q2" },
    { range: "S2:V2", value: "S2" },
    { range: "L3:O3", value: "L3" },
    { range: "Q3:R3", value: "Q3" },
    { range: "S3:V3", value: "S3" },
    { range: "C4:E4", value: "C4" },
    { range: "F4:I4", value: "F4" },
    { range: "L4:O4", value: "L4" },
    { range: "Q4:R4", value: "Q4" },
    { range: "S4:V4", value: "S4" },
    { range: "B5:G5", value: "D5" },
    { range: "H5:K5", value: "I5" },
    { range: "L5:P5", value: "N5" },
    { range: "R5:V5", value: "T5" },
    { range: "Y5:AC5", value: "AA5" },
    { range: "H6:I6", value: "I6" },
  ]
  rangesToMerge.forEach(({ range, value }) => {
    const cellValue = worksheet.getCell(value).value
    worksheet.mergeCells(range)
    const [startCell] = range.split(":")
    worksheet.getCell(startCell).value = cellValue
    worksheet.getCell(startCell).alignment = { horizontal: "center" }
  })

  const borderStyle = {
    style: "thin" as BorderStyle | undefined,
    color: { argb: "000000" },
  }
  const commonBorderStyle = {
    style: "thick" as BorderStyle | undefined,
    color: { argb: "000000" },
  }
  const styledBorder = {
    style: "double" as BorderStyle | undefined,
    color: { argb: "000000" },
  }
  const labelCols = [3, 4, 5, 11, 17, 18]
  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { ...cell.font, name: "Arial" }
      switch (rowNumber) {
        case 1:
          cell.font = { ...cell.font, size: 16, bold: true }
          cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "F8F9FA" } }
          if (colNumber === 1) {
            cell.border = { ...cell.border, left: commonBorderStyle }
          }
          if (colNumber === 31) {
            cell.border = { ...cell.border, right: commonBorderStyle }
          }
          cell.border = { ...cell.border, top: commonBorderStyle }
          break
        case 2:
        case 3:
        case 4:
          cell.font = { ...cell.font, size: 14, bold: true }
          cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "F8F9FA" } }
          if (labelCols.includes(colNumber)) {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "E9EBED" } }
            if (rowNumber === 3 && [3, 4, 5].includes(colNumber)) {
              cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "" } }
            }
          }
          if (colNumber === 31) {
            cell.border = { ...cell.border, right: commonBorderStyle }
          }
          break
        case worksheet.rowCount:
          cell.border = {
            top: styledBorder,
            left: styledBorder,
            bottom: styledBorder,
            right: styledBorder,
          }
          break
        default:
          cell.border = {
            top: borderStyle,
            left: borderStyle,
            bottom: borderStyle,
            right: borderStyle,
          }
          break
      }
    })
  })

  worksheet.protect("CCFT", {})
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${fileName}.xlsx`)
  })
}
