/* eslint-disable react/jsx-props-no-spreading */
import { memo } from "react"
// import { useParams } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { setIftKeyDataFields } from "redux/ift/iftSlice"
import { FieldLabelProps } from "../utils"

const FieldLabel = (props: FieldLabelProps) => {
  const { title, field, fieldType, value, fieldProps } = props

  const dispatch = useAppDispatch()
  // const { estimateId } = useParams()

  const onChange = (e: any) => {
    e.preventDefault()
    dispatch(setIftKeyDataFields({ field, value: e.target.value }))
  }

  return (
    <div className="fields">
      <label>{title}</label>
      <input
        type={fieldType}
        // disabled={!estimateId}
        disabled
        title={value}
        value={value}
        onChange={onChange}
        {...fieldProps}
      />
    </div>
  )
}

export default memo(FieldLabel)
