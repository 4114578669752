import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, FilterRow, Pager, Paging, Selection } from "devextreme-react/data-grid"
import { sharedSelector } from "redux/shared/sharedSlice"
import { rolePermissionSelector } from "redux/role-permission/rolePermissionSlice"
import { estimatorsRoleSelector } from "redux/estimators-roles/estimatorsRoleSlice"
import {
  fetchAssignedAdvanceUserAction,
  fetchUnassignedToAssignedUserAction,
} from "redux/estimators-roles/middleware"
import { allowedPageSizes } from "utils/config"
import "./index.scss"
import { profileSelector } from "redux/profile/profileSlice"
import { allRoles, identifyPermissions } from "utils/common-utils"
import { AccessLevel, AccessType } from "redux/role-permission/types"

const EstimatorRoleTable: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { windowHeight } = useSelector(sharedSelector)
  const { permission } = useSelector(rolePermissionSelector)
  const { advanceUserList } = useSelector(estimatorsRoleSelector)

  const dataGridRef = useRef<any>(null)
  const dataGridRef1 = useRef<any>(null)

  const { userRole } = useSelector(profileSelector)

  const store = new CustomStore({
    load: () => advanceUserList?.filter((item) => item.ROLE_ID === permission?.ROLE_ID),
    key: "ESTIMATOR_ID",
  })
  const store2 = new CustomStore({
    load: () => advanceUserList?.filter((item) => item.ROLE_ID !== permission?.ROLE_ID),
    key: "ESTIMATOR_ID",
  })

  useEffect(() => {
    dispatch(fetchAssignedAdvanceUserAction())
  }, [])

  const allAccess = useMemo(() => {
    return identifyPermissions(userRole, AccessType.GRANT_RIGHTS, AccessLevel.FULL_ACCESS, allRoles)
  }, [userRole])

  const handleButtonClickRight = async () => {
    const selectedItems = await dataGridRef.current.instance.getSelectedRowsData()

    dispatch(
      fetchUnassignedToAssignedUserAction({
        ROLE_CARBON_ID: permission?.ROLE_ID,
        ASSIGNED: selectedItems?.map((item: any) => item.ESTIMATOR_ID),
        UNASSIGNED: [],
      })
    )
    dataGridRef.current.instance.clearSelection()
  }

  const handleButtonClickLeft = async () => {
    const selectedItems = await dataGridRef1.current.instance.getSelectedRowsData()

    dispatch(
      fetchUnassignedToAssignedUserAction({
        ROLE_CARBON_ID: permission?.ROLE_ID,
        ASSIGNED: [],
        UNASSIGNED: selectedItems?.map((item: any) => item.ESTIMATOR_ID),
      })
    )
    dataGridRef1.current.instance.clearSelection()
  }

  const renderUserData = useCallback((rowData: any) => {
    return (
      <div>
        {`${rowData.data.ESTIMATOR_NAME} (${
          rowData.data.ROLE_NAME ? rowData.data.ROLE_NAME : "No Role"
        })`}
      </div>
    )
  }, [])

  return (
    <div className="widget-container">
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={store2}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        showColumnLines
        selection={{
          mode: "multiple",
          showCheckBoxesMode: "none",
        }}
        height={windowHeight - 232}
      >
        <FilterRow visible />
        <Paging enabled defaultPageSize={10} />
        <Selection mode="multiple" deferred showCheckBoxesMode="none" selectByClick />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Column
          key="ESTIMATOR_ID"
          caption={`${t("estimatorRoles.tableCol1Head")} ${permission?.ROLE_NAME}`}
          dataField="ESTIMATOR_NAME"
          cellRender={renderUserData}
        />
      </DataGrid>
      <div>
        <FontAwesomeIcon
          icon={faCircleRight}
          color="green"
          size="2x"
          style={{ margin: "10px" }}
          onClick={allAccess ? handleButtonClickRight : undefined}
          className={allAccess ? "fa" : "fa-disabled"}
        />
        <FontAwesomeIcon
          icon={faCircleLeft}
          color="green"
          size="2x"
          style={{ margin: "10px" }}
          onClick={allAccess ? handleButtonClickLeft : undefined}
          className={allAccess ? "fa" : "fa-disabled"}
        />
      </div>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef1}
        dataSource={store}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        showColumnLines
        selection={{
          mode: "multiple",
          showCheckBoxesMode: "none",
        }}
        height={windowHeight - 232}
      >
        <FilterRow visible />
        <Paging enabled defaultPageSize={10} />
        <Selection mode="multiple" deferred showCheckBoxesMode="none" selectByClick />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Column
          key="ESTIMATOR_ID"
          caption={`${t("estimatorRoles.tableCol2Head")} ${permission?.ROLE_NAME}`}
          dataField="ESTIMATOR_NAME"
          cellRender={renderUserData}
        />
      </DataGrid>
    </div>
  )
}

export default EstimatorRoleTable
