import { Workbook } from "exceljs"
import { TFunction } from "i18next"
import { saveAs } from "file-saver-es"
import { exportDataGrid } from "devextreme/excel_exporter"
import { CarbonCodesCategoryItem } from "redux/masters/types"

export const CodesBreadCrumb = (t: TFunction<"translation", undefined>) => [
  {
    name: t("menu.codes"),
  },
]

export const codeDelconfirmPopUPProps = {
  showTitle: false,
  messageHtml: `<div style="padding: 0.375rem">This code might have associated assumptions. Deleting the code might also delete the assumptions set up for it. Proceed?</div>`,
  buttons: [
    {
      text: "Yes",
      onClick: () => true,
    },
    {
      text: "No",
      onClick: () => false,
    },
  ],
}

export const onCodesExporting = (e: any, currentCode: CarbonCodesCategoryItem | undefined) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("Codes")
  exportDataGrid({
    component: e.component,
    worksheet,
    customizeCell({ gridCell, excelCell }) {
      if (gridCell?.rowType === "header") {
        excelCell.font.bold = true
        excelCell.font.italic = true
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${currentCode?.CUSTOM_LIST_NAME}-Codes.xlsx`
      )
    })
  })
  e.cancel = true
}
