import { memo, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { expenditureProfileReportHeadings } from "../../utils"

const ExpenditureProfileReportHeadings = () => {
  const { t } = useTranslation()

  const headings = useMemo(() => expenditureProfileReportHeadings(t), [])

  return (
    <>
      <div className="flex-1">
        {headings.map(({ key, ExtraClass }) => (
          <span key={key} className={`reportHeading fw-bold ${ExtraClass}`}>
            {key}
          </span>
        ))}
      </div>
      &nbsp;&nbsp;
    </>
  )
}

export default memo(ExpenditureProfileReportHeadings)
