import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import DataGrid, {
  Item,
  Pager,
  Paging,
  Column,
  Export,
  Sorting,
  Toolbar,
  Editing,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { SelectBox } from "devextreme-react"
import { LoadPanel } from "devextreme-react/load-panel"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons"
import useDocumentTitle from "hooks/useDocumentTitle"
import {
  getIFTInflationAction,
  updateIFTInflationAction,
  getIFTInflationMasterDataAction,
} from "redux/ift/middleware"
import { useAppDispatch } from "redux/store"
import { iftSelector } from "redux/ift/iftSlice"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, RoleType } from "redux/role-permission/types"
import {
  handleResetFilter,
  identifyPermissions,
  datagridRemoveGroupLabel,
} from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { LoadingState } from "types/AppNav"
import {
  inflationtitleRender,
  onInflationExporting,
  onInflationCellPrepared,
  handleInflationSortingChange,
} from "./inflation-utils"
import "./inflation.scss"

const IFTInflation: React.FC = (): JSX.Element => {
  useDocumentTitle("IFT Inflation | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { iftInflation, iftInflationRowData, iftInflationMasterData, estimateId, loading } =
    useSelector(iftSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const [changes, setChanges] = useState<Array<any>>([])
  const [changesHistory, setChangesHistory] = useState<Array<any>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([])

  useEffect(() => {
    dispatch(getIFTInflationAction(estimateId || projectId))
    dispatch(getIFTInflationMasterDataAction())
  }, [])

  const inflationSheets = useMemo(() => {
    const hasContractorAccess = identifyPermissions(userRole, -1, AccessLevel.NO_ACCESS, [
      RoleType.CONTRACTOR,
    ])
    return iftInflationMasterData.filter((obj) =>
      hasContractorAccess ? obj.HAS_ACCESS === "Y" : true
    )
  }, [iftInflationMasterData, userRole])

  const getLastChanges = useCallback(() => {
    return changesHistory[changesHistory.length - 1] || { changesOnPageNo: 0, changes: [] }
  }, [changes, changesHistory])

  const onChangesChange = useCallback(
    (newChanges: any) => {
      const data = {
        changesOnPageNo: dataGridRef.current?.instance.pageIndex(),
        changes: newChanges,
      }
      setChanges(newChanges)
      setChangesHistory((prev) => [...prev, data])
    },
    [dataGridRef]
  )

  const undoChanges = useCallback(() => {
    changesHistory.pop()
    const lastChanges = getLastChanges()
    dataGridRef.current?.instance.pageIndex(lastChanges.changesOnPageNo)
    setChanges(lastChanges.changes)
  }, [changes, changesHistory])

  const discardAllChanges = useCallback(() => {
    setChanges([])
    setChangesHistory([])
  }, [])

  // eslint-disable-next-line consistent-return
  const saveChanges = useCallback(async () => {
    try {
      const localChanges: any = []
      changesHistory.forEach((obj: { changesOnPageNo: number; changes: any[] }) => {
        obj.changes.forEach((change: any) => {
          if (change.type === "insert" || change.type === "update") {
            // merge object with the same id in javascript
            const index = localChanges.findIndex((obj: any) => obj.StoreId === change.key.StoreId)
            if (index > -1) {
              localChanges[index] = {
                ...localChanges[index],
                ...change.data,
                StoreId: change?.key?.StoreId,
              }
            } else {
              localChanges.push({
                ...change.data,
                StoreId: change?.key?.StoreId,
              })
            }
          }
        })
      })

      const inflationSheetSelectionError = localChanges.some((item: any) => !item.InflationSheet)
      const updates = localChanges.reduce((acc: any, curr: any) => {
        const found = iftInflationRowData.find((item: any) => {
          const { Properties, properties } = item
          const propArr = Properties || properties || []
          const storeObj = propArr.find((prop: { Name: string }) => prop.Name === "StoreId")
          return storeObj?.Data === curr.StoreId
        })
        const foundPropArr = (get(found, "Properties") || []).filter(
          (item: any) => item.Name !== "InflationSheet"
        )
        const updatedData = {
          Name: "InflationSheet",
          Data: curr.InflationSheet,
          PropertyType: "FixedField",
        }
        const newEntry = {
          DataTypeName: "CostElementInflationSettingXmlDataStore",
          Properties: [...foundPropArr, updatedData],
        }
        return [...acc, newEntry]
      }, [])

      if (inflationSheetSelectionError) return notify(t("inflation.select1Idx"), "error", 2000)

      const projId = (estimateId || projectId)?.toString()
      const res = await dispatch(
        updateIFTInflationAction({
          request: updates,
          projectId: projId,
        })
      )
      if (get(res, "meta.requestStatus") === "fulfilled") {
        discardAllChanges()
        notify(t("inflation.changeSuccess"), "success", 2000)
        dispatch(getIFTInflationAction(estimateId))
      }
    } catch (error) {
      notify(t("inflation.errSaving"), "error", 2000)
    }
  }, [changes, changesHistory])

  const onSelectionChange = useCallback((e: any) => {
    setSelectedRowKeys(e.selectedRowKeys)
  }, [])

  const onInflationSheetChange = useCallback(
    (e: any) => {
      const updatedFields: any = {
        InflationSheet: e?.value,
      }
      const changedRows: any = []
      selectedRowKeys.forEach((row: any) => {
        const obj = {
          type: "update",
          data: updatedFields,
          key: row,
        }
        changedRows.push(obj)
      })
      onChangesChange(changedRows)
    },
    [selectedRowKeys]
  )

  return (
    <div className="inflation">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position="center"
        visible={loading === LoadingState.LOADING}
        showIndicator
        shading
        showPane
      />
      <DataGrid
        dataSource={iftInflation}
        ref={dataGridRef}
        highlightChanges
        repaintChangesOnly
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        noDataText={t("noRecords")}
        height={windowHeight - 230}
        onExporting={onInflationExporting}
        selection={{
          mode: "multiple",
        }}
        onSelectionChanged={onSelectionChange}
        onCellPrepared={onInflationCellPrepared}
        onOptionChanged={(e) => handleInflationSortingChange(e, dataGridRef)}
      >
        <Export enabled />
        <FilterRow visible />
        <Sorting mode="multiple" />
        <SearchPanel visible width={340} />
        <Paging defaultPageSize={defaultPageSize} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Editing
          mode="batch"
          changes={changes}
          allowUpdating={!!estimateId}
          onChangesChange={onChangesChange}
        />
        <Toolbar visible>
          <Item
            location="before"
            widget="dxButton"
            options={{
              icon: "refresh",
              hint: t("toolbarActions.resetAllFilters"),
              onClick: () => handleResetFilter(dataGridRef),
            }}
          />
          <Item
            location="before"
            disabled={!changes.length}
            options={{
              hint: t("toolbarActions.undoAll"),
            }}
            render={() => (
              <FontAwesomeIcon
                aria-disabled={!changes.length}
                cursor="pointer"
                icon={faClockRotateLeft}
                size="1x"
                onClick={discardAllChanges}
                opacity={!changes.length ? 0.3 : 1}
              />
            )}
          />
          <Item
            visible={!!selectedRowKeys.length}
            render={() => (
              <SelectBox
                dataSource={inflationSheets}
                displayExpr="NAME"
                valueExpr="NAME"
                onValueChanged={onInflationSheetChange}
                width={230}
                label={t("inflation.applyToSelRows")}
              />
            )}
          />
          <Item
            location="after"
            disabled={!changes.length}
            widget="dxButton"
            options={{
              icon: "save",
              hint: t("toolbarActions.saveAllChanges"),
              onClick: saveChanges,
            }}
          />
          <Item
            location="after"
            disabled={!changes.length}
            widget="dxButton"
            options={{
              icon: "undo",
              hint: t("toolbarActions.undoPrev"),
              onClick: undoChanges,
            }}
          />
          <Item name="exportButton" />
          <Item name="searchPanel" />
        </Toolbar>
        <Column
          dataField="sectionName"
          dataType="string"
          allowHiding
          allowSorting
          allowEditing={false}
          defaultGroupIndex={0}
          // defaultSortOrder="asc"
          allowHeaderFiltering={false}
          groupCellTemplate={datagridRemoveGroupLabel}
        />
        <Column
          dataField="Description"
          caption={t("inflation.description")}
          width={370}
          fixed
          allowFixing
          allowSorting
          allowEditing={false}
          cssClass="border-class disabled"
          cellRender={inflationtitleRender}
        />
        {/* <Column
          dataField="InflationDate"
          dataType="date"
          caption={t("inflation.costBaseDate")}
          width={270}
          fixed
          allowFixing
          allowEditing
          cssClass="border-class"
        /> */}
        <Column
          dataField="InflationSheet"
          caption={t("inflation.inflationIdx")}
          cssClass="border-class"
          lookup={{
            dataSource: inflationSheets,
            displayExpr: "NAME",
            valueExpr: "NAME",
            allowClearing: true,
          }}
        />
      </DataGrid>
    </div>
  )
}

export default IFTInflation
