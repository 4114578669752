/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import {
  Column,
  Export,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import { TreeList, Button, Popup, ScrollView } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { iftSelector } from "redux/ift/iftSlice"
import { sharedSelector } from "redux/shared/sharedSlice"
import { fetchIFTSpendResultAction } from "redux/ift/middleware"
import { addClass, currencyFormatterConfig, handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import ExportToCSV from "utils/ExportTreeListToCSV"
import "./spend-result-popup.scss"

interface ISpendResult {
  showSpendResultPopup: boolean
  setSpendResultPopup: (isVisible: boolean) => void
}

const SpendResultPopup: React.FC<ISpendResult> = ({
  showSpendResultPopup,
  setSpendResultPopup,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  const { windowHeight } = useSelector(sharedSelector)
  const { estimateId, iftSpendResult } = useSelector(iftSelector)

  const treeListRef = useRef<TreeList>(null)

  useEffect(() => {
    dispatch(fetchIFTSpendResultAction(estimateId || projectId))
  }, [])

  const togglePopup = () => setSpendResultPopup(!showSpendResultPopup)

  const titleRender = useCallback((row: any) => {
    const val = get(row, "data.Title")
    const sectionCheck = get(row, "data.RowType") === "SECTION"

    return <label className={`${sectionCheck ? "fw-semibold" : ""}`}>{val}</label>
  }, [])

  const valueRender = useCallback((row: any, fieldName: string) => {
    const value = row?.data[fieldName]
    if (row?.data?.RowType === "SECTION") {
      return <label className="fw-semibold">{value}</label>
    }
    return <label>{`${value.toFixed(2)}`}</label>
  }, [])

  const onRowPrepared = (e: any) => {
    if (e && e.rowType === "data") {
      const { rowElement, data } = e

      if (data.RowType === "SECTION") {
        addClass(rowElement, "heading-row")
      }

      let sum = 0
      data.Values.forEach((value: any, index: number) => {
        sum += Number(data[`V${index}`])
      })
      if (data.RowType !== "SECTION" && sum.toFixed(2) !== "1.00") {
        addClass(rowElement, "invalid-data-row")
      }
    }
  }

  const onExporting = useCallback(() => {
    const fileName = `IFT Spend Result`
    ExportToCSV(`${fileName}`, treeListRef.current?.instance)
  }, [treeListRef])

  return (
    <Popup
      title={t("spend.spendResPopUpHead")}
      visible={showSpendResultPopup}
      hideOnOutsideClick
      onHiding={togglePopup}
      showCloseButton
      enableBodyScroll
      dragEnabled={false}
      height={windowHeight - 130}
    >
      <ScrollView className="spend-result-popup-wrapper">
        <TreeList
          dataSource={iftSpendResult}
          ref={treeListRef}
          key="UniqueKey"
          highlightChanges
          repaintChangesOnly
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          showBorders
          noDataText={t("noRecords")}
          scrolling={{ mode: "standard" }}
          height={windowHeight - 230}
          onRowPrepared={onRowPrepared}
          className="ift-spend-grid"
          parentIdExpr="Parent_ID"
          keyExpr="ID"
          rootValue={-1}
          autoExpandAll
        >
          <FilterRow visible />
          <SearchPanel visible width={340} />
          <Export enabled />
          <Paging defaultPageSize={defaultPageSize} />
          <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
          <Toolbar visible>
            <Item location="before">
              <span title={t("toolbarActions.resetAllFilters")}>
                <Button
                  icon="refresh"
                  stylingMode="text"
                  onClick={() => handleResetFilter(treeListRef)}
                />
              </span>
            </Item>
            <Item location="after">
              <span title={t("toolbarActions.exportCSV")}>
                <Button icon="export" onClick={onExporting} />
              </span>
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
          <Column
            caption={t("spend.description")}
            width={370}
            fixed
            allowFixing
            allowEditing={false}
            allowSorting={false}
            cssClass="border-class disabled"
          >
            <Column
              dataField="Title"
              caption={t("spend.cashFlowForcast")}
              width={370}
              fixed
              allowFixing
              allowSorting
              allowEditing={false}
              defaultSortOrder="asc"
              cellRender={titleRender}
            />
          </Column>
          <Column
            dataField="TotalCost"
            caption={t("spend.totalCost")}
            width={200}
            cssClass="border-class"
            format={currencyFormatterConfig}
          />
          <Column
            dataField="TotalProfiledCost"
            caption={t("spend.totalProfiledCostWInflation")}
            width={240}
            cssClass="border-class"
            format={currencyFormatterConfig}
          />
          <Column caption={t("spend.timeDivisions")} allowSorting={false}>
            {iftSpendResult.length &&
              iftSpendResult[1]?.Values.map((value: any, index: any) => (
                <Column
                  dataField={`V${index}`}
                  caption={`${index + 1}`}
                  width={200}
                  allowEditing
                  cssClass="border-class"
                  cellRender={(raw) => valueRender(raw, `V${index}`)}
                  format={currencyFormatterConfig}
                  key={index}
                />
              ))}
          </Column>
        </TreeList>
      </ScrollView>
    </Popup>
  )
}

export default SpendResultPopup
