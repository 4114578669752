/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { ErrorResponse } from "services/SuccessResponse"
import { getCostEstimateTableDataAsync } from "./services"

export const getCostEstimateTableDataAction = createAsyncThunk<any, string | undefined>( // any1
  "costEstimateSummary/getCostEstimateTableDataAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getCostEstimateTableDataAsync(id) // any1
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data as any // any1
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
