import { TFunction } from "i18next"
import { get } from "lodash"
import { Button, SelectBox } from "devextreme-react"
import { CodeItems } from "redux/masters/types"
import { addClass } from "utils/common-utils"

export const onContractorSpecificCellPrepared = (e: any) => {
  if (e.rowType === "data" && e.column.allowEditing) {
    addClass(e.cellElement, "bg-grey")
  }
}

export const renderSupplierCell = (props: {
  row: any
  dataField: string
  disableSupplierCell: boolean
  setSelectedItem: (a: any) => void
  setShowSupplierRatesPopup: (a: boolean) => void
  t: TFunction<"translation", undefined>
}) => {
  const { row, dataField, disableSupplierCell, setSelectedItem, setShowSupplierRatesPopup, t } =
    props

  return (
    <div className="supplier-cell">
      <Button
        className="active"
        stylingMode="contained"
        onClick={() => {
          setSelectedItem(row.data)
          setShowSupplierRatesPopup(true)
        }}
        disabled={disableSupplierCell}
        text={get(row, `data.${dataField}`) || t("contractorEstimateLibrary.selectSupplier")}
      />
    </div>
  )
}

export const ModeOfTransportationEditor = (props: {
  t: TFunction<"translation", undefined>
  modesOfTransport: CodeItems[]
  disableModeOfTransport: boolean
  cellInfo: any
}) => {
  const { t, modesOfTransport, disableModeOfTransport, cellInfo } = props
  const { value, setValue } = cellInfo

  const setEditedValue = (valueChangedEventArg: any) => {
    setValue(valueChangedEventArg.value)
  }
  const label = `${
    get(cellInfo, "displayValue")
      ? get(cellInfo, "column.caption")
      : `${t("contractorEstimateLibrary.select")} ${get(cellInfo, "column.caption")}`
  }`

  return (
    <SelectBox
      dataSource={modesOfTransport}
      onValueChanged={setEditedValue}
      searchEnabled
      valueExpr="name"
      displayExpr="name"
      searchExpr="name"
      searchMode="contains"
      label={label}
      defaultValue={value}
      searchTimeout={5}
      minSearchLength={5}
      showDataBeforeSearch
      disabled={disableModeOfTransport}
      className={`${get(cellInfo, "displayValue") ? "transport-mode-select-box" : ""}`}
    />
  )
}

export const renderUpdateCarbonFactorCell = (
  e: any,
  props: {
    t: TFunction<"translation", undefined>
    setSelectedRowKey: (a: any) => void
    setEPDId: (a: number | undefined) => void
    setShowEPDPopup: (a: boolean) => void
  }
) => {
  const { t, setSelectedRowKey, setEPDId, setShowEPDPopup } = props
  const EPDId = (e && e.row.data.EPD_ID) || undefined

  return (
    <div className="align-item-center">
      <Button
        className="active"
        stylingMode="contained"
        text={t(
          EPDId ? "contractorEstimateLibrary.viewOrUpdate" : "contractorEstimateLibrary.addOrUpdate"
        )}
        onClick={() => {
          setSelectedRowKey(e.row.key)
          setEPDId(EPDId)
          setShowEPDPopup(true)
        }}
      />
    </div>
  )
}
