import { useTranslation } from "react-i18next"
import { SelectBox } from "devextreme-react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import {
  estimateLevelSelector,
  setAggregateBy,
  setEstimateNumber,
} from "redux/estimate-level/estimateLevelSlice"
import { aggregateOptions } from "../../utils"

function EstimateLevelHeader() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { aggregateBy, projectIDs, estimateNumber } = useSelector(estimateLevelSelector)

  return (
    <div className="estimateLevelHeader dx-card">
      <div className="dropdown">
        <label>{t("estimateLevel.headerDropdown1Label")}</label>
        <SelectBox
          dataSource={projectIDs}
          displayExpr="PROJECT_QUOTE_NO"
          valueExpr="PROJECT_ID"
          value={estimateNumber}
          searchEnabled
          onSelectionChanged={({ selectedItem }) => dispatch(setEstimateNumber(selectedItem))}
        />
      </div>
      <div className="dropdown">
        <label>{t("estimateLevel.headerDropdown2Label")}</label>
        <SelectBox
          dataSource={aggregateOptions}
          displayExpr={(val: any) => t(val.label)}
          value={aggregateBy}
          onValueChanged={({ value }) => dispatch(setAggregateBy(value))}
        />
      </div>
    </div>
  )
}

export default EstimateLevelHeader
