import { memo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import { Button, TreeList } from "devextreme-react"
import {
  SearchPanel,
  FilterRow,
  Toolbar,
  Column,
  Paging,
  Pager,
  Item,
} from "devextreme-react/tree-list"
import { useAppDispatch } from "redux/store"
import { sharedSelector } from "redux/shared/sharedSlice"
import {
  expenditureProfileSelector,
  setShowSummaryPopUp,
} from "redux/expenditure-profile/expenditureProfileSlice"
import { handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import TotalCarbonHeaderCellRender from "./totalCarbonHeaderCellRender"
import { expenditureTableHeight, onExpenditureReportRowPrepared } from "./tableUtils"
import { renderExpdtreReportCurrency, getCarbonSwitchValue, reportNullCalculate } from "../../utils"

const ExpenseProfileReportTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const treeListRef = useRef<TreeList>(null)

  const { windowHeight } = useSelector(sharedSelector)
  const { carbonSwitches, expenditureReportData, forecastYears, showSummaryPopUp } = useSelector(
    expenditureProfileSelector
  )

  return (
    <TreeList
      id="expenditureProfileReportTable"
      className="expenditure-report-grid"
      ref={treeListRef}
      dataSource={expenditureReportData}
      showBorders
      showRowLines
      columnAutoWidth
      showColumnLines
      allowColumnResizing
      keyExpr="ID"
      filterMode="withAncestors"
      parentIdExpr="PARENT_ID"
      errorRowEnabled={false}
      onRowPrepared={onExpenditureReportRowPrepared}
      height={expenditureTableHeight(windowHeight)}
    >
      <FilterRow visible />
      <SearchPanel visible width={340} />
      <Paging enabled defaultPageSize={defaultPageSize} />
      <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(treeListRef)}
            />
          </span>
        </Item>
        <Item location="before">
          <Button
            stylingMode="contained"
            className="active"
            text={t("carbExpProfileReport.summary")}
            onClick={() => dispatch(setShowSummaryPopUp(!showSummaryPopUp))}
          />
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column caption={t("carbExpProfileReport.sectionCostElement")} fixed minWidth={404}>
        <Column caption={t("carbExpProfileReport.nil")} dataField="TITLE" fixed minWidth={202} />
        <Column caption={t("carbExpProfileReport.nil")} dataField="SUBTITLE" fixed minWidth={202} />
      </Column>
      <Column alignment="center" caption={t("carbExpProfileReport.historicCosts")}>
        <Column
          caption={t("carbExpProfileReport.cost")}
          dataField="TOTAL_HISTORIC_COST"
          cellRender={renderExpdtreReportCurrency}
          calculateCellValue={(row) => reportNullCalculate(get(row, `TOTAL_HISTORIC_COST`))}
          minWidth={120}
        />
        {/* <Column
          caption={t("carbExpProfileReport.a1a3")}
          dataField="HISTORIC_A1_A3"
          visible={getCarbonSwitchValue(carbonSwitches, "historic")}
          calculateCellValue={(row) => reportNullCalculate(get(row, `HISTORIC_A1_A3`))}
          minWidth={128}
        />
        <Column
          caption={t("carbExpProfileReport.a4")}
          dataField="HISTORIC_A4"
          visible={getCarbonSwitchValue(carbonSwitches, "historic")}
          calculateCellValue={(row) => reportNullCalculate(get(row, `HISTORIC_A4`))}
          minWidth={112}
        />
        <Column
          caption={t("carbExpProfileReport.a5a")}
          dataField="HISTORIC_A5A"
          visible={getCarbonSwitchValue(carbonSwitches, "historic")}
          calculateCellValue={(row) => reportNullCalculate(get(row, `HISTORIC_A5A`))}
          minWidth={116}
        />
        <Column
          caption={t("carbExpProfileReport.a5w")}
          dataField="HISTORIC_A5W"
          visible={getCarbonSwitchValue(carbonSwitches, "historic")}
          calculateCellValue={(row) => reportNullCalculate(get(row, `HISTORIC_A5W`))}
          minWidth={116}
        /> */}
        <Column
          caption={t("carbExpProfileReport.totala1a5")}
          dataField="TOTAL_HISTORIC_CARBON"
          calculateCellValue={(row) => reportNullCalculate(get(row, `TOTAL_HISTORIC_CARBON`))}
          // headerCellRender={(data) =>
          //   TotalCarbonHeaderCellRender({ t, data, carbonSwitches, key: "historic" })
          // }
          // minWidth={208}
          minWidth={116}
        />
      </Column>
      {forecastYears.map((item) => (
        <Column alignment="center" caption={`FY ${item}`} key={item}>
          <Column
            caption={t("carbExpProfileReport.cost")}
            dataField={`${item}_TOTAL_COST`}
            cellRender={renderExpdtreReportCurrency}
            calculateCellValue={(row) => reportNullCalculate(get(row, `${item}_TOTAL_COST`))}
            minWidth={120}
          />
          <Column
            caption={t("carbExpProfileReport.a1a3")}
            dataField={`${item}_A1_A3`}
            visible={getCarbonSwitchValue(carbonSwitches, item)}
            calculateCellValue={(row) => reportNullCalculate(get(row, `${item}_A1_A3`))}
            minWidth={128}
          />
          <Column
            caption={t("carbExpProfileReport.a4")}
            dataField={`${item}_A4`}
            visible={getCarbonSwitchValue(carbonSwitches, item)}
            calculateCellValue={(row) => reportNullCalculate(get(row, `${item}_A4`))}
            minWidth={112}
          />
          <Column
            caption={t("carbExpProfileReport.a5a")}
            dataField={`${item}_A5A`}
            visible={getCarbonSwitchValue(carbonSwitches, item)}
            calculateCellValue={(row) => reportNullCalculate(get(row, `${item}_A5A`))}
            minWidth={116}
          />
          <Column
            caption={t("carbExpProfileReport.a5w")}
            dataField={`${item}_A5W`}
            visible={getCarbonSwitchValue(carbonSwitches, item)}
            calculateCellValue={(row) => reportNullCalculate(get(row, `${item}_A5W`))}
            minWidth={116}
          />
          <Column
            caption={t("carbExpProfileReport.totala1a5")}
            dataType="number"
            dataField={`${item}_TOTAL_CARBON`}
            headerCellRender={(data) =>
              TotalCarbonHeaderCellRender({ t, data, carbonSwitches, key: item })
            }
            minWidth={208}
          />
        </Column>
      ))}
      <Column alignment="center" caption={t("carbExpProfileReport.total")}>
        <Column
          caption={t("carbExpProfileReport.cost")}
          dataType="number"
          dataField="totalCost"
          cellRender={renderExpdtreReportCurrency}
          minWidth={120}
        />
        <Column
          caption={t("carbExpProfileReport.a1a3")}
          dataType="number"
          dataField="totalA1A3"
          visible={getCarbonSwitchValue(carbonSwitches, "total")}
          minWidth={128}
        />
        <Column
          caption={t("carbExpProfileReport.a4")}
          dataType="number"
          dataField="totalA4"
          visible={getCarbonSwitchValue(carbonSwitches, "total")}
          minWidth={112}
        />
        <Column
          caption={t("carbExpProfileReport.a5a")}
          dataType="number"
          dataField="totalA5A"
          visible={getCarbonSwitchValue(carbonSwitches, "total")}
          minWidth={116}
        />
        <Column
          caption={t("carbExpProfileReport.a5w")}
          dataType="number"
          dataField="totalA5W"
          visible={getCarbonSwitchValue(carbonSwitches, "total")}
          minWidth={116}
        />
        <Column
          caption={t("carbExpProfileReport.totala1a5")}
          dataType="number"
          dataField="totalCarbon"
          headerCellRender={(data) =>
            TotalCarbonHeaderCellRender({ t, data, carbonSwitches, key: "total" })
          }
          minWidth={208}
        />
      </Column>
    </TreeList>
  )
}

export default memo(ExpenseProfileReportTable)
