import { memo, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { boqSummaryChipsGenerator } from "screens/project-item/utils"

const BOQSummaryCard = () => {
  const { t } = useTranslation()

  const { userRole } = useSelector(profileSelector)
  const { estimatorID, boqItemsColumnWiseTotal } = useSelector(projectSelector)

  const data = useMemo(
    () => boqSummaryChipsGenerator(t, boqItemsColumnWiseTotal, userRole, estimatorID),
    [userRole, estimatorID, boqItemsColumnWiseTotal]
  )

  return (
    <div className="summary-container dx-card">
      <div className="summary-chip-parent">
        {data.map(({ label, value, key }) => (
          <div key={key} className="summary-chip">
            <label className="summary-chip-label">{label}</label>
            <label className="summary-chip-value">{value}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(BOQSummaryCard)
