import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import notify from "devextreme/ui/notify"
import CustomStore from "devextreme/data/custom_store"
import { Button, Popup, ScrollView } from "devextreme-react"
import DataGrid, { Item, Column, Toolbar, Editing, FilterRow } from "devextreme-react/data-grid"
import {
  addSubItemAction,
  fetchSubItemIFTScopeAction,
  fetchRevisionIFTScopeAction,
} from "redux/ift/middleware"
import { profileSelector } from "redux/profile/profileSlice"
import { iftSelector, scopePopUpsInit } from "redux/ift/iftSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, handleResetFilter, identifyPermissions } from "utils/common-utils"
import { currencyConverter } from "../ScopeTable/scopeTableUtils"
import { SubItemModalProps, costCellRender, subItemQtyValidationRules } from "./subitemUtils"
import "./subitem-modal.scss"

const SubItemModal: React.FC<SubItemModalProps> = ({ visible, onHide, rowData }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { userRole } = useSelector(profileSelector)
  const { subItemGroups, subItems, estimateId } = useSelector(iftSelector)

  const tableData = useRef<any[]>([])
  const dataGridRef = useRef<DataGrid | null>(null)
  const dataGridGroupTableRef = useRef<DataGrid | null>(null)
  const dataGridItemsTableRef = useRef<DataGrid | null>(null)
  const dataGridFinalResultTableRef = useRef<DataGrid | null>(null)

  const [rateTableData, setRateTableData] = useState<any[]>([])

  const popUpTitle = useMemo(
    () =>
      `${t("scopeSubItem.addSubItem")} ${
        rowData && `(${get(rowData, "CODE")}:${get(rowData, "DESCRIPTION")})`
      }`,
    [rowData]
  )

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  useEffect(() => {
    if (visible) {
      dataGridRef.current?.instance.option("toolbar.items[1].disabled", true)
    }
    return () => {
      setRateTableData([])
      dispatch(scopePopUpsInit())
      tableData.current = []
    }
  }, [visible])

  const onGroupTableSelectionChanged = useCallback((e: any) => {
    const selRow = e.currentSelectedRowKeys?.[0]

    if (selRow) {
      setRateTableData([])
      dataGridItemsTableRef.current?.instance?.beginCustomLoading("")
      dispatch(fetchSubItemIFTScopeAction(get(selRow, "ITEM_GROUP_ID"))).finally(() => {
        dataGridItemsTableRef.current?.instance?.endCustomLoading()
      })
    }
  }, [])

  const onItemsSelectionChanged = useCallback((e: any) => {
    const selRow = e.currentSelectedRowKeys?.[0]

    if (selRow) {
      const data = [{ ...selRow, SUBCONTRACTOR_COMPANY: "(No Supplier)" }]
      setRateTableData(data)
    }
  }, [])

  const onFinalResTableSelectionChanged = useCallback((keys: string[]) => {
    if (!keys.length) return
    dataGridRef.current?.instance?.addRow()
  }, [])

  const store = useMemo(
    () =>
      new CustomStore({
        key: "ITEM_LIBRARY_ID",
        load: () => tableData.current,
        insert: (values: any) => {
          tableData.current = [{ ...values }, ...tableData.current]
          return Promise.resolve(values)
        },
        update: (key: any, value: any) => {
          const tableItems = [...tableData.current]
          const found = tableItems.find((item) => item.ITEM_LIBRARY_ID === key)
          const PROJECT_ITEM_COST = value.PROJECT_ITEM_QTY * found.ITEM_LIBRARY_RATE
          const filtered = tableItems.filter((item) => item.ITEM_LIBRARY_ID !== key)
          const final = [...filtered, { ...found, PROJECT_ITEM_COST, ...value }]
          tableData.current = final

          return Promise.resolve(tableData.current)
        },
        remove: (rowId: string[]) => {
          const tableItems = [...tableData.current]
          const filteredData = tableItems.filter((item: any) => item.ITEM_LIBRARY_ID !== rowId)
          tableData.current = filteredData

          return Promise.resolve()
        },
      }),
    [tableData.current]
  )

  const handleEditedChanges = () => {
    dataGridRef?.current?.instance?.saveEditData()
    dataGridRef.current?.instance.option("toolbar.items[1].disabled", !tableData.current.length)
  }

  const onInitNewRow = (e: any) => {
    const reqrdData = rateTableData[0]
    const cost =
      (get(reqrdData, "ITEM_LIBRARY_RATE") || 0) * (get(reqrdData, "ITEM_LIBRARY_QTY") || 1)

    e.data = {
      PROJECT_ITEMAGGR_CODE: get(reqrdData, "ITEM_LIBAGGR_CODE"),
      PROJECT_ITEMAGGR_DESC: get(reqrdData, "ITEM_LIBAGGR_DESC"),
      PROJECT_ITEM_QTY: get(reqrdData, "ITEM_LIBRARY_QTY") || 1,
      PROJECT_ITEM_COST: cost,
      PARENT_PROJECT_ITEM_ID: get(rowData, "PROJECT_ITEM_ID"),
      ITEM_LIBRARY_ID: get(reqrdData, "ITEM_LIBRARY_ID"),
      PROJECT_SECTION_ID: get(rowData, "PROJECT_SECTION_ID"),
      UNIT_DESCRIPTION: get(reqrdData, "UNIT_DESC"),
      PROJECT_ITEM_COMPOSITETYPE: "NORMAL_ITEM",
      ITEM_LIBRARY_RATE: get(reqrdData, "ITEM_LIBRARY_RATE"),
      SUBCONTRACTOR_DESC: "(No Supplier)",
      ITEM_CATEGORY_DESC: "SUBITEM",
    }
  }

  // eslint-disable-next-line consistent-return
  const handleSaveClick = () => {
    const zeroCheck = tableData?.current.every((item) => !item.PROJECT_ITEM_QTY)
    if (zeroCheck) return notify(t("scopeItem.qty0Check"), "error", 3000)

    dataGridRef.current?.instance.saveEditData()?.then(() => {
      dispatch(addSubItemAction(tableData?.current))
        .then(() => {
          if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
        })
        .finally(() => {
          dataGridGroupTableRef?.current?.instance?.clearSelection()
          tableData.current = []
          onHide?.()
        })
    })
  }

  return (
    <Popup
      visible={visible}
      onHiding={() => {
        onHide?.()
        dataGridGroupTableRef.current?.instance.deselectAll()
      }}
      dragEnabled={false}
      defaultVisible={false}
      hideOnOutsideClick={false}
      enableBodyScroll
      showCloseButton
      showTitle
      title={popUpTitle}
      container=".dx-viewport"
      width="90%"
      height="90%"
    >
      <ScrollView className="subitem-modal-pop-up">
        <div className="subitem-group-top-row">
          <DataGrid
            dataSource={subItemGroups}
            className="ift-scope-item"
            ref={dataGridGroupTableRef}
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="20%"
            paging={{ enabled: false }}
            onSelectionChanged={onGroupTableSelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridGroupTableRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="ITEM_GROUP_DESC"
              caption={t("scopeSubItem.groups")}
              alignment="center"
            />
          </DataGrid>

          <DataGrid
            dataSource={subItems}
            className="ift-scope-item"
            ref={dataGridItemsTableRef}
            showColumnLines
            showBorders
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="40%"
            paging={{ enabled: false }}
            onSelectionChanged={onItemsSelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridItemsTableRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="ITEM_LIBAGGR_CODE"
              caption={t("scopeSubItem.code")}
              alignment="center"
            />
            <Column
              dataField="ITEM_LIBAGGR_DESC"
              caption={t("scopeSubItem.description")}
              alignment="center"
            />
            <Column
              dataField="ITEM_CATEGORY_DESC"
              caption={t("scopeSubItem.category")}
              alignment="center"
            />
            <Column dataField="UNIT_DESC" caption={t("scopeSubItem.unit")} alignment="center" />
          </DataGrid>
          <DataGrid
            dataSource={rateTableData}
            className="ift-scope-item"
            ref={dataGridFinalResultTableRef}
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="40%"
            paging={{ enabled: false }}
            onSelectedRowKeysChange={onFinalResTableSelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridFinalResultTableRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="ITEM_LIBAGGR_CODE"
              caption={t("scopeSubItem.code")}
              alignment="center"
            />
            <Column
              dataField="SUBCONTRACTOR_COMPANY"
              caption={t("scopeSubItem.subContractor")}
              alignment="center"
            />
            <Column
              dataField="ITEM_LIBRARY_RATE"
              caption={t("scopeSubItem.rate")}
              alignment="center"
              cellRender={(val) => (permsnCheck ? currencyConverter(val.value) : null)}
            />
          </DataGrid>
        </div>

        <div className="subitem-group-bottom-row">
          <DataGrid
            dataSource={store}
            className="ift-provisional-sum"
            ref={dataGridRef}
            allowColumnResizing
            repaintChangesOnly
            highlightChanges
            showColumnLines
            columnAutoWidth
            showBorders
            showRowLines
            scrolling={{ mode: "standard" }}
            paging={{ enabled: false }}
            onInitNewRow={onInitNewRow}
            width="100%"
          >
            <FilterRow visible />
            <Editing
              mode="batch"
              allowAdding
              allowUpdating
              allowDeleting
              onChangesChange={handleEditedChanges}
              refreshMode="full"
            />
            <Toolbar visible>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridRef)}
                  />
                </span>
              </Item>
              <Item name="saveButton" location="after">
                <span title={t("toolbarActions.save")}>
                  <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="PROJECT_ITEMAGGR_CODE"
              caption={t("scopeSubItem.code")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_ITEMAGGR_DESC"
              caption={t("scopeSubItem.description")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="SUBCONTRACTOR_DESC"
              caption={t("scopeSubItem.subContractor")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="ITEM_CATEGORY_DESC"
              caption={t("scopeSubItem.category")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="ITEM_LIBRARY_RATE"
              caption={t("scopeSubItem.rate")}
              alignment="center"
              allowEditing={false}
              cellRender={(val) => (permsnCheck ? currencyConverter(val.value) : null)}
            />
            <Column
              dataField="UNIT_DESCRIPTION"
              caption={t("scopeSubItem.unit")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_ITEM_QTY"
              caption={t("scopeSubItem.qty")}
              alignment="center"
              allowEditing
              validationRules={subItemQtyValidationRules}
            />
            <Column
              dataField="PROJECT_ITEM_COST"
              caption={t("scopeSubItem.cost")}
              alignment="center"
              allowEditing={false}
              cellRender={(row) => (permsnCheck ? costCellRender(row) : null)}
            />
          </DataGrid>
        </div>
      </ScrollView>
    </Popup>
  )
}

export default SubItemModal
