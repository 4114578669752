import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import useDocumentTitle from "hooks/useDocumentTitle"
import { getCostEstimateTableDataAction } from "redux/cost-estimate-summary/middleware"
import { getExpenditureProfileProjectByIdAction } from "redux/expenditure-profile/middleware"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import CostEstimateLoader from "../cost-estimate-summary/components/costEstimateLoader"
import CostEstimateReportHead from "./components/CostEstimateReportHead"
import CostEstimateReportTable from "./components/CostEstimateReportTable"
import { costEstimateSummaryReportBreadCrumb } from "./utils"
import "./cost-estimate-summary-report.scss"

const CostEstimateSummaryReport: React.FC = (): JSX.Element => {
  useDocumentTitle(
    "Cost and Carbon Estimate Summary Sheet (CCESS) Report | Cost and Carbon Forecasting tool"
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { estimateId } = useParams()

  const { selectedExpenditureProjectData } = useSelector(expenditureProfileSelector)

  useEffect(() => {
    if (!selectedExpenditureProjectData) {
      dispatch(getExpenditureProfileProjectByIdAction(estimateId))
      dispatch(getCostEstimateTableDataAction(estimateId))
    }
  }, [])

  return (
    <div className="CostEstimateSummaryReportMain">
      <CostEstimateLoader />
      <BreadCrumb data={costEstimateSummaryReportBreadCrumb(t)} />
      <CostEstimateReportHead />
      <CostEstimateReportTable />
    </div>
  )
}

export default CostEstimateSummaryReport
