import React, { useEffect, useMemo } from "react"
import "./profile.scss"
import Form from "devextreme-react/form"
import useDocumentTitle from "hooks/useDocumentTitle"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { fetchProfileAction } from "redux/profile/middleware"
import { useSelector } from "react-redux"
import { profileSelector, updateProfile } from "redux/profile/profileSlice"
import { ProfileInfo } from "redux/profile/types"
import { FieldDataChangedEvent } from "devextreme/ui/form"

const Profile: React.FC = (): JSX.Element => {
  useDocumentTitle("User profile | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { profile } = useSelector(profileSelector)

  const profileInfo: ProfileInfo | null = useMemo(() => {
    if (profile) {
      const profileData = { ...profile }
      delete profileData?.pictureUrl
      delete profileData?.id
      return {
        ...profileData,
        birthDate: new Date(profileData?.birthDate || ""),
        hireDate: new Date(profileData?.hireDate || ""),
      }
    }
    return null
  }, [profile])

  useEffect(() => {
    if (!profile) {
      dispatch(fetchProfileAction())
    }
  }, [])

  return (
    <>
      <h2 className="content-block">{t("profile")}</h2>

      <div className="content-block dx-card responsive-paddings">
        <div className="form-avatar">
          <img alt="" src={`${profile?.pictureUrl}`} />
        </div>
        <span>{profile?.notes}</span>
      </div>

      {profileInfo ? (
        <div className="content-block dx-card responsive-paddings">
          <Form
            id="profile-form"
            formData={profileInfo}
            onFieldDataChanged={(e: FieldDataChangedEvent) => {
              dispatch(updateProfile({ field: e.dataField || "", value: e.value || "" }))
            }}
            labelLocation="top"
            colCountByScreen={{
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export default Profile
