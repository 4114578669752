import { get } from "lodash"
import { UserRole } from "redux/profile/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, identifyPermissions } from "utils/common-utils"
import { currencyConverter } from "../scopeTableUtils"

export const onEditItemTableCellPrepared = (e: any) => {
  const arr = ["CODE", "DESCRIPTION", "UNIT_DESCRIPTION", "PROJECT_ITEM_RATE", "RATE"]
  if (e.rowType === "data" && arr.includes(e.column.dataField)) {
    e.cellElement.classList.add("bg-grey")
  }
}

export const tableUpdateKeys = [
  "PROJECT_ITEM_CARB_A1_A3_FACTOR",
  "PROJECT_ITEM_CARB_A4_FACTOR",
  "PROJECT_ITEM_CARB_A5A_FACTOR",
  "PROJECT_ITEM_CARB_A5W_FACTOR",
  "PROJECT_ITEM_CARB_WASTE_FACTOR",
  "PROJECT_ITEM_COST",
  "PROJECT_ITEM_ID",
  "PROJECT_ITEM_QTY",
]

const permsnCheck = (userRole: UserRole | null) => {
  return identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles)
}

export const renderEditItemTableRateCell = (item: any, userRole: UserRole | null) => {
  const val =
    !!get(item, "data.PROJECT_ITEM_COST") &&
    !!get(item, "data.PROJECT_ITEM_QTY") &&
    permsnCheck(userRole)
      ? currencyConverter(
          Number(get(item, "data.PROJECT_ITEM_COST") / get(item, "data.PROJECT_ITEM_QTY"))
        )
      : null
  return val
}

export const renderEditItemTableCostCell = (item: any, userRole: UserRole | null) => {
  return get(item, "data.PROJECT_ITEM_COST") && permsnCheck(userRole)
    ? currencyConverter(Number(get(item, "data.PROJECT_ITEM_COST")))
    : null
}
