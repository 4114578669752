import { TFunction } from "i18next"
import { formatNumber } from "devextreme/localization"
import { UserRole } from "redux/profile/types"
import { IProject } from "redux/projects/types"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { allRoles, identifyPermissions } from "utils/common-utils"
import { config } from "utils/config"

export const sectionsBreadCrum = (
  t: TFunction<"translation", undefined>,
  project: IProject | undefined
) => [
  {
    name: t("sections.estimate"),
    link: "/projects",
    title: project?.Title,
  },
]

export const summaryChipsGenerator = (
  t: TFunction<"translation", undefined>,
  project: IProject | undefined,
  sectionColumnWiseTotal: any,
  userRole: UserRole | null,
  estimatorID: number | undefined
) => {
  const permsnCheck = identifyPermissions(
    userRole,
    AccessType.SHOW_COST_DATA,
    AccessLevel.YES,
    allRoles
  )
  const contractorCondition = userRole?.ESTIMATOR_ID === estimatorID
  const currencySignCheck = config.clientName === "HE"
  const currency = currencySignCheck ? "(£)" : "($)"
  const val = formatNumber(sectionColumnWiseTotal?.sectionTotalCost, {
    currency: currencySignCheck ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })

  return [
    {
      key: 1,
      label: t("projectDetails.estimateNo"),
      value: project?.QuoteNo,
    },
    {
      key: 2,
      label: `${t("projectDetails.totalCost")} ${currency}`, // Rendering (&#163;) will give (£)
      value:
        userRole?.ROLE_ID !== RoleType.CONTRACTOR
          ? permsnCheck && val
          : contractorCondition && permsnCheck && val,
    },
    {
      key: 3,
      label: t("projectDetails.schemeTitle"),
      value: project?.SchemaDesc,
      hide: config.clientName === "TFNSW",
    },
    {
      key: 4,
      label: t("projectDetails.totalCarbon"),
      value: formatNumber(sectionColumnWiseTotal?.sectionTotalCarbonCost, {
        precision: 2,
        type: "fixedPoint",
      }),
    },
  ]
}
