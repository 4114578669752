import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { estimateLevelSelector, reset } from "redux/estimate-level/estimateLevelSlice"
import { getEstimateLevelsAction, getProjectIDsAction } from "redux/estimate-level/middleware"
import useDocumentTitle from "hooks/useDocumentTitle"
import EstimateLevelLoader from "./components/estimateLevelLoaderScreen"
import EstimateLevelHeader from "./components/estimateLevelHeader"
import EstimateLevelTable from "./components/estimateLevelTable"
import { estimateLevelBreadCrumb } from "./utils"
import "./estimate-level.scss"

const EstimateLevel: React.FC = (): JSX.Element => {
  useDocumentTitle("Estimate Level | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { aggregateBy, estimateNumber } = useSelector(estimateLevelSelector)

  useEffect(() => {
    dispatch(reset())
    dispatch(getProjectIDsAction())
  }, [])

  useEffect(() => {
    const data = { aggregateBy: aggregateBy.name, projectID: estimateNumber }
    if (estimateNumber) dispatch(getEstimateLevelsAction(data))
  }, [estimateNumber, aggregateBy])

  return (
    <div className="estimateLevelMain">
      <EstimateLevelLoader />
      <BreadCrumb data={estimateLevelBreadCrumb(t)} />
      <EstimateLevelHeader />
      <EstimateLevelTable />
    </div>
  )
}

export default EstimateLevel
