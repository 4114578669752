import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { initial } from "lodash"
import TreeList, {
  AsyncRule,
  Column,
  Editing,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/tree-list"
import { Button } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import { useAppDispatch } from "redux/store"
import { sharedSelector } from "redux/shared/sharedSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { iftSelector, updateIFTScheduleData } from "redux/ift/iftSlice"
import {
  getIFTScheduleBaselineAction,
  getIFTScheduleRevisionAction,
  updateIFTScheduleAction,
} from "redux/ift/middleware"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { handleResetFilter } from "utils/common-utils"
import useDocumentTitle from "hooks/useDocumentTitle"
import ScheduleLoader from "./ScheduleLoader"
import {
  handleScheduleStartEndDateValidation,
  handleScheduleDateValidation,
  convertToAPIReqrdStructure,
  filterIFTScheduleData,
  onRowPrepared,
  renderEstimateEndCell,
} from "./constants"
import "./schedule-style.scss"

const Schedule: React.FC = (): JSX.Element => {
  useDocumentTitle("IFT Schedule | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { project } = useSelector(projectSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { projectId, estimateId, iftScheduleBaselineData, iftScheduleRevisionData } =
    useSelector(iftSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)

  const treeListRef = useRef<TreeList>(null)
  const [changes, setChanges] = useState<any[]>([])
  const [changesHistory, setChangesHistory] = useState<any[]>([])

  useEffect(() => {
    if (!iftScheduleBaselineData.length && projectId) {
      dispatch(getIFTScheduleBaselineAction(projectId))
      if (estimateId) dispatch(getIFTScheduleRevisionAction(estimateId))
    }
  }, [projectId])

  const tableData = useMemo(
    () =>
      filterIFTScheduleData({
        baselineDataSource: iftScheduleBaselineData,
        revisionDataSource: iftScheduleRevisionData,
        estimateId,
        projectId,
      }),
    [iftScheduleBaselineData, iftScheduleRevisionData]
  )

  const tableBranchCheck = useMemo(() => tableData.some((item) => item.Parent_ID), [tableData])

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => tableData,
        key: "StoreId",
        update: async (a, b) => {
          const found = tableData.find((item) => item.StoreId === a)
          const newRecord = { ...found, ...b }
          dispatch(updateIFTScheduleData(newRecord))

          return Promise.resolve().then(() => {
            setChanges([])
            setChangesHistory([])
          })
        },
      }),
    [tableData]
  )

  const onChangesChange = useCallback((newChanges: any) => {
    const data = {
      changesOnPageNo: treeListRef.current?.instance.pageIndex(),
      changes: newChanges,
    }
    setChanges(newChanges)
    setChangesHistory((prev) => {
      return [...prev, data]
    })
  }, [])

  const getLastChanges = useCallback(() => {
    return changesHistory[changesHistory.length - 2] || { changesOnPageNo: 0, changes: [] }
  }, [changesHistory])

  const undoPrevChange = useCallback(() => {
    const newChangeHistory = initial(changesHistory)
    setChangesHistory(newChangeHistory)

    const lastChanges = getLastChanges()
    treeListRef.current?.instance.pageIndex(lastChanges.changesOnPageNo)
    setChanges(lastChanges.changes)
  }, [changes, changesHistory])

  const handleSaveClick = () => treeListRef.current?.instance.saveEditData()

  const handleRevertAll = useCallback(() => {
    treeListRef.current?.instance.cancelEditData()
    setChanges([])
    setChangesHistory([])
  }, [])

  const onSaving = (e: { changes: any }) => {
    const updatedValues = e.changes.map((item: { key: any; data: any }) => {
      const found = tableData.find((ta) => ta.StoreId === item.key)
      return {
        ...found,
        ...item.data,
      }
    })
    const processedData = convertToAPIReqrdStructure(updatedValues)

    dispatch(updateIFTScheduleAction(processedData))
  }

  return (
    <>
      <ScheduleLoader />
      <TreeList
        id="iftScheduleItems"
        className="ift-schedule-tree"
        ref={treeListRef}
        dataSource={dataStore}
        showBorders
        showRowLines
        columnAutoWidth
        showColumnLines
        wordWrapEnabled
        repaintChangesOnly
        allowColumnResizing
        rowAlternationEnabled
        keyExpr="StoreId"
        filterMode="matchOnly"
        parentIdExpr="Parent_ID"
        errorRowEnabled={false}
        height={windowHeight - 188}
        onSaving={onSaving}
        onRowPrepared={onRowPrepared}
      >
        <FilterRow visible />
        <SearchPanel visible width={340} />
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Editing allowUpdating mode="batch" changes={changes} onChangesChange={onChangesChange} />
        <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar>
          <Item location="after">
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => handleResetFilter(treeListRef)}
              />
            </span>
          </Item>
          <Item location="after" disabled={!changes.length} visible={!!estimateId}>
            <span title={t("toolbarActions.undoAll")}>
              <Button icon="close" stylingMode="text" onClick={() => handleRevertAll()} />
            </span>
          </Item>
          <Item location="after" disabled={!changes.length} visible={!!estimateId}>
            <span title={t("toolbarActions.undoPrev")}>
              <Button icon="undo" stylingMode="text" onClick={undoPrevChange} />
            </span>
          </Item>
          <Item location="after" disabled={!changes.length} visible={!!estimateId}>
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Column visible={tableBranchCheck} />
        <Column
          caption={t("ift.stage")}
          dataField="EstimateStage"
          width={300}
          allowEditing={false}
          sortOrder="asc"
        />
        <Column caption={`${t("ift.baseline")}: ${project?.Title}`} alignment="center">
          <Column
            caption={t("ift.startDate")}
            dataField="BaselineMostLikelyStartDateValue"
            alignment="center"
            allowEditing={false}
            dataType="date"
            format="dd/MM/yyyy"
            cssClass="has-data-picker"
          />
          <Column
            caption={t("ift.endDate")}
            dataField="BaselineMostLikelyEndDateValue"
            alignment="center"
            allowEditing={false}
            dataType="date"
            format="dd/MM/yyyy"
            cssClass="has-data-picker"
          />
        </Column>
        <Column
          caption={`${t("ift.revision")} ${selectedRevision?.Title}`}
          alignment="center"
          visible={!!estimateId}
        >
          <Column
            caption={t("ift.startDate")}
            dataField="MostLikelyStartDateValue"
            alignment="center"
            dataType="date"
            format="dd/MM/yyyy"
            cssClass="has-data-picker"
          >
            <AsyncRule
              ignoreEmptyValue
              validationCallback={handleScheduleDateValidation}
              message={t("ift.iftScheduleDateErr")}
            />
            <AsyncRule
              ignoreEmptyValue
              validationCallback={handleScheduleStartEndDateValidation}
              message={t("ift.scheduleStartDateValidation")}
            />
          </Column>
          <Column
            caption={t("ift.endDate")}
            dataField="MostLikelyEndDateValue"
            alignment="center"
            dataType="date"
            format="dd/MM/yyyy"
            cssClass="has-data-picker"
            cellRender={renderEstimateEndCell}
          >
            <AsyncRule
              ignoreEmptyValue
              validationCallback={handleScheduleDateValidation}
              message={t("ift.iftScheduleDateErr")}
            />
            <AsyncRule
              ignoreEmptyValue
              validationCallback={handleScheduleStartEndDateValidation}
              message={t("ift.scheduleEndDateValidation")}
            />
          </Column>
        </Column>
      </TreeList>
    </>
  )
}

export default memo(Schedule)
