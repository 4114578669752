import { useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import TreeList, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  Toolbar,
} from "devextreme-react/tree-list"
import { Button } from "devextreme-react"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { comparatorSelector, setEstimatorTotalsPopUp } from "redux/comparator/iftComparatorSlice"
import { allowedPageSizes, defaultPageSize, config } from "utils/config"
import {
  allRoles,
  renderCurrencyCell,
  identifyPermissions,
  numberFormatterConfig,
} from "utils/common-utils"
import {
  ExportToFile,
  compCostVarCalCellVal,
  compCarbVarCalCellVal,
  comparatorEditorOptions,
  onComparatorCellPrepared,
  comparatorCellDescriptionRender,
} from "./tableConstants"
import NominatedRevision from "../nominatedRevision"

function IFTComparatorLevelTable() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { comprator, revisionNumbers, compareByKeys, showEstimateTotalsPopUp } =
    useSelector(comparatorSelector)

  const treeListRef = useRef<TreeList>(null)
  const [popupVisible, setPopupVisible] = useState(false)

  const nmintePrefRevPermsn = useMemo(
    () =>
      config.clientName === "HE" &&
      identifyPermissions(userRole, -1, AccessLevel.FULL_ACCESS, [
        RoleType.ADMINISTRATORS,
        RoleType.AUTHORITY_ESTIMATOR,
      ]),
    [userRole]
  )
  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  return (
    <>
      <NominatedRevision popupVisible={popupVisible} onClose={() => setPopupVisible(false)} />
      <TreeList
        id="estimateLevelItems"
        className="compact-grid compact-grid-tree"
        ref={treeListRef}
        dataSource={comprator}
        showBorders
        showRowLines
        columnAutoWidth
        showColumnLines
        rowAlternationEnabled
        allowColumnResizing
        keyExpr="id"
        filterMode="matchOnly"
        parentIdExpr="parentID"
        errorRowEnabled={false}
        height={windowHeight - 232}
        rootValue={-1}
        onCellPrepared={onComparatorCellPrepared}
        headerFilter={{ visible: true }}
      >
        <FilterRow visible />
        {/* <SearchPanel visible width={340} /> */}
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar visible>
          <Item location="before">
            <Button
              stylingMode="contained"
              className="active"
              text={t("iftComparatorMain.estimateTotals")}
              onClick={() => {
                if (revisionNumbers.length)
                  dispatch(setEstimatorTotalsPopUp(!showEstimateTotalsPopUp))
              }}
            />
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.exportCSV")}>
              <Button
                icon="export"
                stylingMode="text"
                onClick={() => ExportToFile(treeListRef, userRole)}
              />
            </span>
          </Item>
          <Item location="after" visible={nmintePrefRevPermsn}>
            <span title={t("iftComparatorMain.nominatePreferredRevision")}>
              <Button
                stylingMode="text"
                text={t("iftComparatorMain.nominatePreferredRevision")}
                onClick={() => setPopupVisible(true)}
              />
            </span>
          </Item>
        </Toolbar>
        <Column caption={t("iftComparatorMain.baseline")} alignment="center">
          <Column allowSorting={false} />
          <Column dataField="code" caption={t("iftComparatorMain.code")} dataType="string" />
          <Column
            dataField="description"
            caption={t("iftComparatorMain.desc")}
            cellRender={comparatorCellDescriptionRender}
          />
          <Column
            dataField="V0_COST"
            caption={t("iftComparatorMain.totalCost")}
            editorOptions={comparatorEditorOptions}
            dataType="number"
            cellRender={(row) => (permsnCheck ? renderCurrencyCell(row.data.V0_COST || 0) : null)}
            format={numberFormatterConfig}
            allowFiltering={false}
          />
          <Column
            dataField="totalA1A5"
            caption={t("iftComparatorMain.totalA1A5")}
            editorOptions={comparatorEditorOptions}
            format={numberFormatterConfig}
            allowFiltering={false}
          />
        </Column>
        {compareByKeys.includes("Cost Variance") && (
          <Column caption={t("iftComparatorMain.baseCost")} alignment="center">
            {revisionNumbers.map((rev, index) => (
              <Column
                key={`V${index + 1}_VARIANCE_COST`}
                dataField={`V${index + 1}_VARIANCE_COST`}
                caption={`V${index + 1}`}
                dataType="number"
                cellRender={(row) => compCostVarCalCellVal(row, index)}
                editorOptions={comparatorEditorOptions}
                format={numberFormatterConfig}
                allowFiltering={false}
              />
            ))}
          </Column>
        )}
        {compareByKeys.includes("Carbon Variance") && (
          <Column caption={t("iftComparatorMain.carbon")} alignment="center">
            {revisionNumbers.map((rev, index) => (
              <Column
                key={`V${index + 1}_VARIANCE_CARBON_COST`}
                dataField={`V${index + 1}_VARIANCE_CARBON_COST`}
                caption={`V${index + 1}`}
                dataType="number"
                calculateCellValue={(row) => compCarbVarCalCellVal(row, index)}
                editorOptions={comparatorEditorOptions}
                format={numberFormatterConfig}
                allowFiltering={false}
              />
            ))}
          </Column>
        )}
      </TreeList>
    </>
  )
}

export default IFTComparatorLevelTable
