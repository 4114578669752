import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { getDynamicCarbonLibraryAction } from "redux/masters/middleware"
import { dynamicCarbonSelector, reset } from "redux/masters/dynamicCarbonLibrarySlice"
import useDocumentTitle from "hooks/useDocumentTitle"
import BreadCrumb from "components/bread-crumb"
import ViewEPDPopUp from "./components/viewEPDPopUp"
import DynamicCarbonLoader from "./components/dynamicCarbonLoader"
import DynamicCarbonTable from "./components/dynamicCarbonTable"
import { dynamicCarbonBreadCrumb } from "./utils"
import "./dynamic-carbon.scss"

const DynamicCarbonLibrary: React.FC = (): JSX.Element => {
  useDocumentTitle("Dynamic Carbon Library | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { showEPDPopUp } = useSelector(dynamicCarbonSelector)

  useEffect(() => {
    dispatch(reset())
    dispatch(getDynamicCarbonLibraryAction())
  }, [])

  return (
    <div className="dynamicCarbonMain">
      <DynamicCarbonLoader />
      <BreadCrumb data={dynamicCarbonBreadCrumb(t)} />
      <br />
      <DynamicCarbonTable />
      {showEPDPopUp && <ViewEPDPopUp />}
    </div>
  )
}

export default DynamicCarbonLibrary
