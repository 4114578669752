/* eslint-disable import/prefer-default-export */
import { TFunction } from "i18next"

export const powerBIBreadCrumb = (t: TFunction<"translation", undefined>) => {
  return [
    {
      name: t("menu.powerBi"),
    },
  ]
}
