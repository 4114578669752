/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { useSelector } from "react-redux"
import { get } from "lodash"
import notify from "devextreme/ui/notify"
import Accordion, { Item } from "devextreme-react/accordion"
import { Popup, Button, NumberBox, ScrollView } from "devextreme-react"
import { zeroNullCarbCnfrmDialog } from "screens/project-item/utils"
import { IBOQItem, IItem } from "redux/projects/types"
import { projectSelector } from "redux/projects/projectSlice"
import {
  updateItemsAction,
  updateResourcesAction,
  getResourceDetailsAction,
} from "redux/projects/middleware"
import { config } from "utils/config"

interface IResourceUpdate {
  showEditRecordPopup: boolean
  setShowEditRecordPopup: (setVisible: boolean) => void
  rowData?: IBOQItem
  updateResourceCallback: () => void
}

const UpdateResourcePopup: React.FC<IResourceUpdate> = ({
  showEditRecordPopup,
  setShowEditRecordPopup,
  rowData,
  updateResourceCallback,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [quantity, setQuantity] = useState(rowData?.QUANTITY)
  const [item, setItem] = useState<any>({})

  const { selectedBOQResourceForUpdate } = useSelector(projectSelector)

  const SubItemCheck = useMemo(() => get(rowData, "SUBITEM") === "Y", [rowData])
  const resourceCheck = useMemo(() => !!get(rowData, "PROJECT_RESOURCE_ID"), [rowData])
  const clientCheck = useMemo(() => config.clientName === "HE", [config])

  const title = useMemo(
    () => t(!resourceCheck ? "boq.updateSubItemQty" : "boq.resourceDetails"),
    [resourceCheck]
  )

  useEffect(() => {
    if (rowData?.PROJECT_RESOURCE_ID && showEditRecordPopup) {
      dispatch(getResourceDetailsAction(rowData?.PROJECT_RESOURCE_ID))
    }
  }, [rowData, showEditRecordPopup])

  useEffect(() => {
    if (showEditRecordPopup) {
      setQuantity(rowData?.QUANTITY)
      setItem({
        PROJECT_RESOURCE_CARB_A1_A3_FACTOR:
          get(selectedBOQResourceForUpdate, "PROJECT_RESOURCE_CARB_A1_A3_FACTOR") || 0,
        PROJECT_RESOURCE_CARB_A4_FACTOR:
          get(selectedBOQResourceForUpdate, "PROJECT_RESOURCE_CARB_A4_FACTOR") || 0,
        PROJECT_RESOURCE_CARB_A5A_FACTOR:
          get(selectedBOQResourceForUpdate, "PROJECT_RESOURCE_CARB_A5A_FACTOR") || 0,
        PROJECT_RES_CARB_WASTEFACTOR:
          get(selectedBOQResourceForUpdate, "PROJECT_RES_CARB_WASTEFACTOR") || 0,
      })
    }
  }, [rowData, selectedBOQResourceForUpdate, showEditRecordPopup])

  const onClose = () => {
    setShowEditRecordPopup(!showEditRecordPopup)
    setQuantity(undefined)
  }

  const updateResource = async () => {
    const null0Check = clientCheck ? Object.values(item).some((item) => !item) : false
    const SubItemUpdateMsg = t("boq.SubItemItemUpdateMsg")
    const cost = quantity && rowData?.RATE ? quantity * rowData.RATE : rowData?.COST
    const subItemApiData = {
      ...rowData,
      PROJECT_ITEM_QTY: quantity,
      PROJECT_ITEM_COST: cost,
    } as IItem

    const updateResourceFn = () => {
      const resourceData = clientCheck
        ? { PROJECT_RESOURCE_ID: rowData?.ID, PROJECT_RESOURCE_QTY: quantity || 0, ...item }
        : { PROJECT_RESOURCE_ID: rowData?.ID, PROJECT_RESOURCE_QTY: quantity || 0 }
      dispatch(updateResourcesAction([resourceData])).then(() => {
        updateResourceCallback()
        onClose()
      })
    }

    await (SubItemCheck && !resourceCheck
      ? dispatch(updateItemsAction([subItemApiData])).then(() => {
          if (SubItemCheck && !resourceCheck) notify(SubItemUpdateMsg, "success", 4000)
          updateResourceCallback()
          onClose()
        })
      : null0Check
      ? zeroNullCarbCnfrmDialog(t)
          .show()
          .then((dialogResult: any) => {
            if (dialogResult) updateResourceFn()
          })
      : updateResourceFn())
  }

  const onQuantityChange = (value: any) => setQuantity(value)
  const onValueChanged = (key: string, value: any) => {
    const v: any = {
      ...item,
      [key]: value,
    }
    setItem(v)
  }

  return (
    <Popup
      title={title}
      visible={showEditRecordPopup}
      hideOnOutsideClick
      width={!(resourceCheck && clientCheck) ? 420 : 620}
      maxHeight={!(resourceCheck && clientCheck) ? 270 : 560}
      showCloseButton
      enableBodyScroll
      onHiding={onClose}
      dragEnabled={false}
    >
      <ScrollView className="boq-update-resource-popup-wrapper">
        <div className="container">
          <div className="row p-3">
            <NumberBox
              label={t("boq.qty")}
              labelMode="floating"
              value={quantity}
              onValueChange={onQuantityChange}
            />
          </div>
          {resourceCheck && clientCheck && (
            <Accordion>
              <Item title={t("boq.pas2080A1A5")}>
                <NumberBox
                  label={t("boq.updatePopUpa1a3")}
                  labelMode="floating"
                  value={item.PROJECT_RESOURCE_CARB_A1_A3_FACTOR}
                  onValueChange={(value) =>
                    onValueChanged("PROJECT_RESOURCE_CARB_A1_A3_FACTOR", value)
                  }
                />
                <NumberBox
                  label={t("boq.updatePopUpa4")}
                  labelMode="floating"
                  value={item.PROJECT_RESOURCE_CARB_A4_FACTOR}
                  onValueChange={(value) =>
                    onValueChanged("PROJECT_RESOURCE_CARB_A4_FACTOR", value)
                  }
                />
                <NumberBox
                  label={t("boq.updatePopUpa5a")}
                  labelMode="floating"
                  value={item.PROJECT_RESOURCE_CARB_A5A_FACTOR}
                  onValueChange={(value) =>
                    onValueChanged("PROJECT_RESOURCE_CARB_A5A_FACTOR", value)
                  }
                />
                <NumberBox
                  label={t("boq.updatePopUpWasteFactor")}
                  labelMode="floating"
                  value={item.PROJECT_RES_CARB_WASTEFACTOR}
                  onValueChange={(value) => onValueChanged("PROJECT_RES_CARB_WASTEFACTOR", value)}
                  min={0}
                  max={100}
                />
              </Item>
              <Item title={t("boq.pas2080B1B8")}>{t("boq.comingSoon")}</Item>
              <Item title={t("boq.pas2080C1C4")}>{t("boq.comingSoon")}</Item>
            </Accordion>
          )}
          <div className="row p-3">
            <Button
              className="active"
              text={t("toolbarActions.save")}
              type="default"
              onClick={updateResource}
            />
          </div>
        </div>
      </ScrollView>
    </Popup>
  )
}

export default UpdateResourcePopup
