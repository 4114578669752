/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import notify from "devextreme/ui/notify"
import { LoadingState } from "types/AppNav"
import { ProfileInfo, UserRole } from "./types"
import { setLoading } from "./profileSlice"
import { fetchUserRolePermissionAsync } from "./services"

export const fetchProfileAction = createAsyncThunk<ProfileInfo>(
  "fetchProfileAction",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      // const response: ProfileInfo | ErrorResponse = await fetchProfileAsync()
      const response: ProfileInfo | ErrorResponse = {
        id: 7,
        firstName: "Sandra",
        lastName: "Johnson",
        prefix: "Mrs.",
        position: "Controller",
        pictureUrl:
          "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
        birthDate: "1974-11-05",
        hireDate: "2005-05-11",
        notes:
          "Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.",
        address: "4600 N Virginia Rd.",
      }

      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response as ProfileInfo
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchUserRoleAndPermissionsAction = createAsyncThunk<UserRole>(
  "profile/fetchUserRole",
  async (_, { rejectWithValue }) => {
    const response: SuccessResponse<UserRole> | ErrorResponse = await fetchUserRolePermissionAsync()
    const errRes = response as ErrorResponse
    if (errRes.code) {
      return rejectWithValue(errRes.message)
    }

    return (response as SuccessResponse<UserRole>).data as UserRole
  }
)
