import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  DynamicCarbonSingleData,
  DynamicCarbonState,
  UpdateDynamicCarbonLibraryRequest,
} from "./types"
import { getDynamicCarbonLibraryAction, updateDynamicCarbonLibraryAction } from "./middleware"

const initialState: DynamicCarbonState = {
  loading: LoadingState.DEFAULT,
  dynamicCarbonData: [],
  editedDynamicCarbonData: [],
  selectedDynamicCarbon: {},
  showEPDPopUp: false,
}

const dynamicCarbonLibrarySlice = createSlice({
  name: "DynamicCarbonLibrary",
  initialState,
  reducers: {
    setLoading: (state: DynamicCarbonState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setEditedDynamicCarbonData: (
      state: DynamicCarbonState,
      { payload }: PayloadAction<UpdateDynamicCarbonLibraryRequest>
    ) => {
      const { editedDynamicCarbonData, dynamicCarbonData } = state
      const editData = editedDynamicCarbonData.filter(
        (item) =>
          item.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID !==
          payload.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID
      )

      const existingValCompared =
        dynamicCarbonData.find(
          (item) =>
            item.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID ===
            payload.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID
        )?.IS_ASSURED === payload.IS_ASSURED

      const finalEditData = existingValCompared ? editData : [...editData, payload]

      return { ...state, editedDynamicCarbonData: finalEditData }
    },
    setSelectedDynamicCarbon: (
      state: DynamicCarbonState,
      { payload }: PayloadAction<DynamicCarbonSingleData>
    ) => ({
      ...state,
      selectedDynamicCarbon: payload,
    }),
    setShowEPDPopUp: (state: DynamicCarbonState) => ({
      ...state,
      showEPDPopUp: !state.showEPDPopUp,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDynamicCarbonLibraryAction.pending, (state: DynamicCarbonState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getDynamicCarbonLibraryAction.fulfilled,
      (state: DynamicCarbonState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        dynamicCarbonData: payload,
      })
    )
    builder.addCase(getDynamicCarbonLibraryAction.rejected, (state: DynamicCarbonState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      updateDynamicCarbonLibraryAction.fulfilled,
      (state: DynamicCarbonState, { payload }: PayloadAction<any>) => {
        const { dynamicCarbonData } = state
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          dynamicCarbonData: dynamicCarbonData.map((item) => {
            const found = payload.find(
              (newItem: { CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID: number }) =>
                newItem.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID ===
                item.CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID
            )
            return {
              ...item,
              IS_ASSURED: found ? found.IS_ASSURED : item.IS_ASSURED,
            }
          }),
          editedDynamicCarbonData: [],
        }
      }
    )
  },
})

export const {
  setLoading,
  setEditedDynamicCarbonData,
  setSelectedDynamicCarbon,
  setShowEPDPopUp,
  reset,
} = dynamicCarbonLibrarySlice.actions

export const dynamicCarbonSelector = (state: RootState) => state.DynamicCarbonLibrary

export default dynamicCarbonLibrarySlice.reducer
