import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { ErrorResponse } from "services/SuccessResponse"
import {
  deleteEstimateAsync,
  getEstimateContractors,
  postRevisionContractors,
  putRevisionDetailsAsync,
  getEstimateRevisionAsync,
  getEstimateRevisionsAsync,
  postEstimateRevisionsAsync,
} from "./services"
import {
  ContractorsList,
  EstimateRevisionAPIData,
  GetEstimateContractorsAPIData,
  UpdateContractorsAPIData,
} from "./types"
import { revisionResponseTransformer } from "./utils"

export const getEstimateRevisionsAction = createAsyncThunk<any, EstimateRevisionAPIData>(
  "getEstimateRevisionsAction",
  async (request: EstimateRevisionAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateRevisionsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return revisionResponseTransformer(response.data)
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getEstimateRevisionByIdAction = createAsyncThunk<any, EstimateRevisionAPIData>(
  "getEstimateRevisionByIdAction",
  async (request: EstimateRevisionAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateRevisionAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return revisionResponseTransformer(response.data)
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const postEstimateRevisionsAction = createAsyncThunk<any, EstimateRevisionAPIData>(
  "postEstimateRevisionsAction",
  async (request: EstimateRevisionAPIData, { rejectWithValue }) => {
    try {
      notify("Creating a new revision in background", "info", 2000)
      const response: any | ErrorResponse = await postEstimateRevisionsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("New Revision added successfully.", "success", 2000)
      return revisionResponseTransformer(response.data)
    } catch (error: unknown) {
      notify("Failed to add revision.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)

export const putRevisionDetailsAction = createAsyncThunk<
  any,
  { projectId: number; PROJECT_TITLE: string }
>(
  "putRevisionDetailsAsync",
  async (request: { projectId: number; PROJECT_TITLE: string }, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await putRevisionDetailsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Revision Details updated successfully.", "success", 2000)

      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const deleteEstimateAction = createAsyncThunk<any, number>(
  "deleteEstimateAsync",
  async (request: number, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await deleteEstimateAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Estimate Revision deleted successfully.", "success", 2000)

      return request
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getEstimateContractorsAction = createAsyncThunk<any, GetEstimateContractorsAPIData>(
  "getEstimateContractorsAction",
  async (request: GetEstimateContractorsAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateContractors(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data as ContractorsList
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateEstimateContractorsAction = createAsyncThunk<any, UpdateContractorsAPIData>(
  "updateEstimateContractorsAction",
  async (request: UpdateContractorsAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await postRevisionContractors(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Contractors updated successfully.", "success", 2000)
      return response.data as ContractorsList
    } catch (error: unknown) {
      notify("Contractors update failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)
