import { memo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import DataGrid, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import { iftSelector } from "redux/ift/iftSlice"
import { sharedSelector } from "redux/shared/sharedSlice"
import { handleResetFilter, renderCurrencyCell } from "utils/common-utils"
import { keyDataCurrencySign, keyDataTableHeight } from "../utils"

const RangeEstimateDistTable = () => {
  const { t } = useTranslation()

  const { windowHeight } = useSelector(sharedSelector)
  const { iftKeyDataRangeTableData } = useSelector(iftSelector)

  const dataGridRef = useRef<DataGrid>(null)

  return (
    <DataGrid
      id="gridContainer"
      ref={dataGridRef}
      dataSource={iftKeyDataRangeTableData}
      allowColumnResizing
      columnAutoWidth
      showBorders
      showColumnLines
      height={keyDataTableHeight(windowHeight)}
    >
      <Pager showPageSizeSelector showInfo visible />
      <Scrolling columnRenderingMode="virtual" />
      <SearchPanel visible width={340} />
      <Paging defaultPageSize={10} />
      <FilterRow visible />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column caption={t("keyData.rangeEstDist")} dataField="PVALUE_DESCRIPTION" />
      <Column
        caption={t("keyData.incPortRisk", { 0: keyDataCurrencySign })}
        dataField="INCLUDING_PORTFOLIO_RISK"
        cellRender={(row: any) => renderCurrencyCell(row.data.INCLUDING_PORTFOLIO_RISK)}
      />
      <Column
        caption={t("keyData.excPortRisk", { 0: keyDataCurrencySign })}
        dataField="EXCLUDING_PORTFOLIO_RISK"
        cellRender={(row: any) => renderCurrencyCell(row.data.EXCLUDING_PORTFOLIO_RISK)}
      />
    </DataGrid>
  )
}

export default memo(RangeEstimateDistTable)
