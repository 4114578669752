import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import key from "weak-key"
import { CheckBox, DropDownBox, List, ScrollView, SelectBox } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import {
  comparatorSelector,
  setEstimateNumber,
  setEstimateRevisionNumber,
  setCompareCompByKeys,
  resetComparatorData,
} from "redux/comparator/iftComparatorSlice"
import { getAllEstimateRevisionsAction, getCompDataAction } from "redux/comparator/middleware"

const IFTComparatorLevelHeader: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectIDs, estimateNumber, estimateRevisions, revisionNumbers, compareCompByKeys } =
    useSelector(comparatorSelector)
  const [quoteNos, setQuoteNos] = useState<Array<string>>([])
  const dropDownBoxRef: any = useRef(null)
  const COMPARE_BY = ["Inflation", "Risk Cost"]

  const onSelectionChange = async ({ selectedItem }: any) => {
    await dispatch(setEstimateRevisionNumber([]))
    await dispatch(setEstimateNumber(selectedItem))
    setQuoteNos([])
    await dispatch(
      getAllEstimateRevisionsAction({ projectId: selectedItem?.PROJECT_ID?.toString() })
    )
  }

  const onCompareByOptionChange = ({ addedItems, removedItems }: any) => {
    if (addedItems && addedItems.length > 0) {
      dispatch(setCompareCompByKeys([...compareCompByKeys, ...addedItems]))
    }
    if (removedItems && removedItems.length > 0) {
      const remainingItems = compareCompByKeys.filter((item) => !removedItems.includes(item))
      dispatch(setCompareCompByKeys(remainingItems))
    }
  }

  const onValueChanged = async (e: any, revision: any) => {
    let revisionNum = []
    if (e.value === false) {
      revisionNum = revisionNumbers.filter((item) => item !== revision.Id)
      const newQuoteNos = quoteNos.filter((qt) => qt !== revision?.QuoteNo)
      setQuoteNos(newQuoteNos)
      if (!newQuoteNos.length) dispatch(resetComparatorData())
    } else {
      revisionNum = [...revisionNumbers, revision?.Id]
      setQuoteNos([...quoteNos, revision?.QuoteNo])
    }
    await dispatch(setEstimateRevisionNumber(revisionNum))
    await dispatch(
      getCompDataAction({ baseEestimateId: estimateNumber, estimateRevisionIds: revisionNum })
    )
  }

  const renderDropdown = () => {
    return (
      <ScrollView>
        <div>
          {estimateRevisions.map((rev) => (
            <div key={key(rev)} className="m-4 d-flex align-items-center justify-content-between">
              <CheckBox
                text={rev.QuoteNo}
                hint={rev.QuoteNo}
                onValueChanged={(e) => onValueChanged(e, rev)}
                disabled={
                  !rev.IsCessGenerated ||
                  (revisionNumbers.length === 3 && !revisionNumbers.includes(rev.Id))
                }
              />
              {!rev.IsCessGenerated && (
                <i
                  className="dx-icon-info"
                  title={t("iftComparatorMain.reportNotGen")}
                  style={{ color: "#636363" }}
                />
              )}
            </div>
          ))}
        </div>
      </ScrollView>
    )
  }

  return (
    <div className="estimateLevelHeader dx-card">
      <div className="dropdown">
        <label>{t("iftCostComparatorMain.baseEstimate")}</label>
        <SelectBox
          dataSource={projectIDs}
          displayExpr="PROJECT_QUOTE_NO"
          valueExpr="PROJECT_ID"
          value={estimateNumber}
          searchEnabled
          onSelectionChanged={onSelectionChange}
        />
      </div>
      <div className="dropdown">
        <label>{t("iftComparatorMain.revisions")}</label>
        <DropDownBox
          placeholder="Select Revisions"
          label={quoteNos.toString()}
          ref={dropDownBoxRef}
          dataSource={estimateRevisions}
          dropDownOptions={{ showCloseButton: true, maxHeight: 200, enableBodyScroll: true }}
          contentRender={renderDropdown}
          labelMode="floating"
        />
      </div>
      <div className="dropdown">
        <label>Compare By</label>
        <DropDownBox
          placeholder="Compare By"
          label={compareCompByKeys.toString()}
          value={compareCompByKeys}
          ref={dropDownBoxRef}
          dataSource={COMPARE_BY}
          dropDownOptions={{ showCloseButton: true, maxHeight: 200, enableBodyScroll: true }}
          labelMode="floating"
        >
          <List
            dataSource={COMPARE_BY}
            selectedItemKeys={compareCompByKeys}
            selectionMode="multiple"
            onSelectionChanged={onCompareByOptionChange}
            showSelectionControls
          />
        </DropDownBox>
      </div>
    </div>
  )
}

export default IFTComparatorLevelHeader
