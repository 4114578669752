import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { getAllProjectsAction } from "redux/comparator/middleware"
import useDocumentTitle from "hooks/useDocumentTitle"
import { comparatorSelector } from "redux/comparator/iftComparatorSlice"
import EstimateTotalsPopUp from "./components/estimateTotalsPopUp"
import IFTComparatorLevelHeader from "./components/iftComparatorHeader"
import IFTComparatorLevelLoader from "./components/iftComparatorLoaderScreen"
import IFTComparatorLevelTable from "./components/iftCompratorTable"
import { iftLevelBreadCrumb } from "./utils"
import "./ift-comparator.scss"

const IFTComprartor: React.FC = (): JSX.Element => {
  useDocumentTitle("Comparator | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { showEstimateTotalsPopUp } = useSelector(comparatorSelector)

  useEffect(() => {
    dispatch(getAllProjectsAction())
  }, [])

  return (
    <div className="iftLevelMain">
      <IFTComparatorLevelLoader />
      <BreadCrumb data={iftLevelBreadCrumb(t)} />
      <IFTComparatorLevelHeader />
      <IFTComparatorLevelTable />
      {showEstimateTotalsPopUp && <EstimateTotalsPopUp />}
    </div>
  )
}

export default IFTComprartor
