import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import useDocumentTitle from "hooks/useDocumentTitle"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import {
  getExpenditureProfileProjectByIdAction,
  getExpenditureProfileProjectDataAction,
  getExpenditureProfileSummaryTableAction,
} from "redux/expenditure-profile/middleware"
import ExpenditureProfileReportHead from "./components/expenditureProfileReportHead"
import ExpenditureProfileReportTable from "./components/expenditureProfileReportTable"
import ExpenditureProfileSummaryPopUp from "./components/ExpenditureProfileSummaryPopUp"
import ExpenditureProfileLoader from "../expenditure-profile/components/expenditureProfileLoader"
import { expenditureProfileReportBreadCrumb } from "./utils"
import "./expenditure-profile-report.scss"

const ExpenditureProfileReport: React.FC = (): JSX.Element => {
  useDocumentTitle(
    "Estimated Cost and Carbon Expenditure Profile | Cost and Carbon Forecasting tool"
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { estimateId } = useParams()

  const { showSummaryPopUp, selectedExpenditureProjectData } = useSelector(
    expenditureProfileSelector
  )

  useEffect(() => {
    if (!selectedExpenditureProjectData) {
      dispatch(getExpenditureProfileProjectByIdAction(estimateId))
      dispatch(getExpenditureProfileProjectDataAction(estimateId))
      dispatch(getExpenditureProfileSummaryTableAction(estimateId))
    }
  }, [])

  return (
    <div className="ExpenditureProfileReport">
      <ExpenditureProfileLoader />
      <BreadCrumb data={expenditureProfileReportBreadCrumb(t)} />
      <ExpenditureProfileReportHead />
      {showSummaryPopUp && <ExpenditureProfileSummaryPopUp />}
      <ExpenditureProfileReportTable />
    </div>
  )
}

export default ExpenditureProfileReport
