import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { ITransportAssumptions } from "./types"

export const getJourneyLegValuesAsync = async () => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<ITransportAssumptions[]> | ErrorResponse
    >(`/journey-leg`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const postJourneyLegValuesAsync = async (request: ITransportAssumptions[]) => {
  try {
    const response = await axiosInstance.post<
      SuccessResponse<ITransportAssumptions[]> | ErrorResponse
    >(`/journey-leg/bulk`, { bulk: request })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const putJourneyLegValuesAsync = async (request: ITransportAssumptions[]) => {
  try {
    const response = await axiosInstance.put<
      SuccessResponse<ITransportAssumptions[]> | ErrorResponse
    >(`/journey-leg/bulk`, { bulk: request })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteSingleJourneyLegValueAsync = async (id: string | number) => {
  try {
    const response = await axiosInstance.delete<string>(`/journey-leg/${id}`)
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteJourneyLegValuesAsync = async (id: number[]) => {
  try {
    const idsString = id.join()
    const response = await axiosInstance.delete<string>(`/journey-leg?ids=${idsString}`)
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}
