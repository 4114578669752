import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Popup, ScrollView } from "devextreme-react"
import {
  setSelectedRevision,
  clearRevisionContractors,
  setshowManageContractorsPopUp,
  estmteRvsnMgmntSelector,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { useAppDispatch } from "redux/store"
import ContractorsTable from "./contractorsTable"

const ManageContractorsPopUp = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)

  const onClose = () => {
    dispatch(setshowManageContractorsPopUp(false))
    dispatch(clearRevisionContractors())
    dispatch(setSelectedRevision())
  }

  return (
    <Popup
      visible
      showCloseButton
      width={800}
      maxHeight={670}
      onHiding={onClose}
      dragEnabled={false}
      title={`${t("estimateRevisionManagement.popUpHeading")} (${selectedRevision?.QuoteNo})`}
    >
      <ScrollView className="manageContractorPopUp">
        <div className="popUpSubHeading">
          <h5 className="role">{t("estimateRevisionManagement.role")}</h5>
          <p className="contractors">{t("estimateRevisionManagement.contractors")}</p>
        </div>
        <ContractorsTable />
      </ScrollView>
    </Popup>
  )
}

export default ManageContractorsPopUp
