import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { get } from "lodash"
import notify from "devextreme/ui/notify"
import { Button, ScrollView } from "devextreme-react"
import { formatNumber } from "devextreme/localization"
import { LoadPanel } from "devextreme-react/load-panel"
import { Popup, Position, ToolbarItem } from "devextreme-react/popup"
import {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Toolbar,
  TreeList,
} from "devextreme-react/tree-list"
import BreadCrum from "components/bread-crumb"
import {
  deleteBOQProjectItemAction,
  deleteBOQProjectResourceAction,
  getBOQItemsAndResourcesAction,
  getProjectByIdAction,
  getSectionByIdAction,
  recalculateCarbonAction,
} from "redux/projects/middleware"
import { useAppDispatch } from "redux/store"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { IBOQItem, ICarbonEmissionDoughnutChartData } from "redux/projects/types"
import { LoadingState } from "types/AppNav"
import { allRoles, identifyPermissions, numberFormatterConfig } from "utils/common-utils"
import { iftSelector } from "redux/ift/iftSlice"
import { ContextMenuPreparingEvent } from "devextreme/ui/tree_list"
import ResourceModal from "screens/ift/components/scope/ResourceModal"
import SubItemModal from "screens/ift/components/scope/SubItemModal"
import ItemModal from "screens/ift/components/scope/ItemModal"
// import ProvisionalSumModal from "screens/ift/components/scope/ProvisionalSumModal"
import {
  fetchItemGroupIFTScopeAction,
  fetchSubItemGroupIFTScopeAction,
  fetchResourceGroupIFTScopeAction,
} from "redux/ift/middleware"
import useDocumentTitle from "hooks/useDocumentTitle"
import { config } from "utils/config"
import UpdateItemPopup from "./components/update-item-popup"
import UpdateResourcePopup from "./components/update-resource-popup"
import BoqSummaryCard from "./components/boq-summary-card/boqSummaryCard"
import CarbonEmissionDoughnutChart from "./components/carbon-emission-doughnut-chart"
import CarbonEmissionStackedBarChart from "./components/carbon-emission-stacked-bar-chart"
import {
  ModalStateType,
  defaultModalState,
} from "../ift/components/scope/ScopeTable/scopeTableUtils"
import {
  boqBreadCrum,
  boqCellDescriptionRender,
  boqConfirmationDialog,
  getMultiInstance,
  onBOQRowPrepared,
} from "./utils"
import "./project-item.scss"

const ProjectItem: React.FC = () => {
  useDocumentTitle("BOQ | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { projectId, sectionId } = useParams()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { estimateId, addedIds } = useSelector(iftSelector)
  const {
    editedIds,
    project,
    section,
    isEditable,
    estimatorID,
    boqItems,
    loading,
    boqItemsColumnWiseTotal,
    topFiveCarbonBOQItems,
  } = useSelector(projectSelector)

  const treeListRef = useRef<TreeList>(null)
  const [isBarChartPopUpVisible, setBarChartPopUpVisible] = useState(false)
  const [isPieChartPopUpVisible, setPieChartPopUpVisible] = useState(false)
  const [showResourceUpdatePopup, setShowResourceUpdatePopup] = useState(false)
  const [showItemUpdatePopup, setShowItemUpdatePopup] = useState(false)
  const [selectedResource, setSelectedResource] = useState<IBOQItem>()
  const [showSubItemModal, setShowSubItemModal] = useState<ModalStateType>(defaultModalState)
  // const [showProvisionalModal, setShowProvisionalModal] = useState<ModalStateType>(defaultModalState)
  const [showItemModal, setShowItemModal] = useState<ModalStateType>(defaultModalState)
  const [showResourceModal, setShowResourceModal] = useState<ModalStateType>({
    ...defaultModalState,
    isDuplicate: false,
  })

  const carbonEmissionBySectionLevel: ICarbonEmissionDoughnutChartData[] = [
    {
      region: "A1-A3 (KgCO2e)",
      val:
        boqItemsColumnWiseTotal.A1A3Total / (boqItemsColumnWiseTotal.BOQItemTotalCarbonCost || 1),
    },
    {
      region: "A4 (KgCO2e)",
      val: boqItemsColumnWiseTotal.A4Total / (boqItemsColumnWiseTotal.BOQItemTotalCarbonCost || 1),
    },
    {
      region: "A5a (KgCO2e)",
      val: boqItemsColumnWiseTotal.A5aTotal / (boqItemsColumnWiseTotal.BOQItemTotalCarbonCost || 1),
    },
    {
      region: "A5w (KgCO2e)",
      val: boqItemsColumnWiseTotal.A5wTotal / (boqItemsColumnWiseTotal.BOQItemTotalCarbonCost || 1),
    },
  ]

  const init = useCallback(() => {
    const data = { aggregatedOn: "ITEM_RESOURCE_TREE", projectId, sectionId }
    dispatch(getBOQItemsAndResourcesAction(data))
  }, [projectId, sectionId])

  useEffect(() => {
    if (!project) dispatch(getProjectByIdAction(projectId))
    if (!section) dispatch(getSectionByIdAction(sectionId))
    init()
    dispatch(fetchItemGroupIFTScopeAction())
    dispatch(fetchSubItemGroupIFTScopeAction())
    dispatch(fetchResourceGroupIFTScopeAction())
  }, [])

  const showPopUp = (popUpState: string) => {
    const doc: any = document?.body
    if (popUpState === "visible" && doc.classList.contains("bar-chart")) {
      doc.classList.remove("pie-chart")
      setPieChartPopUpVisible(false)
      setBarChartPopUpVisible(true)
    } else if (popUpState === "visible" && doc.classList.contains("pie-chart")) {
      doc.classList.remove("bar-chart")
      setBarChartPopUpVisible(false)
      setPieChartPopUpVisible(true)
    }
  }

  const updateItemResourceCallback = useCallback(async () => {
    const data = { aggregatedOn: "ITEM_RESOURCE_TREE", projectId, sectionId }
    await dispatch(getBOQItemsAndResourcesAction(data))
  }, [projectId, sectionId])

  const delBOQResOrItem = useCallback((rowData: IBOQItem) => {
    const id = rowData.ID
    const msg = rowData?.PROJECT_ITEM_COMPOSITETYPE === "SubItem" ? "SubItem" : "resource"

    boqConfirmationDialog(msg)
      .show()
      .then((dialogResult: any) => {
        if (dialogResult) {
          const reqrdDelFn = get(rowData, "PROJECT_RESOURCE_ID")
            ? deleteBOQProjectResourceAction
            : deleteBOQProjectItemAction
          const reqrdDelMsg = `Project ${msg} deleted successfully.`

          dispatch(reqrdDelFn({ resourceId: id, projectId: estimateId || Number(projectId) })).then(
            (res) => {
              if (get(res, "payload.status") === 200) notify(reqrdDelMsg, "success", 4000)
              updateItemResourceCallback()
            }
          )
        }
      })
  }, [])

  const addResource = useCallback(
    (row: any) => {
      setShowResourceModal({
        show: true,
        data: row,
        isDuplicate: getMultiInstance(row, boqItems).length > 1,
      })
    },
    [boqItems]
  )

  const onContextMenuPreparing = useCallback(
    (e: ContextMenuPreparingEvent) => {
      if (e.target === "content" && e.row?.rowType === "data" && !e.items) {
        if (e.row?.data?.PROJECT_ITEM_COMPOSITETYPE === "NormalItem") {
          e.items = [
            {
              text: "Add SubItem",
              onItemClick: () => setShowSubItemModal({ show: true, data: e.row?.data }),
              visible: isEditable,
            },
            {
              text: "Add Resource",
              onItemClick: () => addResource(e.row?.data),
              visible: isEditable,
            },
          ]
        } else if (
          e.row?.data?.PROJECT_ITEM_COMPOSITETYPE === "CompositeItem" ||
          e.row?.data?.PROJECT_ITEM_COMPOSITETYPE === "CompositeTotal"
        ) {
          e.items = [
            {
              text: "Add Item",
              visible: isEditable,
              onItemClick: () => setShowItemModal({ show: true, data: e.row?.data }),
            },
            // {
            //   text: "Add Provisional Item",
            //   onItemClick: () => setShowProvisionalModal({ show: true, data: e.row?.data }),
            //   visible: isEditable,
            // },
          ]
        }
        if (e.row?.data?.PROJECT_ITEM_COMPOSITETYPE === "SubItem") {
          e.items = [
            {
              text: "Add Resource",
              onItemClick: () => addResource(e.row?.data),
              visible: isEditable,
            },
          ]
        }
      }
    },
    [isEditable]
  )

  const editCellRender = (row: any) => {
    const carbManualUpdateCheck = get(row, "data.PROJECT_RESOURCE_CARB_MANUAL_SET") === "Y"
    const compositeType = get(row, "data.PROJECT_ITEM_COMPOSITETYPE")
    const check1 =
      !row.row.node.hasChildren &&
      compositeType &&
      !["CompositeItem", "CompositeTotal"].includes(compositeType)
    const check2 = compositeType === "ResourceItem"
    const check3 = compositeType === "SubItem"

    return (
      <div className="d-flex justify-content-around">
        {carbManualUpdateCheck && (
          <span
            title={t("boq.resourceManualUpdateMsg")}
            className="d-flex justify-content-center btn btn-sm px-0"
          >
            <i className="dx-icon-info cursor-pointer" />
          </span>
        )}
        {(check1 || check2 || check3) && (
          <span title={t("toolbarActions.edit")}>
            <button
              type="button"
              className="d-flex justify-content-center btn btn-sm px-0"
              onClick={() => {
                setSelectedResource(row.data)
                if (check1 && !check2) {
                  setShowItemUpdatePopup(true)
                }
                if (check2 || check3) {
                  setShowResourceUpdatePopup(true)
                }
              }}
            >
              <i className="dx-icon-edit cursor-pointer" />
            </button>
          </span>
        )}
        {(check1 || check2 || check3) && (
          <span title={t("toolbarActions.delete")}>
            <button
              type="button"
              className="d-flex justify-content-center btn btn-sm px-0"
              onClick={() => delBOQResOrItem(get(row, "data"))}
            >
              <i className="dx-icon-trash cursor-pointer" />
            </button>
          </span>
        )}
      </div>
    )
  }

  const hideInfo = () => {
    const doc: any = document?.body
    if (doc.classList.contains("bar-chart")) {
      doc.classList.remove("bar-chart")
    }
    if (doc.classList.contains("pie-chart")) {
      doc.classList.remove("pie-chart")
    }

    setBarChartPopUpVisible(false)
    setPieChartPopUpVisible(false)
  }

  const closeButtonOptions = {
    text: "close",
    onClick: hideInfo,
  }

  const showBaseBoqHeading = useMemo(
    () => !identifyPermissions(userRole, -1, AccessLevel.NO_ACCESS, [RoleType.CONTRACTOR]),
    [userRole]
  )

  const contractorCondition = useMemo(
    () => userRole?.ESTIMATOR_ID === estimatorID,
    [userRole, estimatorID]
  )

  const permissionCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const renderBOQCurrencyCell = useCallback(
    (val: any) => {
      const finalValue = formatNumber(val, {
        currency: config.clientName === "HE" ? "GBP" : "USD",
        precision: 2,
        type: "currency",
      })
      return userRole?.ROLE_ID !== RoleType.CONTRACTOR
        ? permissionCheck && finalValue
        : contractorCondition && permissionCheck && finalValue
    },
    [showBaseBoqHeading, contractorCondition]
  )

  const handleRecalculate = async () => {
    notify(t("boq.recalculatingMsg"), "info", 3000)
    await dispatch(recalculateCarbonAction({ projectId, sectionId })).then((res) => {
      init()
      updateItemResourceCallback()
      const resStatus = get(res, "meta.requestStatus")
      const resDataStatus = get(res, "payload.data.status")
      setTimeout(() => {
        notify(resDataStatus, resStatus === "fulfilled" ? "success" : "error", 3000)
      }, 3000)
    })
  }

  return (
    <div data-testid="project-item" className="estimate-section-item">
      <ScrollView height={windowHeight - 60}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position="center"
          visible={loading === LoadingState.LOADING}
          showIndicator
          shading
          showPane
        />
        <UpdateResourcePopup
          showEditRecordPopup={showResourceUpdatePopup}
          setShowEditRecordPopup={setShowResourceUpdatePopup}
          rowData={selectedResource}
          updateResourceCallback={updateItemResourceCallback}
        />
        <UpdateItemPopup
          showEditRecordPopup={showItemUpdatePopup}
          setShowEditRecordPopup={setShowItemUpdatePopup}
          rowData={selectedResource}
        />
        <ResourceModal
          visible={showResourceModal.show}
          rowData={showResourceModal.data}
          isBeingAddedOnDuplicateSubItem={showResourceModal.isDuplicate}
          onHide={() => {
            init()
            setShowResourceModal({ show: false, data: null, isDuplicate: false })
          }}
        />
        <SubItemModal
          visible={showSubItemModal.show}
          rowData={showSubItemModal.data}
          onHide={() => {
            init()
            setShowSubItemModal({ show: false, data: null })
          }}
        />
        {/* <ProvisionalSumModal
        visible={showProvisionalModal.show}
        rowData={showProvisionalModal.data}
        onHide={() => {
          init()
          setShowProvisionalModal({ show: false, data: null, isDuplicate: false })
        }}
      /> */}
        <ItemModal
          visible={showItemModal.show}
          rowData={showItemModal.data}
          onHide={() => {
            init()
            setShowItemModal({ show: false, data: null, isDuplicate: false })
          }}
        />
        <div className="top-section-boq">
          <BreadCrum data={boqBreadCrum(t, project, section)} />
          <div className="chart-container">
            <div className="dx-card chart-wrapper">
              <CarbonEmissionDoughnutChart
                showPopUp={showPopUp}
                data={carbonEmissionBySectionLevel}
              />
              <Popup
                visible={isPieChartPopUpVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick
                showCloseButton={false}
                showTitle={false}
                title={t("boq.carbonEmissions")}
                container=".dx-viewport"
              >
                <div className="p-3">
                  <Position at="center" my="center" collision="fit" />
                  <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                  />
                  <CarbonEmissionDoughnutChart
                    showPopUp={showPopUp}
                    isPieChartPopUpVisible={isPieChartPopUpVisible}
                    data={carbonEmissionBySectionLevel}
                  />
                </div>
              </Popup>
            </div>
            <div className="dx-card chart-wrapper">
              <CarbonEmissionStackedBarChart showPopUp={showPopUp} data={topFiveCarbonBOQItems} />
              <Popup
                visible={isBarChartPopUpVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick
                showCloseButton={false}
                showTitle={false}
                title={t("boq.carbonEmissions")}
                container=".dx-viewport"
              >
                <div className="p-3">
                  <Position at="center" my="center" collision="fit" />
                  <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                  />
                  <CarbonEmissionStackedBarChart
                    showPopUp={showPopUp}
                    isBarChartPopUpVisible={isBarChartPopUpVisible}
                    data={topFiveCarbonBOQItems}
                  />
                </div>
              </Popup>
            </div>
          </div>
          <BoqSummaryCard />
        </div>
        <div className="top-heading">
          <label>
            <span>{t("projectItemHeading")}</span> <i className="dx-icon dx-icon-chevrondown" />
          </label>
          {!get(location, "state.ParentProjId") && showBaseBoqHeading && (
            <label>{t("boq.baselineBOQ")}</label>
          )}
        </div>
        <div className="estimate-section-item-grid">
          <TreeList
            className="compact-grid compact-grid-tree"
            id="sectionItems"
            dataSource={boqItems}
            ref={treeListRef}
            showColumnLines
            showBorders
            showRowLines
            columnAutoWidth
            allowColumnResizing
            rootValue={-1}
            keyExpr="ID"
            parentIdExpr="Parent_ID"
            onRowPrepared={(e) => onBOQRowPrepared(e, addedIds, editedIds)}
            onContextMenuPreparing={onContextMenuPreparing}
            height={windowHeight - 220}
            filterMode="withAncestors"
            stateStoring={{
              enabled: true,
              type: "localStorage",
              storageKey: "boq-tree-list-state", // The arg under selectSection fn in slice should be same as this key
              savingTimeout: 0,
            }}
          >
            <Scrolling mode="standard" />
            <Paging enabled defaultPageSize={100} />
            <Pager showPageSizeSelector showInfo visible infoText={t("boq.infoText")} />
            <FilterRow visible />
            <SearchPanel visible width={240} searchVisibleColumnsOnly />
            <Toolbar visible>
              <Item location="before">
                <span title={t("toolbarActions.resetAll")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => treeListRef.current?.instance.state(null)}
                  />
                </span>
              </Item>
              <Item location="before">
                <span title={t("boq.recalculateCarbon")}>
                  <Button
                    stylingMode="contained"
                    className="active"
                    text={t("boq.recalculate")}
                    onClick={handleRecalculate}
                  />
                </span>
              </Item>
              <Item name="searchPanel" />
            </Toolbar>
            <Column fixed />
            <Column
              dataField="CODE"
              caption={t("boq.code")}
              width={100}
              cssClass="tree-grid-column-bold"
              defaultSortOrder="asc"
              sortOrder="asc"
              fixed
            />
            <Column
              dataField="DESCRIPTION"
              caption={t("boq.desc")}
              width={400}
              cellRender={boqCellDescriptionRender}
              cssClass="tree-grid-column-bold"
              fixed
            />
            <Column
              dataField="RATE"
              caption={t("boq.rate")}
              width={100}
              dataType="number"
              cellRender={(row: any) => renderBOQCurrencyCell(row.data.RATE)}
              fixed
            />
            <Column
              dataField="QUANTITY"
              caption={t("boq.qty")}
              width={85}
              dataType="number"
              format={numberFormatterConfig}
              fixed
            />
            <Column dataField="UNIT_DESCRIPTION" caption={t("boq.unit")} dataType="string" fixed />
            <Column
              dataField="CARB_A1_A3"
              caption={t("boq.a1a3")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="CARB_A4"
              caption={t("boq.a4")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="CARB_A5A"
              caption={t("boq.a5a")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="CARB_A5W"
              caption={t("boq.a5w")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="CARB_TOTAL_A1_A5"
              caption={t("boq.totalA1A5")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="COST"
              caption={t("boq.totalCost")}
              dataType="number"
              cellRender={(row: any) => renderBOQCurrencyCell(row.data.COST)}
            />
            <Column
              cellRender={editCellRender}
              width={70}
              visible={isEditable}
              fixedPosition="right"
              fixed
            />
          </TreeList>
        </div>
      </ScrollView>
    </div>
  )
}

export default ProjectItem
