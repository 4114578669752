import React from "react"
import { useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"
import "./home.scss"

const Home: React.FC = (): JSX.Element => {
  useDocumentTitle("Home | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()

  return <h2 className="content-block">{t("welcomeHome")}</h2>
}
export default Home
