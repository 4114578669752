/* eslint-disable consistent-return */
import { memo, useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { Button } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, Editing, FilterRow, Item, Toolbar } from "devextreme-react/data-grid"
import { useAppDispatch } from "redux/store"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { iftSelector, updateIftRevisionScopeData } from "redux/ift/iftSlice"
import { fetchRevisionIFTScopeAction, updateScopeItemsAction } from "redux/ift/middleware"
import { allRoles, handleResetFilter, identifyPermissions } from "utils/common-utils"
import {
  onEditItemTableCellPrepared,
  renderEditItemTableCostCell,
  renderEditItemTableRateCell,
  tableUpdateKeys,
} from "./EditITemTableConst"
import { EditItemTableProps } from "../scopeTableUtils"

const EditItemTable = (props: EditItemTableProps) => {
  const { popUpClose } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const dataGridRef = useRef<DataGrid>(null)

  const { userRole } = useSelector(profileSelector)
  const { estimateId, iftScopeEditItem } = useSelector(iftSelector)

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => [iftScopeEditItem],
        key: "id",
        update: (a, b) => {
          const found = { ...iftScopeEditItem }
          const newRecord = { ...found, ...b }
          dispatch(updateIftRevisionScopeData({ id: a, newRecord }))
          return Promise.resolve()
        },
      }),
    [iftScopeEditItem]
  )

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const saveRecord = useCallback(() => dataGridRef.current?.instance.saveEditData(), [dataGridRef])

  const onSaving = useCallback(
    (e: { changes: any }) => {
      const { changes } = e
      const updatedValue = changes.reduce((a: any, b: { data: any }) => {
        const val = tableUpdateKeys.reduce((a1, b1) => {
          let dummy = {}
          if (Object.keys(b.data).includes(b1)) dummy = { [b1]: Number(b.data[b1]) }
          else dummy = { [b1]: iftScopeEditItem[b1] }
          return { ...a1, ...dummy }
        }, {})
        return { ...a, ...val }
      }, {})

      if (isEmpty(updatedValue)) return popUpClose()

      dispatch(updateScopeItemsAction([updatedValue])).then(() => {
        popUpClose()
        if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
      })
    },
    [iftScopeEditItem, estimateId]
  )

  return (
    <DataGrid
      id="gridContainer"
      className="editItemTable"
      ref={dataGridRef}
      dataSource={dataStore}
      showBorders
      showColumnLines
      columnAutoWidth
      allowColumnResizing
      allowColumnReordering
      onSaving={onSaving}
      onCellPrepared={onEditItemTableCellPrepared}
    >
      <FilterRow visible />
      <Editing mode="batch" allowUpdating />
      <Toolbar>
        <Item location="after">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.undo")}>
            <Button
              icon="undo"
              stylingMode="text"
              onClick={() => dataGridRef.current?.instance.cancelEditData()}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.save")}>
            <Button icon="save" stylingMode="text" onClick={saveRecord} />
          </span>
        </Item>
      </Toolbar>
      <Column dataField="CODE" caption={t("iftScope.code")} allowEditing={false} />
      <Column dataField="DESCRIPTION" caption={t("iftScope.desc")} allowEditing={false} />
      <Column dataField="UNIT_DESCRIPTION" caption={t("iftScope.unit")} allowEditing={false} />
      <Column dataField="PROJECT_ITEM_QTY" caption={t("iftScope.qty")} />
      <Column
        dataField="PROJECT_ITEM_RATE"
        caption="Rate"
        allowEditing={false}
        cellRender={(item) => renderEditItemTableRateCell(item, userRole)}
      />
      <Column
        dataField="PROJECT_ITEM_COST"
        caption={t("iftScope.cost")}
        allowEditing={permsnCheck}
        cellRender={(item) => renderEditItemTableCostCell(item, userRole)}
      />
      <Column caption={t("iftScope.carbon")} alignment="center">
        <Column dataField="PROJECT_ITEM_CARB_A1_A3_FACTOR" caption={t("iftScope.a1a3kg")} />
        <Column dataField="PROJECT_ITEM_CARB_A4_FACTOR" caption={t("iftScope.a4kg")} />
        <Column dataField="PROJECT_ITEM_CARB_A5A_FACTOR" caption={t("iftScope.a5akg")} />
        <Column
          dataField="PROJECT_ITEM_CARB_WASTE_FACTOR"
          caption={t("iftScope.wasteFactor")}
          alignment="right"
        />
      </Column>
    </DataGrid>
  )
}

export default memo(EditItemTable)
