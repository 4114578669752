import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { FieldValue, LoadingState } from "types/AppNav"
import { ProfileInfo, ProfileState, UserRole } from "./types"
import { fetchProfileAction, fetchUserRoleAndPermissionsAction } from "./middleware"

const INITIAL_STATE: ProfileState = {
  profile: null,
  loading: LoadingState.DEFAULT,
  userRole: null,
}

const profileSlice = createSlice({
  name: "Profile",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state: ProfileState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),

    updateProfile: (state: ProfileState, { payload }: PayloadAction<FieldValue>) => ({
      ...state,
      profile: { ...state.profile, [payload.field]: payload.value || "" },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProfileAction.fulfilled,
      (state: ProfileState, { payload }: PayloadAction<ProfileInfo>) => ({
        ...state,
        profile: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchProfileAction.rejected, (state: ProfileState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      fetchUserRoleAndPermissionsAction.fulfilled,
      (state: ProfileState, { payload }: PayloadAction<UserRole>) => ({
        ...state,
        userRole: payload,
        loading: LoadingState.DEFAULT,
      })
    )
  },
})

export const { setLoading, updateProfile } = profileSlice.actions

export const profileSelector = (state: RootState) => state.Profile

export default profileSlice.reducer
