/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import {
  Item,
  Pager,
  Paging,
  Column,
  Export,
  Editing,
  Toolbar,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { TreeList, Button, LoadPanel } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import useDocumentTitle from "hooks/useDocumentTitle"
import { useAppDispatch } from "redux/store"
import { sharedSelector } from "redux/shared/sharedSlice"
import {
  getIFTSpendAction,
  updateIFTSpendAction,
  getIFTInflationAction,
  updateIFTInflationAction,
} from "redux/ift/middleware"
import { iftSelector } from "redux/ift/iftSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import ExportToCSV from "utils/ExportTreeListToCSV"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { currencyFormatterConfig, handleResetFilter } from "utils/common-utils"
import { LoadingState } from "types/AppNav"
import { profileSelector } from "redux/profile/profileSlice"
import { getContractorEstimateLibrary } from "redux/project-estimate-library-setup/middleware"
import { estimateLibrarySelector } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import SpendResultPopup from "./spend-result-popup"
import {
  calculateTotalProfileCost,
  renderTableCostCell,
  onSpendCellPrepared,
  onSpendRowPrepared,
  spendValueRender,
  spendTitleRender,
} from "./spend-utils"
import "./spend.scss"

const IFTSpend: React.FC = (): JSX.Element => {
  useDocumentTitle("IFT Spend | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()

  const { project } = useSelector(projectSelector)
  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)
  const { iftSpend, loading, estimateId, iftInflationRowData } = useSelector(iftSelector)
  const { contractorEstimateLibraryContent } = useSelector(estimateLibrarySelector)
  const treeListRef = useRef<TreeList>(null)
  const [changes, setChanges] = useState<Array<any>>([])
  const [showSpendResultPopup, setSpendResultPopup] = useState(false)

  const init = useCallback(() => {
    const id = estimateId || projectId
    dispatch(getIFTSpendAction(id))
    dispatch(getIFTInflationAction(id))
    dispatch(getContractorEstimateLibrary({ projectId: Number(id) }))
  }, [estimateId, projectId])

  useEffect(() => {
    init()
  }, [])

  const onChangesChange = useCallback((newChanges: any) => setChanges(newChanges), [])

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => iftSpend,
        key: "ID",
        update: async () => {
          return Promise.resolve()
        },
      }),
    [iftSpend]
  )

  const onSaving = useCallback(
    async (e: any) => {
      const UniqueKey = get(e, "changes[0].key")
      if (!UniqueKey) return

      try {
        let originalRowData: any = iftSpend.find((item) => item.UniqueKey === UniqueKey)
        const changes = e.changes[0].data
        const values = [...originalRowData.Values]
        Object.keys(changes).forEach((key) => {
          if (key.includes("V")) {
            const calcPercentage = (Number(changes[key]) / originalRowData.TotalProfiledCost) * 100
            const index = Number(key.replace("V", ""))
            if (!Number.isNaN(index)) values[index] = Number((calcPercentage / 100).toFixed(4))
          }
        })

        if (get(changes, "TotalProfiledCost")) {
          values.forEach((value: any, index: number) => {
            const val = changes[`V${index}`]
            if (val) {
              values[index] = value || Number(val)
              if (!Number.isNaN(Number(val))) {
                originalRowData = { ...originalRowData, [`V${index}`]: val }
              }
            }
          })
        }

        originalRowData = { ...originalRowData, ProjectId: Number(estimateId), Values: values }
        const sum = values.reduce((a: number, b: number) => a + b, 0)
        if (sum.toFixed(2) !== "1.00") {
          notify(`${t("spend.saveSumErrMsg")} ${originalRowData.Title}`, "error", 2000)
          e.cancel = true
          return
        }

        let updateIftInflationRes = true
        if (changes.InflationDate) {
          const inflationRowData = [...iftInflationRowData]
          const inflationUpdates = inflationRowData.reduce((acc, curr) => {
            if (curr.DataTypeName === "Header") return [...acc]

            const currArr = curr.Properties || curr.properties || []
            const found = currArr.find((x: any) => x.Data === originalRowData.CeCode)
            if (found) {
              let rec = currArr.filter((item: any) => item.Name !== "InflationDate")
              rec = [
                ...rec,
                {
                  Name: "InflationDate",
                  Data: changes.InflationDate,
                  PropertyType: "FixedField",
                },
              ]
              return [
                ...acc,
                { DataTypeName: "CostElementInflationSettingXmlDataStore", Properties: rec },
              ]
            }

            return [...acc, curr]
          }, [])

          if (inflationUpdates.length) {
            const inflationResponse = await dispatch(
              updateIFTInflationAction({
                request: inflationUpdates,
                projectId: estimateId || projectId,
              })
            )
            updateIftInflationRes = get(inflationResponse, "meta.requestStatus") === "fulfilled"
          }
        }

        if (updateIftInflationRes) {
          const data = iftSpend.filter(
            (item) =>
              item?.isDefaultValues &&
              item.RowType === "COSTELEMENT" &&
              item?.UniqueKey !== UniqueKey
          )
          const res = await dispatch(updateIFTSpendAction([originalRowData, ...data]))
          if (get(res, "meta.requestStatus") === "fulfilled") {
            notify(t("spend.changeSaveSCS"), "success", 2000)
            const id = estimateId || projectId
            dispatch(getIFTInflationAction(id))
            dispatch(getIFTSpendAction(id))
            treeListRef.current?.instance.cancelEditData()
          }
        }
      } catch (error) {
        notify(t("spend.changeSaveErr"), "error", 2000)
      }
    },
    [iftSpend, estimateId, projectId, iftInflationRowData]
  )

  const onExporting = useCallback(() => {
    const id = estimateId || projectId
    const fileName = id
      ? `Custom Spend Profile_${selectedRevision?.SchemaDesc}`
      : `Custom Spend Profile_${project?.SchemaDesc}`

    ExportToCSV(`${fileName}`, treeListRef.current?.instance)
  }, [selectedRevision, project, estimateId, projectId, treeListRef])

  return (
    <div className="ift-spend-grid-wrapper">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position="center"
        visible={loading === LoadingState.LOADING}
        showIndicator
        shading
        showPane
      />
      {showSpendResultPopup && (
        <SpendResultPopup
          showSpendResultPopup={showSpendResultPopup}
          setSpendResultPopup={setSpendResultPopup}
        />
      )}
      <TreeList
        dataSource={dataStore}
        ref={treeListRef}
        key="UniqueKey"
        showBorders
        autoExpandAll
        columnAutoWidth
        highlightChanges
        repaintChangesOnly
        allowColumnResizing
        allowColumnReordering
        rootValue={-1}
        noDataText={t("noRecords")}
        height={windowHeight - 230}
        scrolling={{ mode: "standard" }}
        onSaving={onSaving}
        onRowPrepared={(e) => onSpendRowPrepared(e, iftSpend)}
        onCellPrepared={onSpendCellPrepared}
        keyExpr="ID"
        parentIdExpr="Parent_ID"
        className="ift-spend-grid"
      >
        <FilterRow visible />
        <SearchPanel visible width={340} />
        <Export enabled />
        <Paging defaultPageSize={defaultPageSize} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Editing
          mode="row"
          allowUpdating={!!estimateId}
          changes={changes}
          onChangesChange={onChangesChange}
        />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => handleResetFilter(treeListRef)}
              />
            </span>
          </Item>
          <Item location="before">
            <Button
              text={t("spend.viewRes")}
              className="active"
              stylingMode="contained"
              style={{ marginLeft: "40px" }}
              onClick={() => setSpendResultPopup(!showSpendResultPopup)}
            />
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.exportCSV")}>
              <Button icon="export" onClick={onExporting} />
            </span>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Column
          caption={t("spend.description")}
          width={370}
          fixed
          allowFixing
          allowEditing={false}
          allowSorting={false}
          cssClass="border-class disabled"
        >
          <Column
            dataField="Title"
            caption={t("spend.cashFlowForcast")}
            width={370}
            fixed
            allowFixing
            allowSorting
            allowEditing={false}
            defaultSortOrder="asc"
            cellRender={spendTitleRender}
          />
        </Column>
        <Column
          dataField="InflationDate"
          dataType="date"
          caption={t("spend.costBaseDate")}
          width={270}
          allowEditing
          cssClass="border-class"
        />
        <Column
          dataField="TotalCost"
          caption={t("spend.totalCost")}
          width={200}
          cssClass="border-class"
          format={currencyFormatterConfig}
          allowEditing={false}
          cellRender={(cellInfo) =>
            renderTableCostCell(cellInfo, userRole, contractorEstimateLibraryContent)
          }
        />
        <Column
          dataField="TotalProfiledCost"
          caption={t("spend.totalProfiledCost")}
          width={200}
          cssClass="border-class"
          format={currencyFormatterConfig}
          allowEditing={false}
          cellRender={(cellInfo) =>
            renderTableCostCell(cellInfo, userRole, contractorEstimateLibraryContent)
          }
        />
        <Column caption={t("spend.timeDivisions")} allowSorting={false}>
          {iftSpend.length &&
            iftSpend[1]?.Values.map((value, index) => (
              <Column
                dataField={`V${index}`}
                caption={`${index + 1}`}
                width={200}
                allowEditing
                cssClass="border-class"
                cellRender={(raw) => spendValueRender(raw, `V${index}`)}
                format={currencyFormatterConfig}
                setCellValue={(newData, value, currentRowData) =>
                  calculateTotalProfileCost(newData, value, currentRowData, index)
                }
                key={index}
              />
            ))}
        </Column>
      </TreeList>
    </div>
  )
}

export default IFTSpend
