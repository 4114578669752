import { get } from "lodash"
import { DataGrid } from "devextreme-react"
import { ValidationRule } from "devextreme/common"
import { ProvisionalSumItem } from "redux/ift/types"

export type ItemModalProps = {
  visible?: boolean
  onHide?: () => void
  rowData?: any
}

export const setItemModalDeleteToolbar = (
  dataGridRef: { current: DataGrid | null },
  value: boolean
) => {
  dataGridRef.current?.instance.option("toolbar.items[0].disabled", value) // delete button
}
export const setItemModalSaveToolbar = (
  dataGridRef: { current: DataGrid | null },
  value: boolean
) => {
  dataGridRef.current?.instance.option("toolbar.items[3].disabled", value) // save undo button
}
export const setItemModalAddBtnToolbar = (
  itemsDataGridRef: { current: DataGrid | null },
  value: boolean
) => {
  itemsDataGridRef.current?.instance.option("toolbar.items[2].disabled", value)
}

export const itemQtyValidationRules = [
  {
    type: "required",
    message: "Quantity is required",
  },
  {
    type: "numeric",
    message: "Quantity must be numeric",
    ignoreEmptyValue: false,
  },
  {
    type: "range",
    min: 1,
    ignoreEmptyValue: false,
  },
] as unknown as ValidationRule[]

export const calculatedItemModalRateCell = (rowData: ProvisionalSumItem) => {
  const rate = get(rowData, "RATE")
  const qty = get(rowData, "PROJECT_ITEM_QTY")

  return rate && qty ? Number(rate) * Number(qty) : 0
}
