import { useCallback, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get, isEmpty } from "lodash"
import notify from "devextreme/ui/notify"
import Button from "devextreme-react/button"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, Editing, Item, Pager, Paging, Toolbar } from "devextreme-react/data-grid"
import BreadCrumb from "components/bread-crumb"
import { sharedSelector } from "redux/shared/sharedSlice"
import { masterSelector } from "redux/masters/masterSlice"
import { fetchMultipleCodesActions } from "redux/masters/middleware"
import { ITransportAssumptions } from "redux/basline-assumptions/transport/transport-assumptions/types"
import {
  addTransportAssumptionData,
  transportAssumptionSelector,
  updateTransportAssumptionData,
} from "redux/basline-assumptions/transport/transport-assumptions/transportAssumptionsSlice"
import {
  getJourneyLegValuesAction,
  postJourneyLegValuesAction,
} from "redux/basline-assumptions/transport/transport-assumptions/middleware"
import { allowedPageSizes } from "utils/config"
import { cellFormatterConfig } from "utils/common-utils"
import useDocumentTitle from "hooks/useDocumentTitle"
import {
  TATableInit,
  onTARowInserting,
  TAbreadCrumbData,
  onTAContentReady,
  undoTATableChanges,
  renderTADropdownCell,
} from "./utils"

const TransportAssumptions: React.FC = (): JSX.Element => {
  useDocumentTitle("Transport Assumptions | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataGridRef = useRef<DataGrid>(null)

  const { windowHeight } = useSelector(sharedSelector)
  const { multipleCodes } = useSelector(masterSelector)
  const { transportAssumption } = useSelector(transportAssumptionSelector)

  const init = useCallback(async () => {
    await dispatch(
      fetchMultipleCodesActions({ module: "Mode of Transportation", transportCodes: [] })
    )
    await dispatch(fetchMultipleCodesActions({ module: "Transport Category", categoryCodes: [] }))
  }, [])

  useEffect(() => {
    init()
    TATableInit(dataGridRef)
    dispatch(getJourneyLegValuesAction())
  }, [])

  const uniqTransportCategories = useMemo(() => {
    const includedTA = transportAssumption.map((item) => item.TRANSPORT_CATEGORY)
    const filteredData = get(multipleCodes, "categoryCodes") || []
    return filteredData.filter((item: any) => !includedTA.includes(Number(item.Id)))
  }, [transportAssumption, multipleCodes])

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => transportAssumption,
        key: "JOURNEY_LEG_CARBON_ID",
        update: (a, b) => {
          const found = transportAssumption.find((item) => item.JOURNEY_LEG_CARBON_ID === a)
          const newRecord = { ...found, ...b }
          dispatch(updateTransportAssumptionData(newRecord))
          return Promise.resolve()
        },
        insert: (values: ITransportAssumptions) => {
          const newRecord = { ...values }
          if (!isEmpty(values)) {
            dispatch(addTransportAssumptionData(newRecord))
            return Promise.resolve(newRecord)
          }
          notify(t("transportAssumptions.emptyRowErrMsg"), "error", 2000)
          dataGridRef.current?.instance.cancelEditData()
          return Promise.reject()
        },
        remove: () => {
          return Promise.resolve()
        },
      }),
    [transportAssumption]
  )

  const addRow = useCallback(async () => {
    if (dataGridRef.current) {
      await dataGridRef.current.instance.addRow()
      dataGridRef.current?.instance.refresh()
      dataGridRef.current.instance.deselectAll()
    }
  }, [dataGridRef])

  const saveRecord = () => dataGridRef.current?.instance.saveEditData()

  const onSaving = useCallback(
    (e: { changes: any }) => {
      const updatedValues = e.changes.map((item: { key: any; data: any }) => {
        const found = transportAssumption.find((ta) => ta.JOURNEY_LEG_CARBON_ID === item.key)
        return {
          ...found,
          ...item.data,
        }
      })
      const refinedValues = updatedValues?.filter((obj: any) => Object.keys(obj).length)
      if (refinedValues.length)
        dispatch(postJourneyLegValuesAction(refinedValues)).then((res) => {
          if (get(res, "meta.requestStatus") === "fulfilled") undoTATableChanges(dataGridRef)
        })
    },
    [transportAssumption]
  )

  return (
    <>
      <BreadCrumb data={TAbreadCrumbData(t)} />
      <br />
      <DataGrid
        id="transport-assumption-grid"
        key="JOURNEY_LEG_CARBON_ID"
        dataSource={dataStore}
        ref={dataGridRef}
        allowColumnResizing
        repaintChangesOnly
        highlightChanges
        columnAutoWidth
        showBorders
        scrolling={{ mode: "standard" }}
        onSaving={onSaving}
        errorRowEnabled={false}
        height={windowHeight - 148}
        noDataText={t("noRecords")}
        onRowInserting={onTARowInserting}
        onContentReady={() => onTAContentReady(dataGridRef)}
      >
        <Paging enabled defaultPageSize={10} />
        <Editing mode="batch" allowUpdating allowAdding allowDeleting={false} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.add")}>
              <Button icon="add" stylingMode="text" onClick={addRow} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.undoAll")}>
              <Button
                icon="undo"
                stylingMode="text"
                onClick={() => undoTATableChanges(dataGridRef)}
              />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={saveRecord} />
            </span>
          </Item>
        </Toolbar>
        <Column
          caption={t("transportAssumptions.parentColHead")}
          fixed
          alignment="left"
          fixedPosition="left"
        >
          <Column
            dataField="TRANSPORT_CATEGORY"
            caption={t("transportAssumptions.tableCol1Head")}
            alignment="left"
            cssClass="border-class"
            editCellRender={(rowData) => renderTADropdownCell(rowData, uniqTransportCategories)}
            lookup={{
              dataSource: multipleCodes && multipleCodes?.categoryCodes,
              displayExpr: "Name",
              valueExpr: "Id",
            }}
          />
          <Column
            dataField="MODE_OF_TRANSPORT_1"
            caption={t("transportAssumptions.tableCol2Head")}
            alignment="left"
            cssClass="border-class"
            lookup={{
              dataSource: multipleCodes && multipleCodes?.transportCodes,
              displayExpr: "Name",
              valueExpr: "Id",
            }}
          />
          <Column
            dataField="DISTANCE_1"
            caption={t("transportAssumptions.tableCol3Head")}
            alignment="left"
            dataType="number"
            cssClass="border-class"
            format={cellFormatterConfig}
          />
          <Column
            dataField="MODE_OF_TRANSPORT_2"
            caption={t("transportAssumptions.tableCol4Head")}
            alignment="left"
            cssClass="border-class"
            lookup={{
              dataSource: multipleCodes && multipleCodes?.transportCodes,
              displayExpr: "Name",
              valueExpr: "Id",
            }}
          />
          <Column
            dataField="DISTANCE_2"
            caption={t("transportAssumptions.tableCol5Head")}
            alignment="left"
            dataType="number"
            cssClass="border-class"
            format={cellFormatterConfig}
          />
        </Column>
      </DataGrid>
    </>
  )
}

export default TransportAssumptions
