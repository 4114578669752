import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import { config } from "utils/config"
import { EstimateRevisionAPIData, ProjectIDsResponse } from "./types"

export const getCompratorData = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/project-aggr/ift-comp`, {
      baseEestimateId: data.estimateNumber,
      estimateRevisionIds: data.revisionNumbers,
    })
    return response?.data?.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getAllProjects = async () => {
  try {
    const url = `/projects/meta/all?Comparator=true`
    /* CCFT-1107 NH Comparator - Remove the Authorised and Released validations for Estimates */
    // if (userRole && userRole?.ROLE_ID !== RoleType.CONTRACTOR) url += `&isAuthorisedOnly=true`

    const response = await axiosInstance.get<SuccessResponse<ProjectIDsResponse[]> | ErrorResponse>(
      url
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getAllEstimateRevisionsAsync = async (data: EstimateRevisionAPIData) => {
  const { projectId } = data
  try {
    const checkEnv = config.clientName === "HE"
    let url = `/estimate-revisions/${projectId}`
    if (checkEnv) url += "?isCessGenerated=true"

    const response = await axiosInstance.get<any | ErrorResponse>(url)
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const getAllCompProjects = async (data: any) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any[]> | ErrorResponse>(
      `/project-aggr/ift-comp-costelement`,
      data
    )
    return response.data as Array<any>
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getTotalCompProjects = async (apiData: {
  estimateNumber: number
  revisionNumbers: number[]
}) => {
  const { estimateNumber, revisionNumbers } = apiData
  const data = { baseEestimateId: estimateNumber, estimateRevisionIds: revisionNumbers }

  try {
    const response = await axiosInstance.post<SuccessResponse<any[]> | ErrorResponse>(
      `/project-aggr/ift-comp-summary`,
      data
    )
    return response.data as Array<any>
  } catch (err) {
    return isAxiosError(err)
  }
}
