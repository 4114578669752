import React from "react"
import ScrollView from "devextreme-react/scroll-view"
import type { SingleCardProps } from "types/AppNav"
import logo from "assets/icons/CCFTLogo.png"
import "./single-card.scss"

const SingleCard = ({
  title,
  applicationName = "Cost and Carbon Forecasting Tool",
  description,
  children,
}: React.PropsWithChildren<SingleCardProps>): JSX.Element => (
  <ScrollView height="100%" width="100%" className="with-footer single-card">
    <div className="dx-card content" style={{ flexGrow: 0 }}>
      <div className="header">
        <div className="application">
          <img src={logo} alt="logo" className="applicationLogo" />
          <div className="applicationName">
            {applicationName}
            <br />
          </div>
        </div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      {children}
    </div>
  </ScrollView>
)

export default SingleCard
