/* eslint-disable func-names */
import { useEffect, useMemo, useState } from "react"
import { get } from "lodash"
import { models } from "powerbi-client"
import { useSelector } from "react-redux"
import { PowerBIEmbed } from "powerbi-client-react"
import { powerBISelector } from "redux/powerBI/powerBISlice"

const PowerBIDashboard = () => {
  const { powerBIData } = useSelector(powerBISelector)

  const [report, setReport] = useState<any>(null)

  const checkBi = useMemo(() => !!Object.keys(powerBIData).length, [powerBIData])
  const { embedUrl, embedToken, reportId } = useMemo(() => {
    let obj: any = {
      embedUrl: undefined,
      embedToken: undefined,
      reportId: undefined,
    }
    if (Object.keys(powerBIData).length) {
      obj = {
        embedUrl: get(powerBIData, "reports.embedUrl"),
        embedToken: get(powerBIData, "embedToken"),
        reportId: get(powerBIData, "reportId"),
      }
    }
    return obj
  }, [powerBIData])

  useEffect(() => {
    if (report === null) return
    report?.refresh()
  }, [report, powerBIData, embedUrl])

  return (
    <div>
      {checkBi && embedToken && embedUrl && reportId && (
        <PowerBIEmbed
          key={embedToken}
          embedConfig={{
            type: "report",
            id: reportId,
            embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed,
            pageView: "fitToWidth",
            settings: {
              panes: {
                filters: {
                  visible: false,
                },
                pageNavigation: {
                  visible: false,
                },
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded")
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered")
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event?.detail)
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName="bi-embedded"
          getEmbeddedComponent={(embeddedReport) => {
            console.log(embeddedReport)
            setReport(embeddedReport)
            // window.Report = embeddedReport // save report in window object
          }}
        />
      )}
    </div>
  )
}

export default PowerBIDashboard
