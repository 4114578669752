import { get } from "lodash"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import { exportDataGrid } from "devextreme/excel_exporter"
import { CellPreparedEvent } from "devextreme/ui/data_grid"

export const onInflationCellPrepared = (e: CellPreparedEvent) => {
  if (
    e.rowType === "data" &&
    e.column.type === "selection" &&
    e?.data?.DataTypeName === "Header" &&
    e.cellElement.firstChild
  ) {
    e.cellElement.firstChild.remove()
  }
}

export const handleInflationSortingChange = (e: any, dataGridRef: any) => {
  if (e.fullName === "columns[1].sortOrder")
    dataGridRef.current?.instance.columnOption("sectionName", "sortOrder", e.value)
}

export const inflationtitleRender = (row: any) => {
  const val = get(row, "data.Description")
  const headerCheck = get(row, "data.DataTypeName") === "Header"
  return <label className={`${headerCheck ? "fw-semibold" : ""}`}>{val}</label>
}

export const onInflationExporting = async (e: any) => {
  e.component.beginUpdate()
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("Main sheet")
  const { component } = e
  await exportDataGrid({
    component,
    worksheet,
  })
  const buffer = await workbook.csv.writeBuffer()
  saveAs(
    new Blob([buffer], { type: "application/octet-stream" }),
    "Inflation-And-Spend-Template.csv"
  )
  e.component.endUpdate()
}
