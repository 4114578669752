/* eslint-disable no-nested-ternary */
import { TFunction } from "i18next"
import { get, isEmpty } from "lodash"
import { Button } from "devextreme-react"
import { custom } from "devextreme/ui/dialog"
import awardBadge from "assets/icons/award.png"
import { IProject } from "redux/projects/types"
import { UserRole } from "redux/profile/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { EstimateRevisionManagementItem } from "redux/estimate-revision-management/types"
import {
  AssuredLibraryData,
  EstimateLibraryItem,
  Supplier,
} from "redux/project-estimate-library-setup/types"
import { allRoles, identifyPermissions } from "utils/common-utils"

export const estimateLibraryBreadCrumb = (
  project: IProject | undefined,
  selectedRevision: EstimateRevisionManagementItem | undefined
) => {
  const projectId = get(project, "Id") || get(project, "Identity.Value")
  return [
    {
      name: "Estimate: ",
      link: "/projects",
      title: project?.Title,
    },
    {
      name: "Estimate Revision: ",
      link: `/project/${projectId}/estimate-revision-management`,
      title: `${selectedRevision?.Title}`,
    },
    {
      name: "Contractor's Estimate-Specific Library",
    },
  ]
}

export const tempModel: EstimateLibraryItem = {
  CARB_A1_A3: null,
  CARB_A4: null,
  CARB_A5A: null,
  CARB_A5W: null,
  CARB_TOTAL_A1_A5: null,
  CODE: "",
  CONTRACTOR_CODE: "",
  CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID: null,
  CONTRACTOR_ESTIMATE_LIBRARY_ID: null,
  DESCRIPTION: null,
  DISTANCE1: null,
  DISTANCE2: null,
  DISTANCE3: null,
  DISTANCE4: null,
  EPD_ID: null,
  HASHCODE: null,
  IS_ASSURED: null,
  MODE_OF_TRANSPORTATION1: null,
  MODE_OF_TRANSPORTATION2: null,
  MODE_OF_TRANSPORTATION3: null,
  MODE_OF_TRANSPORTATION4: null,
  PROJECT_ITEM_ID: null,
  PROJECT_RESOURCE_ID: null,
  RESOURCE_CATEGORY: null,
  RESOURCE_GROUP: null,
  RESOURCE_REGION: null,
  RESOURCE_TYPE: null,
  RESOURCE_UNIT: null,
  RESOURCE_WEIGHT: null,
  SUPPLIER: null,
  SUPPLIER_RATE: null,
  WASTE_FACTOR_PERCENTAGE: null,
  SUBCONTRACTOR_ID: null,
  RES_CARB_JOURNEY_LEG: "",
  RES_CARB_FUEL_LABOUR: "",
  RES_CARB_WASTE_CATEGORY: "",
}

const confirmationPopUPProps = (t: TFunction<"translation", undefined>) => {
  return {
    showTitle: false,
    messageHtml: `<div style="padding: 0.5rem;">${t(
      "contractorEstimateLibrary.applyAssuredConfirmMsg"
    )}</div>`,
    buttons: [
      {
        text: t("contractorEstimateLibrary.yes"),
        onClick: () => true,
      },
      {
        text: t("contractorEstimateLibrary.no"),
        onClick: () => false,
      },
    ],
  }
}

export function badgeRender(badgeProps: {
  row: any
  assuredLibraryContent: AssuredLibraryData[]
  dataGridRef: any
  setChanges: any
  t: TFunction<"translation", undefined>
}) {
  const { row, assuredLibraryContent, dataGridRef, setChanges, t } = badgeProps

  const dataGrid = dataGridRef.current?.instance
  const showAwardBadge = !!get(row, "row.data.IS_ASSURED_USED")
  const foundAssuredLibraryContent = assuredLibraryContent.find(
    (item) => item.CODE === get(row, "row.data.CODE")
  )
  const showIIcon = !isEmpty(foundAssuredLibraryContent)

  const handleClick = () => {
    const confirmationDialog = custom(confirmationPopUPProps(t))
    confirmationDialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        const data = {
          IS_ASSURED_USED: 1,
          CARB_A1_A3: get(foundAssuredLibraryContent, "CARB_A1_A3") || get(row, "data.CARB_A1_A3"),
          CARB_A4: get(foundAssuredLibraryContent, "CARB_A4") || get(row, "data.CARB_A4"),
          CARB_A5A: get(foundAssuredLibraryContent, "CARB_A5A") || get(row, "data.CARB_A5A"),
          CARB_A5W: get(foundAssuredLibraryContent, "CARB_A5W") || get(row, "data.CARB_A5W"),
          WASTE_FACTOR_PERCENTAGE:
            get(foundAssuredLibraryContent, "WASTE_FACTOR_PERCENTAGE") ||
            get(row, "data.WASTE_FACTOR_PERCENTAGE"),
          SUPPLIER_RATE:
            get(foundAssuredLibraryContent, "SUPPLIER_RATE") || get(row, "data.SUPPLIER_RATE"),
        }
        row.data = { ...row.data, ...data }
        dataGrid.repaintRows([dataGrid.getRowIndexByKey(row.key)])

        setChanges((prev: any) => [
          ...prev,
          {
            data,
            key: row.key,
            type: "update",
          },
        ])
      }
    })
  }

  return showAwardBadge ? (
    <span title={t("contractorEstimateLibrary.adminAssurance")}>
      <img src={awardBadge} alt={t("contractorEstimateLibrary.assured")} />
    </span>
  ) : showIIcon ? (
    <span title={t("contractorEstimateLibrary.assuredDataInfo")}>
      <Button icon="info" className="i-btn" onClick={handleClick} />
    </span>
  ) : null
}

export const getSupplierCode = (item: EstimateLibraryItem) => {
  if (item.SUBCONTRACTOR_ID && item.SUPPLIER && Array.isArray(item.SUPPLIER)) {
    const supplier: Supplier | undefined = item.SUPPLIER.find(
      (sup) => sup.SUBCONTRACTOR_ID === item.SUBCONTRACTOR_ID
    )
    return supplier?.SUBCONTRATOR_CODE || ""
  }
  return ""
}

export const estimateLibFullAccess = (userRole: UserRole | null) => {
  return identifyPermissions(
    userRole,
    AccessType.PROJECT_SPECIFIC_LIBRARY,
    AccessLevel.FULL_ACCESS,
    allRoles
  )
}
