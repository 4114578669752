import { TFunction } from "i18next"
import { custom } from "devextreme/ui/dialog"
import { IProject } from "redux/projects/types"

export const EstimateRevisionBreadCrumb = (
  t: TFunction<"translation", undefined>,
  project: IProject | undefined
) => {
  return [
    {
      name: t("estimateRevisionManagement.breadCrumbHeading1"),
      link: "/projects",
      title: project?.Title,
    },
    {
      name: t("estimateRevisionManagement.breadCrumbHeading2"),
    },
  ]
}

export const pageSizeContractorTable = [5, 10, 15, 20]

export const minCharCnfrmDialog = (t: TFunction<"translation", undefined>) => {
  return custom({
    showTitle: false,
    messageHtml: `<div class="p-2">${t("estimateRevisionManagement.maxCharErr")}</div>`,
    buttons: [
      {
        text: "OK",
        onClick: () => true,
      },
    ],
  })
}
