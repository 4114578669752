import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import CellGenerator from "./cellGenerator"
import ToggleHeader from "./toggleHeader"
import { dataSetGenerator } from "../../utils"

const CostEstimateReportHead = () => {
  const { t } = useTranslation()

  const { selectedExpenditureProjectData } = useSelector(expenditureProfileSelector)

  const InfoCells = useMemo(
    () => dataSetGenerator(t, selectedExpenditureProjectData),
    [selectedExpenditureProjectData]
  )

  return (
    <div className="cost-estimate-report-head">
      <CellGenerator data={InfoCells} />
      <ToggleHeader />
    </div>
  )
}

export default memo(CostEstimateReportHead)
