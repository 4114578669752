import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import { AssignedPermission, EstimatorsRoleState } from "./types"

export const fetchAssignedAdvanceUserActionAsync = async () => {
  try {
    const response = await axiosInstance.get(`/user`)
    return response.data as EstimatorsRoleState
  } catch (err) {
    return isAxiosError(err)
  }
}
export const fetchUnassignedToAssignedUserActionAsync = async (data: AssignedPermission) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/assignPermissionToRole`,
      data
    )
    return response.data as EstimatorsRoleState
  } catch (err) {
    return isAxiosError(err)
  }
}
