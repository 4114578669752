/* eslint-disable import/prefer-default-export */
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { allRoles } from "utils/common-utils"
import { config } from "utils/config"

export const navigation = [
  {
    text: "menu.projectBrowser", // project-browser
    path: "/projects",
    icon: "mediumiconslayout",
    roles: allRoles,
    accessType: -1,
    permissionLevel: -1,
  },
  {
    text: "menu.reports", // reports
    icon: "textdocument",
    roles: allRoles,
    accessType: AccessType.REPORTS,
    permissionLevel: AccessLevel.YES,
    items: [
      {
        text: "menu.estimateLevelCostAndCarbon", // Estimate Level
        icon: "none",
        path: "/estimate-level",
        roles: allRoles,
        accessType: AccessType.REPORTS,
        permissionLevel: AccessLevel.YES,
      },
      {
        text: "menu.iftComparator", // IFT Comparator
        icon: "none",
        path: "/ift-comparator",
        roles: allRoles,
        accessType: AccessType.REPORTS,
        permissionLevel: AccessLevel.YES,
        // visible: config.clientName === "HE",
      },
      {
        text: "menu.iftComp", // IFT Cost Element Comparator
        icon: "none",
        path: "/ift-comp",
        roles: allRoles,
        accessType: AccessType.REPORTS,
        permissionLevel: AccessLevel.YES,
        visible: config.clientName === "HE",
      },
      {
        text: "menu.costEstimateSummary", // Cost Estimate Summary
        icon: "none",
        path: "/costEstimateSummary",
        roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
        accessType: AccessType.REPORTS,
        permissionLevel: AccessLevel.YES,
        visible: config.clientName === "HE",
      },
      {
        text: "menu.carbExpProfile", // Carbon Expenditure Profile
        icon: "none",
        path: "/carbonExpenditureProfile",
        roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
        accessType: AccessType.REPORTS,
        permissionLevel: AccessLevel.YES,
        visible: config.clientName === "HE",
      },
    ],
  },
  {
    text: "menu.admin", // admin
    icon: "user",
    roles: allRoles,
    accessType: -1,
    permissionLevel: -1,
    items: [
      {
        text: "menu.controlPanel", // control-panel
        icon: "none",
        roles: allRoles,
        accessType: AccessType.ASSIGN_INFLATION_INDEX,
        permissionLevel: AccessLevel.YES,
        visible: config.clientName === "HE",
        items: [
          {
            text: "menu.assignInflationIndex", // codes
            icon: "none",
            path: "/control-panel/assign-inflation-index",
            roles: allRoles,
            accessType: AccessType.ASSIGN_INFLATION_INDEX,
            permissionLevel: AccessLevel.YES,
            visible: config.clientName === "HE",
          },
        ],
      },
      {
        text: "menu.masterData", // master-data
        icon: "key",
        roles: allRoles,
        accessType: -1,
        permissionLevel: -1,
        items: [
          {
            text: "menu.codes", // codes
            icon: "none",
            path: "/master/codes",
            roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
            accessType: -1,
            permissionLevel: -1,
          },
          {
            text: "menu.baselineAssumptions", // business-assumptions
            icon: "none",
            roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
            accessType: -1,
            permissionLevel: -1,
            items: [
              {
                text: "menu.transport", // mode-of-transport
                icon: "car",
                roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
                accessType: -1,
                permissionLevel: -1,
                items: [
                  {
                    text: "menu.transportAssumptions", // Transport (Journey Leg) Assumptions
                    icon: "none",
                    path: "/transport/transport-assumptions",
                    roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
                    accessType: -1,
                    permissionLevel: -1,
                  },
                  {
                    text: "menu.modesOfTransport", // Mode of Transport"
                    icon: "none",
                    path: "/transport/modes-of-transport",
                    roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
                    accessType: -1,
                    permissionLevel: -1,
                  },
                ],
                // path: "/mode-of-transport",
              },
              // {
              //   text: "menu.wasteFactor", // waste-factor
              //   icon: "chevronright",
              //   path: "/waste-factor",
              //   roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
              //   accessType: -1,
              //   permissionLevel: -1,
              // },
              // {
              //   text: "menu.fuelType", // fuel-type
              //   icon: "chevronright",
              //   path: "/fuel-type",
              //   roles: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
              //   accessType: -1,
              //   permissionLevel: -1,
              // },
            ],
          },
          {
            text: "menu.emissionFactor", // emmission-factor
            icon: "none",
            roles: allRoles,
            accessType: AccessType.CARBON_LIBRARY,
            permissionLevel: [AccessLevel.FULL_ACCESS, AccessLevel.READ_ONLY],
            items: [
              {
                text: "menu.resourceImport", // resource-import
                icon: "none",
                path: "/resource-emission",
                roles: allRoles,
                accessType: AccessType.CARBON_LIBRARY,
                permissionLevel: [AccessLevel.FULL_ACCESS, AccessLevel.READ_ONLY],
              },
              // {
              //   text: "menu.resourceUpdate", // resource-update
              //   icon: "chevronright",
              //   path: "/carbon-coefficient",
              // },
              // { // Hiding this menu as a part of CCFT-736
              //   text: "menu.itemImport", // item-import
              //   icon: "chevronright",
              //   path: "/item-emission",
              //   roles: allRoles,
              //   accessType: -1,
              //   permissionLevel: -1,
              // },
              // {
              //   text: "menu.itemUpdate", // item-update
              //   icon: "chevronright",
              //   path: "/item-coefficient",
              // },
            ],
          },
          {
            text: "menu.dynamicCarbonLibrary", // dynamic-carbon-library
            icon: "none",
            path: "/master/dynamic-carbon-library",
            roles: allRoles,
            accessType: AccessType.DYNAMIC_CARBON_LIBRARY,
            permissionLevel: [AccessLevel.READ_ONLY, AccessLevel.FULL_ACCESS],
          },
        ],
      },
      {
        text: "menu.rolebasedaction", // role-based-action
        icon: "group",
        roles: [RoleType.ADMINISTRATORS],
        accessType: -1,
        permissionLevel: -1,
        items: [
          {
            text: "menu.assignestimatorsroles", // assign-estimators-roles
            icon: "none",
            path: "/assign-estimators-roles",
            roles: allRoles,
            accessType: AccessType.GRANT_RIGHTS,
            permissionLevel: [AccessLevel.FULL_ACCESS, AccessLevel.READ_ONLY],
          },
          {
            text: "menu.rolepermissions", // role-permission
            path: "/role-permission",
            icon: "none",
            roles: [RoleType.ADMINISTRATORS],
            accessType: -1,
            permissionLevel: -1,
          },
        ],
      },
    ],
  },
  {
    text: "menu.powerBi", // power-bi
    path: "/powerBI",
    icon: "chart",
    roles: allRoles,
    accessType: -1,
    permissionLevel: -1,
    visible: ["www.lowcarb.test.benchmarkestimating.com", "localhost"].includes(
      window.location.hostname
    ),
  },
]
