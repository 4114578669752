import { get } from "lodash"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  fetchMultipleCodesActions,
  fetchCodesByCategoryAction,
  createCodeForCategoryAction,
  fetchCarbonCodeCategoriesAction,
} from "./middleware"
import { CarbonCodesCategoryItem, CodeItems, MasterState } from "./types"

const initialState: MasterState = {
  loading: LoadingState.DEFAULT,
  codeItems: [],
  carbonCodeCategory: [],
  multipleCodes: { categoryCodes: [], transportCodes: [] },
}

const masterSlice = createSlice({
  name: "Masters",
  initialState,
  reducers: {
    setLoading: (state: MasterState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    createCode: (state: MasterState, { payload }: PayloadAction<CodeItems>) => {
      const items = [...state.codeItems]
      items.push(payload)
      return { ...state, codeItems: items }
    },
    updateCode: (state: MasterState, { payload }: PayloadAction<CodeItems>) => {
      const items = [...state.codeItems]
      const index = items.findIndex((item: CodeItems) => item.Id === payload.Id)
      if (index >= 0) {
        items[index] = { ...items[index], ...payload }
      }
      return { ...state, codeItems: items }
    },
    deleteCode: (state: MasterState, { payload }: PayloadAction<string>) => {
      const data: CodeItems[] = [...state.codeItems]
      const filteredCodes = data.filter((item: CodeItems) => item.Id !== payload)
      return {
        ...state,
        codeItems: filteredCodes,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCarbonCodeCategoriesAction.fulfilled,
      (state: MasterState, { payload }: PayloadAction<CarbonCodesCategoryItem[]>) => {
        return { ...state, carbonCodeCategory: payload }
      }
    )
    builder.addCase(
      fetchCodesByCategoryAction.fulfilled,
      (state: MasterState, { payload }: PayloadAction<CodeItems[]>) => ({
        ...state,
        codeItems: payload,
        loading: LoadingState.SUCCESS,
      })
    )
    builder.addCase(
      fetchMultipleCodesActions.fulfilled,
      (state: MasterState, { payload }: PayloadAction<any>) => {
        let categoryCodes = get(payload, "categoryCodes") || []
        let transportCodes = get(payload, "transportCodes") || []

        categoryCodes = categoryCodes.map((item: any) => ({ ...item, Id: Number(item?.Id) }))
        transportCodes = transportCodes.map((item: any) => ({ ...item, Id: Number(item?.Id) }))

        const multipleCodes = { categoryCodes, transportCodes }

        return {
          ...state,
          multipleCodes,
          loading: LoadingState.SUCCESS,
        }
      }
    )
    builder.addCase(
      createCodeForCategoryAction.fulfilled,
      (state: MasterState, { payload }: PayloadAction<CodeItems[]>) => {
        return { ...state, codeItems: payload, loading: LoadingState.SUCCESS }
      }
    )
  },
})

export const { setLoading, updateCode, createCode, deleteCode } = masterSlice.actions

export const masterSelector = (state: RootState) => state.Masters

export default masterSlice.reducer
