import React, { useEffect } from "react"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"
import BreadCrumb from "components/bread-crumb"
import { updateRolePermission } from "redux/role-permission/rolePermissionSlice"
import EstimatorRoleTable from "./components/estimatorRolesTable"
import RollPermissionHeader from "../role-permission/components/rolePermissionHeader"
import EstimatorsRoleBreadCrumb from "./utils"

const RolePermission: React.FC = (): JSX.Element => {
  useDocumentTitle("Estimator Roles | Cost and Carbon Forecasting tool")

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateRolePermission(1))
  }, [])

  return (
    <div className="estimateLevelMain">
      <BreadCrumb data={EstimatorsRoleBreadCrumb(t)} />
      <RollPermissionHeader />
      <EstimatorRoleTable />
    </div>
  )
}

export default RolePermission
