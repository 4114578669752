import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { AdvanceUserList, EstimatorsRoleState } from "./types"
import { fetchAssignedAdvanceUserAction } from "./middleware"

const INITIAL_STATE: EstimatorsRoleState = {
  advanceUserList: [],
  estimatorsAssigned: [],
  assignedPermission: [],
  loading: LoadingState.DEFAULT,
}

const estimatorsRoleSlice = createSlice({
  name: "EstimatorsRole",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state: EstimatorsRoleState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    estimatorsNotAssigned: (state, action: PayloadAction<any[]>) => {
      state.advanceUserList = action.payload
    },
    estimatorsAssigned: (state, action: PayloadAction<any[]>) => {
      state.estimatorsAssigned = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAssignedAdvanceUserAction.fulfilled,
      (state: EstimatorsRoleState, { payload }: PayloadAction<AdvanceUserList[]>) => ({
        ...state,
        advanceUserList: payload,
        loading: LoadingState.DEFAULT,
      })
    )
  },
})

export const { setLoading, estimatorsNotAssigned, estimatorsAssigned } = estimatorsRoleSlice.actions

export const estimatorsRoleSelector = (state: RootState) => state.EstimatorsRole

export default estimatorsRoleSlice.reducer
