/* eslint-disable react/jsx-props-no-spreading */
import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { iftSelector } from "redux/ift/iftSlice"
import FieldLabel from "./fieldLabel"
// import SaveUndoBtns from "./saveUndoBtns"
import { keyDataFields } from "../utils"

const KeyDataHead = () => {
  const { t } = useTranslation()

  const { estimateId } = useParams()
  const { iftKeyDataFields } = useSelector(iftSelector)

  const fields = useMemo(
    () => keyDataFields({ t, hideFields: !!estimateId, iftKeyDataFields }),
    [estimateId, iftKeyDataFields]
  )

  return (
    <>
      <div className="headTitleRow">
        <h6>{t("keyData.projKeyData")}</h6>
        {/* {estimateId && <SaveUndoBtns />} */}
      </div>
      <div className="headFields">
        {fields.map((item) => (
          <FieldLabel key={item.title} {...item} />
        ))}
      </div>
    </>
  )
}

export default memo(KeyDataHead)
