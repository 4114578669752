import { useSelector } from "react-redux"
import { estimateLibrarySelector } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import useDocumentTitle from "hooks/useDocumentTitle"
import AddNewContactorEstimateLibrary from "./AddContractorSpecificLibraryPopup"
import LibraryManagementTable from "./LibraryManagementTable"

const LibraryManagementScreen = () => {
  useDocumentTitle("Library Management | Cost and Carbon Forecasting tool")
  const { showAddNewLibrary } = useSelector(estimateLibrarySelector)
  return (
    <div className="libraryManagementMain">
      <LibraryManagementTable />
      {showAddNewLibrary && <AddNewContactorEstimateLibrary />}
    </div>
  )
}

export default LibraryManagementScreen
