import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { RootState } from "redux/store"
import { RoleType } from "redux/role-permission/types"
import { IProject, IResource } from "redux/projects/types"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { LoadingState } from "types/AppNav"
import { setLoading } from "./iftSlice"
import {
  fetchPreviousEstimateAsync,
  fetchRiskByProjectAsync,
  updateIFTScheduleAsync,
  getIFTScheduleAsync,
  createOrUpdateRiskAsync,
  fetchCostElementsForRiskAsync,
  removeRiskRegisterAsync,
  fetchIFTScopeAsync,
  addProvisionalSumItemAsync,
  fetchResourceGroupIFTScopeAsync,
  updateScopeItemsAsync,
  getScopeItemsDetailsAsync,
  updateScopeResourcesAsync,
  fetchResourceLibraryIFTScopeAsync,
  fetchResourceLibraryContractorIFTScopeAsync,
  addResourceIFTScopeAsync,
  fetchItemGroupIFTScopeAsync,
  fetchItemIFTScopeAsync,
  addItemAsync,
  getIFTSpendAsync,
  updateIFTSpendAsync,
  nominatedEstimateRevisionAsync,
  getIFTInflationAsync,
  getIFTInflationMasterDataAsync,
  updateIFTInflationAsync,
  deleteScopeProjectItemAsync,
  deleteScopeProjectResourceAsync,
  addSubItemAsync,
  fetchSubItemGroupIFTScopeAsync,
  fetchSubItemIFTScopeAsync,
  fetchIFTSpendResultAsync,
  getScopeResourceDetailsAsync,
  fetchKeyDataFieldsDataAsync,
  fetchKeyDataRangeDistTableAsync,
} from "./services"
import {
  IItem,
  ISpend,
  KeyDataFieldsResponse,
  KeyDataRangeTableResponse,
  PreviousEstimateResponse,
  ProvisionalSumItem,
  RiskAllocationItem,
  RiskRegisterItem,
  SubItem,
} from "./types"

// IFT Key Data from Here...
export const fetchKeyDataFieldsDataAction = createAsyncThunk<
  KeyDataFieldsResponse,
  { projectId: number | string | undefined; estimateId: number | string | undefined }
>("fetchKeyDataFieldsDataAsync", async (request, { rejectWithValue }) => {
  try {
    const response: SuccessResponse<KeyDataFieldsResponse> | ErrorResponse =
      await fetchKeyDataFieldsDataAsync(request)
    const errRes = (response as ErrorResponse).message

    if (errRes) {
      return rejectWithValue(errRes)
    }

    return (response as SuccessResponse<KeyDataFieldsResponse>).data!!
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchKeyDataRangeDistTableAction = createAsyncThunk<
  KeyDataRangeTableResponse[],
  number | string | undefined
>("fetchKeyDataRangeDistTableAsync", async (projectId, { rejectWithValue }) => {
  try {
    const response: SuccessResponse<KeyDataRangeTableResponse[]> | ErrorResponse =
      await fetchKeyDataRangeDistTableAsync(projectId)
    const errRes = (response as ErrorResponse).message

    if (errRes) {
      return rejectWithValue(errRes)
    }

    return (response as SuccessResponse<KeyDataRangeTableResponse[]>).data!!
  } catch (err) {
    return rejectWithValue(err)
  }
})

// IFT PREVIOUS ESTIMATE FROM HERE...
export const fetchPreviousEstimatesAction = createAsyncThunk<PreviousEstimateResponse, number>(
  "fetchPreviousEstimates",
  async (projectId, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(LoadingState.LOADING))
    try {
      const response: SuccessResponse<PreviousEstimateResponse> | ErrorResponse =
        await fetchPreviousEstimateAsync(projectId)
      const errRes = (response as ErrorResponse).message

      if (errRes) {
        return rejectWithValue(errRes)
      }

      return (response as SuccessResponse<PreviousEstimateResponse>).data!!
    } catch (err) {
      return rejectWithValue(err)
    } finally {
      dispatch(setLoading(LoadingState.DEFAULT))
    }
  }
)

// IFT Schedule from here...
export const getIFTScheduleBaselineAction = createAsyncThunk<any, string | number>(
  "ift/getIFTBaselineScheduleAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getIFTScheduleAsync(projectId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getIFTScheduleRevisionAction = createAsyncThunk<any, string | number>(
  "ift/getIFTRevisionScheduleAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getIFTScheduleAsync(projectId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateIFTScheduleAction = createAsyncThunk<any, any[]>(
  "ift/updateIFTScheduleAsync",
  async (request: any[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateIFTScheduleAsync(request)
      const errRes = response as ErrorResponse

      if (errRes.code) {
        notify(
          "IFT Schedule Data update failed. Please reload page to get proper data.",
          "error",
          2000
        )
        return rejectWithValue(errRes.message)
      }

      notify("IFT Schedule Data updated successfully.", "success", 2000)
      return response.data
    } catch (error) {
      notify(
        "IFT Schedule Data update failed. Please reload page to get proper data.",
        "error",
        2000
      )
      return rejectWithValue(error)
    }
  }
)

// IFT Scope from here
export const fetchBaselineIFTScopeAction = createAsyncThunk<
  any,
  { projectId: string | number | undefined; estimateId: string | number | undefined }
>("ift/fetchBaselineIFTScopeAsync", async ({ projectId, estimateId }, { rejectWithValue }) => {
  try {
    const response: any | ErrorResponse = await fetchIFTScopeAsync(projectId, estimateId)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    return response.data
  } catch (error: unknown) {
    return rejectWithValue(error)
  }
})

export const fetchRevisionIFTScopeAction = createAsyncThunk<any, string | number | undefined>(
  "ift/fetchRevisionIFTScopeAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchIFTScopeAsync(projectId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getScopeItemDetailsAction = createAsyncThunk<IProject, number | string | undefined>(
  "ift/getItemsDetailsAsync",
  async (itemId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getScopeItemsDetailsAsync(itemId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getScopeResourceDetailsAction = createAsyncThunk<
  IProject,
  number | string | undefined
>("ift/getResourceDetailsAsync", async (resourceId, { rejectWithValue }) => {
  try {
    const response: any | ErrorResponse = await getScopeResourceDetailsAsync(resourceId)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    return response.data
  } catch (error: unknown) {
    return rejectWithValue(error)
  }
})

export const updateScopeItemsAction = createAsyncThunk<any, Array<IItem>>(
  "ift/updateItemsAction",
  async (items: Array<IItem>, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateScopeItemsAsync(items)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("Item data updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateScopeResourcesAction = createAsyncThunk<any, Array<IResource>>(
  "ift/updateScopeResourcesAsync",
  async (resources: Array<IResource>, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateScopeResourcesAsync(resources)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("Resource Quantity updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const deleteScopeProjectItemAction = createAsyncThunk<
  SuccessResponse<any>,
  { resourceId: number; projectId: number }
>("ift/deleteScopeProjectItemAsync", async (request, { rejectWithValue }) => {
  const response: SuccessResponse<any> | ErrorResponse = await deleteScopeProjectItemAsync(request)
  const errRes = (response as ErrorResponse)?.message

  if (errRes) {
    notify(errRes, "error", 2000)
    return rejectWithValue(errRes)
  }

  notify("Project Item deleted successfully.", "success", 2000)
  return response
})

export const deleteScopeProjectResourceAction = createAsyncThunk<
  SuccessResponse<any>,
  { resourceId: number; projectId: number }
>("ift/deleteScopeProjectResourceAsync", async (request, { rejectWithValue }) => {
  const response: SuccessResponse<any> | ErrorResponse = await deleteScopeProjectResourceAsync(
    request
  )
  const errRes = (response as ErrorResponse)?.message

  if (errRes) {
    notify(errRes, "error", 2000)
    return rejectWithValue(errRes)
  }

  notify("Project Resource deleted successfully.", "success", 2000)
  return response
})

export const fetchItemGroupIFTScopeAction = createAsyncThunk<any>(
  "ift/fetchItemGroupIFTScopeAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchItemGroupIFTScopeAsync()
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchItemIFTScopeAction = createAsyncThunk<any, string | number | undefined>(
  "ift/fetchItemIFTScopeAction",
  async (itemGroupId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchItemIFTScopeAsync(itemGroupId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchResourceGroupIFTScopeAction = createAsyncThunk<any, string | number | undefined>(
  "ift/fetchResourceGroupIFTScopeAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchResourceGroupIFTScopeAsync()
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchResourceLibraryIFTScopeAction = createAsyncThunk<
  any,
  string | number | undefined
>("ift/fetchResourceLibraryIFTScopeAction", async (resourceGroupId, { rejectWithValue }) => {
  try {
    const response: any | ErrorResponse = await fetchResourceLibraryIFTScopeAsync(resourceGroupId)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    return response.data || []
  } catch (error: unknown) {
    return rejectWithValue(error)
  }
})

export const fetchResourceLibraryContractorIFTScopeAction = createAsyncThunk<
  any,
  string | number | undefined
>("ift/fetchResourceLibraryContractorIFTScopeAction", async (contractor, { rejectWithValue }) => {
  try {
    const response: any | ErrorResponse = await fetchResourceLibraryContractorIFTScopeAsync(
      contractor
    )
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    return response.data || []
  } catch (error: unknown) {
    return rejectWithValue(error)
  }
})

export const addProvisionalSumItemAction = createAsyncThunk<any, ProvisionalSumItem[]>(
  "scope/addProvisionalSumItem",
  async (request: ProvisionalSumItem[], { rejectWithValue }) => {
    const response: SuccessResponse<any> | ErrorResponse = await addProvisionalSumItemAsync(request)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    notify("Provisional item added successfully", "success", 2000)
    return response
  }
)

export const addItemAction = createAsyncThunk<any, ProvisionalSumItem[]>(
  "scope/addItem",
  async (request: ProvisionalSumItem[], { rejectWithValue }) => {
    const response: SuccessResponse<any> | ErrorResponse = await addItemAsync(request)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    notify("Item(s) added successfully", "success", 2000)
    return response
  }
)

export const addResourceIFTScopeAction = createAsyncThunk<any, any>(
  "ift/addResourceIFTScopeAction",
  async (request, { rejectWithValue, getState }) => {
    try {
      const response: any | ErrorResponse = await addResourceIFTScopeAsync(request)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      const { userRole } = (getState() as RootState).Profile

      if (userRole && userRole?.ROLE_ID === RoleType.CONTRACTOR) {
        notify(
          "New Resources were added. To set up Resource Rates, go the Estimate-specific Library.",
          "success",
          2000
        )
      } else {
        notify("New Resources were added", "success", 2000)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchSubItemGroupIFTScopeAction = createAsyncThunk<any>(
  "ift/fetchSubItemGroupIFTScopeAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchSubItemGroupIFTScopeAsync()
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchSubItemIFTScopeAction = createAsyncThunk<any, string | number | undefined>(
  "ift/fetchSubItemIFTScopeAsync",
  async (subItemGroupId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchSubItemIFTScopeAsync(subItemGroupId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const addSubItemAction = createAsyncThunk<any, SubItem[]>(
  "scope/addSubItem",
  async (request: SubItem[], { rejectWithValue }) => {
    const response: SuccessResponse<any> | ErrorResponse = await addSubItemAsync(request)
    const errorResponse = response as ErrorResponse

    if (errorResponse?.code) {
      notify(errorResponse.message, "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    notify("Sub-Item(s) added successfully", "success", 2000)
    return response
  }
)

// IFT Risk Register From Here..
export const fetchRiskRegistersByProjectAction = createAsyncThunk<RiskRegisterItem[], number>(
  "ift/fetchRiskRegister",
  async (projectId: number, { rejectWithValue }) => {
    const response: SuccessResponse<RiskRegisterItem[]> | ErrorResponse =
      await fetchRiskByProjectAsync(projectId)
    const errRes = (response as ErrorResponse)?.message

    if (errRes) {
      notify(errRes, "error", 2000)
      return rejectWithValue(errRes)
    }

    const riskItems = (response as SuccessResponse<RiskRegisterItem[]>).data || []
    return riskItems
  }
)

export const fetchCostElementsForRiskAction = createAsyncThunk<RiskAllocationItem[]>(
  "ift/fetchRiskAllocationAction",
  async (_, { rejectWithValue }) => {
    const response = await fetchCostElementsForRiskAsync()
    const errRes = (response as ErrorResponse)?.message

    if (errRes) {
      notify(errRes, "error", 2000)
      return rejectWithValue(errRes)
    }

    const riskAllocations = (response as SuccessResponse<RiskAllocationItem[]>).data || []
    return riskAllocations
  }
)

export const createOrUpdateRiskRegisterAction = createAsyncThunk<
  RiskRegisterItem[],
  RiskRegisterItem[]
>("ift/createOrUpdateRisk", async (request: RiskRegisterItem[], { rejectWithValue }) => {
  const response: SuccessResponse<any> | ErrorResponse = await createOrUpdateRiskAsync(request)
  const errRes = (response as ErrorResponse)?.message

  if (errRes) {
    notify(errRes, "error", 2000)
    return rejectWithValue(errRes)
  }

  if ((response as SuccessResponse<any>).statusCode === 200) {
    const fetchRes: SuccessResponse<RiskRegisterItem[]> | ErrorResponse =
      await fetchRiskByProjectAsync(request[0].PROJECT_ID || 0)

    const fetchErrRes = (fetchRes as ErrorResponse)?.message
    if (fetchErrRes) {
      notify(fetchErrRes, "error", 2000)
      return rejectWithValue(fetchErrRes)
    }

    notify("Risk updated successfully", "success", 2000)
    const riskItems = (fetchRes as SuccessResponse<RiskRegisterItem[]>).data || []
    return riskItems
  }

  return rejectWithValue("")
})

export const deleteRiskRegisterAction = createAsyncThunk<SuccessResponse<any>, number[]>(
  "ift/removeRiskRegister",
  async (riskId: number[], { rejectWithValue }) => {
    const response: SuccessResponse<any> | ErrorResponse = await removeRiskRegisterAsync(riskId)
    const errRes = (response as ErrorResponse)?.message

    if (errRes) {
      notify(errRes, "error", 2000)
      return rejectWithValue(errRes)
    }

    notify("Risk removed successfully", "success", 2000)
    return response
  }
)

// IFT Inflation and Spend from here
export const getIFTSpendAction = createAsyncThunk<any, string | number | undefined>(
  "ift/getIFTSpendAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getIFTSpendAsync(projectId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      let parnentIndex = 1
      let index = 1
      const finalItems = response.data.map((item: any) => {
        // if (item.CeCode === "1.2") {
        //   item.CeCode = "1.1"
        // }
        if (item.RowType === "SECTION") {
          item.Parent_ID = -1
          parnentIndex = index
        } else {
          item.Parent_ID = parnentIndex
        }
        item.ID = index
        index += 1
        return item
      })
      return finalItems
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchIFTSpendResultAction = createAsyncThunk<any, string | number | undefined>(
  "ift/fetchIFTResultAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await fetchIFTSpendResultAsync(projectId)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      let parnentIndex = 1
      let index = 1
      const finalItems = response.data.map((item: any) => {
        // if (item.CeCode === "1.2") {
        //   item.CeCode = "1.1"
        // }
        if (item.RowType === "SECTION") {
          item.Parent_ID = -1
          parnentIndex = index
        } else {
          item.Parent_ID = parnentIndex
        }
        item.ID = index
        index += 1
        return item
      })
      return finalItems
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateIFTSpendAction = createAsyncThunk<any, Array<ISpend>>(
  "ift/updateIFTSpendAction",
  async (items: Array<ISpend>, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateIFTSpendAsync(items)
      const errorResponse = response as ErrorResponse

      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("IFT Spend data updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const nominatedEstimateRevisionAction = createAsyncThunk<any, number | undefined>(
  "nominatedEstimateRevisionAction",
  async (request: number | undefined, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await nominatedEstimateRevisionAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("Nominated Revision Successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      notify("Nominated Revision Failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)

export const getIFTInflationAction = createAsyncThunk<any, string | number | undefined>(
  "ift/getIFTInflationAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getIFTInflationAsync(projectId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getIFTInflationMasterDataAction = createAsyncThunk<any>(
  "ift/getIFTInflationMasterDataAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getIFTInflationMasterDataAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateIFTInflationAction = createAsyncThunk<
  any,
  { projectId?: string | number; request: any[] }
>("ift/updateIFTInflationAsync", async (data: any, { rejectWithValue }) => {
  try {
    const response: any | ErrorResponse = await updateIFTInflationAsync(data)
    const errRes = response as ErrorResponse

    if (errRes.code) {
      notify(
        "IFT Inflation Data update failed. Please reload page to get proper data.",
        "error",
        2000
      )
      return rejectWithValue(errRes.message)
    }

    notify("IFT Inflation Data updated successfully.", "success", 2000)
    return response.data
  } catch (error) {
    notify(
      "IFT Inflation Data update failed. Please reload page to get proper data.",
      "error",
      2000
    )
    return rejectWithValue(error)
  }
})
