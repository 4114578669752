import { useEffect, useMemo, useRef, useState } from "react"
import BreadCrumb from "components/bread-crumb"
import isNaN from "lodash/isNaN"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import Button from "devextreme-react/button"
import DataGrid, {
  AsyncRule,
  Column,
  ColumnFixing,
  Editing,
  FilterRow,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { MasterState } from "redux/masters/types"
import { sharedSelector } from "redux/shared/sharedSlice"
import { masterSelector } from "redux/masters/masterSlice"
import { fetchCarbonCodeCategoriesAction } from "redux/masters/middleware"
import {
  ModeOfTransportState,
  UpdateModeOfTransportRequest,
} from "redux/basline-assumptions/transport/mode-of-transport/types"
import {
  fetchAllModeOfTransportationAction,
  updateTranportValueAction,
} from "redux/basline-assumptions/transport/mode-of-transport/middleware"
import {
  modeOfTransportSelector,
  setCarbonCodeForModeOfTransport,
  updateModeOfTransportData,
} from "redux/basline-assumptions/transport/mode-of-transport/modeOfTransportSlice"
import { allowedPageSizes } from "utils/config"
import useDocumentTitle from "hooks/useDocumentTitle"

const ModesOfTransport: React.FC = (): JSX.Element => {
  useDocumentTitle("Mode Of Transport | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { windowHeight } = useSelector(sharedSelector)
  const { carbonCodeCategory }: MasterState = useSelector(masterSelector)
  const { modeOfTransport, carbonCodeItem }: ModeOfTransportState =
    useSelector(modeOfTransportSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const [isEditableColumn, setEditableColumn] = useState<boolean>(false)

  const setSaveRevertToolbar = (value: boolean) => {
    dataGridRef.current?.instance.option("toolbar.items[1].disabled", value) // save
    dataGridRef.current?.instance.option("toolbar.items[2].disabled", value) // revert
  }

  const store = useMemo(
    () =>
      new CustomStore({
        load: () => modeOfTransport,
        key: "id",
        update: () => {
          setEditableColumn(false)
          dataGridRef.current?.instance.option("toolbar.items[0].disabled", false)
          setSaveRevertToolbar(true)
          return Promise.resolve()
        },
      }),
    [modeOfTransport]
  )

  const handleInitNewRowOrStartEditing = () => setSaveRevertToolbar(false)

  const handleSaveClick = () => dataGridRef.current?.instance.saveEditData()

  const handleEditClick = () => {
    setEditableColumn(!isEditableColumn)
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
  }

  const handleRevertAll = () => {
    dataGridRef.current?.instance.cancelEditData()
    setSaveRevertToolbar(true)
  }

  const handleValueValidation = (params: any) => {
    if (isNaN(Number(params.value))) {
      return Promise.reject()
    }
    return Promise.resolve(true)
  }

  useEffect(() => {
    if (!carbonCodeItem && carbonCodeCategory.length === 0) {
      dispatch(fetchCarbonCodeCategoriesAction())
    } else if (carbonCodeCategory.length > 0 && !carbonCodeItem) {
      dispatch(setCarbonCodeForModeOfTransport(carbonCodeCategory))
    } else if (carbonCodeItem) {
      dispatch(fetchAllModeOfTransportationAction(carbonCodeItem))
    }
    setSaveRevertToolbar(true)
  }, [carbonCodeItem, carbonCodeCategory])

  return (
    <>
      <BreadCrumb
        data={[
          {
            name: t("menu.modesOfTransport"),
          },
        ]}
      />
      <br />
      <DataGrid
        id="modes-of-transport-grid"
        dataSource={store}
        ref={dataGridRef}
        showBorders
        columnAutoWidth
        highlightChanges
        repaintChangesOnly
        showColumnLines
        allowColumnReordering
        scrolling={{ mode: "standard" }}
        noDataText={t("noRecords")}
        onEditingStart={handleInitNewRowOrStartEditing}
        onSaving={(elements) => {
          dispatch(updateModeOfTransportData(elements.changes as UpdateModeOfTransportRequest[]))
          dispatch(updateTranportValueAction(elements.changes as UpdateModeOfTransportRequest[]))
        }}
        height={windowHeight - 148}
      >
        <ColumnFixing enabled />
        <FilterRow visible />
        <HeaderFilter visible />
        <SearchPanel visible width={340} />
        <Paging enabled defaultPageSize={10} />
        <Editing mode="batch" allowUpdating allowDeleting={false} allowAdding={false} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.edit")}>
              <Button icon="edit" stylingMode="text" onClick={handleEditClick} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.undoAll")}>
              <Button icon="revert" stylingMode="text" onClick={handleRevertAll} />
            </span>
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
        <Column
          caption={t("modeOfTransportationScreen.columnHeader")}
          alignment="left"
          fixed
          fixedPosition="left"
          cssClass="parent-wrapper"
        >
          <Column
            dataField="code.Name"
            caption={t("modeOfTransportationScreen.modeOfTransportation")}
            alignment="left"
            cssClass="border-class"
            allowEditing={false}
          />
          <Column
            dataField="value"
            caption={t("modeOfTransportationScreen.carbonFactor")}
            alignment="left"
            dataType="number"
            allowHeaderFiltering
            allowEditing={isEditableColumn}
          >
            <AsyncRule
              ignoreEmptyValue
              validationCallback={handleValueValidation}
              message={t("modeOfTransportationScreen.carbonFactorValidation")}
            />
          </Column>
        </Column>
      </DataGrid>
    </>
  )
}

export default ModesOfTransport
