import { useCallback, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, DataGrid } from "devextreme-react"
import { Export, Column, FilterRow, Item, Pager, Paging, Toolbar } from "devextreme-react/data-grid"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, RoleType } from "redux/role-permission/types"
import { comparatorSelector } from "redux/comparator/iftComparatorSlice"
import { allowedPageSizes, config, defaultPageSize } from "utils/config"
import { identifyPermissions, numberFormatterConfig } from "utils/common-utils"
import {
  ExportToFile,
  comparatorEditorOptions,
  onComparatorCellPrepared,
  comparatorCellDescriptionRender,
} from "./tableConstants"
import NominatedRevision from "../nominatedRevision"

function IFTComparatorLevelTable() {
  const { t } = useTranslation()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { comp, revisionNumbers, compareCompByKeys } = useSelector(comparatorSelector)

  const dataGridRef = useRef(null)
  const [popupVisible, setPopupVisible] = useState(false)
  const nmintePrefRevPermsn = useMemo(
    () =>
      config.clientName === "HE" &&
      identifyPermissions(userRole, -1, AccessLevel.FULL_ACCESS, [
        RoleType.ADMINISTRATORS,
        RoleType.AUTHORITY_ESTIMATOR,
      ]),
    [userRole]
  )

  const onExporting = useCallback((e: any) => ExportToFile(e), [])

  return (
    <>
      <NominatedRevision popupVisible={popupVisible} onClose={() => setPopupVisible(false)} />
      <DataGrid
        id="estimateLevelItems"
        className="compact-grid compact-grid-tree"
        ref={dataGridRef}
        dataSource={comp}
        showBorders
        showRowLines
        columnAutoWidth
        showColumnLines
        rowAlternationEnabled
        allowColumnResizing
        errorRowEnabled={false}
        height={windowHeight - 232}
        onCellPrepared={onComparatorCellPrepared}
        onExporting={onExporting}
        headerFilter={{ visible: true }}
      >
        <Export enabled />
        <FilterRow visible />
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar visible>
          <Item name="exportButton" />
          <Item location="after" visible={nmintePrefRevPermsn}>
            <span title="Nominate Preferred Revision">
              <Button
                stylingMode="text"
                text={t("iftComparatorMain.nominatePreferredRevision")}
                onClick={() => setPopupVisible(true)}
              />
            </span>
          </Item>
        </Toolbar>
        <Column caption={t("iftComparatorMain.baseline")} alignment="center">
          <Column allowSorting={false} />
          <Column dataField="CE_CODE" caption={t("iftComparatorMain.code")} dataType="string" />
          <Column
            dataField="CE_DESCRIPTION"
            caption={t("iftComparatorMain.desc")}
            cellRender={comparatorCellDescriptionRender}
          />
        </Column>
        {compareCompByKeys.includes("Risk Cost") && (
          <Column caption={t("iftComparatorMain.risk")} alignment="center">
            {revisionNumbers.map((rev, index) => (
              <Column
                key={`V${index + 1}_VARIANCE_RISK_COST`}
                dataField={`V${index + 1}_VARIANCE_RISK_COST`}
                caption={`V${index + 1}`}
                editorOptions={comparatorEditorOptions}
                format={numberFormatterConfig}
                allowFiltering={false}
              />
            ))}
          </Column>
        )}
        {compareCompByKeys.includes("Inflation") && (
          <Column caption={t("iftComparatorMain.inflation")} alignment="center">
            {revisionNumbers.map((rev, index) => (
              <Column
                key={`V${index + 1}_VARIANCE_INFLATION_COST`}
                dataField={`V${index + 1}_VARIANCE_INFLATION_COST`}
                caption={`V${index + 1}`}
                editorOptions={comparatorEditorOptions}
                format={numberFormatterConfig}
                allowFiltering={false}
              />
            ))}
          </Column>
        )}
      </DataGrid>
    </>
  )
}

export default IFTComparatorLevelTable
