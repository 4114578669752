/* eslint-disable camelcase */
import { find, get, isEmpty } from "lodash"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { getLocalStorage, renderCurrencyCell, setLocalStorage } from "utils/common-utils"
import {
  iftState,
  itemGroup,
  iftScopeItem,
  IFTInflationItem,
  RiskRegisterItem,
  RiskAllocationItem,
  iftRoleBasedPermissions,
  PreviousEstimateResponse,
  KeyDataFieldsResponse,
  KeyDataRangeTableResponse,
} from "./types"
import {
  getIFTScheduleBaselineAction,
  getIFTScheduleRevisionAction,
  fetchPreviousEstimatesAction,
  fetchRiskRegistersByProjectAction,
  fetchCostElementsForRiskAction,
  createOrUpdateRiskRegisterAction,
  fetchBaselineIFTScopeAction,
  fetchRevisionIFTScopeAction,
  fetchResourceGroupIFTScopeAction,
  getScopeItemDetailsAction,
  updateScopeItemsAction,
  updateScopeResourcesAction,
  fetchResourceLibraryIFTScopeAction,
  fetchResourceLibraryContractorIFTScopeAction,
  addResourceIFTScopeAction,
  fetchItemGroupIFTScopeAction,
  fetchItemIFTScopeAction,
  getIFTSpendAction,
  updateIFTSpendAction,
  getIFTInflationAction,
  getIFTInflationMasterDataAction,
  deleteScopeProjectItemAction,
  deleteScopeProjectResourceAction,
  addSubItemAction,
  fetchSubItemGroupIFTScopeAction,
  fetchSubItemIFTScopeAction,
  addItemAction,
  fetchIFTSpendResultAction,
  getScopeResourceDetailsAction,
  fetchKeyDataFieldsDataAction,
  fetchKeyDataRangeDistTableAction,
} from "./middleware"
import { getIFTScheduleParent, transformIFTScheduleAPIResponse } from "./utils"

const initialState: iftState = {
  addedIds: [],
  editedIds: [],
  loading: LoadingState.DEFAULT,
  selectedTab: getLocalStorage("ift-selected-tab", "number"),
  estimateId: 0,
  projectId: 0,
  previousEstimate: undefined,
  resourceLibraryContractors: [],
  resourceLibraries: [],
  resourceGroups: [],
  itemGroups: [],
  items: [],
  subItemGroups: [],
  subItems: [],
  riskRegisters: [],
  riskCostElements: [],
  iftScheduleBaselineData: [],
  iftScheduleRevisionData: [],
  fromBaseline: !window.location.hash.includes("erm"),
  scopeDelConfTxt: "Are you sure you want to delete this record?",
  scopeCarbonSwitch: false,
  scopeBaselineSwitch: false,
  iftBaselineScopeData: [],
  iftRevisionScopeData: [],
  iftScopeEditItem: undefined,
  iftScopeEditResource: undefined,
  iftRoleBasedPermissions: {
    addResourcesOrItems: false,
    editResources: false,
    editItems: false,
    deleteResourcesOrItems: false,
  },
  iftSpend: [],
  iftInflation: [],
  iftInflationRowData: [],
  iftInflationMasterData: [],
  iftSpendResult: [],
  iftKeyDataFields: {},
  iftKeyDataFieldsRef: {},
  iftKeyDataRangeTableData: [],
}

const iftSlice = createSlice({
  name: "iftSlice",
  initialState,
  reducers: {
    setLoading: (state: iftState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setSelectedTab: (state: iftState, { payload }: PayloadAction<number>) => {
      setLocalStorage("ift-selected-tab", payload)

      return {
        ...state,
        selectedTab: payload,
      }
    },
    checkFromBaseline: (state: iftState) => ({
      ...state,
      fromBaseline: !window.location.hash.includes("erm"),
    }),
    // IFT Key data from here
    setIftKeyDataFields: (
      state: iftState,
      { payload }: PayloadAction<{ field: string; value: any }>
    ) => {
      const { field, value } = payload
      // "T00:00:00.000Z" suffix this for datestrings while updating in API
      return {
        ...state,
        iftKeyDataFields: { ...state.iftKeyDataFields, [field]: value },
      }
    },
    resetIftKeyDataFields: (state: iftState) => ({
      ...state,
      iftKeyDataFields: state.iftKeyDataFieldsRef,
    }),
    // IFT Schedule from here
    updateIFTScheduleData: (state: iftState, { payload }: PayloadAction<any>) => {
      const { iftScheduleRevisionData } = state
      const filteredData = iftScheduleRevisionData.filter(
        (item) => item.StoreId !== payload.StoreId
      )

      return {
        ...state,
        iftScheduleRevisionData: [...filteredData, payload].sort((a, b) => a.StoreId - b.StoreId),
      }
    },
    reset: (
      state: iftState,
      { payload }: PayloadAction<{ projectId: number; estimateId: number }>
    ) => {
      return {
        ...initialState,
        projectId: payload.projectId,
        estimateId: payload.estimateId,
        iftRoleBasedPermissions: state.iftRoleBasedPermissions,
      }
    },
    // for risk register to update locally.
    updateRiskRegister: (state: iftState, { payload }: PayloadAction<RiskRegisterItem>) => {
      let riskItems = [...state.riskRegisters]

      const index = riskItems.findIndex(
        (item: RiskRegisterItem) => item.RISK_REGISTER_ID === Number(payload.RISK_REGISTER_ID)
      )
      if (index >= 0) {
        riskItems[index] = { ...riskItems[index], ...payload }
      }
      riskItems = riskItems.map((item: any) => {
        let obj = {}
        if (item?.RISK_REGISTER_PROBABILITY > 100) {
          obj = { RISK_REGISTER_PROBABILITY: item.RISK_REGISTER_PROBABILITY / 100 }
        }
        return { ...item, ...obj }
      })

      return { ...state, riskRegisters: riskItems }
    },
    deleteRiskRegister: (state: iftState, { payload }: PayloadAction<number[]>) => {
      return {
        ...state,
        riskRegisters: state.riskRegisters.filter(
          (item: RiskRegisterItem) => !payload.includes(item.RISK_REGISTER_ID || 0)
        ),
      }
    },
    // IFT Scope from here
    scopeInit: (state: iftState) => ({
      ...state,
      scopeCarbonSwitch: false,
      scopeBaselineSwitch: false,
    }),
    setScopeCarbonSwitch: (state: iftState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      scopeCarbonSwitch: payload,
    }),
    setScopeBaselineSwitch: (state: iftState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      scopeBaselineSwitch: payload,
    }),
    setScopeDelConfTxt: (state: iftState, { payload }: PayloadAction<string>) => ({
      ...state,
      scopeDelConfTxt: payload,
    }),
    setIFTScopeEditItem: (state: iftState, { payload }: PayloadAction<any | undefined>) => ({
      ...state,
      iftScopeEditItem: payload,
    }),
    setIFTScopeEditResource: (state: iftState, { payload }: PayloadAction<any | undefined>) => ({
      ...state,
      iftScopeEditResource: payload,
    }),
    setIftRoleBasedPermissions: (
      state: iftState,
      { payload }: PayloadAction<iftRoleBasedPermissions>
    ) => ({
      ...state,
      iftRoleBasedPermissions: payload,
    }),
    updateIftRevisionScopeData: (state: iftState, { payload }: PayloadAction<any>) => {
      const { id, newRecord } = payload
      const { iftRevisionScopeData, iftScopeEditItem } = state

      let finalData = iftRevisionScopeData
      let iftScopeEditItemUpdated = iftScopeEditItem

      if (newRecord) {
        // For edit functionality
        finalData = iftRevisionScopeData.map((item: any) =>
          item.id === id ? { ...newRecord } : item
        )

        if (!isEmpty(iftScopeEditItem) && !get(newRecord, "PROJECT_RESOURCE_ID")) {
          iftScopeEditItemUpdated = { ...newRecord }
        }
      } else {
        // For delete functionality
        finalData = iftRevisionScopeData.filter((item) => item.id !== id)
      }

      return {
        ...state,
        iftScopeEditItem: iftScopeEditItemUpdated,
        iftRevisionScopeData: finalData,
      }
    },
    scopePopUpsInit: (state: iftState) => ({
      ...state,
      items: [],
      subItems: [],
      resourceLibraries: [],
      resourceLibraryContractors: [],
    }),
  },
  extraReducers: (builder) => {
    // IFT Key Data from here...
    builder.addCase(fetchKeyDataFieldsDataAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      fetchKeyDataFieldsDataAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<KeyDataFieldsResponse>) => {
        const obj = Object.keys(payload).reduce((acc, curr) => {
          let prevVal = get(payload, curr)
          if (curr.includes("DATE") && prevVal) {
            const [datePart] = prevVal.split("T")
            prevVal = datePart
          }
          if (curr === "SOFA") {
            prevVal = renderCurrencyCell(Math.round((prevVal || 0) * 100) / 100 || 0)
          }

          return { ...acc, [curr]: prevVal === null ? undefined : prevVal }
        }, {})

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftKeyDataFields: obj,
          iftKeyDataFieldsRef: obj,
        }
      }
    )
    builder.addCase(fetchKeyDataFieldsDataAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(fetchKeyDataRangeDistTableAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      fetchKeyDataRangeDistTableAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<KeyDataRangeTableResponse[]>) => {
        const iftKeyDataRangeTableData = payload.reduce((accTableData: any[], currObj) => {
          const keys = Object.keys(currObj)
          const updatedObj = keys.reduce((accObjData, currKey) => {
            let val = get(currObj, currKey)
            if (typeof val === "number" && currKey !== "PVALUE_ID") {
              val = Math.round((val || 0) * 100) / 100 || 0
            }

            return { ...accObjData, [currKey]: val }
          }, {})
          return [...accTableData, updatedObj]
        }, [])

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftKeyDataRangeTableData,
        }
      }
    )
    builder.addCase(fetchKeyDataRangeDistTableAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    // Previous estimate from here...
    builder.addCase(
      fetchPreviousEstimatesAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<PreviousEstimateResponse>) => {
        return { ...state, previousEstimate: payload, loading: LoadingState.DEFAULT }
      }
    )
    builder.addCase(fetchPreviousEstimatesAction.rejected, (state: iftState) => {
      return { ...state, loading: LoadingState.DEFAULT }
    })

    // IFT Schedule from here
    builder.addCase(getIFTScheduleBaselineAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getIFTScheduleBaselineAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const processedData = transformIFTScheduleAPIResponse(payload)

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftScheduleBaselineData: processedData,
        }
      }
    )
    builder.addCase(getIFTScheduleBaselineAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getIFTScheduleRevisionAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getIFTScheduleRevisionAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const processedData = transformIFTScheduleAPIResponse(payload)

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftScheduleRevisionData: processedData,
        }
      }
    )
    builder.addCase(getIFTScheduleRevisionAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    // IFT Scope from here
    builder.addCase(fetchBaselineIFTScopeAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      fetchBaselineIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const { BOQ } = payload || []
        const data = BOQ.map((item: any) => {
          const Parent_ID = getIFTScheduleParent(item)

          return {
            ...item,
            CODE: get(item, "CODE") || "",
            id:
              get(item, "PROJECT_RESOURCE_ID") ||
              get(item, "PROJECT_ITEM_ID") ||
              get(item, "PROJECT_SECTION_ID"),
            Parent_ID,
          }
        })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftBaselineScopeData: data,
        }
      }
    )
    builder.addCase(fetchBaselineIFTScopeAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(fetchRevisionIFTScopeAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      fetchRevisionIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const { BOQ } = payload || []
        const data = BOQ.map((item: any) => {
          const Parent_ID = getIFTScheduleParent(item)

          return {
            ...item,
            CODE: get(item, "CODE") || "",
            id:
              get(item, "PROJECT_RESOURCE_ID") ||
              get(item, "PROJECT_ITEM_ID") ||
              get(item, "PROJECT_SECTION_ID"),
            Parent_ID,
          }
        })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftRevisionScopeData: data,
        }
      }
    )
    builder.addCase(fetchRevisionIFTScopeAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getScopeItemDetailsAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getScopeItemDetailsAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const dataFromAPI = Array.isArray(payload) ? payload[0] : {}
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftScopeEditItem: { ...state.iftScopeEditItem, ...dataFromAPI },
        }
      }
    )
    builder.addCase(getScopeItemDetailsAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getScopeResourceDetailsAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getScopeResourceDetailsAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const dataFromAPI = Array.isArray(payload) ? payload[0] : {}
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          iftScopeEditResource: { ...state.iftScopeEditResource, ...dataFromAPI },
        }
      }
    )
    builder.addCase(getScopeResourceDetailsAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateScopeItemsAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateScopeItemsAction.fulfilled, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateScopeItemsAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateScopeResourcesAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateScopeResourcesAction.fulfilled, (state: iftState, { payload }) => {
      state.loading = LoadingState.DEFAULT
      state.editedIds = [...state.editedIds, payload[0]]
    })
    builder.addCase(updateScopeResourcesAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteScopeProjectItemAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(deleteScopeProjectItemAction.fulfilled, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteScopeProjectItemAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteScopeProjectResourceAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(deleteScopeProjectResourceAction.fulfilled, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteScopeProjectResourceAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      fetchItemGroupIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<itemGroup[]>) => ({
        ...state,
        itemGroups: payload,
      })
    )
    builder.addCase(
      fetchItemIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<iftScopeItem[]>) => ({
        ...state,
        items: payload,
      })
    )
    builder.addCase(addItemAction.fulfilled, (state: iftState, { payload }: PayloadAction<any>) => {
      const addedIds = [...state.addedIds, ...payload.data]

      return {
        ...state,
        loading: LoadingState.DEFAULT,
        addedIds,
        items: [],
      }
    })
    builder.addCase(
      fetchResourceGroupIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => ({
        ...state,
        resourceGroups: payload,
        resourceLibraries: [],
        resourceLibraryContractors: [],
      })
    )
    builder.addCase(
      fetchResourceLibraryIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => ({
        ...state,
        resourceLibraries: payload,
        resourceLibraryContractors: [],
      })
    )
    builder.addCase(
      fetchResourceLibraryContractorIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any[]>) => {
        const resourceLibraryContractors = payload.map((item: any) => ({
          ...item,
          SUBCONTRATOR_CODE: item.SUBCONTRATOR_CODE || "[ - ]",
          SUBCONTRACTOR_COMPANY: item.SUBCONTRACTOR_COMPANY || "(No Supplier)",
        }))

        return {
          ...state,
          resourceLibraryContractors,
        }
      }
    )
    builder.addCase(
      addResourceIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const addedResourceId = payload.reduce((a: number[], b: any) => {
          const reqrdId = get(b, "projectResourceInsertRecord.PROJECT_RESOURCE_ID")
          return [...a, reqrdId]
        }, [])
        const addedIds = [...state.addedIds, ...addedResourceId]

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          addedIds,
          resourceLibraries: [],
          resourceLibraryContractors: [],
        }
      }
    )
    builder.addCase(
      fetchSubItemGroupIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<itemGroup[]>) => ({
        ...state,
        subItemGroups: payload,
      })
    )
    builder.addCase(
      fetchSubItemIFTScopeAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<iftScopeItem[]>) => {
        const subItems = payload.map((item: iftScopeItem) => ({
          ...item,
          ITEM_CATEGORY_DESC: "SUBITEM",
        }))

        return {
          ...state,
          subItems,
        }
      }
    )
    builder.addCase(addSubItemAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      addSubItemAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<any>) => {
        const addedIds = [...state.addedIds, ...payload.data]

        return {
          ...state,
          addedIds,
          loading: LoadingState.DEFAULT,
        }
      }
    )
    builder.addCase(addSubItemAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    // Risk register from here..
    builder.addCase(
      fetchRiskRegistersByProjectAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<RiskRegisterItem[]>) => {
        const riskRegisters = (payload || []).map((item) => {
          return { ...item, RISK_REGISTER_PROBABILITY: (item.RISK_REGISTER_PROBABILITY || 0) * 100 }
        })
        return { ...state, riskRegisters }
      }
    )
    builder.addCase(
      fetchCostElementsForRiskAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<RiskAllocationItem[]>) => {
        return { ...state, riskCostElements: payload }
      }
    )
    builder.addCase(
      createOrUpdateRiskRegisterAction.fulfilled,
      (state: iftState, { payload }: PayloadAction<RiskRegisterItem[]>) => {
        const riskRegisters = (payload || []).map((item) => {
          return { ...item, RISK_REGISTER_PROBABILITY: (item.RISK_REGISTER_PROBABILITY || 0) * 100 }
        })
        return { ...state, riskRegisters }
      }
    )

    // IFT Inflation and Spend from here
    builder.addCase(getIFTSpendAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(getIFTSpendAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getIFTSpendAction.fulfilled, (state: iftState, { payload }) => {
      const data = payload.map((row: any) => {
        const result = find(state.iftInflation, { Code: row.CeCode })
        if (result) {
          row.InflationDate = result.InflationDate
        }
        return row
      })
      return {
        ...state,
        iftSpend: data,
        loading: LoadingState.DEFAULT,
      }
    })
    builder.addCase(updateIFTSpendAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateIFTSpendAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateIFTSpendAction.fulfilled, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getIFTInflationAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(getIFTInflationAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getIFTInflationAction.fulfilled, (state: iftState, { payload }) => {
      const inflationData = payload.inflationData || []
      const headerRows = inflationData.filter(
        (item: IFTInflationItem) => item.DataTypeName === "Header"
      )
      const finalInflationData = inflationData
        .filter((item: IFTInflationItem) => item.DataTypeName !== "Header")
        .map((item: IFTInflationItem) => {
          const sectionName = headerRows.find(
            (sec: IFTInflationItem) => sec.SectionNumber === item.SectionNumber
          )?.Description
          return { ...item, sectionName }
        })
        .sort((a: IFTInflationItem, b: IFTInflationItem) => a.SectionNumber - b.SectionNumber)

      return {
        ...state,
        iftInflation: finalInflationData,
        iftInflationRowData: payload.inflationRowData,
        loading: LoadingState.DEFAULT,
      }
    })
    builder.addCase(getIFTInflationMasterDataAction.pending, (state: iftState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(getIFTInflationMasterDataAction.rejected, (state: iftState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getIFTInflationMasterDataAction.fulfilled, (state: iftState, { payload }) => ({
      ...state,
      iftInflationMasterData: payload,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(fetchIFTSpendResultAction.fulfilled, (state: iftState, { payload }) => ({
      ...state,
      iftSpendResult: payload,
    }))
  },
})

export const {
  setLoading,
  setSelectedTab,
  updateIFTScheduleData,
  reset,
  updateRiskRegister,
  deleteRiskRegister,
  checkFromBaseline,
  scopeInit,
  scopePopUpsInit,
  setScopeCarbonSwitch,
  setScopeBaselineSwitch,
  setScopeDelConfTxt,
  setIFTScopeEditItem,
  setIFTScopeEditResource,
  updateIftRevisionScopeData,
  setIftRoleBasedPermissions,
  setIftKeyDataFields,
  resetIftKeyDataFields,
} = iftSlice.actions

export const iftSelector = (state: RootState) => state.Ift

export default iftSlice.reducer
