import { get } from "lodash"
import { custom } from "devextreme/ui/dialog"
import { formatNumber } from "devextreme/localization"
import SubItemIcon from "assets/icons/SubItem.png"
import ItemTextIcon from "assets/icons/ItemText.png"
import ResourceItemIcon from "assets/icons/Resource.png"
import NormalItemIcon from "assets/icons/NormalItem.png"
import ItemVariableIcon from "assets/icons/ItemVariable.png"
import CompositeItemIcon from "assets/icons/CompositeItem.png"
import CompositeTotalIcon from "assets/icons/CompositeTotal.png"
import { UserRole } from "redux/profile/types"
import { ResourceType } from "redux/projects/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { setScopeDelConfTxt } from "redux/ift/iftSlice"
import { iftRoleBasedPermissions } from "redux/ift/types"
import { allRoles, identifyPermissions } from "utils/common-utils"
import { config } from "utils/config"

export interface ModalStateType {
  show: boolean
  data: any
  isDuplicate?: boolean
}

export interface EditItemPopupProps {
  tableRef: any
}
export interface EditResourcePopupProps {
  tableRef: any
}
export interface EditItemTableProps {
  popUpClose: () => void
}

export const defaultModalState = {
  show: false,
  data: null,
}

export const checkMultiInstanceCount = (gridData: any, CODE: string) => {
  return gridData.filter((item: any) => item.SUBITEM === "Y" && item.CODE === CODE).length
}
export const isMultiInstance = (gridData: any, CODE: string) => {
  return checkMultiInstanceCount(gridData, CODE) > 1
}

const removeLink = (e: any, type: string) => {
  const links = e.cellElement.querySelectorAll("a")

  links.forEach(
    (link: { [x: string]: any; textContent: string | string[]; remove: () => void }) => {
      if (link.getAttribute("class").includes(type)) link?.remove()
    }
  )
}

const CostElementCompositeTypes = [1, 2]
const allowEdit = (e: any, roleBasedAddEditDelPermission: iftRoleBasedPermissions) => {
  if (CostElementCompositeTypes.includes(e.data.PROJECT_ITEM_COMPOSITETYPE)) return false
  if ((e.row.data.Parent_ID === -1 || e.row.node.hasChildren) && e.row.data.SUBITEM !== "Y")
    return false
  if (!get(e, "data.PROJECT_RESOURCE_ID")) return roleBasedAddEditDelPermission.editItems

  return roleBasedAddEditDelPermission.editResources
}

const allowDelete = (e: any, roleBasedAddEditDelPermission: iftRoleBasedPermissions) => {
  if (CostElementCompositeTypes.includes(e.data.PROJECT_ITEM_COMPOSITETYPE)) return false
  if ((e.row.data.Parent_ID === -1 || e.row.node.hasChildren) && e.row.data.SUBITEM !== "Y")
    return false
  if (!get(e, "data.PROJECT_RESOURCE_ID"))
    return roleBasedAddEditDelPermission.deleteResourcesOrItems

  return roleBasedAddEditDelPermission.deleteResourcesOrItems
}

export const onScopeTableCellPrepared = (props: {
  e: any
  roleBasedAddEditDelPermission: iftRoleBasedPermissions
  addedIds: any[]
  editedIds: any[]
  dispatch: any
}) => {
  const { e, roleBasedAddEditDelPermission, addedIds, editedIds, dispatch } = props
  const arr = ["UNIT_DESCRIPTION", "QUANTITY", "RATE", "V_QUANTITY", "V_RATE"]

  if (e.rowType === "data") {
    if (addedIds.includes(e.data.id) || addedIds.includes(e.data.Parent_ID)) {
      e.cellElement.classList.add("bg-added")
    }
    if (editedIds.includes(e.data.PROJECT_RESOURCE_ID)) {
      e.cellElement.classList.add("bg-green")
    }
    if (e.data.Parent_ID === -1 && arr.includes(e.column.dataField)) {
      e.cellElement.classList.add("bg-grey")
    } else if (e.data.PROJECT_ITEM_ISPROVSUM === "Y") {
      e.cellElement.classList.add("bg-cream")
    }

    if (e.column.command === "edit") {
      const links = e.cellElement.querySelectorAll("a")
      const resourceUpdateCheck = get(e, "data.PROJECT_RESOURCE_CARB_MANUAL_SET") === "Y"
      const resourceCheck = get(e, "data.PROJECT_RESOURCE_ID")
      if (resourceCheck && resourceUpdateCheck) {
        const infoIcon = document.createElement("a")
        infoIcon.href = "#"
        infoIcon.className = "dx-link dx-link-info dx-icon-info dx-link-icon me-1"
        infoIcon.title = "NOTE: This row has a Resource Carbon value set manually."
        infoIcon.ariaLabel = "Info"
        infoIcon.onclick = (e) => e.preventDefault()
        e.cellElement.insertBefore(infoIcon, e.cellElement.firstChild)
      }

      if (links.length) {
        links.forEach((link: any) => {
          link.addEventListener("click", (event: any) => {
            event.preventDefault()
            if (link.getAttribute("class").includes("delete")) {
              const text =
                e.data.SUBITEM === "Y"
                  ? "Do you want to Remove this SubItem from Item?"
                  : "Are you sure you want to delete this record?"
              dispatch(setScopeDelConfTxt(text))
            }
          })
        })
      }

      if (!allowEdit(e, roleBasedAddEditDelPermission)) {
        removeLink(e, "edit")
      }
      if (!allowDelete(e, roleBasedAddEditDelPermission)) {
        removeLink(e, "delete")
      }
    }
  }
}

export const onProvisionalSumCellPrepared = (e: any) => {
  const arr = ["RATE"]
  if (e.rowType === "data" && arr.includes(e.column.dataField)) {
    e.cellElement.classList.add("bg-grey")
  }
}

export const onScopeTableContentReady = (e: {
  component: { columnOption: (arg0: string, arg1: string, arg2: number) => void }
}) => {
  e.component.columnOption("command:edit", "visibleIndex", -1)
}

export const handleCodeSort = (a: any, b: any) => {
  const fa = a
    .toString()
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
  const fb = b
    .toString()
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()

  if (fa < fb) return -1
  if (fa > fb) return 1
  return 0
}

export const currencyConverter = (val: any) => {
  return formatNumber(val || 0, {
    currency: config.clientName === "HE" ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

const permsnCheck = (userRole: UserRole | null) => {
  return identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles)
}

const checkContractorRevisionApplied = (userRoleId: number | null, libraryContent?: any) => {
  return (libraryContent || []).find(
    (item: any) => item.CONTRACTOR_ID === userRoleId && item.IS_APPLIED === 1
  )
}

export const renderScopeTableRateCell = (
  cellInfo: any,
  userRole: UserRole | null,
  libraryContent?: any
) => {
  if (cellInfo.data.Parent_ID === -1) return null
  if (get(cellInfo, "data.V_IS_DELETED") && get(cellInfo, "column.dataField") === "V_RATE")
    return "NA"

  return permsnCheck(userRole) ||
    checkContractorRevisionApplied(userRole?.ESTIMATOR_ID ?? null, libraryContent)
    ? currencyConverter(Number(cellInfo.value))
    : null
}

export const renderScopeTableCostCell = (
  cellInfo: any,
  userRole: UserRole | null,
  libraryContent?: any
) => {
  if (get(cellInfo, "data.V_IS_DELETED") && get(cellInfo, "column.dataField") === "V_COST")
    return "NA"

  return permsnCheck(userRole) ||
    checkContractorRevisionApplied(userRole?.ESTIMATOR_ID ?? null, libraryContent)
    ? currencyConverter(cellInfo.value)
    : null
}

export const carbNullCalculate = (val: any, deletedCheck?: any) => (deletedCheck ? "NA" : val || 0)

export const qtyNullCalculate = (val: any, row?: any) => {
  const deletedCheck = get(row, "V_IS_DELETED")
  if (row.Parent_ID === -1) return null
  return deletedCheck ? "NA" : val || 0
}

const cellDescriptionProps = (compositeItemType: string) => {
  let obj = {
    icon: ItemTextIcon,
    alt: "",
    title: "Text Item",
    labelStyles: {
      color: "",
      // fontWeight: "bold",
      fontStyle: "",
    },
  }
  switch (compositeItemType) {
    case "CompositeTotal":
      obj = {
        icon: CompositeTotalIcon,
        alt: "Composite Total",
        title: "Composite Total",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "NormalItem":
      obj = {
        icon: NormalItemIcon,
        alt: "Normal Item",
        title: "Normal Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "CompositeItem":
      obj = {
        icon: CompositeItemIcon,
        alt: "Composite Item",
        title: "Composite Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ItemVariable":
      obj = {
        icon: ItemVariableIcon,
        alt: "Item Variable",
        title: "Item Variable",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ResourceItem":
      obj = {
        icon: ResourceItemIcon,
        alt: "Resource Item",
        title: "Resource",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "SubItem":
      obj = {
        icon: SubItemIcon,
        alt: "Sub Item",
        title: "Sub Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    default:
      break
  }
  return obj
}

export const scopeCellDescriptionRender = (row: any) => {
  const subItemCheck =
    row.data.SUBITEM === "Y" && row.data.PROJECT_ITEM_COMPOSITETYPE === 0
      ? "SubItem"
      : ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]
  const { icon, alt, title, labelStyles } = cellDescriptionProps(subItemCheck)
  const sectionCheck = ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]

  return (
    <div className="cell-description">
      {sectionCheck && <img src={icon} alt={alt} title={title} />}
      <label style={labelStyles} title={row.value}>
        {row.value}
      </label>
    </div>
  )
}

export const dupSubItemCnfrmDialog = (rowData: any, count?: number) => {
  const desc = get(rowData, "DESCRIPTION")
  return custom({
    showTitle: false,
    messageHtml: `<div class="p-2">There are ${
      count || "multiple"
    } instances of ${desc} within this project.<br/><br/>
    All SubItems for a project must be the same. Accepting this change will synchronise the changes throughout the project.
    <br/><br/>Do you want to proceed?
    </div>`,
    buttons: [
      {
        text: "OK",
        onClick: () => true,
      },
      {
        text: "Cancel",
        onClick: () => false,
      },
    ],
  })
}
