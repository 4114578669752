/* eslint-disable react/jsx-props-no-spreading */
import { get, isNaN } from "lodash"
import { TFunction } from "i18next"
import notify from "devextreme/ui/notify"
import { custom } from "devextreme/ui/dialog"
import { DataGrid, SelectBox } from "devextreme-react"
import { RiskRegisterItem } from "redux/ift/types"

export const RiskContractualAllocation = [
  { id: 0, name: "None" },
  { id: 1, name: "Contractor/Delivery Partner Risk" },
  { id: 2, name: "Employer Risk" },
]

export const setRiskSaveRevertToolbar = (
  dataGridRef: { current: DataGrid | null },
  value: boolean
) => {
  dataGridRef.current?.instance.option("toolbar.items[3].disabled", value) // save button
  dataGridRef.current?.instance.option("toolbar.items[4].disabled", value) // single undo button
}

export const handleRiskRevertAll = (dataGridRef: { current: DataGrid | null }) => {
  dataGridRef.current?.instance.beginCustomLoading("")
  dataGridRef.current?.instance.cancelEditData()
  setRiskSaveRevertToolbar(dataGridRef, true)
  dataGridRef.current?.instance.endCustomLoading()
}

export const calculateSumOfCells = (rowData: RiskRegisterItem) => {
  const fieldNames = [
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_1",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_2",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_3",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_4",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_5",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_6_IN_DEVELOPMENT",
    "RISK_ALLOCATION_COLUMN_CESS_STAGE_6_IN_CONSTRUCTION",
    "RISK_ALLOCATION_COLUMN_CESS_LANDS_BLIGHT",
    "RISK_ALLOCATION_COLUMN_CESS_LANDS_ACQUISITION",
    "RISK_ALLOCATION_COLUMN_CESS_LANDS_PART_1_CLAIMS",
    "RISK_ALLOCATION_COLUMN_LANDS_INDEXATION_LANDS_RISK_GENERAL",
    "RISK_ALLOCATION_COLUMN_CESS_STATUTORY_UNDERTAKERS",
    "RISK_ALLOCATION_COLUMN_AUTHORITY_COSTS_RAIL_ENV_LOCAL_ETC",
    "RISK_ALLOCATION_COLUMN_HIGHWAYS_ENGLAND_EMP_AGENT_STAGE_6",
    "RISK_ALLOCATION_COLUMN_HIGHWAYS_ENGLAND_EMP_AGENT_STAGE_7",
  ]
  const sum = fieldNames.reduce((a: number, b: string) => {
    const newCheckVal = get(rowData, `${b}.VALUE`) || 0
    return a + newCheckVal
  }, 0)

  return sum
}

const requireFields = [
  /* "RISK_REGISTER_CAUSE",
  "RISK_REGISTER_IMPACT",
  "RISK_REGISTER_EVENT", */
  "RISK_REGISTER_NUMBER",
]
export const requiredFieldsCheck = (checkObj: RiskRegisterItem) => {
  return requireFields.some((item) => !get(checkObj, `${item}.length`))
}

export const onRiskRegisterCellPrepared = (e: any) => {
  if (e?.rowType === "data") {
    switch (e?.column?.dataField) {
      case "RISK_REGISTER_PROBABILITY":
        if (get(e, "data.RISK_CONTRACTUALALLOCATION") === 0) {
          e.cellElement.style.backgroundColor = "#e5e5e5"
          e.cellElement.title = "Change Contractual Allocation to edit this field."
        }
        break
      case "SUMMATION_CHECK":
        {
          const total = calculateSumOfCells(e.data)
          if (total === 100) e.cellElement.style.backgroundColor = "green"
          else if (total > 100) e.cellElement.style.backgroundColor = "red"
          else e.cellElement.style.backgroundColor = "#FFBF00"
        }
        break
      case "RISK_REGISTER_NUMBER": {
        if (!e.row.isNewRow) {
          e.cellElement.setAttribute("contenteditable", "false")
          e.cellElement.setAttribute("readonly", "true")
          e.cellElement.classList.add("disabled")
          e.cellElement.style.pointerEvents = "none"
        }
        break
      }
      default:
        break
    }
  }
}

export const renderDropdownCell = (rowData: any) => (
  <SelectBox
    {...rowData.column.lookup}
    defaultValue={rowData.value}
    onValueChanged={(e: any) => {
      rowData.setValue(e.value)
    }}
  />
)

export const validateRiskNumberExist = (
  data: RiskRegisterItem,
  riskRegisters: RiskRegisterItem[]
) => {
  const riskNumber = data.RISK_REGISTER_NUMBER
  const itemFound = riskRegisters.find(
    (item: RiskRegisterItem) => item.RISK_REGISTER_NUMBER === riskNumber && !data.RISK_REGISTER_ID
  )

  return !itemFound
}

export const riskRegNumValidate = (
  param: any,
  riskRegisters: RiskRegisterItem[],
  t: TFunction<"translation", undefined>
) => {
  return new Promise((resolve) => {
    const isValid = validateRiskNumberExist(param?.data, riskRegisters)

    if (isValid) resolve(true)
    else {
      notify(t("riskRegisters.riskRegisterNumberError"), "error", 3000)
      resolve(false)
    }
  })
}

export const prcntCellRender = (rowData: any, dataField: string) => {
  const val = get(rowData, `data.${dataField}`)

  return (!val || isNaN(val) ? 0 : Number(val) / 100).toLocaleString(undefined, {
    style: "percent",
  })
}

export const prcntVldte = (
  param: any,
  dataField: string,
  t: TFunction<"translation", undefined>
) => {
  return new Promise((resolve) => {
    const value = get(param, `data.${dataField}`)

    if (value >= 0 && value <= 100) resolve(true)
    else {
      notify(t("riskRegisters.minMaxValidation"), "error", 3000)
      resolve(false)
    }
  })
}

export const costImpactVal = (rowData: any) => {
  let dispVal =
    (get(rowData, "RISK_REGISTER_PROBABILITY") || 0) * (get(rowData, "RISK_REGISTER_COSTML") || 0)
  dispVal = Math.round((dispVal || 0) * 100) / 10000
  return dispVal
}

export const summationCheckCellVal = (rowData: RiskRegisterItem) => {
  const total = calculateSumOfCells(rowData)

  return isNaN(total) || !total ? null : total
}

export const summationCheckValidate = (param: any, t: TFunction<"translation", undefined>) => {
  return new Promise((resolve) => {
    const total = calculateSumOfCells(param?.data)
    if (total === 100) resolve(true)
    else {
      notify(t("riskRegisters.summationNotEqual100"), "error", 4000)
      resolve(false)
    }
  })
}

export const riskDelCnfrmDialog = (t: any) => {
  return custom({
    showTitle: false,
    message: t("riskRegisters.delConfirmation"),
    buttons: [
      {
        text: "Yes",
        onClick: () => true,
      },
      {
        text: "No",
        onClick: () => false,
      },
    ],
  })
}
