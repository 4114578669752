import { memo, useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { uniqBy } from "lodash"
import { Button, TreeList } from "devextreme-react"
import {
  SearchPanel,
  FilterRow,
  Toolbar,
  Column,
  Paging,
  Pager,
  Item,
} from "devextreme-react/tree-list"
import { sharedSelector } from "redux/shared/sharedSlice"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { costEstimateSummarySelector } from "redux/cost-estimate-summary/costEstimateSummarySlice"
import { handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { costSummaryReportCurrencySign, renderCostSummaryReportCurrency } from "../../utils"
import TotalCarbHeaderCellRender from "./totalCarbHeaderCelRender"
import {
  ExportCCESSReport,
  costSummaryReportTableHeight,
  onCostSummaryReportRowPrepared,
  totColCaptionDatafieldGenerator,
} from "./tableUtils"

const CostEstimateReportTable = () => {
  const { t } = useTranslation()

  const treeListRef = useRef<TreeList>(null)
  const { windowHeight } = useSelector(sharedSelector)
  const { selectedExpenditureProjectData } = useSelector(expenditureProfileSelector)
  const {
    showPortfolioRisk,
    showInflation,
    carbSwitches: { showMLCarb, showProjectCarb, showPortfolioCarb, showInflationCarb },
    costEstimateReportData,
  } = useSelector(costEstimateSummarySelector)

  const data = useMemo(() => uniqBy(costEstimateReportData, "ID"), [costEstimateReportData])
  const { costCaption, costDataField, costWidth, carbCaption, carbDataField, carbWidth } = useMemo(
    () => totColCaptionDatafieldGenerator(t, showPortfolioRisk, showInflation),
    [showPortfolioRisk, showInflation]
  )

  const onExporting = useCallback(() => {
    ExportCCESSReport(treeListRef.current?.instance, selectedExpenditureProjectData)
  }, [treeListRef.current, selectedExpenditureProjectData])

  return (
    <TreeList
      id="costSummaryReportTable"
      className="cost-summary-grid compact-grid-tree"
      ref={treeListRef}
      dataSource={data}
      showBorders
      showRowLines
      columnAutoWidth
      showColumnLines
      allowColumnResizing
      keyExpr="ID"
      filterMode="withAncestors"
      parentIdExpr="PARENT_ID"
      errorRowEnabled={false}
      sorting={{ mode: "none" }}
      defaultExpandedRowKeys={[99999]}
      onRowPrepared={onCostSummaryReportRowPrepared}
      height={costSummaryReportTableHeight(windowHeight)}
    >
      <FilterRow visible />
      <SearchPanel visible width={340} />
      <Paging enabled defaultPageSize={defaultPageSize} />
      <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(treeListRef)}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.exportExcel")}>
            <Button icon="export" onClick={onExporting} />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column
        caption={t("costEstimateSummary.sectionCostElement")}
        dataField="DESCRIPTION"
        dataType="string"
        fixed
        minWidth={400}
      />
      <Column caption={t("costEstimateSummary.totalBaseEstimate")} alignment="center">
        <Column
          caption={t("costEstimateSummary.MLCost", { costSummaryReportCurrencySign })}
          dataField="BASE_ML"
          minWidth={192}
          dataType="number"
          cellRender={renderCostSummaryReportCurrency}
        />
        <Column
          caption={t("costEstimateSummary.a1a3")}
          dataField="A1_A3"
          minWidth={168}
          dataType="number"
          visible={showMLCarb}
        />
        <Column
          caption={t("costEstimateSummary.a4")}
          dataField="A4"
          minWidth={168}
          dataType="number"
          visible={showMLCarb}
        />
        <Column
          caption={t("costEstimateSummary.a5a")}
          dataField="A5A"
          minWidth={168}
          dataType="number"
          visible={showMLCarb}
        />
        <Column
          caption={t("costEstimateSummary.a5w")}
          dataField="A5W"
          minWidth={168}
          dataType="number"
          visible={showMLCarb}
        />
        <Column
          caption={t("costEstimateSummary.totala1a5")}
          dataField="TOTAL_A1_A5"
          minWidth={192}
          dataType="number"
          headerCellRender={(data) =>
            TotalCarbHeaderCellRender({ t, data, val: showMLCarb, key: "ML" })
          }
        />
      </Column>
      <Column caption={t("costEstimateSummary.projRiskOppUncertCost")} alignment="center">
        <Column caption="Project Risk" alignment="center">
          <Column
            caption={t("costEstimateSummary.MLEmployerRisk", { costSummaryReportCurrencySign })}
            dataField="EMPLOYERRISK_ML"
            dataType="number"
            minWidth={168}
            cellRender={renderCostSummaryReportCurrency}
          />
          <Column
            caption={t("costEstimateSummary.MLContractDlvryPartnrRsk", {
              costSummaryReportCurrencySign,
            })}
            dataField="CONTRACTORISK_ML"
            dataType="number"
            minWidth={292}
            cellRender={renderCostSummaryReportCurrency}
          />
        </Column>
        <Column caption={t("costEstimateSummary.uncertainty")} alignment="center">
          <Column
            caption={t("costEstimateSummary.MLUncertainity", { costSummaryReportCurrencySign })}
            dataField="UNCERTAINTYCOST_ML"
            dataType="number"
            minWidth={168}
            cellRender={renderCostSummaryReportCurrency}
          />
        </Column>
        <Column caption={t("costEstimateSummary.totalProjRiskAndUncertinty")} alignment="center">
          <Column
            caption={t("costEstimateSummary.MLtotalProjRiskAndUncertintyCurr", {
              costSummaryReportCurrencySign,
            })}
            dataField="TOTAL_PROJECT_RISK"
            dataType="number"
            minWidth={300}
            cellRender={renderCostSummaryReportCurrency}
          />
        </Column>
      </Column>
      <Column caption={t("costEstimateSummary.projRiskOpprtntyUncertCarb")} alignment="center">
        <Column
          caption={t("costEstimateSummary.a1a3")}
          dataField="TOTAL_PROJECT_RISK_A1_A3"
          dataType="number"
          minWidth={132}
          visible={showProjectCarb}
        />
        <Column
          caption={t("costEstimateSummary.a4")}
          dataField="TOTAL_PROJECT_RISK_A4"
          dataType="number"
          minWidth={132}
          visible={showProjectCarb}
        />
        <Column
          caption={t("costEstimateSummary.a5a")}
          dataField="TOTAL_PROJECT_RISK_A5A"
          dataType="number"
          minWidth={132}
          visible={showProjectCarb}
        />
        <Column
          caption={t("costEstimateSummary.a5w")}
          dataField="TOTAL_PROJECT_RISK_A5W"
          dataType="number"
          minWidth={132}
          visible={showProjectCarb}
        />
        <Column
          caption={t("costEstimateSummary.totalCarbProjRiskUncertAllwnce")}
          dataField="TOTAL_PROJECT_RISK_TOTAL_A1_A5"
          dataType="number"
          minWidth={460}
          headerCellRender={(data) =>
            TotalCarbHeaderCellRender({ t, data, val: showProjectCarb, key: "project" })
          }
        />
      </Column>
      <Column
        caption={t("costEstimateSummary.portfolioRiskCost")}
        alignment="center"
        visible={showPortfolioRisk}
      >
        <Column
          caption={t("costEstimateSummary.MLPortfolioRisk", { costSummaryReportCurrencySign })}
          dataField="PORTFOLIO_RISK_COST"
          dataType="number"
          minWidth={168}
          cellRender={renderCostSummaryReportCurrency}
        />
      </Column>
      <Column
        caption={t("costEstimateSummary.portfolioRiskCarb")}
        alignment="center"
        visible={showPortfolioRisk}
      >
        <Column
          caption={t("costEstimateSummary.a1a3")}
          dataField="TOTAL_PORTFOLIO_RISK_A1_A3"
          visible={showPortfolioRisk && showPortfolioCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a4")}
          dataField="TOTAL_PORTFOLIO_RISK_A4"
          visible={showPortfolioRisk && showPortfolioCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a5a")}
          dataField="TOTAL_PORTFOLIO_RISK_A5A"
          visible={showPortfolioRisk && showPortfolioCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a5w")}
          dataField="TOTAL_PORTFOLIO_RISK_A5W"
          visible={showPortfolioRisk && showPortfolioCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.totalCarbPortfolioRiskAllwnce")}
          dataField="TOTAL_PORTFOLIO_RISK_TOTAL_A1_A5"
          visible={showPortfolioRisk}
          dataType="number"
          minWidth={360}
          headerCellRender={(data) =>
            TotalCarbHeaderCellRender({ t, data, val: showPortfolioCarb, key: "portfolio" })
          }
        />
      </Column>
      <Column
        caption={t("costEstimateSummary.costBaseDate")}
        dataField="COST_BASE_DATE"
        dataType="date"
        minWidth={160}
      />
      <Column
        caption={t("costEstimateSummary.totalCostInflation", { costSummaryReportCurrencySign })}
        alignment="center"
        visible={showInflation}
      >
        <Column
          caption=""
          dataField="TOTAL_INFLATION"
          dataType="number"
          minWidth={168}
          cellRender={renderCostSummaryReportCurrency}
        />
      </Column>
      <Column
        caption={t("costEstimateSummary.carbInflation")}
        alignment="center"
        visible={showInflation}
      >
        <Column
          caption={t("costEstimateSummary.a1a3")}
          dataField="INFLATION_A1_A3"
          visible={showInflation && showInflationCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a4")}
          dataField="INFLATION_A4"
          visible={showInflation && showInflationCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a5a")}
          dataField="INFLATION_A5A"
          visible={showInflation && showInflationCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.a5w")}
          dataField="INFLATION_A5W"
          visible={showInflation && showInflationCarb}
          dataType="number"
          minWidth={132}
        />
        <Column
          caption={t("costEstimateSummary.totala1a5")}
          dataField="INFLATION_TOTAL_A1_A5"
          visible={showInflation}
          dataType="number"
          minWidth={220}
          headerCellRender={(data) =>
            TotalCarbHeaderCellRender({ t, data, val: showInflationCarb, key: "inflation" })
          }
        />
      </Column>
      <Column
        caption={costCaption}
        dataField={costDataField}
        dataType="number"
        minWidth={costWidth}
        cellRender={renderCostSummaryReportCurrency}
      />
      <Column
        caption={carbCaption}
        dataField={carbDataField}
        dataType="number"
        minWidth={carbWidth}
      />
    </TreeList>
  )
}

export default memo(CostEstimateReportTable)
