import { PayloadAction, createSlice, current } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  RoleAccessLevelItem,
  RolePermissionLevelItem,
  RolePermissionState,
  SelectRole,
} from "./types"
import {
  fetchRoleAccessListAction,
  fetchRolePermissionAction,
  updatePermissionLevelForRoleAction,
} from "./middleware"

const INITIAL_STATE: RolePermissionState = {
  roleList: [],
  rolePermissionList: [],
  accessList: [],
  roleAccessLevelList: [],
  permission: null,
  loading: LoadingState.DEFAULT,
}

const rolePermissionSlice = createSlice({
  name: "RolePermission",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state: RolePermissionState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    updateRolePermission: (state, { payload }) => {
      const { roleList } = current(state)
      const roleFound = roleList.find((item: SelectRole) => item.ROLE_ID === payload)

      if (roleFound) state.permission = roleFound
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRolePermissionAction.fulfilled,
      (state: RolePermissionState, { payload }: PayloadAction<SelectRole[]>) => ({
        ...state,
        roleList: payload,
        permission: payload?.[0] || null,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchRolePermissionAction.rejected, (state: RolePermissionState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(
      fetchRoleAccessListAction.fulfilled,
      (state: RolePermissionState, { payload }: PayloadAction<RoleAccessLevelItem>) => ({
        ...state,
        rolePermissionList: payload.rolePermissionList,
        accessList: payload.accessList,
        roleAccessLevelList: payload.roleAccessLevelList,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchRoleAccessListAction.rejected, (state: RolePermissionState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(
      updatePermissionLevelForRoleAction.fulfilled,
      (state: RolePermissionState, { payload }: PayloadAction<RolePermissionLevelItem>) => {
        const permissions = [...state.rolePermissionList]
        const itemIndex = permissions.findIndex(
          (item: RolePermissionLevelItem) => item.ROLEACCESS_ID === payload.ROLEACCESS_ID
        )
        if (itemIndex >= 0) {
          permissions[itemIndex] = { ...permissions[itemIndex], ...payload }
        }

        return { ...state, rolePermissionList: permissions }
      }
    )
  },
})

export const { setLoading, updateRolePermission } = rolePermissionSlice.actions

export const rolePermissionSelector = (state: RootState) => state.RolePermission

export default rolePermissionSlice.reducer
