import { Button, DataGrid } from "devextreme-react"
import { Column, Scrolling } from "devextreme-react/data-grid"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { iftSelector } from "redux/ift/iftSlice"
import { fetchPreviousEstimatesAction } from "redux/ift/middleware"
import { sharedSelector } from "redux/shared/sharedSlice"
import { useAppDispatch } from "redux/store"
import useDocumentTitle from "hooks/useDocumentTitle"
import "./previous-estimate.scss"
import {
  exportPreviousEstimate,
  onCellPrepared,
  renderPreviousEstimateDataCell,
} from "./tableFunctions"

const PreviousEstimate: React.FC = (): JSX.Element => {
  useDocumentTitle("Previous Estimate | Cost and Carbon Forecasting tool")
  const { projectId, previousEstimate } = useSelector(iftSelector)
  const dispatch = useAppDispatch()
  const { windowHeight } = useSelector(sharedSelector)
  const { t } = useTranslation()
  const dataGridRef = useRef<DataGrid>(null)

  useEffect(() => {
    if (!previousEstimate && projectId) {
      dispatch(fetchPreviousEstimatesAction(projectId))
    }
  }, [projectId, previousEstimate])

  return (
    <div className="previous-estimate-container">
      <div className="previous-estimate-top">
        <div>
          <b>{t("previousEstimate.projectName")}:</b> {previousEstimate?.projectName}
          <br />
          <b>{t("previousEstimate.schemeType")}:</b> {previousEstimate?.schemeType}
        </div>
        <Button
          text={t("toolbarActions.export")}
          onClick={() => exportPreviousEstimate(dataGridRef.current?.instance)}
          stylingMode="contained"
          type="normal"
        />
      </div>

      <DataGrid
        id="previous-estimate-grid"
        dataSource={previousEstimate?.estimateData || []}
        ref={dataGridRef}
        allowColumnResizing
        columnAutoWidth
        showBorders
        showColumnLines
        showRowLines
        noDataText={t("noRecords")}
        height={windowHeight - 245}
        paging={{ enabled: false }}
        onCellPrepared={onCellPrepared}
      >
        <Scrolling mode="standard" />

        <Column
          caption={t("previousEstimate.costEstimateSummarySheet")}
          alignment="center"
          width={175}
          dataField="TITLE"
        />
        <Column
          caption={t("previousEstimate.totalBaseEstimate")}
          alignment="center"
          fixedPosition="left"
          width={175}
        >
          <Column caption="" width={175} alignment="center">
            <Column
              dataField="BASE_ML"
              dataType="number"
              width={175}
              caption={t("previousEstimate.mostLikely")}
              alignment="center"
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
        </Column>

        <Column
          width={175}
          caption={t("previousEstimate.riskAllocation")}
          alignment="center"
          fixedPosition="left"
        >
          <Column
            caption={t("previousEstimate.contractorRisk")}
            alignment="center"
            fixedPosition="left"
            width={175}
          >
            <Column
              caption={t("previousEstimate.mostLikelyRisk")}
              dataField="CONTRACTORRISK_ML"
              alignment="center"
              fixedPosition="left"
              dataType="number"
              width={175}
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
          <Column
            caption={t("previousEstimate.employerRisk")}
            alignment="center"
            dataType="number"
            format="currency"
            fixedPosition="left"
            width={175}
          >
            <Column
              caption={t("previousEstimate.mostLikelyRisk")}
              dataField="EMPLOYERRISK_ML"
              alignment="center"
              fixedPosition="left"
              dataType="number"
              format="currency"
              width={175}
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
          <Column
            caption={t("previousEstimate.totalRiskAdjustment")}
            width={175}
            alignment="center"
            fixedPosition="left"
          >
            <Column
              dataField="TOTAL_RISK_ADJUSTMENT_ML"
              caption={t("previousEstimate.mostLikelyRisk")}
              alignment="center"
              fixedPosition="left"
              dataType="number"
              width={175}
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
        </Column>

        <Column caption={t("previousEstimate.baseEstimateIncRisk")} alignment="center" width={300}>
          <Column width={300} caption="" alignment="center">
            <Column
              width={300}
              dataField="TOTAL_BASE_ESTIMATE_INCLUDING_RISK_ML"
              caption={t("previousEstimate.cumulativeMostLikely")}
              alignment="center"
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
        </Column>

        <Column
          width={175}
          caption={t("previousEstimate.uncertainty")}
          alignment="center"
          fixedPosition="left"
        >
          <Column width={175} caption="" alignment="center">
            <Column
              width={175}
              dataField="UNCERTAINTY_ML"
              caption={t("previousEstimate.mostLikely")}
              alignment="center"
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
        </Column>

        <Column caption={t("previousEstimate.estimateTotal")} alignment="center" width={300}>
          <Column width={300} caption="" alignment="center">
            <Column
              width={300}
              caption={t("previousEstimate.mostLikelyFinal")}
              alignment="center"
              dataField="TOTAL_ML"
              cellRender={renderPreviousEstimateDataCell}
            />
          </Column>
        </Column>
      </DataGrid>
    </div>
  )
}

export default PreviousEstimate
