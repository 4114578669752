/* eslint-disable no-unsafe-optional-chaining, no-nested-ternary */
import { RefObject } from "react"
import { Button, DataGrid } from "devextreme-react"
import { formatDate } from "devextreme/localization"
import { UserRole } from "redux/profile/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import {
  allRoles,
  renderCurrencyCell,
  identifyPermissions,
  handleDateStringSorting,
  DataGridColumnDataTypeProps,
  DataGridColumnSortOrderProps,
} from "utils/common-utils"
import { IProject } from "redux/projects/types"
import { config } from "utils/config"

export const sortingEstimateNos = (a: string, b: string) => {
  // Extract numbers after 'CT' using regex
  const numberA = parseFloat(a.match(/CT([\d.]+)/)![1])
  const numberB = parseFloat(b.match(/CT([\d.]+)/)![1])

  // Compare the extracted numbers
  return numberA - numberB
}

const renderERMContextMenuCell = (row: any, dataGridRef: RefObject<DataGrid<any, any>>) => {
  const handleClick = (e: any) => {
    e.event.preventDefault()
    const dxContextMenuEvent = new MouseEvent("dxcontextmenu", e.event)
    e.event.currentTarget.dispatchEvent(dxContextMenuEvent)
    dataGridRef.current?.instance.selectRowsByIndexes([row.rowIndex])
  }

  return (
    <div className="erm-context-container">
      <Button
        id="erm-context-btn"
        className="erm-context-btn active"
        icon="spindown"
        onClick={handleClick}
      />
    </div>
  )
}

export const tableColumnsGenerator = (props: {
  userRole: UserRole | null
  dataGridRef: RefObject<DataGrid<any, any>>
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { userRole, dataGridRef } = props
  const permsnCheck = identifyPermissions(
    userRole,
    AccessType.SHOW_COST_DATA,
    AccessLevel.YES,
    allRoles
  )

  return [
    {
      key: 1,
      dataField: "SchemaDesc",
      caption: "estimateRevisionManagement.tableCol1Head",
      visible: config.clientName !== "TFNSW",
      dataType: "string" as DataGridColumnDataTypeProps,
      fixed: true,
    },
    {
      key: 2,
      dataField: "QuoteNo",
      caption: "estimateRevisionManagement.tableCol2Head",
      sortOrder: "asc" as DataGridColumnSortOrderProps,
      dataType: "string" as DataGridColumnDataTypeProps,
      fixed: true,
      sortingMethod: sortingEstimateNos,
    },
    {
      key: 3,
      dataField: "Title",
      caption: "estimateRevisionManagement.tableCol3Head",
      dataType: "string" as DataGridColumnDataTypeProps,
      fixed: true,
      width: 328,
    },
    {
      key: 4,
      dataField: "",
      caption: "estimateRevisionManagement.tableCol4Head",
      cellRender: (row: any) => renderERMContextMenuCell(row, dataGridRef),
      dataType: "string" as DataGridColumnDataTypeProps,
      width: 76,
      fixed: true,
    },
    {
      key: 5,
      dataField: "EstimateStage",
      caption: "estimateRevisionManagement.tableCol5Head",
      dataType: "string" as DataGridColumnDataTypeProps,
    },
    {
      key: 6,
      dataField: "Date",
      caption: "estimateRevisionManagement.tableCol6Head",
      calculateCellValue: (rowData: {
        Date: string | number | Date
        UnixDate: string | number | Date
      }) =>
        rowData?.UnixDate
          ? formatDate(new Date(rowData?.UnixDate), "dd/MM/yyyy")
          : rowData?.Date
          ? formatDate(new Date(rowData?.Date), "dd/MM/yyyy")
          : "",
      sortingMethod: handleDateStringSorting,
      dataType: "string" as DataGridColumnDataTypeProps,
    },
    {
      key: 7,
      dataField: "EstimatorUserId",
      caption: "estimateRevisionManagement.tableCol7Head",
      calculateCellValue: (rowData: { Estimator: string; EstimatorUserId: string }) =>
        rowData.Estimator || rowData.EstimatorUserId,
      dataType: "string" as DataGridColumnDataTypeProps,
    },
    {
      key: 8,
      dataField: "Region",
      caption: "estimateRevisionManagement.tableCol8Head",
      dataType: "string" as DataGridColumnDataTypeProps,
    },
    {
      key: 9,
      dataField: "ProjectCost",
      caption: "estimateRevisionManagement.tableCol9Head",
      cellRender: (row: any) => (permsnCheck ? renderCurrencyCell(row.data.ProjectCost) : null),
      dataType: "number" as DataGridColumnDataTypeProps,
    },
    {
      key: 10,
      dataField: "TotalCarbonValue",
      caption: "estimateRevisionManagement.tableCol10Head",
      dataType: "number" as DataGridColumnDataTypeProps,
    },
  ]
}

export const confirmationPopUPProps = {
  showTitle: false,
  messageHtml: "Are you sure you want to add a new revision?",
  buttons: [
    {
      text: "Add",
      onClick: () => true,
    },
    {
      text: "Cancel",
      onClick: () => false,
    },
  ],
}

export const addRevisionPermission = (userRole: UserRole | null, project: IProject | undefined) => {
  const permFn = (accessType: number) => {
    return identifyPermissions(userRole, accessType, AccessLevel.YES, allRoles)
  }
  if (project?.ParentProjAuthorised) return permFn(AccessType.CREATE_REVISION_ESTIMATE)

  return (
    permFn(AccessType.CREATE_REVISION_ESTIMATE) &&
    permFn(AccessType.ADD_A_REVISION_TO_UNAUTHORISED_ESTIMATE)
  )
}
