/* eslint-disable no-nested-ternary */
import { get } from "lodash"
import { TFunction } from "i18next"
import { formatDate, formatNumber } from "devextreme/localization"
import { ExpenditureProfileProjectData } from "redux/expenditure-profile/types"
import { config } from "utils/config"

export const expenditureProfileReportBreadCrumb = (t: TFunction<"translation", undefined>) => [
  {
    name: t("carbExpProfile.breadCrumbHeading"),
    link: "/carbonExpenditureProfile",
  },
  {
    name: t("carbExpProfile.breadCrumbHeading2"),
  },
]

export const headerTilesGenerator = (
  t: TFunction<"translation", undefined>,
  selectedExpenditureProjectData: ExpenditureProfileProjectData | undefined
) => {
  return [
    {
      key: t("carbExpProfileReport.schemeType"),
      value: get(selectedExpenditureProjectData, "SCHEME_TYPE") || "",
    },
    {
      key: `${t("carbExpProfileReport.currPCFStage")}:`,
      value: get(selectedExpenditureProjectData, "PROJECT_EST_STAGE") || "",
    },
    {
      key: t("carbExpProfileReport.scheme"),
      value: get(selectedExpenditureProjectData, "PROJ_COMP_DESC") || "",
      value2: get(selectedExpenditureProjectData, "PROJECT_QUOTE_NO") || "",
    },
  ]
}

export const expenditureProfileReportHeadings = (t: TFunction<"translation", undefined>) => {
  return [
    { key: t("carbExpProfileReport.nationalHighways") },
    { key: t("carbExpProfileReport.commercialServcsDiv") },
    {
      key: t("carbExpProfileReport.sasHeading"),
      ExtraClass: "border-bottom border-secondary border-2",
    },
  ]
}

export const detailsTableDataGenerator = (
  t: TFunction<"translation", undefined>,
  selectedExpenditureProjectData: ExpenditureProfileProjectData | undefined
) => {
  return [
    {
      key: t("carbExpProfileReport.fundingDirectorate"),
      value: get(selectedExpenditureProjectData, "PORTFOLIO_NAME") || "",
    },
    {
      key: t("carbExpProfileReport.projSchemeName"),
      value: get(selectedExpenditureProjectData, "PROJ_COMP_DESC") || "",
    },
    {
      key: t("carbExpProfileReport.optionName"),
      value:
        get(selectedExpenditureProjectData, "PROJ_COMP_DESC") ||
        get(selectedExpenditureProjectData, "PROJECT_QUOTE_NO")
          ? `${get(selectedExpenditureProjectData, "PROJ_COMP_DESC") || "-----"} ${
              get(selectedExpenditureProjectData, "PROJECT_QUOTE_NO") || "-----"
            }`
          : "",
    },
    {
      key: t("carbExpProfileReport.estReleaseDate"),
      value: get(selectedExpenditureProjectData, "ESTIMATE_RELEASE_DATE")
        ? formatDate(
            new Date(get(selectedExpenditureProjectData, "ESTIMATE_RELEASE_DATE") || ""),
            "dd MMMM yyyy"
          )
        : "",
    },
    {
      key: t("carbExpProfileReport.currPCFStage"),
      value: get(selectedExpenditureProjectData, "PROJECT_EST_STAGE") || "",
    },
    {
      key: t("carbExpProfileReport.typeOfEstimate"),
      value: get(selectedExpenditureProjectData, "LEVEL_OF_ESTIMATE_DESCRIPTION") || "",
    },
    {
      key: t("carbExpProfileReport.leadCostEngineer"),
      value: get(selectedExpenditureProjectData, "ESTIMATOR_NAME") || "",
    },
  ]
}

const currencySignCheck = config.clientName === "HE"
export const ExpdtreReportCurrencySign = currencySignCheck ? "£" : "$"

export const renderExpdtreReportCurrency = (row: any) => {
  const val = get(row, "value")

  return formatNumber(val, {
    currency: currencySignCheck ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

export const getCarbonSwitchValue = (carbonSwitches: { [x: string]: boolean }, key: string) => {
  return carbonSwitches[key] || false
}

export const reportNullCalculate = (val: any) => val || 0

export const reportTotalCarbonCellCalculate = (row: any, year: string) => {
  const data = get(row, "data") || row || {}
  const carbonKeys = ["A1_A3", "A4", "A5A", "A5W"]
  const keys = Object.keys(data).filter((key) => {
    return (
      carbonKeys.some((cKey) => key.includes(cKey)) && key.includes(year) && !key.includes("TOTAL")
    )
  })
  const totalCarbon = keys.reduce((acc, curr) => {
    const val = get(data, curr) || 0
    return acc + val
  }, 0)

  return keys.length ? totalCarbon : 0
}

export const totalFieldsCellRender = (row: any, field: string) => {
  const data = get(row, "data") || row || {}
  const keys = Object.keys(data).filter((item) => item.includes(field))
  const totalVal = keys.reduce((acc, curr) => {
    const val = get(data, curr) || 0
    return acc + val
  }, 0)
  if (field === "TOTAL_COST") {
    return Math.round((totalVal + (get(data, "TOTAL_HISTORIC_COST") || 0)) * 100) / 100
  }

  return Math.round((totalVal || 0) * 100) / 100
}

export const totalCarbonFieldCellRender = (row: any) => {
  const totalA1A3 = totalFieldsCellRender(row, "A1_A3")
  const totalA4 = totalFieldsCellRender(row, "A4")
  const totalA5A = totalFieldsCellRender(row, "A5A")
  const totalA5W = totalFieldsCellRender(row, "A5W")
  const total = totalA1A3 + totalA4 + totalA5A + totalA5W

  return Math.round((total || 0) * 100) / 100
}
