import { get } from "lodash"
import { exportDataGrid } from "devextreme/excel_exporter"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import { UserRole } from "redux/profile/types"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { allRoles, identifyPermissions } from "utils/common-utils"
import renderCheckboxCell from "./checkboxCell"
import renderViewEPD from "./viewEPDCell"

export const tableColumnsGenerator = (userRole: UserRole | null) => {
  const assuredFieldDisabled = !identifyPermissions(
    userRole,
    AccessType.DYNAMIC_CARBON_LIBRARY,
    AccessLevel.FULL_ACCESS,
    allRoles
  )

  return [
    {
      key: 1,
      dataField: "CODE",
      caption: "dynamicCarbonLibrary.tableCol1Head",
    },
    {
      key: 2,
      dataField: "DESCRIPTION",
      caption: "dynamicCarbonLibrary.tableCol2Head",
    },
    {
      key: 3,
      dataField: "RESOURCE_UNIT",
      caption: "dynamicCarbonLibrary.tableCol3Head",
    },
    {
      key: 4,
      dataField: "CONTRACTOR_CODE",
      caption: "dynamicCarbonLibrary.tableCol4Head",
    },
    {
      key: 5,
      dataField: "SUBCONTRACTOR_COMPANY",
      caption: "dynamicCarbonLibrary.tableCol5Head",
      calculateCellValue: (rowData: any) => get(rowData, "SUBCONTRACTOR_COMPANY") || "N/A",
    },
    {
      key: 6,
      dataField: "SUPPLIER_RATE",
      caption: "dynamicCarbonLibrary.tableCol6Head",
    },
    {
      key: 7,
      dataField: "RESOURCE_REGION",
      caption: "dynamicCarbonLibrary.tableCol7Head",
    },
    {
      key: 8,
      dataField: "RESOURCE_WEIGHT",
      caption: "dynamicCarbonLibrary.tableCol8Head",
    },
    {
      key: 9,
      dataField: "RESOURCE_CATEGORY",
      caption: "dynamicCarbonLibrary.tableCol9Head",
    },
    {
      key: 10,
      dataField: "RESOURCE_GROUP",
      caption: "dynamicCarbonLibrary.tableCol10Head",
    },
    {
      key: 11,
      dataField: "RESOURCE_TYPE",
      caption: "dynamicCarbonLibrary.tableCol11Head",
    },
    {
      key: 12,
      dataField: "",
      caption: "dynamicCarbonLibrary.tableCol12Head",
      cellRender: (row: any) => renderViewEPD(row),
    },
    {
      key: 13,
      dataField: "CARB_A1_A3",
      caption: "dynamicCarbonLibrary.tableCol13Head",
    },
    {
      key: 14,
      dataField: "CARB_A5A",
      caption: "dynamicCarbonLibrary.tableCol14Head",
    },
    {
      key: 15,
      dataField: "WASTE_FACTOR_PERCENTAGE",
      caption: "dynamicCarbonLibrary.tableCol15Head",
    },
    {
      key: 16,
      dataField: "IS_ASSURED",
      caption: "dynamicCarbonLibrary.tableCol16Head",
      cellRender: (row: { data: { IS_ASSURED: number } }) =>
        renderCheckboxCell(row.data, assuredFieldDisabled),
      // visible: identifyPermissions(
      //   userRole,
      //   AccessType.DYNAMIC_CARBON_LIBRARY,
      //   AccessLevel.FULL_ACCESS,
      //   [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR]
      // ),
      visible: userRole?.ROLE_ID !== RoleType.CONTRACTOR,
    },
  ]
}

export const onExporting = (e: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("Main sheet")

  exportDataGrid({
    component: e.component,
    worksheet,
    customizeCell(options) {
      if (
        options.gridCell?.column?.dataField === "IS_ASSURED" &&
        options.gridCell?.rowType !== "header"
      ) {
        options.excelCell.value = options.gridCell.value ? "Yes" : "No"
      }
    },
  }).then(() => {
    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Dynamic Carbon Library.csv")
    })
  })
}
