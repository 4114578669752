import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  DateBox,
  SelectBox,
  Popup,
  TextBox,
  FileUploader,
  ScrollView,
} from "devextreme-react"
import notify from "devextreme/ui/notify"
import PdfIcon from "assets/icons/PdfIcon.svg"
import {
  createResourceEpdAction,
  getEpdFileAction,
  getResourceEpdAction,
  updateResourceEpdAction,
  uploadResourceEpdAction,
} from "redux/project-estimate-library-setup/middleware"
import { useAppDispatch } from "redux/store"
import "./epd-popup.scss"

interface IEpd {
  epdId?: number | null
  showEPDPopup: boolean
  setShowEPDPopup: (isVisible: boolean) => void
  epdPopupCallback: (epd: IEpd) => void
  allowUpdate: boolean
}

const EPD: React.FC<IEpd> = ({
  showEPDPopup,
  setShowEPDPopup,
  epdId,
  epdPopupCallback,
  allowUpdate,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [file, setFile] = useState([])
  const [existingEPDUrl, setExistingEPDUrl] = useState()
  const [data, setData] = useState<any>({
    CARB_EPD_A1_A3_ISINCL: "Y",
  })

  useEffect(() => {
    if (epdId) {
      dispatch(getResourceEpdAction(epdId)).then((res: any) => {
        if (res?.meta?.requestStatus === "fulfilled" && res?.payload) {
          setData(res?.payload[0])
          if (res?.payload[0]?.UPLOAD_ID) {
            dispatch(getEpdFileAction(res?.payload[0]?.UPLOAD_ID)).then((res: any) => {
              if (res?.meta?.requestStatus === "fulfilled" && res?.payload) {
                setExistingEPDUrl(res?.payload?.data)
              }
            })
          }
        }
      })
    }
  }, [epdId])

  const onFileChange = useCallback(async (value: any) => {
    if (value.length > 0 && !value[0].name.includes(".pdf")) {
      notify(t("epd.importErr"), "error", 2000)
      return
    }
    setFile(value)
    if (value && value.length === 0) {
      setExistingEPDUrl(undefined)
    }
  }, [])

  const onValueChange = useCallback(
    (key: string, v: any) => {
      if (
        key === "CARB_EPD_EXP_DATE" &&
        data?.CARB_EPD_ISSUE_DATE &&
        Date.parse(data?.CARB_EPD_ISSUE_DATE) > Date.parse(v)
      ) {
        notify("epd.expDateErr", "error", 2000)
        return
      }
      setData({
        ...data,
        [key]: v,
      })
    },
    [data]
  )

  const onSubmitEpd = useCallback(async () => {
    try {
      let res
      if (epdId) {
        res = await dispatch(updateResourceEpdAction(data))
      } else {
        res = await dispatch(createResourceEpdAction(data))
      }
      if (res?.payload?.data) {
        notify(t("epd.saveSucs"), "success", 2000)
        epdPopupCallback(res?.payload?.data[0])
      }
    } catch (error: any) {
      notify(error?.message, "error", 2000)
    }
  }, [data])

  const togglePopup = () => {
    setShowEPDPopup(!showEPDPopup)
  }

  return (
    <Popup
      title={t("epd.title")}
      visible={showEPDPopup}
      hideOnOutsideClick
      onHiding={togglePopup}
      showCloseButton
      enableBodyScroll
      dragEnabled={false}
      width={720}
      maxHeight={670}
    >
      <ScrollView className="epd-popup-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <FileUploader
                value={file}
                accept=".pdf"
                maxFileSize={1024 * 1024 * 20}
                invalidMaxFileSizeMessage={t("epd.fileSizeErr")}
                uploadedMessage={t("epd.uploadErr")}
                uploadFailedMessage={t("epd.uploadFail")}
                invalidFileExtensionMessage={t("epd.fileTypeErr")}
                uploadAbortedMessage={t("epd.uploadAbortMsg")}
                multiple={false}
                uploadMode="instantly"
                onValueChange={onFileChange}
                onUploadError={(error: any) =>
                  error && error.message && notify(error.message, "error", 2000)
                }
                uploadFile={
                  (file[0],
                  async (pdf: any) => {
                    const response = await dispatch(uploadResourceEpdAction(pdf))
                    if (response?.payload?.data) {
                      onValueChange("UPLOAD_ID", response.payload.data[0].UPLOAD_ID)
                      onValueChange("CARB_EPD_PATH", response.payload.data[0].UPLOAD_ID)
                      if (response.payload.data[0].UPLOAD_ID) {
                        dispatch(getEpdFileAction(response.payload.data[0].UPLOAD_ID)).then(
                          (res: any) => {
                            if (res?.meta?.requestStatus === "fulfilled" && res?.payload) {
                              setExistingEPDUrl(res?.payload?.data)
                            }
                          }
                        )
                      }
                    }
                  })
                }
                readyToUploadMessage={t("epd.uploadSucsMsg")}
              />
            </div>
            <div className="col-md-4 p-3">
              {data?.UPLOAD_ID && (
                <img
                  src={PdfIcon}
                  alt="View EPD File"
                  className="link"
                  title={t("epd.viewEPD")}
                  width={50}
                  onClick={() => window.open(existingEPDUrl, "_blank")}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextBox
                mode="text"
                label={t("epd.code")}
                labelMode="floating"
                className="margin-10"
                valueChangeEvent="input"
                value={data?.CARB_EPD_CODE}
                onValueChange={(v: string) => onValueChange("CARB_EPD_CODE", v)}
                width={200}
              />
            </div>
            <div className="col-md-6">
              <TextBox
                mode="text"
                labelMode="floating"
                className="margin-10"
                label={t("epd.desc")}
                valueChangeEvent="input"
                value={data?.CARB_EPD_DESC}
                onValueChange={(v: string) => onValueChange("CARB_EPD_DESC", v)}
                width={200}
                style={{ alignSelf: "flex-end" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <DateBox
                type="date"
                label={t("epd.issuDate")}
                labelMode="floating"
                className="margin-10"
                value={data?.CARB_EPD_ISSUE_DATE}
                onValueChange={(v: string) => onValueChange("CARB_EPD_ISSUE_DATE", v)}
                width={200}
                max={data?.CARB_EPD_EXP_DATE}
              />
            </div>
            <div className="col-md-6">
              <DateBox
                type="date"
                label={t("epd.expDate")}
                labelMode="floating"
                className="margin-10"
                value={data?.CARB_EPD_EXP_DATE}
                onValueChange={(v: string) => onValueChange("CARB_EPD_EXP_DATE", v)}
                width={200}
                style={{ alignSelf: "flex-end" }}
                min={data?.CARB_EPD_ISSUE_DATE}
              />
            </div>
          </div>
          <div>
            <div className="col-md-12">
              <div className="strip">
                <label>{t("epd.carbFactors")}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <SelectBox
                dataSource={[
                  { id: "Y", text: "Yes" },
                  { id: "N", text: "No" },
                ]}
                valueExpr="id"
                displayExpr="text"
                label={t("epd.a1a3Scope")}
                labelMode="floating"
                value={data?.CARB_EPD_A1_A3_ISINCL}
                onValueChange={(v: string) => onValueChange("CARB_EPD_A1_A3_ISINCL", v)}
                className="margin-10"
                width={200}
              />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col">
                  <div className="label-wrapper">
                    <label className="label">{t("epd.a1a3Label")}</label>
                  </div>
                </div>
                <div className="col">
                  <TextBox
                    mode="text"
                    labelMode="floating"
                    className="margin-10"
                    valueChangeEvent="input"
                    label={t("epd.a1a3GWP")}
                    value={data?.CARB_EPD_GWP_A1_A3}
                    onValueChange={(v: string) => onValueChange("CARB_EPD_GWP_A1_A3", v)}
                    disabled={data?.CARB_EPD_A1_A3_ISINCL !== "Y"}
                    width={200}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <SelectBox
                disabled
                dataSource={[
                  { id: "Y", text: "Yes" },
                  { id: "N", text: "No" },
                ]}
                valueExpr="id"
                label={t("epd.a4Scope")}
                displayExpr="text"
                labelMode="floating"
                className="margin-10"
                value={data?.CARB_EPD_A4_ISINCL ? data?.CARB_EPD_A4_ISINCL : "N"}
                onValueChange={(v: string) => onValueChange("CARB_EPD_A4_ISINCL", v)}
                width={200}
              />
            </div>
            <div className="col-md-8">
              <div className="label-wrapper">
                <label className="label">{t("epd.a4desc")}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <SelectBox
                dataSource={[
                  { id: "Y", text: "Yes" },
                  { id: "N", text: "No" },
                ]}
                valueExpr="id"
                displayExpr="text"
                label={t("epd.a5aScope")}
                labelMode="floating"
                className="margin-10"
                value={data?.CARB_EPD_A5_ISINCL ? data?.CARB_EPD_A5_ISINCL : "N"}
                onValueChange={(v: string) => onValueChange("CARB_EPD_A5_ISINCL", v)}
                width={200}
              />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col">
                  <div className="label-wrapper">
                    <label className="label">{t("epd.fuelCarbDesc")}</label>
                  </div>
                </div>
                <div className="col">
                  <TextBox
                    mode="text"
                    labelMode="floating"
                    className="margin-10"
                    label={t("epd.a5aGWP")}
                    valueChangeEvent="input"
                    value={data?.CARB_EPD_GWP_A5}
                    disabled={data?.CARB_EPD_A5_ISINCL !== "Y"}
                    onValueChange={(v: string) => onValueChange("CARB_EPD_GWP_A5", v)}
                    width={200}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <SelectBox
                disabled
                dataSource={[
                  { id: "Y", text: "Yes" },
                  { id: "N", text: "No" },
                ]}
                valueExpr="id"
                displayExpr="text"
                label={t("epd.a5wScope")}
                labelMode="floating"
                className="margin-10"
                value={data?.CARB_EPD_A5A_ISINCL ? data?.CARB_EPD_A5A_ISINCL : "N"}
                onValueChange={(v: string) => onValueChange("CARB_EPD_A5A_ISINCL", v)}
                width={200}
              />
            </div>
            <div className="col-md-8">
              <div className="label-wrapper">
                <label className="label">{t("epd.a1a4Desc")}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-end m-2">
                <Button
                  width={150}
                  height={30}
                  text={t("epd.save")}
                  className={
                    data?.CARB_EPD_CODE && allowUpdate && existingEPDUrl ? "button-success" : ""
                  }
                  onClick={onSubmitEpd}
                  stylingMode="contained"
                  disabled={!data?.CARB_EPD_CODE || !allowUpdate || !existingEPDUrl}
                />
                <Button
                  disabled
                  width={150}
                  height={30}
                  text={t("epd.addMore")}
                  className="active"
                  stylingMode="contained"
                  style={{ marginLeft: "50px" }}
                  onClick={() => setShowEPDPopup(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollView>
    </Popup>
  )
}

export default EPD
