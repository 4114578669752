import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse } from "services/SuccessResponse"
import notify from "devextreme/ui/notify"
import { ICarbonResource, IItemResource, ITransportAssumption } from "./types"
import {
  fetchResourcesAsync,
  fetchTransportAssumptionAsync,
  updateResourcesAsync,
  fetchItemsAsync,
  updateItemsAsync,
} from "./services"

export const fetchResourcesAction = createAsyncThunk<ICarbonResource[], undefined>(
  "coefficient/fetchResourcesByRegion",
  async (_, { rejectWithValue }) => {
    try {
      const response: ICarbonResource[] | ErrorResponse = await fetchResourcesAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }

      return response as ICarbonResource[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateResourcesAction = createAsyncThunk<ICarbonResource[], ICarbonResource[]>(
  "resources/updateResourcesAction",
  async (resources, { rejectWithValue }) => {
    try {
      const response: ICarbonResource[] | ErrorResponse = await updateResourcesAsync(resources)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response as ICarbonResource[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchTransportAssumptionAction = createAsyncThunk<ITransportAssumption[], undefined>(
  "coefficient/fetchTransportAssumptionAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: ITransportAssumption[] | ErrorResponse = await fetchTransportAssumptionAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }

      return response as ITransportAssumption[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchItemsAction = createAsyncThunk<IItemResource[], undefined>(
  "item/fetchItemsByRegion",
  async (_, { rejectWithValue }) => {
    try {
      const response: IItemResource[] | ErrorResponse = await fetchItemsAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }

      return response as IItemResource[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateItemsAction = createAsyncThunk<IItemResource[], IItemResource[]>(
  "item/updateItemsAction",
  async (resources, { rejectWithValue }) => {
    try {
      const response: IItemResource[] | ErrorResponse = await updateItemsAsync(resources)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response as IItemResource[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
