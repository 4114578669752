/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { get } from "lodash"
import notify from "devextreme/ui/notify"
import { Button, ScrollView } from "devextreme-react"
import { formatNumber } from "devextreme/localization"
import { Popup, Position, ToolbarItem } from "devextreme-react/popup"
import DataGrid, {
  Column,
  Toolbar,
  Item,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid"
import { LoadPanel } from "devextreme-react/load-panel"
import useDocumentTitle from "hooks/useDocumentTitle"
import BreadCrum from "components/bread-crumb"
import {
  getProjectByIdAction,
  getEstimateLevelsAction,
  recalculateCarbonAction,
  synchronizeCarbonAction,
} from "redux/projects/middleware"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { ICarbonEmissionDoughnutChartData } from "redux/projects/types"
import { projectSelector, selectSection } from "redux/projects/projectSlice"
import CarbonEmissionDoughnutChart from "screens/project-item/components/carbon-emission-doughnut-chart"
import CarbonEmissionStackedBarChart from "screens/project-item/components/carbon-emission-stacked-bar-chart"
import { LoadingState } from "types/AppNav"
import { allRoles, identifyPermissions, numberFormatterConfig } from "utils/common-utils"
import { config } from "utils/config"
import { sectionsBreadCrum } from "./utils"
import SectionSummaryCard from "./components/SectionSummaryCard"
import "./project-sections.scss"

const ProjectSections: React.FC = () => {
  useDocumentTitle("Estimate Sections | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { loading, project, sections, estimatorID, sectionColumnWiseTotal, topFiveCarbonSections } =
    useSelector(projectSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const [isBarChartPopUpVisible, setBarChartPopUpVisible] = useState(false)
  const [isPieChartPopUpVisible, setPieChartPopUpVisible] = useState(false)

  const carbonEmissionBySectionLevel: ICarbonEmissionDoughnutChartData[] = [
    {
      region: "A1-A3 (KgCO2e)",
      val: sectionColumnWiseTotal.A1A3Total / (sectionColumnWiseTotal.sectionTotalCarbonCost || 1),
    },
    {
      region: "A4 (KgCO2e)",
      val: sectionColumnWiseTotal.A4Total / (sectionColumnWiseTotal.sectionTotalCarbonCost || 1),
    },
    {
      region: "A5a (KgCO2e)",
      val: sectionColumnWiseTotal.A5aTotal / (sectionColumnWiseTotal.sectionTotalCarbonCost || 1),
    },
    {
      region: "A5w (KgCO2e)",
      val: sectionColumnWiseTotal.A5wTotal / (sectionColumnWiseTotal.sectionTotalCarbonCost || 1),
    },
  ]

  const init = useCallback(() => {
    if (!project) dispatch(getProjectByIdAction(projectId))
    const data = { aggregatedOn: "SECTION", projectId }
    dispatch(getEstimateLevelsAction(data))
  }, [project, projectId])

  useEffect(() => {
    init()
  }, [])

  const permissionCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const contractorCondition = useMemo(
    () => userRole?.ESTIMATOR_ID === estimatorID,
    [userRole, estimatorID]
  )

  const renderDirectCostCell = useCallback(
    (row: any) => {
      const val = formatNumber(row.data.PROJECT_SECTION_COST, {
        currency: config.clientName === "HE" ? "GBP" : "USD",
        precision: 2,
        type: "currency",
      })

      return userRole?.ROLE_ID !== RoleType.CONTRACTOR
        ? permissionCheck && val
        : contractorCondition && permissionCheck && val
    },
    [permissionCheck, contractorCondition]
  )

  const showPopUp = (popUpState: string) => {
    const doc: any = document?.body
    if (popUpState === "visible" && doc.classList.contains("bar-chart")) {
      doc.classList.remove("pie-chart")
      setPieChartPopUpVisible(false)
      setBarChartPopUpVisible(true)
    } else if (popUpState === "visible" && doc.classList.contains("pie-chart")) {
      doc.classList.remove("bar-chart")
      setBarChartPopUpVisible(false)
      setPieChartPopUpVisible(true)
    }
  }

  const hideInfo = () => {
    const doc: any = document?.body
    if (doc.classList.contains("bar-chart")) {
      doc.classList.remove("bar-chart")
    }

    if (doc.classList.contains("pie-chart")) {
      doc.classList.remove("pie-chart")
    }

    setBarChartPopUpVisible(false)
    setPieChartPopUpVisible(false)
  }

  const handleRecalculate = async () => {
    notify(t("sections.recalculatingMsg"), "info", 3000)
    await dispatch(recalculateCarbonAction({ projectId })).then((res) => {
      init()
      const resStatus = get(res, "meta.requestStatus")
      const resDataStatus = get(res, "payload.data.status")
      setTimeout(() => {
        notify(resDataStatus, resStatus === "fulfilled" ? "success" : "error", 3000)
      }, 3000)
    })
  }
  const handleSynchronize = useCallback(async () => {
    notify(t("sections.syncMsg"), "info", 3000)
    await dispatch(synchronizeCarbonAction(projectId)).then(async (res) => {
      const resStatus = get(res, "meta.requestStatus")
      // const resDataStatus = get(res, "Carbon Synchronize Completed")
      await handleRecalculate()
      setTimeout(() => {
        notify(
          "Carbon data synchronised successfully",
          resStatus === "fulfilled" ? "success" : "error",
          3000
        )
      }, 3000)
    })
  }, [projectId])

  const closeButtonOptions = {
    text: "close",
    onClick: hideInfo,
  }

  const onRowClick = useCallback(
    (e: any) => {
      const rowData = get(e, "data")
      const id = get(e, "data.PROJECT_SECTION_ID")
      const ParentProjId = get(location, "state.ParentProjId")

      if (id) {
        dispatch(selectSection(rowData))
        ParentProjId
          ? navigate(`/project/${projectId}/section/${id}/items`, {
              state: { ParentProjId },
            })
          : navigate(`/project/${projectId}/section/${id}/items`)
      }
    },
    [location, projectId, dataGridRef]
  )

  return (
    <div data-testid="project-section" className="estimate-section-wrapper">
      <ScrollView height={windowHeight - 60}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position="center"
          visible={loading === LoadingState.LOADING}
          showIndicator
          shading
          showPane
        />
        <div className="project-top-section">
          <BreadCrum data={sectionsBreadCrum(t, project)} />
          <div className="chart-container">
            <div className="dx-card chart-wrapper">
              <CarbonEmissionDoughnutChart
                showPopUp={showPopUp}
                data={carbonEmissionBySectionLevel}
              />
            </div>
            <div className="dx-card chart-wrapper">
              <CarbonEmissionStackedBarChart showPopUp={showPopUp} data={topFiveCarbonSections} />
            </div>
          </div>
          <SectionSummaryCard />
        </div>
        <div className="estimate-section-grid">
          <Popup
            visible={isPieChartPopUpVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick
            showCloseButton={false}
            showTitle={false}
            title={t("sections.carbonEmissions")}
            container=".dx-viewport"
          >
            <div className="p-3">
              <Position at="center" my="center" collision="fit" />
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={closeButtonOptions}
              />
              <CarbonEmissionDoughnutChart
                showPopUp={showPopUp}
                isPieChartPopUpVisible={isPieChartPopUpVisible}
                data={carbonEmissionBySectionLevel}
              />
            </div>
          </Popup>
          <Popup
            visible={isBarChartPopUpVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick
            showCloseButton={false}
            showTitle={false}
            title={t("sections.carbonEmissions")}
            container=".dx-viewport"
          >
            <div className="p-3">
              <Position at="center" my="center" collision="fit" />
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={closeButtonOptions}
              />
              <CarbonEmissionStackedBarChart
                showPopUp={showPopUp}
                isBarChartPopUpVisible={isBarChartPopUpVisible}
                data={topFiveCarbonSections}
              />
            </div>
          </Popup>
          <div className="grid-title">
            <label>{t("sections.sectionsInEstimate")}</label>
          </div>
          <DataGrid
            className="compact-grid"
            ref={dataGridRef}
            dataSource={sections}
            showBorders
            showColumnLines
            columnAutoWidth
            hoverStateEnabled
            allowColumnResizing
            rowAlternationEnabled
            onRowClick={onRowClick}
            height={windowHeight - 252}
            headerFilter={{ visible: true }}
            stateStoring={{
              enabled: true,
              type: "localStorage",
              storageKey: "estimate-sections-grid-state", // The arg under selectProject fn in slice should be same as this key
              savingTimeout: 0,
            }}
          >
            <FilterRow visible />
            <Paging defaultPageSize={10} />
            <SearchPanel visible width={240} />
            <Pager showPageSizeSelector showInfo visible />
            <Toolbar>
              <Item location="before">
                <span title={t("toolbarActions.resetAll")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => dataGridRef.current?.instance.state(null)}
                  />
                </span>
              </Item>
              <Item location="before">
                <span title={t("sections.recalculateCarbon")}>
                  <Button
                    stylingMode="contained"
                    className="active"
                    text={t("sections.recalculate")}
                    onClick={handleRecalculate}
                  />
                </span>
              </Item>
              <Item location="before">
                <span title={t("sections.syncDesc")}>
                  <Button
                    stylingMode="contained"
                    className="active mx-1 px-3"
                    text={t("sections.sync")}
                    onClick={handleSynchronize}
                  />
                </span>
              </Item>
              <Item name="searchPanel" />
            </Toolbar>
            <Column
              dataField="PROJECT_SECTION_NUMBER"
              width={140}
              dataType="number"
              caption={t("sections.number")}
              sortOrder="asc"
              defaultSortOrder="asc"
              fixed
            />
            {/* <Column dataField="Code" width={120} dataType="number" caption="Section Code" /> */}
            <Column
              dataField="PROJECT_SECTION_DESC"
              minWidth={160}
              dataType="string"
              caption={t("sections.desc")}
              fixed
            />
            <Column
              dataField="PROJECT_SECTION_CARB_A1_A3"
              caption={t("sections.a1a3")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="PROJECT_SECTION_CARB_A4"
              caption={t("sections.a4")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="PROJECT_SECTION_CARB_A5A"
              caption={t("sections.a5a")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="PROJECT_SECTION_CARB_A5W"
              caption={t("sections.a5w")}
              dataType="number"
              format={numberFormatterConfig}
            />
            <Column
              dataField="PROJECT_SECTION_CARB_TOTAL_A1_A5"
              caption={t("sections.totalA1A5")}
              dataType="number"
              format={numberFormatterConfig}
            />
            {/* <Column
            dataField="TotalCO2"
            width={180}
            dataType="number"
            caption={t("sections.totalCO2")}
          /> */}
            <Column
              dataField="PROJECT_SECTION_COST"
              width={160}
              cellRender={renderDirectCostCell}
              caption={t("sections.totalCost")}
              defaultSortOrder="asc"
              dataType="number"
            />
            <Column
              width={150}
              dataField="PROJECT_SECTION_COMPLETED"
              caption={t("sections.completed")}
              dataType="boolean"
              cssClass="border-class"
              allowFiltering={false}
              allowEditing={false}
              allowHeaderFiltering
            />
          </DataGrid>
        </div>
      </ScrollView>
    </div>
  )
}

export default ProjectSections
