import { get } from "lodash"
import { TFunction } from "i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import {
  setCarbonSwitches,
  setPopUpCarbonSwitches,
} from "redux/expenditure-profile/expenditureProfileSlice"
import { myUtils } from "utils/common-utils"
import { getCarbonSwitchValue } from "../../utils"

const TotalCarbonHeaderCellRender = (props: {
  t: TFunction<"translation", undefined>
  data: any
  carbonSwitches?: any
  popUpCarbonSwitches?: any
  key: string
}) => {
  const { t, data, carbonSwitches, popUpCarbonSwitches, key } = props

  const { dispatch } = myUtils
  const columnTitle = get(data, "column.caption")
  const val = getCarbonSwitchValue(carbonSwitches || popUpCarbonSwitches, key)

  const onIconClick = (e: any) => {
    e.stopPropagation()
    if (carbonSwitches) dispatch(setCarbonSwitches({ key, value: !val }))
    if (popUpCarbonSwitches) dispatch(setPopUpCarbonSwitches({ key, value: !val }))
  }

  return (
    <div className="exp-report-header-cell">
      <FontAwesomeIcon
        icon={val ? faMinus : faPlus}
        size="lg"
        color="grey"
        className="exp-report-carbon-toggle"
        onClick={onIconClick}
        title={t(val ? "carbExpProfileReport.hideCarbVals" : "carbExpProfileReport.showCarbVals")}
      />
      <span className="exp-report-header-value" title={columnTitle}>
        {columnTitle}
      </span>
    </div>
  )
}

export default TotalCarbonHeaderCellRender
