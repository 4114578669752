import axiosInstance, { isAxiosError } from "services/api"
import { sort } from "fast-sort"
import ResponseTransformer from "utils/response-transformer"
import {
  IProject,
  ISection,
  EstimateLevelAPIData,
  ResourceType,
  IResource,
  IItem,
  RecalculateThunkRequest,
} from "./types"

export const defaultSort = (records: Array<any>, sortField: string) => {
  return sort(records).by([
    {
      asc: (u: any) => u[sortField],
      comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare,
    },
  ])
}

export const getProjectsAsync = async () => {
  let projects: Array<IProject> = []
  try {
    const response = await axiosInstance.get(`/projects`)
    if (response?.data?.data) {
      projects = new ResponseTransformer(response?.data?.data).transformResponsetoJSON()
    }
    const sortedProjects = defaultSort(projects, "QuoteNo")
    return sortedProjects
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getProjectByIdAsync = async (projectId?: string) => {
  let project: IProject = {}
  try {
    const response = await axiosInstance.get(`/project/${projectId}`)
    if (response?.data?.data) {
      const projects: Array<IProject> = new ResponseTransformer(
        response?.data?.data
      ).transformResponsetoJSON()
      if (projects.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        project = projects[0]
      }
    }
    return project
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getSectionByIdAsync = async (sectionId?: string) => {
  let section: ISection = {}
  try {
    const response = await axiosInstance.get(`/project/section/${sectionId}`)
    if (response?.data && response?.data?.data) {
      section = response.data.data[0] as ISection
    }
    return section
  } catch (err) {
    return isAxiosError(err)
  }
}

export const recalculateCarbonAsync = async (recalculateThunkRequest: RecalculateThunkRequest) => {
  try {
    const url = recalculateThunkRequest.sectionId
      ? `/carbon-calc/calculate/project/${recalculateThunkRequest.projectId}?sectionId=${recalculateThunkRequest.sectionId}`
      : `/carbon-calc/calculate/project/${recalculateThunkRequest.projectId}`

    const response = await axiosInstance.get(url)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const synchronizeCarbonAsync = async (projectId?: string) => {
  try {
    // Update URL
    const url = `/carbon-calc/sync/${projectId}`
    const response = await axiosInstance.get(url)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getEstimateLevelsAsync = async (data: EstimateLevelAPIData) => {
  const { aggregatedOn, projectId, sectionId } = data
  try {
    const params: EstimateLevelAPIData = {}
    if (aggregatedOn) {
      params.aggregatedOn = aggregatedOn
    }
    if (sectionId) {
      params.sectionId = sectionId
    }
    const response = await axiosInstance.get(`/project-aggr/project/${projectId}`, { params })
    if (sectionId && response && response.data && response.data.data) {
      response?.data?.data?.BOQ?.forEach((obj: any) => {
        obj.PROJECT_ITEMAGGR_HIERARCHY = obj.PROJECT_ITEMAGGR_HIERARCHY?.replaceAll("][", ",")
        obj.PROJECT_ITEMAGGR_HIERARCHY = obj.PROJECT_ITEMAGGR_HIERARCHY
          ? JSON.parse(obj.PROJECT_ITEMAGGR_HIERARCHY)
          : []
        obj.PROJECT_ITEM_COMPOSITETYPE = ResourceType[obj.PROJECT_ITEM_COMPOSITETYPE]
        if (obj.SUBITEM === "Y" && obj.PROJECT_ITEM_COMPOSITETYPE === "NormalItem") {
          obj.PROJECT_ITEM_COMPOSITETYPE = "SubItem"
        }
        obj.CODE = obj.CODE || ""
        if (obj.PROJECT_RESOURCE_ID) {
          obj.ID = obj.PROJECT_RESOURCE_ID
          obj.Parent_ID = obj.PROJECT_ITEM_ID
        } else {
          obj.ID = obj.PROJECT_ITEM_ID
          obj.Parent_ID = obj.PROJECT_ITEMAGGR_HIERARCHY.length
            ? obj.PROJECT_ITEMAGGR_HIERARCHY[obj.PROJECT_ITEMAGGR_HIERARCHY.length - 1]
            : -1
        }
        for (const [key, value] of Object.entries(obj)) {
          if (value && typeof value === "number") obj[key] = parseFloat(value.toFixed(2))
        }
      })
      const sortedProjects = defaultSort(response.data.data, "CODE")
      response.data.data = sortedProjects
    }
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const getItemsDetailsAsync = async (itemId?: number | string) => {
  try {
    const response = await axiosInstance.get(`/project-item/${itemId}`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getResourceDetailsAsync = async (resourceId?: number | string) => {
  try {
    const response = await axiosInstance.get(`/project-resource/${resourceId}`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateResourcesAsync = async (resources?: Array<IResource>) => {
  try {
    const response = await axiosInstance.put("/project-resource/bulk", { bulk: resources })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateItemsAsync = async (items?: Array<IItem>) => {
  try {
    const response = await axiosInstance.put("/project-item/bulk", { bulk: items })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteBOQProjectItemAsync = async (resourceId: number, projectId: number) => {
  try {
    const response = await axiosInstance.delete<string>(
      `/project-item?ids=${resourceId}&projectId=${projectId}`
    )
    return response
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteBOQProjectResourceAsync = async (resourceId: number, projectId: number) => {
  try {
    const response = await axiosInstance.delete<string>(
      `/project-resource?ids=${resourceId}&projectId=${projectId}`
    )
    return response
  } catch (err) {
    return isAxiosError(err)
  }
}
