import { get } from "lodash"
import { iftScheduleItem } from "./types"

export const getIFTScheduleParent = (item: any) => {
  let PROJECT_ITEMAGGR_HIERARCHY = get(item, "PROJECT_ITEMAGGR_HIERARCHY")?.replaceAll("][", ",")
  PROJECT_ITEMAGGR_HIERARCHY = PROJECT_ITEMAGGR_HIERARCHY
    ? JSON.parse(PROJECT_ITEMAGGR_HIERARCHY)
    : []
  const ParentIDFromAggr = PROJECT_ITEMAGGR_HIERARCHY.length
    ? PROJECT_ITEMAGGR_HIERARCHY[PROJECT_ITEMAGGR_HIERARCHY.length - 1]
    : get(item, "PROJECT_SECTION_ID")

  const ParentID = () => {
    switch (get(item, "PROJECT_ITEM_COMPOSITETYPE")) {
      case 3:
        return get(item, "PROJECT_ITEM_ID")
      case undefined:
        return -1
      default:
        return ParentIDFromAggr
    }
  }

  return ParentID()
}

export const getIFTScheduleParentForComparator = (item: any) => {
  if (item.LEVEL === 2) {
    return get(item, "PROJECT_ITEM_ID")
  }
  let PROJECT_ITEMAGGR_HIERARCHY = get(item, "PROJECT_ITEMAGGR_HIERARCHY")?.replaceAll("][", ",")
  PROJECT_ITEMAGGR_HIERARCHY = PROJECT_ITEMAGGR_HIERARCHY
    ? JSON.parse(PROJECT_ITEMAGGR_HIERARCHY)
    : []
  const ParentIDFromAggr = PROJECT_ITEMAGGR_HIERARCHY.length
    ? PROJECT_ITEMAGGR_HIERARCHY[PROJECT_ITEMAGGR_HIERARCHY.length - 1]
    : get(item, "PROJECT_SECTION_ID")

  const ParentID = () => {
    switch (get(item, "PROJECT_ITEM_COMPOSITETYPE")) {
      case 3:
        return get(item, "PROJECT_ITEM_ID")
      case undefined:
        return -1
      default:
        return ParentIDFromAggr
    }
  }

  return ParentID()
}

export const transformIFTScheduleAPIResponse = (iftScheduleAPIResponse: any[]) => {
  return Array.isArray(iftScheduleAPIResponse)
    ? iftScheduleAPIResponse
        .reduce((acc, curr) => {
          const row = curr.Properties.reduce(
            (a: iftScheduleItem, b: { Name: string; Data: string | number | null }) => {
              const obj = { [b.Name]: b.Data }
              return { ...a, ...obj }
            },
            {}
          )
          return [...acc, row]
        }, [])
        .sort((a: iftScheduleItem, b: iftScheduleItem) => a.StoreId - b.StoreId)
    : []
}

export const getValFromBESStructure = (source: any[], key: string) => {
  return source[0].Properties.find((item: { Name: string }) => item.Name === key).Data
}

export const calculateTotalProfiledCostForResult = (
  inflationValues: number[],
  itemValues: number[]
) => {
  return inflationValues.reduce(
    (sum: number, inflationValue: number, index: number) =>
      sum + inflationValue + itemValues[index],
    0
  )
}
