import { memo } from "react"
// import { SelectBox } from "devextreme-react"
import HeaderTiles from "./headerTiles"
import DetailsTable from "../detailsTable"
import ExpenditureProfileReportHeadings from "./headings"

const ExpenditureProfileReportHead = () => {
  return (
    <div className="expenditure-profile-report-head">
      <HeaderTiles />
      <div className="expenditure-profile-header-block">
        <span>
          <ExpenditureProfileReportHeadings />
          <DetailsTable />
        </span>
        <div className="expenditure-profile-header-block-2child">
          {/* If any content coming, please use code splitting and write as separate file, like DetailsTable done above */}

          {/* <div className="dropdown">
            <label className="me-2">Select P Value</label>
            <SelectBox
              dataSource={[]}
              displayExpr="PROJECT_QUOTE_NO"
              valueExpr="PROJECT_ID"
              style={{ height: "min-content" }}
              // value={estimateNumber}
              searchEnabled
              // onSelectionChanged={({ selectedItem }) => dispatch(setEstimateNumber(selectedItem))}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default memo(ExpenditureProfileReportHead)
