import { ValidationRule } from "devextreme/common"
import { currencyConverter } from "../ScopeTable/scopeTableUtils"

export type SubItemModalProps = {
  visible?: boolean
  onHide?: () => void
  rowData?: any
}

export const costCellRender = (val: any) => {
  const final =
    val.data.PROJECT_ITEM_QTY &&
    val.data.ITEM_LIBRARY_RATE &&
    val.data.ITEM_LIBRARY_RATE * val.data.PROJECT_ITEM_QTY
  return currencyConverter(final)
}

export const subItemQtyValidationRules = [
  {
    type: "required",
    message: "Quantity is required",
  },
  {
    type: "numeric",
    message: "Quantity must be numeric",
    ignoreEmptyValue: false,
  },
  {
    type: "range",
    min: 1,
    ignoreEmptyValue: false,
  },
] as unknown as ValidationRule[]
