import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { get, orderBy, uniqueId } from "lodash"
import Button from "devextreme-react/button"
import { custom } from "devextreme/ui/dialog"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, {
  AsyncRule,
  Column,
  Editing,
  Export,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import BreadCrumb from "components/bread-crumb"
import useDocumentTitle from "hooks/useDocumentTitle"
import { useAppDispatch } from "redux/store"
import { sharedSelector } from "redux/shared/sharedSlice"
import { CarbonCodesCategoryItem, CodeItems } from "redux/masters/types"
import { createCode, deleteCode, masterSelector, updateCode } from "redux/masters/masterSlice"
import {
  editCodeForCategoryAction,
  fetchCodesByCategoryAction,
  createCodeForCategoryAction,
  deleteCodesJourneyLegAction,
  deleteCodesForCategoryAction,
  fetchCarbonCodeCategoriesAction,
} from "redux/masters/middleware"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { CodesBreadCrumb, codeDelconfirmPopUPProps, onCodesExporting } from "./utils"
import "./codes.scss"

const Codes: React.FC = (): JSX.Element => {
  useDocumentTitle("Codes | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataGridRef = useRef<DataGrid>(null)

  const { windowHeight } = useSelector(sharedSelector)
  const { codeItems, carbonCodeCategory } = useSelector(masterSelector)

  const [currentCode, setCurrentCode] = useState<CarbonCodesCategoryItem>()

  useEffect(() => {
    dispatch(fetchCarbonCodeCategoriesAction())
  }, [])

  useEffect(() => {
    if (carbonCodeCategory.length && !currentCode) setCurrentCode(carbonCodeCategory[0])
  }, [carbonCodeCategory])

  useEffect(() => {
    if (currentCode) {
      dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
      dataGridRef.current?.instance.option("toolbar.items[1].disabled", false)
      dataGridRef.current?.instance.pageIndex(0)
      if (currentCode.CUSTOM_LIST_NAME) {
        dispatch(fetchCodesByCategoryAction(currentCode.CUSTOM_LIST_NAME))
      }
    }
  }, [currentCode])

  const store = useMemo(() => {
    return new CustomStore({
      load: () => orderBy(codeItems, ["Name"], ["asc"]),
      key: "Id",
      update: async (key, value) => {
        dispatch(
          updateCode({
            ...value,
            Id: key,
          })
        )
        await dispatch(
          editCodeForCategoryAction({
            ...value,
            Id: key,
            category: currentCode?.CUSTOM_LIST_NAME || "",
          })
        )
        return Promise.resolve(codeItems)
      },
      insert: async (value: CodeItems) => {
        const newItem: CodeItems = { Id: uniqueId(), Name: value.Name, Description: value.Name }
        dispatch(createCode(newItem))
        await dispatch(
          createCodeForCategoryAction({
            category: currentCode?.CUSTOM_LIST_NAME || "",
            Name: value.Name,
            Description: value.Name,
          })
        )
        return Promise.resolve(newItem)
      },
      remove: async (key: string) => {
        await dispatch(deleteCodesForCategoryAction({ id: key })).then((res) => {
          if (!get(res, "error.message")) {
            dispatch(deleteCode(key))
            dispatch(deleteCodesJourneyLegAction({ id: key }))
            return Promise.resolve()
          }
          return Promise.reject()
        })
      },
    })
  }, [codeItems])

  const handleDeleteClick = () => {
    const selectedRows = dataGridRef.current?.instance.getSelectedRowsData()
    if (selectedRows && selectedRows.length) {
      const result = custom(codeDelconfirmPopUPProps)
      result.show().then((dialogResult: boolean) => {
        if (dialogResult) {
          selectedRows
            ?.map((row) => row.Id)
            ?.forEach((element) => {
              store
                .remove(element)
                .then(() => dataGridRef.current?.instance.option("toolbar.items[0].disabled", true))
            })
        }
      })
    }
  }

  const handleAddClick = () => {
    dataGridRef.current?.instance.cancelEditData()
    dataGridRef.current?.instance.addRow()
  }

  const handleRowSelection = (e: any) => {
    const selectedRowIds = e.selectedRowKeys as string[]
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", !selectedRowIds.length)
  }

  const handleNameValidation = (params: any) => {
    if (!params.value || !params.value.trim().length) return Promise.reject()
    return Promise.resolve(true)
  }

  return (
    <>
      <BreadCrumb data={CodesBreadCrumb(t)} />
      <table className="codesTable">
        <tbody>
          <tr>
            {carbonCodeCategory.map((item: CarbonCodesCategoryItem) => (
              <td key={item.CUSTOM_LIST_ID}>
                <Button
                  className={`codeButtons ${
                    currentCode?.CUSTOM_LIST_NAME === item.CUSTOM_LIST_NAME ? "active" : ""
                  }`}
                  id={`btn${item.CUSTOM_LIST_ID}`}
                  text={
                    item.CUSTOM_LIST_NAME === "Fuel or Labour Type"
                      ? t("codesCategory.fuelType")
                      : item.CUSTOM_LIST_NAME
                  }
                  onClick={() => setCurrentCode(item)}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <DataGrid
        ref={dataGridRef}
        keyExpr="Id"
        dataSource={store}
        highlightChanges
        repaintChangesOnly
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        noDataText={t("noRecords")}
        selection={{ mode: "multiple" }}
        onSelectionChanged={handleRowSelection}
        onExporting={(e) => onCodesExporting(e, currentCode)}
        height={windowHeight - 162}
      >
        <Export enabled />
        <SearchPanel visible width={340} />
        <Editing mode="row" allowUpdating allowAdding />
        <Paging defaultPageSize={defaultPageSize} />
        <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.delete")}>
              <Button icon="trash" stylingMode="text" text="" onClick={handleDeleteClick} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.add")}>
              <Button icon="add" stylingMode="text" text="" onClick={handleAddClick} />
            </span>
          </Item>
          <Item name="exportButton" />
          <Item name="searchPanel" />
        </Toolbar>
        <Column dataField="Name" caption={t("codesCategory.values")}>
          <AsyncRule
            message={t("codesCategory.emptyCodeDescription")}
            validationCallback={handleNameValidation}
          />
        </Column>
      </DataGrid>
    </>
  )
}

export default memo(Codes)
