/* eslint-disable no-nested-ternary */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { get, uniqBy } from "lodash"
import { getIFTScheduleParentForComparator } from "redux/ift/utils"
import { IFTComparatorState } from "./types"
import {
  getCompratorDataAction,
  getAllProjectsAction,
  getAllEstimateRevisionsAction,
  getCompDataAction,
  getCompTotalDataAction,
} from "./middleware"
import { getLevel } from "./utils"

const INITIAL_STATE: IFTComparatorState = {
  loading: LoadingState.DEFAULT,
  comprator: [],
  comp: [],
  estimateNumber: 0,
  revisionNumbers: [],
  estimateName: "",
  estimateDesc: "",
  projectIDs: [],
  estimateRevisions: [],
  compareByKeys: ["Cost Variance"],
  compareCompByKeys: ["Risk Cost"],
  showEstimateTotalsPopUp: false,
  comparedEstimateTotals: [],
}

const comparatorSlice = createSlice({
  name: "Comparator",
  initialState: INITIAL_STATE,
  reducers: {
    selectComparator: (state, action) => {
      state.comprator = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEstimateNumber: (state, action) => {
      state.estimateNumber = action.payload?.PROJECT_ID
      state.estimateName = action.payload?.PROJECT_QUOTE_NO
      state.estimateDesc = action.payload?.PROJECT_TITLE
    },
    setEstimateRevisionNumber: (state, action) => {
      state.revisionNumbers = action.payload
    },
    setCompareByKeys: (state, action) => {
      state.compareByKeys = action.payload
    },
    setCompareCompByKeys: (state, action) => {
      state.compareCompByKeys = action.payload
    },
    setEstimatorTotalsPopUp: (state, action) => {
      state.showEstimateTotalsPopUp = action.payload
    },
    resetComparatorData: (state) => {
      state.comp = []
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCompratorDataAction.pending, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getCompratorDataAction.fulfilled,
      (state: IFTComparatorState, { payload }: any) => {
        const data = (payload || []).map((item: any) => {
          let parentID = getIFTScheduleParentForComparator(item)
          const level = getLevel(item)
          if (item.LEVEL === 0) {
            parentID = -1
          }
          return {
            parentID,
            ...item,
            code: get(item, "CODE"),
            description: get(item, "DESCRIPTION"),
            id:
              level === 0
                ? get(item, "PROJECT_SECTION_ID")
                : level === 1
                ? get(item, "PROJECT_ITEM_ID")
                : get(item, "PROJECT_RESOURCE_ID"),
            totalA1A5: get(item, "V0_CARB_TOTAL_A1_A5"),
            totalCost: get(item, "V0_COST"),
            level,
          }
        })
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          comprator: uniqBy(data, "id"),
        }
      }
    )
    builder.addCase(getCompratorDataAction.rejected, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getAllProjectsAction.pending, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getAllProjectsAction.fulfilled,
      (state: IFTComparatorState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        projectIDs: payload,
        // estimateNumber: payload[0]?.PROJECT_ID,
        // estimateName: payload[0]?.PROJECT_QUOTE_NO,
        // estimateDesc: payload[0]?.PROJECT_TITLE,
      })
    )
    builder.addCase(getAllProjectsAction.rejected, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getAllEstimateRevisionsAction.pending, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getAllEstimateRevisionsAction.fulfilled,
      (state: IFTComparatorState, { payload }: PayloadAction<any>) => {
        const data = [...payload].sort((a, b) => {
          const aGenerated = get(a, "IsCessGenerated")
          const bGenerated = get(b, "IsCessGenerated")
          const aScheme = get(a, "QuoteNo") || ""
          const bScheme = get(b, "QuoteNo") || ""

          if (aGenerated && bGenerated) return aScheme.localeCompare(bScheme)
          if (!aGenerated && !bGenerated) return aScheme.localeCompare(bScheme)
          return aGenerated && !bGenerated ? -1 : 0
        })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          estimateRevisions: data,
        }
      }
    )
    builder.addCase(getAllEstimateRevisionsAction.rejected, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getCompDataAction.pending, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getCompDataAction.fulfilled,
      (state: IFTComparatorState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        comp: payload,
      })
    )
    builder.addCase(getCompDataAction.rejected, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(getCompTotalDataAction.pending, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getCompTotalDataAction.fulfilled,
      (state: IFTComparatorState, { payload }: PayloadAction<any>) => {
        const updatedData = payload.reduce((acc: any, curr: {}) => {
          const obj: any = {}
          const keys = Object.keys(curr)
          const updatedObj = keys.reduce((acc1, curr1) => {
            if (typeof get(curr, curr1) === "number") {
              obj[curr1] = Math.round((get(curr, curr1) || 0) * 100) / 100 || 0
            } else obj[curr1] = get(curr, curr1)

            return { ...acc1, ...obj }
          }, {})
          return [...acc, updatedObj]
        }, [])

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          comparedEstimateTotals: updatedData,
        }
      }
    )
    builder.addCase(getCompTotalDataAction.rejected, (state: IFTComparatorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const comparatorSelector = (state: RootState) => state.IFTComparator

export const {
  setLoading,
  setCompareByKeys,
  selectComparator,
  setEstimateNumber,
  resetComparatorData,
  setCompareCompByKeys,
  setEstimatorTotalsPopUp,
  setEstimateRevisionNumber,
} = comparatorSlice.actions
export default comparatorSlice.reducer
