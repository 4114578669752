import { get } from "lodash"
import { TFunction } from "i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { setCostReportCarbonSwitches } from "redux/cost-estimate-summary/costEstimateSummarySlice"
import { myUtils } from "utils/common-utils"

const TotalCarbHeaderCellRender = (props: {
  t: TFunction<"translation", undefined>
  data: any
  val: boolean
  key: string
}) => {
  const { t, data, val, key } = props

  const { dispatch } = myUtils
  const columnTitle = get(data, "column.caption")

  const onIconClick = (e: any) => {
    e.stopPropagation()
    dispatch(setCostReportCarbonSwitches({ key, value: !val }))
  }

  return (
    <div className="cost-summary-report-header-cell">
      <FontAwesomeIcon
        icon={val ? faMinus : faPlus}
        size="lg"
        color="grey"
        className="cost-summary-report-carbon-toggle"
        onClick={onIconClick}
        title={t(val ? "costEstimateSummary.hideCarbVals" : "costEstimateSummary.showCarbVals")}
      />
      <span className="cost-summary-report-header-value" title={columnTitle}>
        {columnTitle}
      </span>
    </div>
  )
}

export default TotalCarbHeaderCellRender
