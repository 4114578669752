import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { ControlPanelState } from "./types"

import { fetchAssignInflationIndexAction } from "./middleware"

const INITIAL_STATE: ControlPanelState = {
  loading: LoadingState.DEFAULT,
  assignInflationIndexList: [],
}

const controlPanelSlice = createSlice({
  name: "ControlPanel",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state: ControlPanelState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),

    setEditedHasAccess: (state: ControlPanelState, { payload }: PayloadAction<any>) => {
      const items = [...state.assignInflationIndexList]
      const idx = items.findIndex((item: any) => item.XML_STORE_ID === payload.XML_STORE_ID)
      if (idx >= 0) {
        items[idx] = { ...items[idx], ...payload }
      }
      return { ...state, assignInflationIndexList: items }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAssignInflationIndexAction.fulfilled,
      (state: ControlPanelState, { payload }: PayloadAction<any[]>) => ({
        ...state,
        assignInflationIndexList: payload,
        loading: LoadingState.DEFAULT,
      })
    )
  },
})

export const { setLoading, setEditedHasAccess } = controlPanelSlice.actions

export const controlPanelSelector = (state: RootState) => state.ControlPanel

export default controlPanelSlice.reducer
