import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { useAppDispatch } from "redux/store"
import { projectSelector } from "redux/projects/projectSlice"
import { getProjectByIdAction } from "redux/projects/middleware"
import {
  getEstimateContractorsAction,
  getEstimateRevisionsAction,
} from "redux/estimate-revision-management/middleware"
import {
  estmteRvsnMgmntSelector,
  reset,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import useDocumentTitle from "hooks/useDocumentTitle"
import EditRevisionPopUp from "./components/editRevisionPopUp"
import ManageContractorsPopUp from "./components/manageContractors"
import RevisionManagementTable from "./components/revisionManagementTable"
import RevisionManagementLoader from "./components/revisionManagementLoader"
import { EstimateRevisionBreadCrumb } from "./utils"
import "./estimate-revision-management.scss"

const EstimateRevisionManagement: React.FC = (): JSX.Element => {
  useDocumentTitle("Estimate Revision Management | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId } = useParams()
  const { project } = useSelector(projectSelector)
  const { showManageContractorsPopUp, selectedRevision, selectedUpdateRevision } =
    useSelector(estmteRvsnMgmntSelector)

  useEffect(() => {
    dispatch(reset())
    dispatch(getEstimateRevisionsAction({ projectId }))
    if (!project) dispatch(getProjectByIdAction(projectId))
  }, [])

  useEffect(() => {
    const { Id, ParentProjId } = selectedRevision || {}
    if (Id && ParentProjId) dispatch(getEstimateContractorsAction({ Id, ParentProjId }))
  }, [selectedRevision])

  return (
    <div className="estimateRevisionMgmntMain">
      <RevisionManagementLoader />
      <BreadCrumb data={EstimateRevisionBreadCrumb(t, project)} />
      {showManageContractorsPopUp && <ManageContractorsPopUp />}
      {selectedUpdateRevision && <EditRevisionPopUp />}
      <RevisionManagementTable />
    </div>
  )
}

export default EstimateRevisionManagement
