import React, { useEffect } from "react"
import Popup from "devextreme-react/popup"
import { useAppDispatch } from "redux/store"
import { IBOQItem } from "redux/projects/types"
import { getItemDetailsAction } from "redux/projects/middleware"
import { clearSelectedBOQItemForUpdate } from "redux/projects/projectSlice"
import UpdateItemTable from "./updateItemTable"

interface IItemUpdate {
  showEditRecordPopup: boolean
  setShowEditRecordPopup: (setVisible: boolean) => void
  rowData?: IBOQItem
}

const UpdateItemPopup: React.FC<IItemUpdate> = ({
  showEditRecordPopup,
  setShowEditRecordPopup,
  rowData,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (rowData?.PROJECT_ITEM_ID && showEditRecordPopup) {
      dispatch(getItemDetailsAction(rowData?.PROJECT_ITEM_ID))
    }
  }, [rowData, showEditRecordPopup])

  const onClose = () => {
    dispatch(clearSelectedBOQItemForUpdate())
    setShowEditRecordPopup(!showEditRecordPopup)
  }

  return (
    <Popup
      title="Update Item"
      visible={showEditRecordPopup}
      hideOnOutsideClick
      onHiding={onClose}
      showCloseButton
      enableBodyScroll
      maxHeight={400}
      dragEnabled={false}
    >
      <UpdateItemTable popUpClose={onClose} rowData={rowData} />
    </Popup>
  )
}

export default UpdateItemPopup
