import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import { v4 as uuidv4 } from "uuid"
import notify from "devextreme/ui/notify"
import { DataChange } from "devextreme/common/grids"
import CustomStore from "devextreme/data/custom_store"
import { Button, Popup, ScrollView } from "devextreme-react"
import DataGrid, {
  Item,
  Column,
  Editing,
  Toolbar,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import { useAppDispatch } from "redux/store"
import { profileSelector } from "redux/profile/profileSlice"
import { iftSelector, scopePopUpsInit } from "redux/ift/iftSlice"
import { ProvisionalSumItem, iftScopeItem } from "redux/ift/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import {
  addItemAction,
  fetchItemIFTScopeAction,
  fetchRevisionIFTScopeAction,
} from "redux/ift/middleware"
import { allRoles, handleResetFilter, identifyPermissions } from "utils/common-utils"
import {
  currencyConverter,
  handleCodeSort,
  onProvisionalSumCellPrepared,
} from "../ScopeTable/scopeTableUtils"
import {
  ItemModalProps,
  itemQtyValidationRules,
  setItemModalSaveToolbar,
  setItemModalDeleteToolbar,
  setItemModalAddBtnToolbar,
} from "./itemAddTableUtils"

const ItemModal: React.FC<ItemModalProps> = ({ visible, onHide, rowData }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { userRole } = useSelector(profileSelector)
  const { estimateId, itemGroups, items } = useSelector(iftSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const itemsDataGridRef = useRef<DataGrid>(null)
  const itemGroupDataGridRef = useRef<DataGrid>(null)
  const updatedData = useRef<ProvisionalSumItem[]>([])

  const [tableData, setTableData] = useState<ProvisionalSumItem[]>([])

  useEffect(() => {
    setItemModalSaveToolbar(dataGridRef, true)
    setItemModalDeleteToolbar(dataGridRef, true)
    setItemModalAddBtnToolbar(itemsDataGridRef, true)
    setTableData([])
    updatedData.current = []
    dispatch(scopePopUpsInit())
    // dataGridRef.current?.instance?.addRow()
  }, [visible])

  const itemGroupStore = useMemo(
    () =>
      new CustomStore({
        key: "ITEM_GROUP_ID",
        load: () => itemGroups,
      }),
    [itemGroups]
  )

  const itemsStore = useMemo(
    () =>
      new CustomStore({
        key: "ITEM_LIBRARY_ID",
        load: () => items,
      }),
    [items]
  )

  const store = useMemo(
    () =>
      new CustomStore({
        key: "ROW_ID",
        load: () => tableData,
        insert: (values: ProvisionalSumItem) => {
          setTableData([{ ...values }, ...tableData])
          return Promise.resolve(values)
        },
        remove: (rowIds: string[]) => {
          const tableItems = [...tableData]
          const filteredData = tableItems.filter(
            (item: ProvisionalSumItem) => !rowIds.includes(item.ROW_ID || "")
          )
          updatedData.current = filteredData
          setTableData(filteredData)
          return Promise.resolve()
        },
        update: (key: any, value: ProvisionalSumItem) => {
          const found = tableData.find((item) => item.ROW_ID === key)
          setTableData((prev) => {
            const final = [...prev, { ...found, ...value }].reduce(
              (acc: ProvisionalSumItem[], curr: any) => {
                const data = acc.filter(
                  (item) => item.PROJECT_ITEMAGGR_CODE !== curr.PROJECT_ITEMAGGR_CODE
                )
                return [...data, curr]
              },
              []
            )
            updatedData.current = final
            return final
          })
          return Promise.resolve()
        },
      }),
    [tableData]
  )

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const handleAddItemGridSelChange = () => {
    const selectedRows = itemsDataGridRef.current?.instance?.getSelectedRowsData()
    setItemModalAddBtnToolbar(itemsDataGridRef, !selectedRows?.length)
  }

  const handleRowSelection = (e: any) => {
    const selectedRowIds = e.selectedRowKeys as string[]
    setItemModalDeleteToolbar(dataGridRef, !selectedRowIds.length)
  }

  const onSavingTableData = (changes: DataChange<any, any>[]) => {
    for (const change of changes) {
      if (change.type === "insert") {
        store.insert(change.data)
        updatedData.current = [...updatedData.current, change.data]
        setTableData([...updatedData.current, change.data])
      }
    }
  }

  const handleDeleteClick = () => {
    dataGridRef?.current?.instance.cancelEditData()
    const selectedRows = dataGridRef.current?.instance.getSelectedRowsData()
    const selectedRowIds = selectedRows?.map((row) => row.ROW_ID) || []
    if (selectedRowIds.length) {
      store.remove(selectedRowIds)
      dataGridRef.current?.instance.option("toolbar.items[0].disabled", !selectedRows?.length)
    }
    setItemModalSaveToolbar(dataGridRef, !updatedData.current.length)
  }

  // eslint-disable-next-line consistent-return
  const handleSaveClick = () => {
    const zeroCheck = updatedData.current.every((item) => !item.PROJECT_ITEM_QTY)
    if (zeroCheck) return notify(t("scopeItem.qty0Check"), "error", 3000)

    dataGridRef.current?.instance.saveEditData()?.then(() => {
      const dataForSave = updatedData.current
      dispatch(addItemAction(dataForSave)).then(() => {
        if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
        onHide?.()
        setTableData([])
        itemGroupDataGridRef.current?.instance.deselectAll()
      })
    })
  }

  const handleButtonClick = () => {
    const selectedRows = itemsDataGridRef.current?.instance?.getSelectedRowsData()

    if (selectedRows) {
      const recToInsert: ProvisionalSumItem[] = selectedRows?.map((k: iftScopeItem) => ({
        // PROJECT_ITEM_CARB_A1_A3_FACTOR:null,
        // PROJECT_ITEM_CARB_A4_FACTOR:null,
        // PROJECT_ITEM_CARB_A5A_FACTOR:null,
        // PROJECT_ITEM_CARB_A5W_FACTOR:null,
        // PROJECT_ITEM_CARB_WASTE_FACTOR:null,
        ITEM_LIBRARY_ID: k.ITEM_LIBRARY_ID,
        PROJECT_ITEM_COST: k.ITEM_LIBRARY_COST,
        PROJECT_ITEM_QTY: k.ITEM_LIBRARY_QTY,
        PROJECT_ITEMAGGR_CODE: k.ITEM_LIBAGGR_CODE,
        PROJECT_ITEMAGGR_DESC: k.ITEM_LIBAGGR_DESC,
        RATE: k.ITEM_LIBRARY_RATE,
        UNIT_DESCRIPTION: k.UNIT_DESC,
        PROJECT_SECTION_ID: rowData.PROJECT_SECTION_ID,
        ROW_ID: uuidv4(),
        PARENT_PROJECT_ITEM_ID: rowData.PROJECT_ITEM_ID,
        PROJECT_ITEM_COMPOSITETYPE: "NORMAL_ITEM",
        PROJECT_ITEM_ISPROVSUM: 0,
      }))

      const allRec = [...tableData, ...recToInsert]
      setTableData(allRec)

      updatedData.current = allRec
      setItemModalSaveToolbar(dataGridRef, false)
    }

    itemsDataGridRef.current?.instance?.clearSelection()
  }

  const onItemGroupSelectionChanged = useCallback(
    (e: any) => {
      const selRowId = e.currentSelectedRowKeys?.[0]
      if (selRowId) {
        itemsDataGridRef.current?.instance?.beginCustomLoading("")
        dispatch(fetchItemIFTScopeAction(selRowId)).finally(() =>
          itemsDataGridRef.current?.instance?.endCustomLoading()
        )
      }
    },
    [itemsDataGridRef]
  )

  const setQTYCellValue = (newData: any, value: any, currentRowData: any) => {
    newData.PROJECT_ITEM_QTY = value
    newData.PROJECT_ITEM_COST = currentRowData.RATE * value
  }

  return (
    <Popup
      visible={visible}
      defaultVisible={false}
      onHiding={() => {
        onHide?.()
        itemGroupDataGridRef.current?.instance.deselectAll()
      }}
      hideOnOutsideClick={false}
      dragEnabled={false}
      enableBodyScroll
      showCloseButton
      showTitle
      title={t("scopeItem.addItem")}
      container=".dx-viewport"
      width="90%"
      height="90%"
    >
      <ScrollView className="item-modal-pop-up" direction="both">
        <div className="ift-scope-item-add-wrapper">
          <div className="ift-scope-item-group-grid">
            <DataGrid
              dataSource={itemGroupStore}
              ref={itemGroupDataGridRef}
              highlightChanges
              showBorders
              showColumnLines
              showRowLines
              hoverStateEnabled
              selection={{ mode: "single" }}
              onSelectionChanged={onItemGroupSelectionChanged}
            >
              <FilterRow visible />
              <Scrolling mode="virtual" />
              <Toolbar visible>
                <Item location="after">
                  <span title={t("toolbarActions.resetAllFilters")}>
                    <Button
                      icon="refresh"
                      stylingMode="text"
                      onClick={() => handleResetFilter(itemGroupDataGridRef)}
                    />
                  </span>
                </Item>
              </Toolbar>
              <Column
                dataField="ITEM_GROUP_DESC"
                caption={t("scopeItem.itemGroups")}
                alignment="center"
              />
            </DataGrid>
          </div>
          <div className="ift-scope-items-selected-items-grid">
            <div className="ift-scope-items-grid">
              <DataGrid
                dataSource={itemsStore}
                ref={itemsDataGridRef}
                highlightChanges
                showBorders
                showColumnLines
                showRowLines
                columnAutoWidth
                allowColumnReordering
                allowColumnResizing
                wordWrapEnabled
                selection={{ mode: "multiple" }}
                onSelectionChanged={handleAddItemGridSelChange}
                paging={{ enabled: false }}
              >
                <FilterRow visible />
                <Toolbar visible>
                  <Item location="after">
                    <span title={t("toolbarActions.resetAllFilters")}>
                      <Button
                        icon="refresh"
                        stylingMode="text"
                        onClick={() => handleResetFilter(itemsDataGridRef)}
                      />
                    </span>
                  </Item>
                  <Item location="after">
                    <Button
                      stylingMode="contained"
                      text={t("scopeItem.addSelectedItem")}
                      className="roundBorderGreenBtn"
                      onClick={handleButtonClick}
                    />
                  </Item>
                </Toolbar>
                <Column
                  dataField="ITEM_LIBAGGR_CODE"
                  caption={t("scopeItem.code")}
                  alignment="center"
                  sortingMethod={handleCodeSort}
                  width={108}
                />
                <Column
                  dataField="ITEM_LIBAGGR_DESC"
                  caption={t("scopeItem.description")}
                  alignment="center"
                />
                <Column
                  dataField="UNIT_DESC"
                  caption={t("scopeItem.unit")}
                  alignment="center"
                  width={80}
                />
                <Column
                  dataField="ITEM_LIBRARY_QTY"
                  caption={t("scopeItem.qty")}
                  alignment="center"
                  width={88}
                />
                <Column
                  dataField="ITEM_LIBRARY_RATE"
                  caption={t("scopeItem.rate")}
                  alignment="center"
                  cellRender={(val) => (permsnCheck ? currencyConverter(val.value) : null)}
                  width={128}
                />
                <Column
                  dataField="ITEM_LIBRARY_COST"
                  caption={t("scopeItem.cost")}
                  alignment="center"
                  cellRender={(val) => (permsnCheck ? currencyConverter(val.value) : null)}
                  width={132}
                />
              </DataGrid>
            </div>
            <div className="ift-scope-items-grid">
              <DataGrid
                dataSource={store}
                ref={dataGridRef}
                highlightChanges
                allowColumnResizing
                showBorders
                showColumnLines
                showRowLines
                columnAutoWidth
                wordWrapEnabled
                selection={{ mode: "multiple" }}
                onSelectionChanged={handleRowSelection}
                onCellPrepared={onProvisionalSumCellPrepared}
                onSaving={(e) => onSavingTableData(e.changes)}
              >
                <Editing mode="batch" allowAdding={false} allowUpdating refreshMode="full" />
                <Toolbar visible>
                  <Item name="revertButton" location="before">
                    <span title={t("toolbarActions.delete")}>
                      <Button icon="trash" stylingMode="text" onClick={handleDeleteClick} />
                    </span>
                  </Item>
                  <Item location="center">{t("scopeItem.selectedItem")}</Item>
                  <Item location="after">
                    <span title={t("toolbarActions.resetAllFilters")}>
                      <Button
                        icon="refresh"
                        stylingMode="text"
                        onClick={() => handleResetFilter(dataGridRef)}
                      />
                    </span>
                  </Item>
                  <Item name="saveButton" location="after">
                    <span title={t("toolbarActions.save")}>
                      <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
                    </span>
                  </Item>
                </Toolbar>
                <Column
                  dataField="PROJECT_ITEMAGGR_CODE"
                  caption={t("scopeItem.code")}
                  alignment="center"
                  allowEditing={false}
                  width={108}
                />
                <Column
                  dataField="PROJECT_ITEMAGGR_DESC"
                  caption={t("scopeItem.description")}
                  alignment="center"
                  allowEditing={false}
                />
                <Column
                  dataField="UNIT_DESCRIPTION"
                  caption={t("scopeItem.unit")}
                  alignment="center"
                  allowEditing={false}
                  width={80}
                />
                <Column
                  dataField="PROJECT_ITEM_QTY"
                  caption={t("scopeItem.qty")}
                  alignment="center"
                  dataType="number"
                  validationRules={itemQtyValidationRules}
                  setCellValue={setQTYCellValue}
                  width={88}
                />
                <Column
                  dataField="RATE"
                  caption={t("scopeItem.rate")}
                  alignment="center"
                  dataType="number"
                  allowEditing={false}
                  cellRender={(row) =>
                    permsnCheck ? currencyConverter(get(row, "data.RATE")) : null
                  }
                  width={128}
                />
                <Column
                  dataField="PROJECT_ITEM_COST"
                  caption={t("scopeItem.cost")}
                  alignment="center"
                  dataType="number"
                  allowEditing={false}
                  cellRender={(row) =>
                    permsnCheck ? currencyConverter(get(row, "data.PROJECT_ITEM_COST")) : null
                  }
                  width={132}
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </ScrollView>
    </Popup>
  )
}

export default ItemModal
