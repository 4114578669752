import { memo } from "react"

const CellGenerator = (props: {
  data: { label: string; value: string | number | undefined }[]
}) => {
  const { data } = props
  return (
    <div className="info-group">
      {data.map(({ label, value }) => (
        <div className="info-cell" key={label}>
          <label>{label}:</label>
          <div>{value}</div>
        </div>
      ))}
    </div>
  )
}

export default memo(CellGenerator)
