import React, { useEffect, useRef, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import TreeView from "devextreme-react/tree-view"
import * as events from "devextreme/events"
import { navigation } from "navigators/app-navigation"
import { useNavigation } from "contexts/navigation"
import { profileSelector } from "redux/profile/profileSlice"
import type { SideNavigationMenuProps } from "types/AppNav"
import { identifyPermissions } from "utils/common-utils"
import { filterEmptyItems } from "./utils"
import "./side-nav-menu.scss"

export default function SideNavigationMenu(
  props: React.PropsWithChildren<SideNavigationMenuProps>
) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props

  const { t } = useTranslation()
  const { userRole } = useSelector(profileSelector)

  const {
    navigationData: { currentPath },
  } = useNavigation()

  const items = useMemo(() => {
    if (userRole) {
      const recursiveItems = (values: any[]): any[] =>
        values
          ?.map((it: any) =>
            identifyPermissions(userRole, it.accessType, it.permissionLevel, it.roles)
              ? {
                  ...it,
                  text: t(it.text),
                  expanded: false,
                  path: it.path && !/^\//.test(it.path) ? `/${it.path}` : it.path,
                  items: recursiveItems(it.items),
                }
              : null
          )
          .filter(Boolean)

      const finalItems = filterEmptyItems(recursiveItems(navigation))
      return finalItems.filter((item: any) => item !== null)
    }
    return []
  }, [userRole])

  const treeViewRef = useRef<TreeView>(null)
  const wrapperRef = useRef<HTMLDivElement>()
  const getWrapperRef = useCallback(
    (element: HTMLDivElement) => {
      const prevElement = wrapperRef.current
      if (prevElement) {
        events.off(prevElement, "dxclick")
      }

      wrapperRef.current = element
      events.on(element, "dxclick", (e: React.PointerEvent) => {
        openMenu(e)
      })
    },
    [openMenu]
  )

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance
    if (!treeView) {
      return
    }

    if (currentPath === "/home") {
      treeView.unselectAll()
      treeView.collapseAll()
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath)
      treeView.expandItem(currentPath)
    }

    if (compactMode) {
      treeView.collapseAll()
    }
  }, [currentPath, compactMode])

  return (
    <div className="dx-swatch-additional side-navigation-menu" ref={getWrapperRef}>
      {children}
      <div className="menu-container">
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr="path"
          selectionMode="single"
          focusStateEnabled={false}
          expandEvent="click"
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width="100%"
          noDataText=""
          searchEnabled
        />
      </div>
    </div>
  )
}
