import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { CarbonCodesCategoryItem } from "redux/masters/types"
import { ModeOfTransportItem, ModeOfTransportState, UpdateModeOfTransportRequest } from "./types"
import { fetchAllModeOfTransportationAction, updateTranportValueAction } from "./middleware"

const INITIAL_STATE: ModeOfTransportState = {
  loading: LoadingState.DEFAULT,
  modeOfTransport: [],
  carbonCodeItem: null,
}

const modeOfTransportSlice = createSlice({
  name: "ModeOfTransport",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setCarbonCodeForModeOfTransport: (
      state: ModeOfTransportState,
      { payload }: PayloadAction<CarbonCodesCategoryItem[]>
    ) => {
      const item = payload.find(
        (item: CarbonCodesCategoryItem) =>
          item.CUSTOM_LIST_NAME?.toLowerCase() === "mode of transportation"
      )
      if (item) {
        return { ...state, carbonCodeItem: item }
      }

      return { ...state }
    },

    updateModeOfTransportData: (
      state: ModeOfTransportState,
      { payload }: PayloadAction<UpdateModeOfTransportRequest[]>
    ) => {
      const modeOfTransports = [...state.modeOfTransport]
      payload.forEach((request: UpdateModeOfTransportRequest) => {
        const idx: number = modeOfTransports.findIndex((item) => item.id === request.key)
        if (idx >= 0) {
          const updatedItem = { ...modeOfTransports[idx], ...request.data }
          modeOfTransports[idx] = updatedItem
        }
      })

      return { ...state, modeOfTransport: modeOfTransports }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllModeOfTransportationAction.fulfilled,
      (state: ModeOfTransportState, { payload }: PayloadAction<ModeOfTransportItem[]>) => ({
        ...state,
        modeOfTransport: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(
      updateTranportValueAction.fulfilled,
      (state: ModeOfTransportState, { payload }: PayloadAction<ModeOfTransportItem[]>) => ({
        ...state,
        modeOfTransport: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchAllModeOfTransportationAction.rejected, (state: ModeOfTransportState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const modeOfTransportSelector = (state: RootState) => state.ModeOfTransport
export const { setLoading, setCarbonCodeForModeOfTransport, updateModeOfTransportData } =
  modeOfTransportSlice.actions
export default modeOfTransportSlice.reducer
