/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { useIdleTimer } from "react-idle-timer"
import notify from "devextreme/ui/notify"
import { fetchUserRoleAndPermissionsAction } from "redux/profile/middleware"
import { setWindowHeight } from "redux/shared/sharedSlice"
import { logout } from "redux/auth/authSlice"
import SideNavBarLayout from "layouts/side-nav-outer-toolbar"
import { config } from "utils/config"
import routes from "./app-routes"

export default function AppNavigator() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  window.addEventListener("resize", () => {
    dispatch(setWindowHeight(window.innerHeight))
  })

  useEffect(() => {
    dispatch(fetchUserRoleAndPermissionsAction())
    setTimeout(() => {
      dispatch(setWindowHeight(window.innerHeight))
    }, 2000)
  }, [])

  const onIdle = () => {
    dispatch(logout())
    localStorage.clear()
    notify(t("sessionLogoutMsg"), "success", 10000)
  }

  const _ = useIdleTimer({
    onIdle,
    timeout: config.sessionExpiryTime,
    throttle: 500,
  })

  return (
    <SideNavBarLayout title={t("appTitle")}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </SideNavBarLayout>
  )
}
