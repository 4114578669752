export enum ItemLevel {
  SECTION = 0,
  ITEM = 1,
  RESOURCE = 2,
}

// eslint-disable-next-line import/prefer-default-export
export const getLevel = (item: any) => {
  if (item.LEVEL === 0) {
    return ItemLevel.SECTION
  }
  if (item.LEVEL === 1) {
    return ItemLevel.ITEM
  }
  return ItemLevel.RESOURCE
}
