/* eslint-disable import/prefer-default-export */
import axiosInstance from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"

export const powerBILoginAsync = async (id: string) => {
  try {
    if (!id) console.log(id)

    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(`/powerbi-ccft`)

    return response.data
  } catch (error) {
    console.error("Error:", error)
    return error
  }
}
