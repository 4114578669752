import { memo } from "react"
import { useSelector } from "react-redux"
import { LoadPanel } from "devextreme-react"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"

const RevisionManagementLoader = () => {
  const { loading } = useSelector(estmteRvsnMgmntSelector)

  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loading === "LOADING"}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(RevisionManagementLoader)
