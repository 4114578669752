import { memo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Switch } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import {
  costEstimateSummarySelector,
  setInflation,
  setPortfolioRisk,
} from "redux/cost-estimate-summary/costEstimateSummarySlice"

const ToggleHeader = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { showPortfolioRisk, showInflation } = useSelector(costEstimateSummarySelector)

  return (
    <div className="toggle-head">
      <span>
        <Switch
          value={showPortfolioRisk}
          onValueChanged={(e) => dispatch(setPortfolioRisk(e.value))}
        />
        <label>{t("costEstimateSummary.portfolioRisk")}</label>
      </span>
      <span>
        <Switch value={showInflation} onValueChanged={(e) => dispatch(setInflation(e.value))} />
        <label>{t("costEstimateSummary.inflation")}</label>
      </span>
    </div>
  )
}

export default memo(ToggleHeader)
