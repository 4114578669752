import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Switch from "devextreme-react/switch"
import IFTInflation from "./Inflation"
import IFTSpend from "./Spend"

const InflationAndSpend: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const [isSwitchOn, setSwitchOn] = useState(false)

  const onSwitchStatusChange = useCallback((e: any) => setSwitchOn(e.value), [])

  return (
    <div className="m-3">
      <div className="d-flex m-2">
        <label className={`m-2 mt-0 fw-bold ${!isSwitchOn ? "active-label" : ""}`}>
          {t("ift.inflationSwitchTxt")}
        </label>
        <Switch onValueChanged={onSwitchStatusChange} />
        <label className={`m-2 mt-0 fw-bold ${isSwitchOn ? "active-label" : ""}`}>
          {t("ift.spendSwitchTxt")}
        </label>
      </div>
      {isSwitchOn ? <IFTSpend /> : <IFTInflation />}
    </div>
  )
}

export default InflationAndSpend
