import { useRef, useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import LoadIndicator from "devextreme-react/load-indicator"
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from "devextreme-react/form"
import useDocumentTitle from "hooks/useDocumentTitle"
import eye from "assets/icons/eye.png"
import eyeSlash from "assets/icons/eye-slash.png"
import { authSelector, setLoading } from "redux/auth/authSlice"
import { loginAction } from "redux/auth/middleware"
import { LoadingState } from "types/AppNav"
import { config } from "utils/config"
import { PASSWORD_MODE, PASSWORD_TOOLTIP } from "./passwordConst"
import "./login-form.scss"

const LoginForm = () => {
  useDocumentTitle("Login | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { loading } = useSelector(authSelector)

  const formData = useRef({ email: "", password: "" })

  const [passwordMode, setPasswordMode] = useState("password")

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    dispatch(setLoading(LoadingState.LOADING))
    dispatch(
      loginAction({
        connectionName: config.databaseConnection,
        username: formData.current.email,
        password: formData.current.password,
      })
    )
  }, [])

  useEffect(() => {
    if (loading === LoadingState.SUCCESS) {
      navigate("/", { replace: true })
      dispatch(setLoading(LoadingState.DEFAULT))
    }
  }, [loading])

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <i className="far fa-eye" id="togglePassword" />
      <Form formData={formData.current} disabled={loading === LoadingState.LOADING}>
        <Item
          dataField="email"
          editorType="dxTextBox"
          editorOptions={{
            stylingMode: "filled",
            placeholder: t("enterUserName"),
            mode: "text",
            type: "text",
          }}
        >
          <RequiredRule message={t("emptyUserName")} />
          <Label visible={false} />
        </Item>
        <Item
          dataField="password"
          editorType="dxTextBox"
          cssClass="test"
          editorOptions={{
            stylingMode: "filled",
            placeholder: t("enterPassword"),
            mode: passwordMode,
            buttons: [
              {
                name: "password",
                location: "after",
                options: {
                  icon: passwordMode === PASSWORD_MODE.PASSWORD ? eye : eyeSlash,
                  type: "default",
                  onClick: () =>
                    passwordMode === PASSWORD_MODE.PASSWORD
                      ? setPasswordMode(PASSWORD_MODE.TEXT)
                      : setPasswordMode(PASSWORD_MODE.PASSWORD),
                  hint:
                    passwordMode === PASSWORD_MODE.PASSWORD
                      ? PASSWORD_TOOLTIP.TEXT
                      : PASSWORD_TOOLTIP.PASSWORD,
                  stylingMode: "text",
                },
              },
            ],
          }}
        >
          <RequiredRule message={t("emptyPassword")} />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width="100%" type="default" useSubmitBehavior>
            <span className="dx-button-text">
              {loading === LoadingState.LOADING ? (
                <LoadIndicator width="24px" height="24px" visible />
              ) : (
                "Log In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className="link">
            <Link to="/reset-password">{t("forgotPassword")}</Link>
          </div>
        </Item>
      </Form>
    </form>
  )
}

export default LoginForm
