import { createAsyncThunk } from "@reduxjs/toolkit"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { fetchCodeItemsByCategoryAsync } from "redux/masters/services"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { v4 as uuidv4 } from "uuid"
import { LoadingState } from "types/AppNav"
import notify from "devextreme/ui/notify"
import { setLoading } from "redux/auth/authSlice"
import { CarbonCodesCategoryItem, CodeItems } from "redux/masters/types"
import { extractResponseData } from "utils/common-utils"
import { RootState } from "redux/store"
import isNaN from "lodash/isNaN"
import {
  createCarbonValueForModeOfTranportAsync,
  fetchModeOfTransportationValuesAsync,
} from "./services"
import {
  CarbonModeOfTransportItem,
  ModeOfTransportItem,
  UpdateModeOfTransportRequest,
} from "./types"

export const fetchAllModeOfTransportationAction = createAsyncThunk<
  ModeOfTransportItem[],
  CarbonCodesCategoryItem
>(
  "fuelType/fetchAllFuelTypeActions",
  async (carbonCodeCategory: CarbonCodesCategoryItem, { rejectWithValue }) => {
    const data: ModeOfTransportItem[] = []
    try {
      // First fetch all fuel types codes....
      const codesResponse: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(carbonCodeCategory?.CUSTOM_LIST_NAME || "")
      const codesErrorRes = codesResponse as ErrorResponse
      if (codesErrorRes.code) {
        return rejectWithValue(codesErrorRes.message)
      }
      const modeOfTransportCodes: CodeItems[] = extractResponseData(
        codesResponse as BenchmarkDataContainer[]
      )

      if (modeOfTransportCodes.length > 0) {
        modeOfTransportCodes.forEach((code: CodeItems) => {
          data.push({
            id: uuidv4(),
            code,
          })
        })
      }

      // fetch existing data for the fuel type entries.
      const ModeOfTransportWithValuesResponse:
        | SuccessResponse<CarbonModeOfTransportItem[]>
        | ErrorResponse = await fetchModeOfTransportationValuesAsync(
        Number(carbonCodeCategory.CUSTOM_LIST_ID)
      )
      const errRes = ModeOfTransportWithValuesResponse as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes)
      }

      const carbonResponseItem: CarbonModeOfTransportItem[] =
        (ModeOfTransportWithValuesResponse as SuccessResponse<CarbonModeOfTransportItem[]>).data ||
        []

      const finalTransportItems: ModeOfTransportItem[] = data.map((item: ModeOfTransportItem) => {
        const transportFound = carbonResponseItem.find(
          (carbonVal: CarbonModeOfTransportItem) =>
            Number(item.code?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
        )

        if (transportFound) {
          return {
            ...item,
            id: transportFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
            value: transportFound.CARBON_VALUE,
          }
        }
        return item
      })

      return finalTransportItems
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateTranportValueAction = createAsyncThunk<
  ModeOfTransportItem[],
  UpdateModeOfTransportRequest[]
>(
  "updateTranportValueAction",
  async (request: UpdateModeOfTransportRequest[], { dispatch, rejectWithValue, getState }) => {
    try {
      const modeOfTransportState = (getState() as RootState).ModeOfTransport || []

      const requestFuelItems: ModeOfTransportItem[] =
        request
          .map((item: UpdateModeOfTransportRequest) => {
            const transportObj = modeOfTransportState.modeOfTransport.find(
              (tranportItem: ModeOfTransportItem) => tranportItem.id === item.key
            )
            if (transportObj) {
              item.data = { ...transportObj, value: item.data.value }
              return item
            }
            return item
          })
          .map((item: UpdateModeOfTransportRequest) => item.data) || []

      const finalRequest: CarbonModeOfTransportItem[] = requestFuelItems.map(
        (item: ModeOfTransportItem) => {
          const carbonTransportItem: CarbonModeOfTransportItem = {
            CUSTOM_LIST_ID: modeOfTransportState.carbonCodeItem?.CUSTOM_LIST_ID || 0,
            CUSTOM_LIST_CONTENT_ID: Number(item.code?.Id || 0),
            CARBON_VALUE: Number(item.value),
          }

          if (!isNaN(Number(item.id))) {
            carbonTransportItem.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID = Number(item.id)
          }

          return carbonTransportItem
        }
      )

      dispatch(setLoading(LoadingState.LOADING))
      const response: SuccessResponse<CarbonModeOfTransportItem[]> | ErrorResponse =
        await createCarbonValueForModeOfTranportAsync(finalRequest)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      // once api success.
      if ((response as SuccessResponse<CarbonModeOfTransportItem[]>).statusCode === 200) {
        const fuelItemsWithValuesResponse:
          | SuccessResponse<CarbonModeOfTransportItem[]>
          | ErrorResponse = await fetchModeOfTransportationValuesAsync(
          Number(modeOfTransportState.carbonCodeItem?.CUSTOM_LIST_ID)
        )
        const errRes = fuelItemsWithValuesResponse as ErrorResponse
        if (errRes.code) {
          return rejectWithValue(errRes)
        }

        const carbonFuelItems: CarbonModeOfTransportItem[] =
          (fuelItemsWithValuesResponse as SuccessResponse<CarbonModeOfTransportItem[]>).data || []

        const finalFuelItems: ModeOfTransportItem[] = modeOfTransportState.modeOfTransport.map(
          (item: ModeOfTransportItem) => {
            const fuelItemFound = carbonFuelItems.find(
              (carbonVal: CarbonModeOfTransportItem) =>
                Number(item.code?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
            )

            if (fuelItemFound) {
              return {
                ...item,
                id: fuelItemFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
                value: fuelItemFound.CARBON_VALUE,
              }
            }
            return item
          }
        )

        notify("Carbon factors updated successfully.", "success", 2000)

        return finalFuelItems
      }

      return []
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
