import { LoadingState } from "types/AppNav"

export interface ProjectState {
  editedIds: any[]
  loading: LoadingState
  project?: IProject
  section?: ISection
  parentProjectId?: number
  isEditable?: boolean
  estimatorID?: number
  projects: Array<IProject>
  sections: Array<ISection>
  boqItems: Array<IBOQItem>
  sectionColumnWiseTotal: ISectionColumnWiseTotal
  boqItemsColumnWiseTotal: IBOQItemColumnWiseTotal
  topFiveCarbonSections: Array<ISection>
  topFiveCarbonBOQItems: Array<IBOQItem>
  selectedBOQItemForUpdate: IItem | undefined
  selectedBOQResourceForUpdate: IResource | undefined
}

export interface IProject {
  Id?: number
  QuoteNo?: string | number
  OriginalQuoteNo?: number
  Title?: string
  Date?: string
  Region?: string
  Completed?: boolean
  Authorized?: boolean
  ParentProjAuthorised?: boolean
  JobNo?: boolean
  estimateStage?: string
  estimator?: string
  HashCode?: string
  TimeStamp?: string
  State?: string
  Location?: string
  PostCode?: number
  SchemaDesc?: string
  ShowIFT?: boolean
}
export interface IHiddenColumn {
  Caption?: string
  VisibleIndex?: number
}
export interface ISection {
  Identity?: number
  ProjectId?: number
  Completed?: boolean
  CompletedCaption?: string
  PROJECT_SECTION_CARB_A1_A3?: number
  PROJECT_SECTION_CARB_A4?: number
  PROJECT_SECTION_CARB_A5A?: number
  PROJECT_SECTION_CARB_A5W?: number
  PROJECT_SECTION_CARB_TOTAL_A1_A5?: number
  PROJECT_SECTION_COST?: number
  PROJECT_SECTION_DESC?: string
  PROJECT_SECTION_ID?: number
  PROJECT_SECTION_NUMBER?: string
}

export interface ISectionColumnWiseTotal {
  sectionTotalCost: number
  sectionTotalCarbonCost: number
  A1A3Total: number
  A4Total: number
  A5aTotal: number
  A5wTotal: number
}

export interface IBOQItemColumnWiseTotal {
  BOQItemTotalCost: number
  BOQItemTotalCarbonCost: number
  A1A3Total: number
  A4Total: number
  A5aTotal: number
  A5wTotal: number
}

export interface ICarbonEmissionDoughnutChartData {
  region?: string
  val?: number
}
export interface ICarbonEmissionDoughnutChartProps {
  showPopUp?: (props: any) => void
  isPieChartPopUpVisible?: boolean
  data: ICarbonEmissionDoughnutChartData[]
}

export interface IBOQItem {
  ID: number
  CARB_A1_A3: number
  CARB_A4: number
  CARB_A5A: number
  CARB_A5W: number
  CARB_TOTAL_A1_A5: number
  CODE: null
  COST: number
  DESCRIPTION: string
  PROJECT_ITEMAGGR_HIERARCHY: Array<number> | string
  PROJECT_ITEMAGGR_ORDER: number
  PROJECT_ITEM_COMPOSITETYPE: string
  PROJECT_ITEM_ID: number
  PROJECT_ITEM_PARENT_ID: null
  QUANTITY: number
  RATE: number
  UNIT_DESCRIPTION: string
  PROJECT_RESOURCE_ID?: number
  LEVEL?: number
}

export type EstimateLevelAPIData = {
  aggregatedOn?: string
  projectId?: number | string | undefined
  sectionId?: number | string | undefined
}

export type RecalculateThunkRequest = {
  projectId: number | string | undefined
  sectionId?: number | string | undefined
}

export type EstimateLevelResponse = {
  PROJECT_ITEMAGGR_DESC?: string
  PROJECT_ITEMAGGR_HIERARCHY?: string
  PROJECT_ITEMAGGR_ORDER?: number
  PROJECT_ITEM_CARB_A1_A3?: number
  PROJECT_ITEM_CARB_A4?: number
  PROJECT_ITEM_CARB_A5A?: number
  PROJECT_ITEM_CARB_A5W?: number
  PROJECT_ITEM_CARB_TOTAL_A1_A5?: number
  PROJECT_ITEM_COST?: number
  PROJECT_ITEM_ID?: number
  PROJECT_RESAGGR_DESC?: string
  PROJECT_RESOURCE_CARB_A1_A3?: number
  PROJECT_RESOURCE_CARB_A4?: number
  PROJECT_RESOURCE_CARB_A5A?: number
  PROJECT_RESOURCE_CARB_A5W?: number
  PROJECT_RESOURCE_CARB_TOTAL_A1_A5?: number
  PROJECT_RESOURCE_CODE?: string
  PROJECT_RESOURCE_COST?: number
  PROJECT_RESOURCE_ID?: number
  PROJECT_SECTION_CARB_A1_A3: number
  PROJECT_SECTION_CARB_A4: number
  PROJECT_SECTION_CARB_A5A: number
  PROJECT_SECTION_CARB_A5W: number
  PROJECT_SECTION_CARB_TOTAL_A1_A5: number
  PROJECT_SECTION_COST: number
  PROJECT_SECTION_DESC: string
  PROJECT_SECTION_ID: number
  PROJECT_SECTION_NUMBER: string
}

export enum ResourceType {
  NormalItem,
  CompositeItem,
  CompositeTotal,
  ResourceItem,
  SubItem,
}

export interface IResource {
  PROJECT_RESOURCE_ID?: number
  PROJECT_RESOURCE_QTY?: number
  PROJECT_RESOURCE_CARB_A1_A3_FACTOR?: number
  PROJECT_RESOURCE_CARB_A4_FACTOR?: number
  PROJECT_RESOURCE_CARB_A5A_FACTOR?: number
  PROJECT_RESOURCE_WASTEFACTOR?: number
}
export interface IItem {
  PROJECT_ITEM_ID: number
  PROJECT_ITEM_QTY: number | undefined
  PROJECT_ITEM_COST: number
  PROJECT_ITEM_CARB_A1_A3_FACTOR: number
  PROJECT_ITEM_CARB_A4_FACTOR: number
  PROJECT_ITEM_CARB_A5A_FACTOR: number
  PROJECT_ITEM_CARB_A5W_FACTOR: number
  PROJECT_ITEM_CARB_WASTE_FACTOR: number
}
