/* eslint-disable no-unused-vars, no-nested-ternary, security/detect-unsafe-regex, 
security/detect-non-literal-regexp, @typescript-eslint/no-unused-vars */
import { isArray } from "lodash"
import notify from "devextreme/ui/notify"
import { formatNumber } from "devextreme/localization"
import { CodeItems } from "redux/masters/types"
import { RoleType } from "redux/role-permission/types"
import { UserRole, UserRolePermission } from "redux/profile/types"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { config } from "./config"

export const allRoles = [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR, RoleType.CONTRACTOR]

export function camelToKebab(str: string) {
  return str
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2")
    .toLowerCase()
    .replace("-", "")
}

export function hasClass(el: any, className: any) {
  return el.classList
    ? el.classList.contains(className)
    : !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`))
}

export function addClass(el: any, className: any) {
  if (el.classList) el.classList.add(className)
  else if (!hasClass(el, className)) {
    el.className = `${el.className} ${className}`
  }
}

export function decimalFormatter(value: number, precision: number): string {
  if (!value) value = 0
  if (!precision) precision = 2
  return value.toFixed(precision)
}

export function setLocalStorage(key: string, value: any) {
  try {
    const jsonString = JSON.stringify(value)
    localStorage.setItem(key, jsonString)
  } catch (error) {
    notify(`An error occurred while stringifying JSON: ${error}`, "error", 2000)
  }
}

export function getLocalStorage(key: string, type?: any) {
  // Only number case checked, please check working of others
  switch (type) {
    case "number":
      return +(localStorage.getItem(key) || 0) as any
    case "string":
      return (localStorage.getItem(key) || "") as any
    case "boolean":
      return (localStorage.getItem(key) || true) as any
    case "object":
      return (localStorage.getItem(key) || {}) as any
    case "array":
      return (localStorage.getItem(key) || []) as any
    default:
      return localStorage.getItem(key) as any
  }
}

export const CSVtoArray = (text: any) => {
  const reValid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
  const reValue =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g

  // Return empty array if input string is not well formed CSV string.
  if (!reValid.test(text)) return []

  const a = [] // Initialize array to receive values.
  text.replace(
    reValue, // "<String with comma value in it>" the string using replace with callback.
    (m0: any, m1?: string, m2?: string, m3?: any) => {
      // Remove backslash from \' in single quoted values.
      if (m1 || m1 === "") a.push(m1.replace(/\\'/g, "'"))
      // Remove backslash from \" in double quoted values.
      else if (m2 || m2 === "") a.push(m2.replace(/\\"/g, '"'))
      else if (m3 || m3 === "") a.push(m3)
      return "" // Return empty string.
    }
  )
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("")
  return a
}

export function downloadCSVTemplate(data: any, templateFileName: string) {
  const csvContent = `data:text/csv;charset=utf-8,${data
    .map((row: any[]) => row.join(","))
    .join("\n")}`
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${templateFileName}.csv`)
  document.body.appendChild(link)
  link.click()
}

export const navUtil = {
  navigate: (a: any, b?: any) => {},
  location: {
    hash: "",
    key: "",
    pathname: "",
    search: "",
    state: null,
  },
}

export const myUtils = {
  dispatch: (action?: any) => {},
}

export const handleResetFilter = (dataGridRef: any) => {
  dataGridRef.current?.instance.beginUpdate()
  dataGridRef.current?.instance.clearFilter()
  dataGridRef.current?.instance.deselectAll()
  dataGridRef.current?.instance.clearSorting()
  dataGridRef.current?.instance.getVisibleColumns().forEach((column: { dataField: null }) => {
    if (column.dataField != null) {
      dataGridRef.current?.instance.columnOption(
        column.dataField,
        "selectedFilterOperation",
        undefined
      )
    }
  })
  dataGridRef.current?.instance.endUpdate()
}

export const datagridRemoveGroupLabel = (cellElement: any, cellInfo: any) => {
  const html =
    cellInfo.text +
    // check if there's "continue to" message
    (cellInfo.groupContinuesMessage
      ? ` (${cellInfo.groupContinuesMessage})`
      : // check if there's "continue from" message
      cellInfo.groupContinuedMessage
      ? ` (${cellInfo.groupContinuedMessage})`
      : "")
  cellElement.append(html)
}

export const extractResponseData = (data: Array<BenchmarkDataContainer>) => {
  const codeItems: CodeItems[] = data.map((container) => ({
    Name: container.Properties[1].Data,
    Description: container.Properties[2].Data,
    Id: container.Properties[0].Data,
  }))
  return codeItems
}

export const bytesToSize = (response: any): string => {
  const bytes = new Blob([JSON.stringify(response)]).size
  const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte"]

  const navigatorLocal =
    navigator.languages && navigator.languages.length >= 0 ? navigator.languages[0] : "en-US"
  const unitIndex = Math.max(
    0,
    Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
  )

  return Intl.NumberFormat(navigatorLocal, {
    style: "unit",
    unit: units[unitIndex],
  }).format(bytes / 1024 ** unitIndex)
}

export const handleDateStringSorting = (a: string, b: string) => {
  a = a.split("/").reverse().join("")
  b = b.split("/").reverse().join("")
  return a > b ? 1 : a < b ? -1 : 0
}

export const identifyPermissions = (
  userRole: UserRole | null,
  accessType: number,
  permissionLevel: number | number[],
  roles?: number[]
): boolean => {
  if (!userRole) return false
  // this to identify does the user has the same role which we are requesting if we passed roleType in params,
  // then only we will check for accessType else straight we will check permission.
  if (roles && !roles.includes(userRole.ROLE_ID)) {
    return false
  }

  if (accessType === -1 || permissionLevel === -1) return true

  const permission = userRole.permissions.find(
    (rolePermission: UserRolePermission) => rolePermission.ROLEACCESS_ACCESSID === accessType
  )
  if (permission) {
    if (isArray(permissionLevel))
      return permissionLevel.includes(permission.ROLEACCESS_ACCESSLEVELID)

    return permission.ROLEACCESS_ACCESSLEVELID === permissionLevel
  }

  return false
}

export const cellValueFormatter = (value: number | Date) => Number(value).toFixed(2)
export const cellFormatterConfig = {
  type: "decimal",
  precision: 2,
  formatter: cellValueFormatter,
}
export const numberFormatterConfig = {
  formatter: (value: any) => formatNumber(value, { precision: 2, type: "fixedPoint" }),
}

export const currencyFormatterConfig = {
  formatter: (value: any) =>
    formatNumber(value, {
      currency: config.clientName === "HE" ? "GBP" : "USD",
      precision: 2,
      type: "currency",
    }),
}

export const renderCurrencyCell = (val: any) => {
  return formatNumber(val, {
    currency: config.clientName === "HE" ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

// Common Types for Datagrid
export type DataGridColumnSortOrderProps = "asc" | "desc" | undefined
export type DataGridColumnAlignmentProps = "left" | "center" | "right" | undefined
export type DataGridColumnDataTypeProps =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "date"
  | "datetime"
  | undefined
export type DataGridEditingModeProps = "row" | "form" | "popup" | "batch" | "cell" | undefined

export const generateProjectItemHierarchy = (exitingHierarchy: string, itemId: string) => {
  if (!itemId) return ""

  if (!exitingHierarchy) return `[${itemId}]`

  // Remove the square brackets from the input string
  const cleanedInput = exitingHierarchy.slice(1, -1)
  // Append the stringToAppend
  return `[${cleanedInput}][${itemId}]`
}
