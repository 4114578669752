import { memo, useCallback, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import TreeList, {
  Item,
  Pager,
  Paging,
  Column,
  Toolbar,
  Editing,
  FilterRow,
  SearchPanel,
} from "devextreme-react/tree-list"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"
import CustomStore from "devextreme/data/custom_store"
import { ContextMenuPreparingEvent } from "devextreme/ui/tree_list"
import {
  deleteScopeProjectResourceAction,
  deleteScopeProjectItemAction,
  fetchRevisionIFTScopeAction,
  fetchBaselineIFTScopeAction,
  updateScopeItemsAction,
} from "redux/ift/middleware"
import { ResourceType } from "redux/projects/types"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { recalculateCarbonAction } from "redux/projects/middleware"
import {
  iftSelector,
  setIFTScopeEditItem,
  setIFTScopeEditResource,
  updateIftRevisionScopeData,
} from "redux/ift/iftSlice"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { cellFormatterConfig, handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { estimateLibrarySelector } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import {
  ModalStateType,
  handleCodeSort,
  isMultiInstance,
  qtyNullCalculate,
  carbNullCalculate,
  defaultModalState,
  dupSubItemCnfrmDialog,
  checkMultiInstanceCount,
  onScopeTableCellPrepared,
  onScopeTableContentReady,
  renderScopeTableCostCell,
  renderScopeTableRateCell,
  scopeCellDescriptionRender,
} from "./scopeTableUtils"
import ItemModal from "../ItemModal"
import SubItemModal from "../SubItemModal"
import EditItemPopup from "./EditItemPopup"
import ResourceModal from "../ResourceModal"
import ProvisionalSumModal from "../ProvisionalSumModal"
import EditResourcePopup from "./EditResourcePopup"

const ScopeTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { project } = useSelector(projectSelector)
  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)
  const { contractorEstimateLibraryContent } = useSelector(estimateLibrarySelector)
  const {
    addedIds,
    editedIds,
    projectId,
    estimateId,
    scopeDelConfTxt,
    scopeCarbonSwitch,
    scopeBaselineSwitch,
    iftBaselineScopeData,
    iftRevisionScopeData,
    iftScopeEditItem,
    iftScopeEditResource,
    iftRoleBasedPermissions,
  } = useSelector(iftSelector)

  const [showSubItemModal, setShowSubItemModal] = useState<ModalStateType>(defaultModalState)
  const [showResourceModal, setShowResourceModal] = useState<ModalStateType>({
    ...defaultModalState,
    isDuplicate: false,
  })
  const [showProvisionalModal, setShowProvisionalModal] =
    useState<ModalStateType>(defaultModalState)
  const [showItemModal, setShowItemModal] = useState<ModalStateType>(defaultModalState)

  const treeListRef = useRef<TreeList>(null)
  const baselineCheck = useMemo(
    () => !(estimateId && !scopeBaselineSwitch),
    [estimateId, scopeBaselineSwitch]
  )
  const tableHeading = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      baselineCheck
        ? estimateId
          ? `(${t("ift.baseline")}: ${project?.Title || ""}) --  v/s -- (${t("ift.revision")} ${
              selectedRevision?.Title || ""
            })`
          : `${t("ift.baseline")}: ${project?.Title || ""}`
        : `${t("ift.revision")} ${selectedRevision?.Title || ""}`,
    [baselineCheck, project, selectedRevision]
  )
  const tableData = useMemo(
    () => (baselineCheck ? iftBaselineScopeData : iftRevisionScopeData),
    [scopeBaselineSwitch, iftBaselineScopeData, iftRevisionScopeData]
  )

  const onContextMenuPreparing = useCallback(
    (e: ContextMenuPreparingEvent) => {
      const SubItem = get(e, "row.data.SUBITEM") === "Y"

      if (baselineCheck) return
      if (e.target === "content" && get(e, "row.rowType") === "data" && !e.items) {
        if (get(e, "row.data.PROJECT_ITEM_COMPOSITETYPE") === ResourceType.NormalItem) {
          e.items = [
            {
              text: "Add SubItem",
              onItemClick: () => setShowSubItemModal({ show: true, data: e.row?.data }),
              visible: !SubItem && iftRoleBasedPermissions.addResourcesOrItems,
            },
            {
              text: "Add Resource",
              onItemClick: () => {
                setShowResourceModal({
                  show: true,
                  data: e.row?.data,
                  isDuplicate: isMultiInstance(tableData, get(e, "row.data.CODE") || ""),
                })
              },
              visible: iftRoleBasedPermissions.addResourcesOrItems,
            },
          ]
        } else if (
          get(e, "row.data.PROJECT_ITEM_COMPOSITETYPE") === ResourceType.CompositeItem ||
          get(e, "row.data.PROJECT_ITEM_COMPOSITETYPE") === ResourceType.CompositeTotal
        ) {
          e.items = [
            {
              text: "Add Item",
              visible: iftRoleBasedPermissions.addResourcesOrItems,
              onItemClick: () => setShowItemModal({ show: true, data: e.row?.data }),
            },
            {
              text: "Add Provisional Item",
              onItemClick: () => setShowProvisionalModal({ show: true, data: e.row?.data }),
              visible: iftRoleBasedPermissions.addResourcesOrItems,
            },
          ]
        }
      }
    },
    [tableData]
  )

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => tableData,
        key: "id",
        update: async (a, b) => {
          const found = tableData.find((item: { id: any }) => item.id === a)
          const parent = tableData.find((item) => item.id === found?.Parent_ID)
          const SubItemCheck = get(found, "SUBITEM") === "Y"
          const newRecord = { ...found, ...b }
          const PROJECT_ITEM_COST =
            get(b, "QUANTITY") && found.RATE ? get(b, "QUANTITY") * found.RATE : get(b, "COST")
          const apiUpdateData = {
            ...found,
            PROJECT_ITEM_QTY: get(b, "QUANTITY"),
            PROJECT_ITEM_COST,
          }

          const updateFn = async () => {
            dispatch(updateIftRevisionScopeData({ id: a, newRecord }))
            await dispatch(updateScopeItemsAction([apiUpdateData])).then(() => {
              notify(t("iftScope.SubItemItemUpdateMsg"), "success", 4000)
              if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
            })
          }

          if (SubItemCheck && isMultiInstance(tableData, get(parent, "CODE"))) {
            dupSubItemCnfrmDialog(parent, checkMultiInstanceCount(tableData, get(parent, "CODE")))
              .show()
              .then((dialogResult: boolean) => dialogResult && updateFn())
          } else updateFn()

          return Promise.resolve()
        },
        remove: (id: any) => {
          const found = tableData.find((item) => item?.id === id)
          const parent = tableData.find((item) => item?.id === found?.Parent_ID)
          const SubItemCheck = get(found, "SUBITEM") === "Y"
          const resourceCheck = !!get(found, "PROJECT_RESOURCE_ID")
          const reqrdDelFn = get(found, "PROJECT_RESOURCE_ID")
            ? deleteScopeProjectResourceAction
            : deleteScopeProjectItemAction
          const apiData = { resourceId: id, projectId: estimateId }

          const delFn = () => {
            dispatch(reqrdDelFn(apiData)).then((res) => {
              if (get(res, "payload.status") === 200) {
                if (SubItemCheck) notify(t("iftScope.SubItemItemDelMsg"), "success", 4000)
                dispatch(updateIftRevisionScopeData({ id }))
              }
            })
          }

          if (SubItemCheck && resourceCheck && isMultiInstance(tableData, get(parent, "CODE"))) {
            dupSubItemCnfrmDialog(parent, checkMultiInstanceCount(tableData, get(parent, "CODE")))
              .show()
              .then((dialogResult: boolean) => dialogResult && delFn())
          } else delFn()

          return Promise.resolve()
        },
      }),
    [tableData]
  )

  const onEditingStart = useCallback((e: any) => {
    const SubItem = get(e, "data.SUBITEM") === "Y"

    if (!SubItem && !get(e, "data.PROJECT_RESOURCE_ID")) dispatch(setIFTScopeEditItem(e.data))
    if (get(e, "data.PROJECT_RESOURCE_ID")) dispatch(setIFTScopeEditResource(e.data))
  }, [])

  const init = useCallback(() => {
    dispatch(fetchBaselineIFTScopeAction({ projectId, estimateId }))
    if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
  }, [projectId, estimateId])

  const handleRecalculate = useCallback(async () => {
    notify(t("iftScope.recalculatingMsg"), "info", 5000)
    await dispatch(
      recalculateCarbonAction(baselineCheck ? { projectId } : { projectId: estimateId })
    )
      .then((response) => {
        // eslint-disable-next-line no-unused-expressions
        get(response, "meta.requestStatus") === "fulfilled"
          ? notify(get(response, "payload.data.status"), "success", 4000)
          : notify(get(response, "payload.data.status"), "error", 4000)
      })
      .then(() => {
        setTimeout(() => {
          notify(t("iftScope.recalculatingAftrMsg"), "info", 3000)
          init()
        }, 1500)
      })
  }, [baselineCheck])

  return (
    <>
      {iftScopeEditItem && <EditItemPopup tableRef={treeListRef} />}
      {iftScopeEditResource && <EditResourcePopup tableRef={treeListRef} />}
      <ProvisionalSumModal
        visible={showProvisionalModal.show}
        rowData={showProvisionalModal.data}
        onHide={() => setShowProvisionalModal(defaultModalState)}
      />
      <ResourceModal
        visible={showResourceModal.show}
        rowData={showResourceModal.data}
        isBeingAddedOnDuplicateSubItem={showResourceModal.isDuplicate}
        onHide={() => setShowResourceModal({ ...defaultModalState, isDuplicate: false })}
      />
      <SubItemModal
        visible={showSubItemModal.show}
        rowData={showSubItemModal.data}
        onHide={() => setShowSubItemModal(defaultModalState)}
      />
      <ItemModal
        visible={showItemModal.show}
        rowData={showItemModal.data}
        onHide={() => setShowItemModal(defaultModalState)}
      />
      <TreeList
        id="iftScopeItems"
        className="ift-scope-tree"
        ref={treeListRef}
        dataSource={dataStore}
        showBorders
        showRowLines
        wordWrapEnabled
        columnAutoWidth
        showColumnLines
        allowColumnResizing
        keyExpr="id"
        rootValue={-1}
        filterMode="matchOnly"
        parentIdExpr="Parent_ID"
        height={windowHeight - 232}
        onEditingStart={onEditingStart}
        onContentReady={onScopeTableContentReady}
        onContextMenuPreparing={onContextMenuPreparing}
        onCellPrepared={(e) =>
          onScopeTableCellPrepared({
            e,
            roleBasedAddEditDelPermission: iftRoleBasedPermissions,
            dispatch,
            addedIds,
            editedIds,
          })
        }
      >
        <FilterRow visible />
        <SearchPanel visible width={340} />
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing
          mode="row"
          allowUpdating={!baselineCheck}
          allowDeleting={!baselineCheck}
          texts={{
            confirmDeleteMessage: scopeDelConfTxt,
          }}
        />
        <Toolbar>
          <Item location="before">
            <span title={t("iftScope.recalculateCarbon")}>
              <Button
                stylingMode="contained"
                className="active"
                text={t("iftScope.recalculate")}
                onClick={handleRecalculate}
              />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => handleResetFilter(treeListRef)}
              />
            </span>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Column caption={tableHeading} alignment="center">
          <Column fixed allowEditing={false} />
          <Column
            dataField="CODE"
            caption={t("iftScope.code")}
            allowEditing={false}
            dataType="string"
            sortOrder="asc"
            sortingMethod={handleCodeSort}
          />
          <Column
            dataField="DESCRIPTION"
            caption={t("iftScope.desc")}
            allowEditing={false}
            cellRender={scopeCellDescriptionRender}
          />
          <Column dataField="UNIT_DESCRIPTION" caption={t("iftScope.unit")} allowEditing={false} />
          <Column
            dataField="CARB_A1_A3"
            caption={t("iftScope.a1a3")}
            visible={scopeCarbonSwitch}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.CARB_A1_A3)}
          />
          <Column
            dataField="V_CARB_A1_A3"
            caption={t("iftScope.reva1a3")}
            visible={scopeCarbonSwitch && !!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.V_CARB_A1_A3, row?.V_IS_DELETED)}
          />
          <Column
            dataField="CARB_A4"
            caption={t("iftScope.a4")}
            visible={scopeCarbonSwitch}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.CARB_A4)}
          />
          <Column
            dataField="V_CARB_A4"
            caption={t("iftScope.reva4")}
            visible={scopeCarbonSwitch && !!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.V_CARB_A4, row?.V_IS_DELETED)}
          />
          <Column
            dataField="CARB_A5A"
            caption={t("iftScope.a5a")}
            visible={scopeCarbonSwitch}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.CARB_A5A)}
          />
          <Column
            dataField="V_CARB_A5A"
            caption={t("iftScope.reva5a")}
            visible={scopeCarbonSwitch && !!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.V_CARB_A5A, row?.V_IS_DELETED)}
          />
          <Column
            dataField="CARB_A5W"
            caption={t("iftScope.a5w")}
            visible={scopeCarbonSwitch}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.CARB_A5W)}
          />
          <Column
            dataField="V_CARB_A5W"
            caption={t("iftScope.reva5w")}
            visible={scopeCarbonSwitch && !!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => carbNullCalculate(row.V_CARB_A5W, row?.V_IS_DELETED)}
          />
          <Column
            dataField="CARB_TOTAL_A1_A5"
            caption={t("iftScope.totalA1A5")}
            visible={scopeCarbonSwitch}
            allowEditing={false}
            format={cellFormatterConfig}
            minWidth={120}
            calculateCellValue={(row) => carbNullCalculate(row.CARB_TOTAL_A1_A5)}
          />
          <Column
            dataField="V_CARB_TOTAL_A1_A5"
            caption={t("iftScope.revTotalA1A5")}
            visible={scopeCarbonSwitch && !!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            minWidth={120}
            calculateCellValue={(row) =>
              carbNullCalculate(row.V_CARB_TOTAL_A1_A5, row?.V_IS_DELETED)
            }
          />
          <Column
            dataField="QUANTITY"
            caption={t("iftScope.qty")}
            allowEditing
            format={cellFormatterConfig}
          />
          <Column
            dataField="V_QUANTITY"
            caption={t("iftScope.revQty")}
            visible={!!estimateId && baselineCheck}
            allowEditing={false}
            format={cellFormatterConfig}
            calculateCellValue={(row) => qtyNullCalculate(row.V_QUANTITY, row)}
          />
          <Column
            dataField="RATE"
            caption={t("iftScope.rate")}
            alignment="right"
            allowEditing={false}
            cellRender={(cellInfo) =>
              renderScopeTableRateCell(cellInfo, userRole, contractorEstimateLibraryContent)
            }
          />
          <Column
            dataField="V_RATE"
            caption={t("iftScope.revRate")}
            alignment="right"
            allowEditing={false}
            visible={!!estimateId && baselineCheck}
            cellRender={(cellInfo) =>
              renderScopeTableRateCell(cellInfo, userRole, contractorEstimateLibraryContent)
            }
          />
          <Column
            dataField="COST"
            caption={t("iftScope.cost")}
            allowEditing={false}
            cellRender={(cellInfo) =>
              renderScopeTableCostCell(cellInfo, userRole, contractorEstimateLibraryContent)
            }
          />
          <Column
            dataField="V_COST"
            caption={t("iftScope.revCost")}
            allowEditing={false}
            visible={!!estimateId && baselineCheck}
            cellRender={(cellInfo) =>
              renderScopeTableCostCell(cellInfo, userRole, contractorEstimateLibraryContent)
            }
          />
        </Column>
      </TreeList>
    </>
  )
}

export default memo(ScopeTable)
