import axiosInstance, { isAxiosError } from "services/api"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { CarbonFuelItem } from "./types"

export const fetchUnitAsync = async (category: string) => {
  try {
    const response = await axiosInstance.get<BenchmarkDataContainer[]>(`/bes/codes/${category}`)
    return response.data as BenchmarkDataContainer[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchFuelTypesWithValueAsync = async (carbonCodeID: number) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<CarbonFuelItem[]> | ErrorResponse>(
      `/codetype-unit?filter=CUSTOM_LIST_ID||$eq||${carbonCodeID}`
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchCreateCodeUpdateActionAsync = async (request: CarbonFuelItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<CarbonFuelItem[]> | ErrorResponse>(
      `/codetype-unit/bulk`,
      { bulk: request }
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
