import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse } from "services/SuccessResponse"
import notify from "devextreme/ui/notify"
import { revisionResponseTransformer } from "redux/estimate-revision-management/utils"
import orderBy from "lodash/orderBy"
import {
  getAllCompProjects,
  getAllEstimateRevisionsAsync,
  getAllProjects,
  getCompratorData,
  getTotalCompProjects,
} from "./services"
import { EstimateRevisionAPIData, ProjectIDsResponse } from "./types"

export const getCompratorDataAction = createAsyncThunk<
  any[],
  { estimateNumber: number; revisionNumbers: number[] }
>(
  /* TODO */ "ift-comparator/getCompratorData",
  async (request: { estimateNumber: number; revisionNumbers: number[] }, { rejectWithValue }) => {
    try {
      const response: Array<any> | ErrorResponse = await getCompratorData(request) /* TODO */
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response as Array<any> /* TODO */
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getAllProjectsAction = createAsyncThunk<any>(
  "getAllProjectsAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getAllProjects()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      const finalList = (response.data as ProjectIDsResponse[])
        .map((item: ProjectIDsResponse) => ({
          ...item,
          PROJECT_QUOTE_NO: item.PROJECT_QUOTE_NO?.toString(),
        }))
        .filter((item: ProjectIDsResponse) => item.PROJECT_QUOTE_NO)

      const projectIds = orderBy(finalList, "PROJECT_QUOTE_NO")
      return projectIds || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getAllEstimateRevisionsAction = createAsyncThunk<any, EstimateRevisionAPIData>(
  "getAllEstimateRevisionsAction",
  async (request: EstimateRevisionAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getAllEstimateRevisionsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return revisionResponseTransformer(response.data)
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getCompDataAction = createAsyncThunk<
  any[],
  { baseEestimateId: number; estimateRevisionIds: number[] }
>(
  "ift-comparator/getCompData",
  async (
    request: { baseEestimateId: number; estimateRevisionIds: number[] },
    { rejectWithValue }
  ) => {
    try {
      const { estimateRevisionIds } = request
      if (estimateRevisionIds.length) {
        const response: any | ErrorResponse = await getAllCompProjects(request)
        const errorResponse = response as ErrorResponse
        if (errorResponse?.code) {
          notify(errorResponse.message, "error", 2000)
          return rejectWithValue(errorResponse.message)
        }
        return response.data as Array<any>
      }
      return []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getCompTotalDataAction = createAsyncThunk<
  any[],
  { estimateNumber: number; revisionNumbers: number[] }
>(
  "ift-comparator/getTotalCompProjects",
  async (request: { estimateNumber: number; revisionNumbers: number[] }, { rejectWithValue }) => {
    try {
      const { revisionNumbers } = request
      if (revisionNumbers.length) {
        const response: any | ErrorResponse = await getTotalCompProjects(request)
        const errorResponse = response as ErrorResponse
        if (errorResponse?.code) {
          notify(errorResponse.message, "error", 2000)
          return rejectWithValue(errorResponse.message)
        }
        return response.data as Array<any>
      }
      return []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
