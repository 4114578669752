import { get } from "lodash"
import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { calculateTotalProfiledCost } from "screens/ift/components/inflationAndSpend/spend-utils"
import { IResource } from "redux/projects/types"
import {
  KeyDataRangeTableResponse,
  PreviousEstimateResponse,
  KeyDataFieldsResponse,
  ProvisionalSumItem,
  RiskAllocationItem,
  RiskRegisterItem,
  SubItem,
  ISpend,
  IItem,
} from "./types"
import { calculateTotalProfiledCostForResult, getValFromBESStructure } from "./utils"

// IFT Key Data From Here...
export const fetchKeyDataFieldsDataAsync = async (obj: {
  projectId: number | string | undefined
  estimateId: number | string | undefined
}) => {
  try {
    const { projectId, estimateId } = obj
    const url = estimateId
      ? `/ift/key-data-list/project/${projectId}?revisionId=${estimateId}`
      : `/ift/key-data-list/project/${projectId}`
    const response = await axiosInstance.get<
      SuccessResponse<KeyDataFieldsResponse> | ErrorResponse
    >(url)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchKeyDataRangeDistTableAsync = async (projectId: number | string | undefined) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<KeyDataRangeTableResponse[]> | ErrorResponse
    >(`/ift/key-data-list/project-detail/${projectId}`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

// IFT Previous Estimate From Here..
export const fetchPreviousEstimateAsync = async (projectId: number) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<PreviousEstimateResponse> | ErrorResponse
    >(`/ift/cess-report?filter=ESTIMATE_BES_ID||$eq||${projectId}`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

// IFT Schedule from here
export const getIFTScheduleAsync = async (projectId: string | number) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      `/bes/xmldatastore/ExpenseForecastScheduleXmlDataStore?ProjectId=${projectId}`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateIFTScheduleAsync = async (data: any) => {
  const projectId = getValFromBESStructure(data, "ProjectId")

  try {
    const response = await axiosInstance.put<string>(
      `/ift/updateXmlStoreData/ExpenseForecastScheduleXmlDataStore/${projectId}`,
      data
    )

    return response
  } catch (err) {
    return isAxiosError(err)
  }
}

// IFT Scope from here
export const fetchIFTScopeAsync = async (
  projectId: string | number | undefined,
  estimateId?: string | number | undefined
) => {
  const apiURL = estimateId
    ? `/project-aggr/project/${projectId}?aggregatedOn=ITEM_RESOURCE_TREE&includeSection=true&versionId=${estimateId}`
    : `/project-aggr/project/${projectId}?aggregatedOn=ITEM_RESOURCE_TREE&includeSection=true`

  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(apiURL)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getScopeItemsDetailsAsync = async (itemId?: number | string) => {
  try {
    const response = await axiosInstance.get(`/project-item/${itemId}`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getScopeResourceDetailsAsync = async (resourceId?: number | string) => {
  try {
    const response = await axiosInstance.get(`/project-resource/${resourceId}`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateScopeItemsAsync = async (items?: Array<IItem>) => {
  try {
    const response = await axiosInstance.put("/project-item/bulk", { bulk: items })

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateScopeResourcesAsync = async (resources?: Array<IResource>) => {
  try {
    const response = await axiosInstance.put("/project-resource/bulk", { bulk: resources })

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteScopeProjectItemAsync = async (data: { resourceId: any; projectId: any }) => {
  const { resourceId, projectId } = data

  try {
    const response = await axiosInstance.delete<string>(
      `/project-item?ids=${resourceId}&projectId=${projectId}`
    )
    return response
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteScopeProjectResourceAsync = async (data: {
  resourceId: any
  projectId: any
}) => {
  const { resourceId, projectId } = data
  try {
    const response = await axiosInstance.delete<string>(
      `/project-resource?ids=${resourceId}&projectId=${projectId}`
    )
    return response
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchItemGroupIFTScopeAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/item-group`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchItemIFTScopeAsync = async (itemGroupID: string | number | undefined) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      `/item-library?ITEM_GROUP_ID=${itemGroupID}`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchResourceGroupIFTScopeAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/resource-group`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchResourceLibraryIFTScopeAsync = async (
  resourceGroupId: string | number | undefined
) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      `/resource-library?RESOURCE_GROUP_ID=${resourceGroupId}`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchResourceLibraryContractorIFTScopeAsync = async (
  contractor: string | number | undefined
) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      `/resource-library/contractor/${contractor}`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const addProvisionalSumItemAsync = async (request: ProvisionalSumItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any> | ErrorResponse>(
      `/project-item/bulk`,
      {
        bulk: request,
      }
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const addItemAsync = async (request: ProvisionalSumItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any> | ErrorResponse>(
      `/project-item/bulk`,
      {
        bulk: request,
      }
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const addResourceIFTScopeAsync = async (request: any) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any> | ErrorResponse>(
      `/project-resource/bulk`,
      { bulk: request }
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchSubItemGroupIFTScopeAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/item-group?IS_SUB_ITEM=Y`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchSubItemIFTScopeAsync = async (subItemGroupID: string | number | undefined) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      `/item-library?ITEM_GROUP_ID=${subItemGroupID}&IS_SUB_ITEM=Y`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const addSubItemAsync = async (request: SubItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any> | ErrorResponse>(
      `/project-sub-item/bulk`,
      {
        bulk: request,
      }
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

// IFT Risk Register From Here..
export const fetchRiskByProjectAsync = async (projectId: number) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<RiskRegisterItem[]> | ErrorResponse>(
      `/risk-register/project/${projectId}`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchCostElementsForRiskAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<RiskAllocationItem[]> | ErrorResponse>(
      `/risk-register/cost-element`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const createOrUpdateRiskAsync = async (request: RiskRegisterItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<any> | ErrorResponse>(
      `/risk-register`,
      {
        bulk: request,
      }
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const removeRiskRegisterAsync = async (riskId: number[]) => {
  try {
    const response = await axiosInstance.delete<SuccessResponse<any> | ErrorResponse>(
      `/risk-register?ids=${riskId.join("|")}`
    )

    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

// IFT Inflation and Spend from here
export const getIFTSpendAsync = async (projectId?: number | string) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/ift/spendProfile/${projectId}`)
    const resData = get(response, "data.data") || []
    const data = resData.map((item: any) => {
      const Values = get(item, "Values") || []
      const vArr = Values.reduce((acc: any, curr: number, index: number) => {
        const key = `V${index}`
        const val = item.RowType !== "SECTION" ? curr * item.TotalCost : curr
        return { ...acc, [key]: val }
      }, {})

      return {
        ...item,
        ...vArr,
        TotalProfiledCost: calculateTotalProfiledCost(item, "Values"),
      }
    })

    return resData ? { ...response.data, data } : response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchIFTSpendResultAsync = async (projectId: string | number | undefined) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/ift/result/${projectId}`)
    const resData = get(response, "data.data") || []
    const finalData = get(response, "data.data").map((item: any) => {
      const InflationValues = get(item, "InflationValues") || []
      const Values = get(item, "Values") || []
      let addArr = Values.reduce((acc: any, curr: number, index: number) => {
        const key = `V${index}`
        const val = item.RowType !== "SECTION" ? curr + (item.InflationValues?.[index] ?? 0) : curr
        return { ...acc, [key]: val }
      }, {})
      if (item.RowType !== "SECTION") {
        addArr = {
          ...addArr,
          TotalProfiledCost: calculateTotalProfiledCostForResult(InflationValues, Values),
        }
      }

      return {
        ...item,
        ...addArr,
      }
    })

    return resData ? { ...response.data, data: finalData } : response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateIFTSpendAsync = async (items?: Array<ISpend>) => {
  try {
    const response = await axiosInstance.post("/ift/spendProfile", { bulk: items })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const nominatedEstimateRevisionAsync = async (data: number | undefined) => {
  try {
    const response = await axiosInstance.put<SuccessResponse<any> | ErrorResponse>(
      `/estimate-revision/preferred/${data}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const getIFTInflationAsync = async (projectId?: number | string) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(`/ift/inflation/${projectId}`)
    const resData = get(response, "data.data") || []
    const data =
      resData.map((item: any) => {
        const obj: any = {
          DataTypeName: item?.DataTypeName,
          ...Object.fromEntries(
            (item.properties ?? item.Properties ?? []).map((el: any) => [el.Name, el.Data])
          ),
        }

        return obj
      }) ?? []

    return { inflationRowData: resData, inflationData: data }
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getIFTInflationMasterDataAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any>>(
      "/admin/inflation-sheet-index-access"
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateIFTInflationAsync = async (data: any) => {
  try {
    const response = await axiosInstance.put<string>(
      `/ift/updateXmlStoreData/CostElementInflationSettingXmlDataStore/${data.projectId}`,
      data.request
    )
    return response
  } catch (err) {
    return isAxiosError(err)
  }
}
