import { memo } from "react"
import { LoadPanel } from "devextreme-react"
import { useSelector } from "react-redux"
import { comparatorSelector } from "redux/comparator/iftComparatorSlice"
import { LoadingState } from "types/AppNav"

const IFTComparatorLevelLoader = () => {
  const { loading } = useSelector(comparatorSelector)
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loading === LoadingState.LOADING}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(IFTComparatorLevelLoader)
