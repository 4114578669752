import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CarbonCodesCategoryItem } from "redux/masters/types"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { fetchAllWasteFactorizationAction, updateTranportValueAction } from "./middleware"
import { UpdateWasteFactorRequest, WasteFactorItem, WasteFactorState } from "./types"

const INITIAL_STATE: WasteFactorState = {
  loading: LoadingState.DEFAULT,
  wasteFactor: [],
  carbonCodeItem: null,
}

const wasteFactorSlice = createSlice({
  name: "WasteFactor",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setCarbonCodeForWasteFactor: (
      state: WasteFactorState,
      { payload }: PayloadAction<CarbonCodesCategoryItem[]>
    ) => {
      const item = payload.find(
        (item: CarbonCodesCategoryItem) => item.CUSTOM_LIST_NAME?.toLowerCase() === "wrap category"
      )
      if (item) {
        return { ...state, carbonCodeItem: item }
      }

      return { ...state }
    },

    updateWasteFactorData: (
      state: WasteFactorState,
      { payload }: PayloadAction<UpdateWasteFactorRequest[]>
    ) => {
      const wasteFactors = [...state.wasteFactor]
      payload.forEach((request: UpdateWasteFactorRequest) => {
        const idx: number = wasteFactors.findIndex((item) => item.id === request.key)
        if (idx >= 0) {
          const updatedItem = { ...wasteFactors[idx], ...request.data }
          wasteFactors[idx] = updatedItem
        }
      })

      return { ...state, wasteFactor: wasteFactors }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllWasteFactorizationAction.fulfilled,
      (state: WasteFactorState, { payload }: PayloadAction<WasteFactorItem[]>) => ({
        ...state,
        wasteFactor: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(
      updateTranportValueAction.fulfilled,
      (state: WasteFactorState, { payload }: PayloadAction<WasteFactorItem[]>) => ({
        ...state,
        wasteFactor: payload,
        loading: LoadingState.DEFAULT,
      })
    )

    builder.addCase(fetchAllWasteFactorizationAction.rejected, (state: WasteFactorState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const wasteFactorSelector = (state: RootState) => state.WasteFactor
export const { setLoading, setCarbonCodeForWasteFactor, updateWasteFactorData } =
  wasteFactorSlice.actions
export default wasteFactorSlice.reducer
