import { withNavigationWatcher } from "contexts/navigation"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
// import CarbonCoefficient from "screens/emission-factors/carbon-coefficient"
// import ItemCoefficent from "screens/emission-factors/item-coefficient"
import ItemEmission from "screens/emission-factors/item-emission"
import ResourceEmission from "screens/emission-factors/resource-emission"
import EstimateRevisionManagement from "screens/estimate-revision-management"
import EstimatorsRoles from "screens/estimators-roles"
import FuelType from "screens/fuel-type"
import Home from "screens/home"
import IFTScreen from "screens/ift"
import Codes from "screens/masters/codes"
import AssignInflationIndex from "screens/control-panel/assign-inflation-index"
import DynamicCarbonLibrary from "screens/masters/dynamic-carbon-library"
import Profile from "screens/profile"
import EstimateLibrarySetup from "screens/project-estimate-library-setup"
import ProjectItem from "screens/project-item"
import ProjectSections from "screens/project-sections"
import Projects from "screens/projects"
import EstimateLevel from "screens/reports/estimate-level"
import IFTComprartor from "screens/reports/ift-comparator"
import RolePermission from "screens/role-permission"
import TasksPage from "screens/tasks"
import ModesOfTransport from "screens/transport/modes-of-transport"
import TransportAssumptions from "screens/transport/transport-assumptions"
import WasteFactor from "screens/wasteFactor"
import IFTComp from "screens/reports/ift-cost-comp"
import CarbonExpenditureProfile from "screens/reports/expenditure-profile"
import ExpenditureProfileReport from "screens/reports/expenditureProfileReport"
import CostEstimateSummary from "screens/reports/cost-estimate-summary"
import CostEstimateSummaryReport from "screens/reports/costEstimateSummaryReport"
import PowerBI from "screens/powerBI"
import { allRoles } from "utils/common-utils"

const routes = [
  {
    path: "/projects",
    element: Projects,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/project/:projectId/sections",
    element: ProjectSections,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/project/:projectId/section/:sectionId/items",
    element: ProjectItem,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/project/:projectId/estimate-library-setup/:estimateId",
    element: EstimateLibrarySetup,
    props: {
      role: allRoles,
      accessType: AccessType.PROJECT_SPECIFIC_LIBRARY,
      permissionLevel: [AccessLevel.READ_ONLY, AccessLevel.FULL_ACCESS],
    },
  },
  {
    path: "/project/:projectId/estimate-revision-management",
    element: EstimateRevisionManagement,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/project/:projectId/ift",
    element: IFTScreen,
    props: { role: allRoles, accessType: AccessType.IFT, permissionLevel: AccessLevel.YES },
  },
  {
    path: "/project/:projectId/erm/:estimateId/ift",
    element: IFTScreen,
    props: { role: allRoles, accessType: AccessType.IFT, permissionLevel: AccessLevel.YES },
  },
  // {
  //   path: "/carbon-coefficient",
  //   element: CarbonCoefficient,
  // },
  // {
  //   path: "/item-coefficient",
  //   element: ItemCoefficent,
  // },
  {
    path: "/item-emission",
    element: ItemEmission,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/resource-emission",
    element: ResourceEmission,
    props: {
      role: allRoles,
      accessType: AccessType.CARBON_LIBRARY,
      permissionLevel: [AccessLevel.FULL_ACCESS, AccessLevel.READ_ONLY],
    },
  },
  {
    path: "/home",
    element: Home,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/profile",
    element: Profile,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/control-panel/assign-inflation-index",
    element: AssignInflationIndex,
    props: {
      role: allRoles,
      accessType: AccessType.ASSIGN_INFLATION_INDEX,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/master/codes",
    element: Codes,
    props: {
      role: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/master/dynamic-carbon-library",
    element: DynamicCarbonLibrary,
    props: {
      role: allRoles,
      accessType: AccessType.DYNAMIC_CARBON_LIBRARY,
      permissionLevel: [AccessLevel.READ_ONLY, AccessLevel.FULL_ACCESS],
    },
  },
  {
    path: "/transport/modes-of-transport",
    element: ModesOfTransport,
    props: {
      role: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/transport/transport-assumptions",
    element: TransportAssumptions,
    props: {
      role: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/tasks",
    element: TasksPage,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
  {
    path: "/fuel-type",
    element: FuelType,
    props: {
      role: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/waste-factor",
    element: WasteFactor,
    props: {
      role: [RoleType.ADMINISTRATORS, RoleType.AUTHORITY_ESTIMATOR],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/estimate-level",
    element: EstimateLevel,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/ift-comparator",
    element: IFTComprartor,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/ift-comp",
    element: IFTComp,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/costEstimateSummary",
    element: CostEstimateSummary,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/costEstimateSummary/:estimateId/report",
    element: CostEstimateSummaryReport,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/carbonExpenditureProfile",
    element: CarbonExpenditureProfile,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/carbonExpenditureProfile/:estimateId/report",
    element: ExpenditureProfileReport,
    props: {
      role: allRoles,
      accessType: AccessType.REPORTS,
      permissionLevel: AccessLevel.YES,
    },
  },
  {
    path: "/assign-estimators-roles",
    element: EstimatorsRoles,
    props: {
      role: allRoles,
      accessType: AccessType.GRANT_RIGHTS,
      permissionLevel: [AccessLevel.FULL_ACCESS, AccessLevel.READ_ONLY],
    },
  },
  {
    path: "/role-permission",
    element: RolePermission,
    props: {
      role: [RoleType.ADMINISTRATORS],
      accessType: -1,
      permissionLevel: -1,
    },
  },
  {
    path: "/powerBI",
    element: PowerBI,
    props: { role: allRoles, accessType: -1, permissionLevel: -1 },
  },
]

export default routes.map((route) => ({
  ...route,
  element: withNavigationWatcher(route.element, route.path, route.props),
}))
