import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import { EstimateLevelAPIData, EstimateLevelResponse, ProjectIDsResponse } from "./types"

export const getProjectIDsAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<ProjectIDsResponse[]> | ErrorResponse>(
      `/projects/meta/all`
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getEstimateLevelsAsync = async (data: EstimateLevelAPIData) => {
  const { aggregateBy, projectID } = data
  try {
    const response = await axiosInstance.get<
      SuccessResponse<EstimateLevelResponse[]> | ErrorResponse
    >(`/project-aggr/project/${projectID}`, {
      params: {
        aggregatedOn: aggregateBy,
      },
    })
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}
