import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { headerTilesGenerator } from "../../utils"

const HeaderTiles = () => {
  const { t } = useTranslation()

  const { selectedExpenditureProjectData } = useSelector(expenditureProfileSelector)

  const tileHeadings = useMemo(
    () => headerTilesGenerator(t, selectedExpenditureProjectData),
    [selectedExpenditureProjectData]
  )

  return (
    <div className="detail-tiles-row">
      {tileHeadings.map(({ key, value, value2 }) => (
        <span key={key} style={{}}>
          <span>{key}</span>
          <span className="detail-box">{value}</span>
          {value2 && <span className="detail-box2">{value2}</span>}
        </span>
      ))}
    </div>
  )
}

export default memo(HeaderTiles)
