import { Popup, ScrollView } from "devextreme-react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { comparatorSelector, setEstimatorTotalsPopUp } from "redux/comparator/iftComparatorSlice"
import EstimateTotalsTable from "./estimateTotalsTable"

const EstimateToolsPopUp = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { showEstimateTotalsPopUp } = useSelector(comparatorSelector)

  return (
    <Popup
      visible={showEstimateTotalsPopUp}
      showCloseButton
      enableBodyScroll
      minHeight={400}
      maxHeight={800}
      height="auto"
      onHiding={() => dispatch(setEstimatorTotalsPopUp(!showEstimateTotalsPopUp))}
      title={t("iftComparatorMain.estimateTotals")}
      dragEnabled={false}
    >
      <ScrollView>
        <EstimateTotalsTable />
      </ScrollView>
    </Popup>
  )
}

export default EstimateToolsPopUp
