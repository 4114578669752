import { memo, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Popup } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import {
  estmteRvsnMgmntSelector,
  setSelectedUpdateRevision,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import RevisionEditForm from "./revisionEditForm"

const EditRevisionPopUp = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { selectedUpdateRevision } = useSelector(estmteRvsnMgmntSelector)

  const onClose = useCallback(() => {
    dispatch(setSelectedUpdateRevision())
  }, [])

  const title = useMemo(
    () =>
      `${t("estimateRevisionManagement.editHeading")} [${selectedUpdateRevision?.QuoteNo} (${
        selectedUpdateRevision?.Title
      })]`,
    [selectedUpdateRevision]
  )

  return (
    <Popup
      visible
      showCloseButton
      enableBodyScroll
      maxWidth={600}
      maxHeight={240}
      onHiding={onClose}
      title={title}
      dragEnabled={false}
    >
      <RevisionEditForm />
    </Popup>
  )
}

export default memo(EditRevisionPopUp)
