import { useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DataGrid, {
  Item,
  Pager,
  Paging,
  Column,
  Toolbar,
  Scrolling,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import { formatDate } from "devextreme/localization"
import { profileSelector } from "redux/profile/profileSlice"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import {
  setShowAddNewLibrary,
  estimateLibrarySelector,
} from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import { useAppDispatch } from "redux/store"
import { handleResetFilter } from "utils/common-utils"
import { allowedPageSizes } from "utils/config"
import { estimateLibFullAccess } from "../../utils"
import { renderApplyEstimateCell, renderRedirectCell } from "./utils"

const LibraryManagementTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataGridRef = useRef<DataGrid>(null)
  const { estimateId } = useParams()

  const { userRole } = useSelector(profileSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)
  const { contractorEstimateLibraryContent } = useSelector(estimateLibrarySelector)

  const handleButtonClick = () => dispatch(setShowAddNewLibrary(true))

  const libraryNoFullAccess = useMemo(() => !estimateLibFullAccess(userRole), [userRole])
  const renderApplyCell = useCallback(
    (e: any) => {
      return renderApplyEstimateCell({
        e,
        t,
        dispatch,
        dataGridRef,
        selectedRevision,
        libraryNoFullAccess,
        projectId: estimateId,
      })
    },
    [libraryNoFullAccess, dispatch, dataGridRef, selectedRevision]
  )

  return (
    <DataGrid
      id="gridContainer"
      ref={dataGridRef}
      dataSource={contractorEstimateLibraryContent}
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      showBorders
      showColumnLines
    >
      <FilterRow visible />
      <Scrolling columnRenderingMode="virtual" />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
      <SearchPanel visible width={240} />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
        <Item disabled={libraryNoFullAccess}>
          <Button
            stylingMode="contained"
            text={t("contractorEstimateLibrary.addLibrary")}
            className="roundBorderGreenBtn"
            onClick={handleButtonClick}
          />
        </Item>
      </Toolbar>
      <Column
        dataField="TITLE"
        key="Title"
        caption={t("contractorEstimateLibrary.title")}
        cellRender={(row) => renderRedirectCell(row, "TITLE", dispatch)}
      />
      <Column
        dataField="CREATED_ON"
        caption={t("contractorEstimateLibrary.creationDate")}
        calculateCellValue={(rowData: { CREATED_ON: string | number | Date }) =>
          rowData?.CREATED_ON ? formatDate(new Date(rowData?.CREATED_ON), "dd/MM/yyyy") : ""
        }
      />
      <Column dataField="ESTIMATOR_NAME" caption={t("contractorEstimateLibrary.creator")} />
      <Column cellRender={renderApplyCell} />
    </DataGrid>
  )
}

export default LibraryManagementTable
