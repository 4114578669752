import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { iftSelector } from "redux/ift/iftSlice"
import { profileSelector } from "redux/profile/profileSlice"
import Scope from "../scope"
import KeyData from "../keyData"
import Schedule from "../schedule"
import RiskRegister from "../riskRegister"
import PreviousEstimate from "../previousEstimate"
import InflationAndSpend from "../inflationAndSpend"
import { tabsOptions } from "../../utils"

function IFTContent() {
  const { t } = useTranslation()
  const { selectedTab } = useSelector(iftSelector)
  const { userRole } = useSelector(profileSelector)

  const selectedTabKey = useMemo(
    () => tabsOptions(t, userRole).find(({ id }) => id === selectedTab)?.key,
    [selectedTab]
  )

  switch (selectedTabKey) {
    case "schedule":
      return <Schedule />
    case "scope":
      return <Scope />
    case "riskRegister":
      return <RiskRegister />
    case "inflationAndSpend":
      return <InflationAndSpend />
    case "keyData":
      return <KeyData />
    default:
      return <PreviousEstimate />
  }
}

export default IFTContent
