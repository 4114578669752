/* eslint-disable react/jsx-props-no-spreading */
import { TFunction } from "i18next"
import { get, isEmpty } from "lodash"
import { SelectBox } from "devextreme-react"

export const TAbreadCrumbData = (t: TFunction<"translation", undefined>) => [
  {
    name: t("menu.transportAssumptions"),
  },
]

export const onTARowInserting = (e: any) => {
  if (isEmpty(e.data)) {
    // Prevent the insertion of the empty or invalid row
    e.cancel = Promise.resolve()
  }
}

export const TATableInit = (dataGridRef: any) => {
  dataGridRef.current?.instance.option("toolbar.items[1].disabled", true)
  dataGridRef.current?.instance.option("toolbar.items[2].disabled", true)
}

export const onTAContentReady = (dataGridRef: any) => {
  if (dataGridRef.current?.instance.hasEditData()) {
    dataGridRef.current?.instance.option("toolbar.items[1].disabled", false)
    dataGridRef.current?.instance.option("toolbar.items[2].disabled", false)
  }
}

export const undoTATableChanges = (dataGridRef: any) => {
  dataGridRef.current?.instance.cancelEditData()
  TATableInit(dataGridRef)
}

export const renderTADropdownCell = (rowData: any, uniqTransportCategories: any) => {
  return (
    <SelectBox
      {...rowData.column.lookup}
      dataSource={uniqTransportCategories}
      defaultValue={get(rowData, "value")}
      searchEnabled
      displayExpr="Name"
      valueExpr="Id"
      onValueChanged={(e) => rowData.setValue(e.value)}
    />
  )
}
