import React, { useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { get } from "lodash"
import { Button, Popup } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, Editing, Item, Toolbar } from "devextreme-react/data-grid"
import { useAppDispatch } from "redux/store"
import { iftSelector } from "redux/ift/iftSlice"
import { ProvisionalSumItem } from "redux/ift/types"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { addProvisionalSumItemAction, fetchRevisionIFTScopeAction } from "redux/ift/middleware"
import { allRoles, identifyPermissions } from "utils/common-utils"
import { currencyConverter, onProvisionalSumCellPrepared } from "../ScopeTable/scopeTableUtils"
import { ProvisionalSumModalProps } from "./pSumUtils"

const ProvisionalSumModal: React.FC<ProvisionalSumModalProps> = ({ visible, onHide, rowData }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { estimateId } = useSelector(iftSelector)
  const { userRole } = useSelector(profileSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const tableData = useRef<ProvisionalSumItem[]>([])

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const setSaveRevertToolbar = (value: boolean) => {
    dataGridRef.current?.instance.option("toolbar.items[1].disabled", value) // save button
    dataGridRef.current?.instance.option("toolbar.items[2].disabled", value) // single undo button
  }

  useEffect(() => {
    if (visible) {
      setSaveRevertToolbar(true)
      dataGridRef.current?.instance?.addRow()
    }
  }, [visible])

  const store = useMemo(
    () =>
      new CustomStore({
        key: "ROW_ID",
        load: () => tableData.current,
        insert: (values: ProvisionalSumItem) => {
          tableData.current = [{ ...values }, ...tableData.current]
          return Promise.resolve(values)
        },
        update: (key: any, value: ProvisionalSumItem) => {
          const tableItems = [...tableData.current]
          const idx = tableItems.findIndex((item: ProvisionalSumItem) => item.ROW_ID === key)
          if (idx >= 0) {
            tableItems[idx] = { ...tableItems[idx], ...value }
          }
          tableData.current = tableItems
          return Promise.resolve(tableItems)
        },
        remove: (rowIds: string[]) => {
          const tableItems = [...tableData.current]
          const filteredData = tableItems.filter(
            (item: ProvisionalSumItem) => !rowIds.includes(item.ROW_ID || "")
          )
          tableData.current = filteredData
          return Promise.resolve()
        },
      }),
    [tableData.current]
  )

  const handleRowSelection = (e: any) => {
    const selectedRowIds = e.selectedRowKeys as string[]
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", selectedRowIds.length === 0)
  }

  const handleInitNewRowOrStartEditing = () => setSaveRevertToolbar(false)

  const onInitNewRow = (e: any) => {
    setSaveRevertToolbar(false)
    e.data = {
      PROJECT_SECTION_ID: rowData.PROJECT_SECTION_ID,
      UNIT_DESCRIPTION: "item",
      ROW_ID: uuidv4(),
      PROJECT_ITEMAGGR_HIERARCHY: null,
      PROJECT_ITEM_CARB_A5W_FACTOR: 0,
      PROJECT_ITEM_COMPOSITETYPE: "NORMAL_ITEM",
      PARENT_PROJECT_ITEM_ID: rowData.PROJECT_ITEM_ID,
      PROJECT_ITEM_ISPROVSUM: 1,
    }
  }

  const handleDeleteClick = () => {
    dataGridRef?.current?.instance?.saveEditData().then(() => {
      const selectedRows = dataGridRef.current?.instance.getSelectedRowsData()
      const indexes =
        selectedRows?.map((item: ProvisionalSumItem) =>
          tableData.current.findIndex(
            (content: ProvisionalSumItem) => content.ROW_ID === item.ROW_ID
          )
        ) || []
      for (const index of indexes) {
        dataGridRef.current?.instance?.deleteRow(index)
      }
      dataGridRef?.current?.instance?.saveEditData()
      dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
    })
  }

  const handleAddClick = async () => {
    dataGridRef?.current?.instance?.saveEditData()?.then(() => {
      dataGridRef.current?.instance?.addRow()
    })
  }

  const handleSaveClick = () => {
    dataGridRef.current?.instance.saveEditData()?.then(() => {
      dispatch(addProvisionalSumItemAction(tableData.current)).then(() => {
        if (estimateId) {
          dispatch(fetchRevisionIFTScopeAction(estimateId))
        }
      })
      tableData.current = []
      onHide?.()
    })
  }

  return (
    <Popup
      visible={visible}
      dragEnabled={false}
      defaultVisible={false}
      onHiding={() => {
        dataGridRef?.current?.instance?.cancelEditData()
        onHide?.()
      }}
      hideOnOutsideClick={false}
      showCloseButton
      showTitle
      title={t("provisionalSum.addProvisionalSum")}
      container=".dx-viewport"
      width="90%"
      height="90%"
    >
      <div className="ift-provisional-sum">
        <DataGrid
          dataSource={store}
          className="ift-provisional-sum"
          ref={dataGridRef}
          highlightChanges
          allowColumnResizing
          columnAutoWidth
          showBorders
          showColumnLines
          showRowLines
          scrolling={{ mode: "standard" }}
          selection={{ mode: "multiple" }}
          onInitNewRow={onInitNewRow}
          onSelectionChanged={handleRowSelection}
          onCellPrepared={onProvisionalSumCellPrepared}
          onEditingStart={handleInitNewRowOrStartEditing}
        >
          <Column
            dataField="PROJECT_ITEMAGGR_CODE"
            caption={t("provisionalSum.code")}
            alignment="center"
          />
          <Column
            dataField="PROJECT_ITEMAGGR_DESC"
            caption={t("provisionalSum.description")}
            alignment="center"
          />
          <Column
            dataField="UNIT_DESCRIPTION"
            caption={t("provisionalSum.unit")}
            alignment="center"
          />
          <Column
            dataField="PROJECT_ITEM_QTY"
            caption={t("provisionalSum.qty")}
            alignment="center"
            dataType="number"
          />
          <Column
            dataField="RATE"
            caption={t("provisionalSum.rate")}
            alignment="center"
            dataType="number"
            allowEditing={false}
            format={{ precision: 4 }}
            calculateCellValue={(rowData: ProvisionalSumItem) => {
              if (!permsnCheck) return null
              if (rowData.PROJECT_ITEM_COST && rowData.PROJECT_ITEM_QTY) {
                const total = Number(rowData.PROJECT_ITEM_COST) / Number(rowData.PROJECT_ITEM_QTY)
                return total
              }
              return 0
            }}
          />
          <Column
            dataField="PROJECT_ITEM_COST"
            caption={t("provisionalSum.cost")}
            alignment="center"
            dataType="number"
            allowEditing={permsnCheck}
            cellRender={(row) =>
              permsnCheck ? currencyConverter(get(row, "data.PROJECT_ITEM_COST")) : null
            }
          />
          <Column caption="Carbon" alignment="center">
            <Column
              dataField="PROJECT_ITEM_CARB_A1_A3_FACTOR"
              caption={t("provisionalSum.a1_a3")}
              alignment="center"
              dataType="number"
            />
            <Column
              dataField="PROJECT_ITEM_CARB_A4_FACTOR"
              caption={t("provisionalSum.a4")}
              alignment="center"
              dataType="number"
            />
            <Column
              dataField="PROJECT_ITEM_CARB_A5A_FACTOR"
              caption={t("provisionalSum.a5a")}
              alignment="center"
              dataType="number"
            />
            <Column
              dataField="PROJECT_ITEM_CARB_WASTE_FACTOR"
              caption={t("provisionalSum.wasteFactor")}
              alignment="center"
              dataType="number"
            />
          </Column>
          <Toolbar visible>
            <Item name="revertButton" location="before">
              <span title={t("toolbarActions.delete")}>
                <Button icon="trash" stylingMode="text" text="" onClick={handleDeleteClick} />
              </span>
            </Item>
            <Item name="addRowButton" location="after">
              <span title={t("toolbarActions.add")}>
                <Button icon="add" stylingMode="text" text="" onClick={handleAddClick} />
              </span>
            </Item>
            <Item name="saveButton" location="after">
              <span title={t("toolbarActions.save")}>
                <Button icon="save" stylingMode="text" text="" onClick={handleSaveClick} />
              </span>
            </Item>
          </Toolbar>
          <Editing mode="batch" allowAdding allowUpdating refreshMode="full" />
        </DataGrid>
      </div>
    </Popup>
  )
}

export default ProvisionalSumModal
