import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Tooltip,
  Size,
} from "devextreme-react/chart"
import { sharedSelector } from "redux/shared/sharedSlice"
import { IBOQItem, ISection } from "redux/projects/types"
import "./carbon-emission-stacked-bar-chart.scss"
import { formatNumber } from "devextreme/localization"

interface ICarbonEmissionStackedBarChartProps {
  showPopUp?: (props: any) => void | undefined
  isBarChartPopUpVisible?: boolean | false
  data: Array<ISection | IBOQItem> | []
}

const CarbonEmissionStackedBarChart: React.FC<ICarbonEmissionStackedBarChartProps> = ({
  showPopUp,
  isBarChartPopUpVisible,
  data,
}) => {
  const { windowHeight } = useSelector(sharedSelector)
  const [carbonEmissionData, setCarbonEmissionData] = useState<Array<ISection | IBOQItem>>([])

  useEffect(() => {
    if (data && data.length > 0) {
      const carbonData: any = []
      data.forEach((item: any) => {
        carbonData.push({
          state: item?.PROJECT_SECTION_DESC || item?.DESCRIPTION || "",
          "A1-A3": Number(item?.PROJECT_SECTION_CARB_A1_A3 || item?.CARB_A1_A3) || 0,
          A4: Number(item?.PROJECT_SECTION_CARB_A4 || item?.CARB_A4) || 0,
          A5a: Number(item?.PROJECT_SECTION_CARB_A5A || item?.CARB_A5A) || 0,
          A5w: Number(item?.PROJECT_SECTION_CARB_A5W || item?.CARB_A5W) || 0,
        })
      })
      setCarbonEmissionData(carbonData)
    }
  }, [data])

  const onPointClick = (e: any) => {
    const point = e.target
    document.body.classList.add("bar-chart")
    point.select()
    if (showPopUp) showPopUp("visible")
  }

  return (
    <div className="carbon-emission-bar-chart-wrapper">
      <Chart
        id="chart"
        title="Sections With Highest Carbon Emissions"
        dataSource={carbonEmissionData}
        palette="Ocean"
        onPointClick={onPointClick}
        tooltip={{
          enabled: true,
          location: "center",
          zIndex: 999999,
        }}
        argumentAxis={{
          label: {
            customizeText: (arg: any) => {
              if (arg?.valueText?.length > 30) {
                return `${arg?.valueText?.slice(0, 30)}...`
              }
              return arg?.valueText
            },
            customizeHint: (arg: any) => {
              return arg?.valueText
            },
          },
        }}
      >
        <CommonSeriesSettings argumentField="state" type="stackedbar" />
        <Series valueField="A1-A3" name="A1-A3" />
        <Series valueField="A4" name="A4" />
        <Series valueField="A5a" name="A5a" />
        <Series valueField="A5w" name="A5w" />
        <ValueAxis position="left">
          <Title text="Carbon Emissions (kgco2e)" />
        </ValueAxis>
        <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top" />
        <Tooltip
          enabled
          location="edge"
          format={{
            formatter: (value: any) =>
              formatNumber(value, {
                precision: 2,
                type: "fixedPoint",
              }),
          }}
        />
        {isBarChartPopUpVisible && <Size height={windowHeight - 250} />}
      </Chart>
    </div>
  )
}

CarbonEmissionStackedBarChart.defaultProps = {
  showPopUp: undefined,
  isBarChartPopUpVisible: false,
}
export default CarbonEmissionStackedBarChart
