import { useEffect, useMemo, useRef, useState } from "react"
import BreadCrumb from "components/bread-crumb"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import Button from "devextreme-react/button"
import DataGrid, {
  Column,
  Editing,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { controlPanelSelector, setEditedHasAccess } from "redux/control-panel/controlPanelSlice"
import { ControlPanelState } from "redux/control-panel/types"
import {
  fetchAssignInflationIndexAction,
  updateAssignInflationIndexAction,
} from "redux/control-panel/middleware"
import { identifyPermissions } from "utils/common-utils"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import useDocumentTitle from "hooks/useDocumentTitle"

const AssignInflationIndex: React.FC = (): JSX.Element => {
  useDocumentTitle("Assign Infaltion Index | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { assignInflationIndexList }: ControlPanelState = useSelector(controlPanelSelector)
  const { userRole } = useSelector(profileSelector)
  const [allowEdit, setAllowEdit] = useState(false)
  const dataGridRef = useRef<DataGrid>(null)

  const store = useMemo(
    () =>
      new CustomStore({
        load: () => assignInflationIndexList,
        key: "XML_STORE_ID",
        update: (key: string, value: any) => {
          dispatch(
            setEditedHasAccess({
              ...value,
              XML_STORE_ID: key,
              HAS_ACCESS: value.HAS_ACCESS ? 1 : 0,
            })
          )
          return Promise.resolve()
        },
      }),
    [assignInflationIndexList]
  )

  const handleSaveClick = () => dataGridRef.current?.instance.saveEditData()

  const handleSaving = (e: any) => {
    console.log("CHANGES", e.changes)
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", true) // save
    const finalRequest = []
    for (const change of e.changes) {
      const updatedItem = assignInflationIndexList.find(
        (item: any) => item.XML_STORE_ID === change.key
      )
      if (updatedItem) {
        finalRequest.push({ ...updatedItem, HAS_ACCESS: change.data.HAS_ACCESS ? "Y" : "N" })
      }
    }
    dispatch(updateAssignInflationIndexAction(finalRequest))
  }

  useEffect(() => {
    if (userRole) {
      dispatch(fetchAssignInflationIndexAction()).then(() => {
        dataGridRef.current?.instance.option("toolbar.items[0].disabled", true) // save
        const isPermit = identifyPermissions(
          userRole,
          AccessType.ASSIGN_INFLATION_INDEX,
          AccessLevel.YES
        )
        setAllowEdit(isPermit)
      })
    }
  }, [userRole])

  return (
    <>
      <BreadCrumb
        data={[
          {
            name: t("menu.assignInflationIndex"),
          },
        ]}
      />
      <br />
      <DataGrid
        id="assign-inflation-index-grid"
        dataSource={store}
        ref={dataGridRef}
        showBorders
        columnAutoWidth
        allowColumnResizing
        highlightChanges
        repaintChangesOnly
        showColumnLines
        showRowLines
        allowColumnReordering={false}
        scrolling={{ mode: "standard" }}
        noDataText={t("noRecords")}
        onSaving={handleSaving}
      >
        <FilterRow visible />

        <SearchPanel visible width={340} />
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Editing
          mode="batch"
          onChangesChange={(changes) => {
            dataGridRef.current?.instance.option("toolbar.items[0].disabled", !changes.length) // save
          }}
          allowUpdating
        />
        <Pager showInfo allowedPageSizes={allowedPageSizes} visible />

        <Column
          dataField="NAME"
          caption={t("assignInflationIndexScreen.inflationIndex")}
          alignment="left"
          cssClass="border-class"
          allowEditing={false}
        />

        <Column
          dataField="HAS_ACCESS"
          dataType="boolean"
          caption={t("assignInflationIndexScreen.contractorAccess")}
          allowFiltering={false}
          cssClass="border-class"
          allowEditing={allowEdit}
        />
        <Toolbar visible>
          <Item location="after">
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
      </DataGrid>
    </>
  )
}

export default AssignInflationIndex
