/* eslint-disable radix */
import { get } from "lodash"
import { RootState } from "redux/store"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { LoadingState } from "types/AppNav"
import {
  reportTotalCarbonCellCalculate,
  totalCarbonFieldCellRender,
  totalFieldsCellRender,
} from "screens/reports/expenditureProfileReport/utils"
import {
  getExpenditureProfileSummaryTableAction,
  getExpenditureProfileProjectByIdAction,
  getExpenditureProfileProjectDataAction,
  getExpenditureProfileProjectsAction,
} from "./middleware"
import {
  ExpenditureProfileProject,
  ExpenditureProfileProjectData,
  ExpenditureProfileState,
} from "./types"

const initialState: ExpenditureProfileState = {
  loading: LoadingState.DEFAULT,
  showSummaryPopUp: false,
  expenditureProfileProjects: [],
  selectedExpenditureProject: undefined,
  selectedExpenditureProjectData: undefined,
  expenditureReportData: [],
  forecastYears: [],
  expenditureSummaryData: [],
  carbonSwitches: {
    historic: false,
    total: false,
  },
  popUpCarbonSwitches: {
    historic: false,
    total: false,
  },
}

const expenditureProfileSlice = createSlice({
  name: "ExpenditureProfile",
  initialState,
  reducers: {
    setLoading: (state: ExpenditureProfileState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setSelectedExpenditureProject: (
      state: ExpenditureProfileState,
      { payload }: PayloadAction<ExpenditureProfileProject>
    ) => ({
      ...state,
      selectedExpenditureProject: payload,
    }),
    setSelectedExpenditureProjectData: (
      state: ExpenditureProfileState,
      { payload }: PayloadAction<ExpenditureProfileProjectData | undefined>
    ) => ({
      ...state,
      selectedExpenditureProjectData: payload,
    }),
    setCarbonSwitches: (state: ExpenditureProfileState, { payload }: PayloadAction<any>) => {
      const carbonSwitches = { ...state.carbonSwitches }
      carbonSwitches[payload.key] = payload.value

      return {
        ...state,
        carbonSwitches,
      }
    },
    setShowSummaryPopUp: (state: ExpenditureProfileState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showSummaryPopUp: payload,
    }),
    setPopUpCarbonSwitches: (state: ExpenditureProfileState, { payload }: PayloadAction<any>) => {
      const popUpCarbonSwitches = { ...state.popUpCarbonSwitches }
      popUpCarbonSwitches[payload.key] = payload.value

      return {
        ...state,
        popUpCarbonSwitches,
      }
    },
    resetYears: (state: ExpenditureProfileState) => ({
      ...state,
      forecastYears: [],
      carbonSwitches: {
        historic: false,
        total: false,
      },
      popUpCarbonSwitches: {
        historic: false,
        total: false,
      },
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getExpenditureProfileProjectsAction.pending,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.LOADING,
      })
    )
    builder.addCase(
      getExpenditureProfileProjectsAction.fulfilled,
      (state: ExpenditureProfileState, { payload }: PayloadAction<ExpenditureProfileProject[]>) => {
        const data = Array.isArray(payload) ? [...payload] : []
        const expenditureProfileProjects = data.sort((a, b) => {
          const aGenerated = get(a, "PROJECT_REPORT_GENERATED") === "Y"
          const bGenerated = get(b, "PROJECT_REPORT_GENERATED") === "Y"
          const aScheme = get(a, "PROJ_COMP_DESC") || ""
          const bScheme = get(b, "PROJ_COMP_DESC") || ""

          if (aGenerated && bGenerated) return aScheme.localeCompare(bScheme)
          if (!aGenerated && !bGenerated) return aScheme.localeCompare(bScheme)
          return aGenerated && !bGenerated ? -1 : 0
        })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          expenditureProfileProjects,
        }
      }
    )
    builder.addCase(
      getExpenditureProfileProjectsAction.rejected,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      })
    )

    builder.addCase(
      getExpenditureProfileProjectByIdAction.pending,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.LOADING,
      })
    )
    builder.addCase(
      getExpenditureProfileProjectByIdAction.fulfilled,
      (
        state: ExpenditureProfileState,
        { payload }: PayloadAction<ExpenditureProfileProjectData>
      ) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        selectedExpenditureProjectData: payload,
      })
    )
    builder.addCase(
      getExpenditureProfileProjectByIdAction.rejected,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      })
    )

    builder.addCase(
      getExpenditureProfileProjectDataAction.pending,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.LOADING,
        expenditureReportData: [],
      })
    )
    builder.addCase(
      getExpenditureProfileProjectDataAction.fulfilled,
      (state: ExpenditureProfileState, { payload }: PayloadAction<any[]>) => {
        const forecastYears = payload.reduce((acc, curr) => {
          const keys = Object.keys(curr).filter((item) => {
            return item.includes("_") && !Number.isNaN(+item[0])
          })
          const years = keys
            .map((item) => item.split("_")[0])
            .reduce((acc1: any, curr1) => {
              if (acc1.includes(curr1 || "")) return [...acc1]
              return [...acc1, curr1]
            }, [])
          const newYears = years.filter((item: any) => !acc.includes(item))
          if (newYears.length)
            return [...acc, ...newYears].sort((a, b) => {
              const yearA = parseInt(a.split("-")[1])
              const yearB = parseInt(b.split("-")[1])
              return yearA - yearB
            })

          return [...acc]
        }, [])

        const carbonSwitchesInital = forecastYears.reduce(
          (acc: any, curr: any) => {
            const obj: any = {}
            obj[`${curr}`] = false

            return { ...acc, ...obj }
          },
          {
            historic: false,
            total: false,
          }
        )
        const carbonSwitches = { ...carbonSwitchesInital }
        const popUpCarbonSwitches = { ...carbonSwitchesInital }

        const numberedKeys = ["A1_A3", "A4", "A5A", "A5W", "TOTAL"]
        const expenditureReportData = payload
          .map((item) => {
            const keys = Object.keys(item).filter((key) => {
              return numberedKeys.some((nKey) => key.includes(nKey))
            })
            const formattedData = keys.reduce((acc, curr) => {
              const obj: any = {}
              obj[`${curr}`] = Math.round((get(item, curr) || 0) * 100) / 100
              return { ...acc, ...obj }
            }, {})

            return { ...item, ...formattedData }
          })
          .map((item) => {
            const totalCost = totalFieldsCellRender(item, "TOTAL_COST")
            const totalA1A3 = totalFieldsCellRender(item, "A1_A3")
            const totalA4 = totalFieldsCellRender(item, "A4")
            const totalA5A = totalFieldsCellRender(item, "A5A")
            const totalA5W = totalFieldsCellRender(item, "A5W")
            const totalCarbon = totalCarbonFieldCellRender(item)

            return { ...item, totalCost, totalA1A3, totalA4, totalA5A, totalA5W, totalCarbon }
          })
          .map((item) => {
            const yearWiseTotalCarbon = forecastYears.reduce((acc: any, curr: string) => {
              const obj: any = {}
              obj[`${curr}_TOTAL_CARBON`] =
                Math.round((reportTotalCarbonCellCalculate(item, curr) || 0) * 100) / 100

              return { ...acc, ...obj }
            }, {})
            return { ...item, ...yearWiseTotalCarbon }
          })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          expenditureReportData,
          forecastYears,
          carbonSwitches,
          popUpCarbonSwitches,
        }
      }
    )
    builder.addCase(
      getExpenditureProfileProjectDataAction.rejected,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      })
    )

    builder.addCase(
      getExpenditureProfileSummaryTableAction.pending,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.LOADING,
      })
    )
    builder.addCase(
      getExpenditureProfileSummaryTableAction.fulfilled,
      (state: ExpenditureProfileState, { payload }: PayloadAction<any[]>) => {
        const expenditureSummaryData = payload.map((item) => {
          const keys = Object.keys(item).filter((key) => key !== "TITLE")
          const formattedData = keys.reduce((acc, curr) => {
            const obj: any = {}
            obj[`${curr}`] = Math.round((get(item, curr) || 0) * 100) / 100
            return { ...acc, ...obj }
          }, {})

          return { ...item, ...formattedData }
        })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          expenditureSummaryData,
        }
      }
    )
    builder.addCase(
      getExpenditureProfileSummaryTableAction.rejected,
      (state: ExpenditureProfileState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      })
    )
  },
})

export const {
  setSelectedExpenditureProjectData,
  setSelectedExpenditureProject,
  setPopUpCarbonSwitches,
  setShowSummaryPopUp,
  setCarbonSwitches,
  setLoading,
  resetYears,
  reset,
} = expenditureProfileSlice.actions

export const expenditureProfileSelector = (state: RootState) => state.ExpenditureProfile

export default expenditureProfileSlice.reducer
