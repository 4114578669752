import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  getJourneyLegValuesAction,
  postJourneyLegValuesAction,
  deleteJourneyLegValuesAction,
} from "./middleware"
import { ITransportAssumptions, TransportAssumptionsState } from "./types"

const INITIAL_STATE: TransportAssumptionsState = {
  loading: LoadingState.DEFAULT,
  transportAssumption: [],
}

const transportAssumptionSlice = createSlice({
  name: "TransportAssumptions",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    addTransportAssumptionData: (
      state: TransportAssumptionsState,
      { payload }: PayloadAction<ITransportAssumptions>
    ) => ({
      ...state,
      transportAssumption: [...state.transportAssumption, payload],
    }),
    updateTransportAssumptionData: (
      state: TransportAssumptionsState,
      { payload }: PayloadAction<ITransportAssumptions>
    ) => {
      const filteredData = state.transportAssumption.map((item) => {
        if (item.JOURNEY_LEG_CARBON_ID === payload.JOURNEY_LEG_CARBON_ID) return payload
        return item
      })
      return {
        ...state,
        transportAssumption: [...filteredData],
      }
    },
    deleteTransportAssumptionData: (
      state: TransportAssumptionsState,
      { payload }: PayloadAction<number>
    ) => {
      const filterData = state.transportAssumption
      const transportAssumption = filterData.filter(
        (item) => item.JOURNEY_LEG_CARBON_ID !== payload
      )

      return {
        ...state,
        transportAssumption,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJourneyLegValuesAction.pending, (state: TransportAssumptionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getJourneyLegValuesAction.fulfilled,
      (state: TransportAssumptionsState, { payload }: PayloadAction<ITransportAssumptions[]>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        transportAssumption: payload,
      })
    )
    builder.addCase(getJourneyLegValuesAction.rejected, (state: TransportAssumptionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      postJourneyLegValuesAction.fulfilled,
      (state: TransportAssumptionsState, { payload }: PayloadAction<ITransportAssumptions[]>) => ({
        ...state,
        transportAssumption: payload,
      })
    )
    builder.addCase(deleteJourneyLegValuesAction.pending, (state: TransportAssumptionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(deleteJourneyLegValuesAction.rejected, (state: TransportAssumptionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteJourneyLegValuesAction.fulfilled, (state: TransportAssumptionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const {
  setLoading,
  addTransportAssumptionData,
  updateTransportAssumptionData,
  deleteTransportAssumptionData,
} = transportAssumptionSlice.actions

export const transportAssumptionSelector = (state: RootState) => state.TransportAssumptions

export default transportAssumptionSlice.reducer
