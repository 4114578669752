import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { get, isEqual, orderBy } from "lodash"
import { IProject, ProjectState, ISection, IBOQItem } from "./types"
import {
  getProjectByIdAction,
  getSectionByIdAction,
  recalculateCarbonAction,
  getEstimateLevelsAction,
  getBOQItemsAndResourcesAction,
  updateResourcesAction,
  getProjectsAction,
  getItemDetailsAction,
  getResourceDetailsAction,
  deleteBOQProjectResourceAction,
  deleteBOQProjectItemAction,
} from "./middleware"

const INITIAL_STATE: ProjectState = {
  editedIds: [],
  loading: LoadingState.DEFAULT,
  project: undefined,
  section: undefined,
  parentProjectId: undefined,
  projects: [],
  sections: [],
  boqItems: [],
  sectionColumnWiseTotal: {
    A1A3Total: 0,
    A4Total: 0,
    A5aTotal: 0,
    A5wTotal: 0,
    sectionTotalCarbonCost: 1,
    sectionTotalCost: 1,
  },
  boqItemsColumnWiseTotal: {
    A1A3Total: 0,
    A4Total: 0,
    A5aTotal: 0,
    A5wTotal: 0,
    BOQItemTotalCarbonCost: 1,
    BOQItemTotalCost: 1,
  },
  topFiveCarbonSections: [],
  topFiveCarbonBOQItems: [],
  selectedBOQItemForUpdate: undefined,
  selectedBOQResourceForUpdate: undefined,
}

const projectSlice = createSlice({
  name: "Projects",
  initialState: INITIAL_STATE,
  reducers: {
    selectProject: (state, action) => {
      if (!isEqual(state.project, action.payload)) {
        localStorage.removeItem("estimate-sections-grid-state")
      }
      state.project = action.payload
    },
    selectSection: (state, action) => {
      if (!isEqual(state.section, action.payload)) localStorage.removeItem("boq-tree-list-state")
      state.section = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    updateSelectedBOQItemForUpdate: (state, action) => {
      const { payload } = action
      state.selectedBOQItemForUpdate = { ...state.selectedBOQItemForUpdate, ...payload }
    },
    clearSelectedBOQItemForUpdate: (state) => {
      state.selectedBOQItemForUpdate = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getProjectsAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<IProject[]>) => ({
        ...state,
        projects: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(getProjectsAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      getEstimateLevelsAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<ISection[]>) => {
        let totalA1A3 = 0
        let totalA4 = 0
        let totalA5a = 0
        let totalA5w = 0
        let totalCost = 0
        let totalCarbonCost = 0
        let sortedSections: ISection[] = []
        if (payload && payload.length > 0) {
          sortedSections = orderBy(
            payload.map((obj) => {
              obj.PROJECT_SECTION_CARB_A1_A3 = Number(obj.PROJECT_SECTION_CARB_A1_A3) || 0
              obj.PROJECT_SECTION_CARB_A4 = Number(obj.PROJECT_SECTION_CARB_A4) || 0
              obj.PROJECT_SECTION_CARB_A5A = Number(obj.PROJECT_SECTION_CARB_A5A) || 0
              obj.PROJECT_SECTION_CARB_A5W = Number(obj.PROJECT_SECTION_CARB_A5W) || 0
              obj.PROJECT_SECTION_CARB_TOTAL_A1_A5 =
                Number(obj.PROJECT_SECTION_CARB_TOTAL_A1_A5) || 0
              totalA1A3 += Number(obj.PROJECT_SECTION_CARB_A1_A3)
              totalA4 += Number(obj.PROJECT_SECTION_CARB_A4)
              totalA5a += Number(obj.PROJECT_SECTION_CARB_A5A)
              totalA5w += Number(obj.PROJECT_SECTION_CARB_A5W)
              totalCost += Number(obj.PROJECT_SECTION_COST)
              totalCarbonCost += Number(obj.PROJECT_SECTION_CARB_TOTAL_A1_A5)
              return obj
            }),
            ["PROJECT_SECTION_CARB_TOTAL_A1_A5"],
            ["desc"]
          )
        }
        return {
          ...state,
          sectionColumnWiseTotal: {
            A1A3Total: totalA1A3 || 0,
            A4Total: totalA4 || 0,
            A5aTotal: totalA5a || 0,
            A5wTotal: totalA5w || 0,
            sectionTotalCost: totalCost || 0,
            sectionTotalCarbonCost: totalCarbonCost || 0,
          },
          estimatorID: get(sortedSections, "[0].ESTIMATOR_ID"),
          topFiveCarbonSections: sortedSections.slice(0, 5),
          sections: sortedSections,
          loading: LoadingState.DEFAULT,
        }
      }
    )
    builder.addCase(getProjectByIdAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getProjectByIdAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<IProject>) => ({
        ...state,
        project: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(getProjectByIdAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      getSectionByIdAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<ISection>) => ({
        ...state,
        section: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(
      getBOQItemsAndResourcesAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<any>) => {
        let totalA1A3 = 0
        let totalA4 = 0
        let totalA5a = 0
        let totalA5w = 0
        let totalCost = 0
        let totalCarbonCost = 0
        if (payload && payload.BOQ && payload.BOQ.length > 0) {
          payload.BOQ.forEach((obj: IBOQItem) => {
            if (!obj.PROJECT_ITEMAGGR_HIERARCHY.length && !obj.PROJECT_RESOURCE_ID) {
              totalA1A3 += Number(obj.CARB_A1_A3)
              totalA4 += Number(obj.CARB_A4)
              totalA5a += Number(obj.CARB_A5A)
              totalA5w += Number(obj.CARB_A5W)
              totalCost += Number(obj.COST)
              totalCarbonCost += Number(obj.CARB_TOTAL_A1_A5)
            }
          })
        }
        let sortedBOQItems: IBOQItem[] = []
        if (payload && payload.BOQ.length > 0) {
          sortedBOQItems = orderBy(
            payload.BOQ.filter((obj: IBOQItem) => {
              if (
                obj.PROJECT_ITEMAGGR_HIERARCHY &&
                obj.PROJECT_ITEMAGGR_HIERARCHY.length === 0 &&
                obj.CARB_TOTAL_A1_A5 &&
                obj.LEVEL &&
                obj.LEVEL === 1
              ) {
                return true
              }
              return false
            }),
            ["CARB_TOTAL_A1_A5"],
            ["desc"]
          )
        }
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          boqItems: payload.BOQ,
          parentProjectId: payload.PARENT_PROJ_ID,
          isEditable: payload.IS_EDITABLE,
          estimatorID: payload.ESTIMATOR_ID,
          boqItemsColumnWiseTotal: {
            A1A3Total: totalA1A3 || 0,
            A4Total: totalA4 || 0,
            A5aTotal: totalA5a || 0,
            A5wTotal: totalA5w || 0,
            BOQItemTotalCost: totalCost || 0,
            BOQItemTotalCarbonCost: totalCarbonCost || 0,
          },
          topFiveCarbonBOQItems: sortedBOQItems.slice(0, 5),
        }
      }
    )
    builder.addCase(recalculateCarbonAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(recalculateCarbonAction.fulfilled, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(recalculateCarbonAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getEstimateLevelsAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(getEstimateLevelsAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateResourcesAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateResourcesAction.fulfilled, (state: ProjectState, { payload }) => {
      state.loading = LoadingState.DEFAULT
      state.editedIds = [...state.editedIds, payload[0]]
    })
    builder.addCase(updateResourcesAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      getItemDetailsAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        selectedBOQItemForUpdate: Array.isArray(payload) ? payload[0] : undefined,
      })
    )
    builder.addCase(
      getResourceDetailsAction.fulfilled,
      (state: ProjectState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        selectedBOQResourceForUpdate: Array.isArray(payload) ? payload[0] : undefined,
      })
    )
    builder.addCase(deleteBOQProjectItemAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(deleteBOQProjectItemAction.fulfilled, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteBOQProjectItemAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteBOQProjectResourceAction.pending, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(deleteBOQProjectResourceAction.fulfilled, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(deleteBOQProjectResourceAction.rejected, (state: ProjectState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const projectSelector = (state: RootState) => state.Projects

export const {
  setLoading,
  selectProject,
  selectSection,
  clearSelectedBOQItemForUpdate,
  updateSelectedBOQItemForUpdate,
} = projectSlice.actions

export default projectSlice.reducer
