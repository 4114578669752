import { AnyAction, CombinedState, combineReducers } from "redux"
import authReducer from "redux/auth/authSlice"
import emmissionFactorReducer from "redux/emissionfactor/emissionFactorSlice"
import estimatorsRoleReducer from "redux/estimators-roles/estimatorsRoleSlice"
import mastersReducer from "redux/masters/masterSlice"
import profileReducer from "redux/profile/profileSlice"
import projectReducer from "redux/projects/projectSlice"
import rolePermissionReducer from "redux/role-permission/rolePermissionSlice"
import controlPanelReducer from "redux/control-panel/controlPanelSlice"
import iftComparatorReducer from "redux/comparator/iftComparatorSlice"
import fuelTypeReducer from "./basline-assumptions/fuel-type/fuelTypeSlice"
import modeOfTransportSlice from "./basline-assumptions/transport/mode-of-transport/modeOfTransportSlice"
import transportAssumptionsSlice from "./basline-assumptions/transport/transport-assumptions/transportAssumptionsSlice"
import wasteFactorSlice from "./basline-assumptions/waste-factor/wasteFactorSlice"
import estimateLevelReducer from "./estimate-level/estimateLevelSlice"
import costEstimateSummaryReducer from "./cost-estimate-summary/costEstimateSummarySlice"
import expenditureProfileReducer from "./expenditure-profile/expenditureProfileSlice"
import iftReducer from "./ift/iftSlice"
import estimateRevisionManagementReducer from "./estimate-revision-management/estimateRevisionManagementSlice"
import dynamicCarbonLibraryReducer from "./masters/dynamicCarbonLibrarySlice"
import projectEstimateLibrarySlice from "./project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import sharedReducer from "./shared/sharedSlice"
import powerBIReducer from "./powerBI/powerBISlice"

const reducer = combineReducers({
  Auth: authReducer,
  EmissionFactor: emmissionFactorReducer,
  Profile: profileReducer,
  RolePermission: rolePermissionReducer,
  ControlPanel: controlPanelReducer,
  EstimatorsRole: estimatorsRoleReducer,
  Projects: projectReducer,
  Masters: mastersReducer,
  Shared: sharedReducer,
  TransportAssumptions: transportAssumptionsSlice,
  ModeOfTransport: modeOfTransportSlice,
  WasteFactor: wasteFactorSlice,
  FuelTypes: fuelTypeReducer,
  ProjectEstimateLibrary: projectEstimateLibrarySlice,
  EstimateLevel: estimateLevelReducer,
  ExpenditureProfile: expenditureProfileReducer,
  CostEstimateSummary: costEstimateSummaryReducer,
  EstimateRevisionManagement: estimateRevisionManagementReducer,
  DynamicCarbonLibrary: dynamicCarbonLibraryReducer,
  Ift: iftReducer,
  IFTComparator: iftComparatorReducer,
  PowerBI: powerBIReducer,
})

const rootReducer = (state: CombinedState<any> | undefined, action: AnyAction) => {
  if (action.type === "Auth/logout") {
    state = undefined
  }
  return reducer(state, action)
}

export default rootReducer
