import { get } from "lodash"
import { TFunction } from "i18next"
import { formatDate } from "devextreme/localization"

export const expenditureProfileDateCellVal = (rowData: {
  PROJECT_DATE: string | number | Date
}) => {
  return formatDate(new Date(rowData.PROJECT_DATE), "dd/MM/yyyy")
}

export const onExpenditureProfileCellPrepared = (
  e: any,
  t: TFunction<"translation", undefined>
) => {
  const reportNotGenerated = get(e, "row.data.PROJECT_REPORT_GENERATED") === "N"
  if (e?.rowType === "data" && reportNotGenerated) {
    e.cellElement.classList.add("bg-grey")
    e.cellElement.title = t("carbExpProfile.noReportText")
  }
}
