/* eslint-disable import/prefer-default-export */
import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { CostSummaryProject } from "./types"

export const getCostEstimateTableDataAsync = async (id: any) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<CostSummaryProject[]> | ErrorResponse>(
      `/sas-report/cess-report/${id}/detail?format=true`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
