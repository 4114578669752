import { TFunction } from "i18next"

export const estimateLevelBreadCrumb = (t: TFunction<"translation", undefined>) => [
  {
    name: t("estimateLevel.breadCrumbHeading"),
  },
]

export const aggregateOptions = [
  {
    name: "SECTION",
    label: "estimateLevel.aggregateOption1",
  },
  {
    name: "ITEM",
    label: "estimateLevel.aggregateOption2",
  },
  {
    name: "RESOURCE",
    label: "estimateLevel.aggregateOption3",
  },
]
