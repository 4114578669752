import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import { AccessLevelItem, RoleAccessList, RolePermissionLevelItem, SelectRole } from "./types"

export const fetchRolePermissionAsync = async () => {
  try {
    const response = await axiosInstance.get(`/role`)

    return response.data as SelectRole[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchRoleAccessListAsync = async (): Promise<
  SuccessResponse<RoleAccessList[]> | ErrorResponse
> => {
  try {
    const response = await axiosInstance.get<SuccessResponse<RoleAccessList[]> | ErrorResponse>(
      `/accessList`
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchAccessListItemsAsync = async (): Promise<
  SuccessResponse<AccessLevelItem[]> | ErrorResponse
> => {
  try {
    const response = await axiosInstance.get<SuccessResponse<AccessLevelItem[]> | ErrorResponse>(
      `/accessLevel`
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchRoleAndPermissionByRoleIdAsync = async (
  roleId: number
): Promise<SuccessResponse<RolePermissionLevelItem[]> | ErrorResponse> => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<RolePermissionLevelItem[]> | ErrorResponse
    >(`/roleAccess/${roleId}`)

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updatePermissionLevelForRoleAsync = async (request: RolePermissionLevelItem) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/roleAccess`,
      [request]
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
