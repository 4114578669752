/* eslint-disable import/prefer-default-export */
export const passwordRules = [
  {
    id: 1,
    rule: "pwdMinLngthCheck",
    ruleFn: (val: string) => val.length > 7,
    pass: false,
  },
  {
    id: 2,
    rule: "pwdUpperCaseCheck",
    ruleFn: (val: string) => /[A-Z]/.test(val),
    pass: false,
  },
  {
    id: 3,
    rule: "pwdLowerCaseCheck",
    ruleFn: (val: string) => /[a-z]/.test(val),
    pass: false,
  },
  {
    id: 4,
    rule: "pwdNumCheck",
    ruleFn: (val: string) => /\d/.test(val),
    pass: false,
  },
  {
    id: 5,
    rule: "pwdSpecialCharCheck",
    ruleFn: (val: string) => /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(val),
    pass: false,
  },
]
