import React, { useState, useCallback, useRef } from "react"
import { useNavigate } from "react-router"
import { get } from "lodash"
import Drawer from "devextreme-react/drawer"
import { ClickEvent } from "devextreme/ui/button"
import ScrollView from "devextreme-react/scroll-view"
import { ItemClickEvent } from "devextreme/ui/tree_view"
import { Template } from "devextreme-react/core/template"
import Header from "components/Header"
import Footer from "components/Footer"
import SideNavigationMenu from "components/SideNavigationMenu"
import { useNavigation } from "contexts/navigation"
import { SideNavToolbarProps } from "types/AppNav"
import { useScreenSize } from "utils/media-query"
import { useMenuPatch } from "utils/patches"
import "./outer-toolbar.scss"

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
}

export default function SideNavOuterToolbar({
  title,
  children,
}: React.PropsWithChildren<SideNavToolbarProps>) {
  const navigate = useNavigate()
  const scrollViewRef = useRef<ScrollView>(null)
  const { isXSmall, isLarge } = useScreenSize()
  const [patchCssClass, onMenuReady] = useMenuPatch()
  const [menuStatus, setMenuStatus] = useState(isLarge ? MenuStatus.Opened : MenuStatus.Closed)

  const {
    navigationData: { currentPath },
  } = useNavigation()

  const toggleMenu = useCallback(({ event }: ClickEvent) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed
    )
    event?.stopPropagation()
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus
    )
  }, [])

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus
    )
    return menuStatus === MenuStatus.Closed
  }, [isLarge])

  const onNavigationChanged = useCallback(
    ({ itemData, event, node }: ItemClickEvent) => {
      if (
        menuStatus === MenuStatus.Closed ||
        !itemData?.path ||
        (node?.selected && get(node, "itemData.path") === currentPath)
      ) {
        event?.preventDefault()
        return
      }

      navigate(itemData?.path)
      scrollViewRef.current?.instance.scrollTo(0)

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed)
        event?.stopPropagation()
      }
    },
    [navigate, menuStatus, currentPath, isLarge]
  )

  return (
    <div className="side-nav-outer-toolbar">
      <Header menuToggleEnabled toggleMenu={toggleMenu} title={title} />
      <Drawer
        className={["drawer", patchCssClass].join(" ")}
        position="before"
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? "shrink" : "overlap"}
        revealMode={isXSmall ? "slide" : "expand"}
        minSize={isXSmall ? 0 : 60}
        maxSize={280}
        shading={!isLarge}
        opened={menuStatus !== MenuStatus.Closed}
        template="menu"
      >
        <div>
          <ScrollView ref={scrollViewRef} className="layout-body with-footer">
            <div className="content">
              {React.Children.map(children, (item: any) => item.type !== Footer && item)}
            </div>
            <div className="content-block">
              {React.Children.map(children, (item: any) => item.type === Footer && item)}
            </div>
          </ScrollView>
        </div>
        <Template name="menu">
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          />
        </Template>
      </Drawer>
    </div>
  )
}
