import { memo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "devextreme-react"
import DataGrid, { Column, FilterRow, Item, SearchPanel, Toolbar } from "devextreme-react/data-grid"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { handleResetFilter } from "utils/common-utils"
import { getCarbonSwitchValue, renderExpdtreReportCurrency } from "../../utils"
import TotalCarbonHeaderCellRender from "../expenditureProfileReportTable/totalCarbonHeaderCellRender"

const SummaryTable = () => {
  const { t } = useTranslation()
  const dataGridRef = useRef<DataGrid>(null)

  const { expenditureSummaryData, popUpCarbonSwitches, forecastYears } = useSelector(
    expenditureProfileSelector
  )

  return (
    <DataGrid
      id="expenditure-profile-container"
      ref={dataGridRef}
      dataSource={expenditureSummaryData}
      showBorders
      showColumnLines
      columnAutoWidth
      hoverStateEnabled
      allowColumnResizing
    >
      <FilterRow visible />
      <SearchPanel visible width={340} />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column caption={t("carbExpProfileReport.sectionCostElement")} dataField="TITLE" fixed />

      {/* Historic */}
      <Column alignment="center" caption={t("carbExpProfileReport.historicCosts")}>
        <Column
          caption={t("carbExpProfileReport.cost")}
          dataField="HISTORIC_COST"
          cellRender={renderExpdtreReportCurrency}
        />
        <Column caption={t("carbExpProfileReport.totala1a5")} dataField="HISTORIC_TOTAL_A1_A5" />
      </Column>

      {/* Forecast years */}
      {forecastYears.map((item) => (
        <Column alignment="center" caption={`FY ${item}`} key={item}>
          <Column
            caption={t("carbExpProfileReport.cost")}
            dataField={`${item}_COST`}
            cellRender={renderExpdtreReportCurrency}
          />
          <Column
            caption={t("carbExpProfileReport.a1a3")}
            dataField={`${item}_COST_A1_A3`}
            visible={getCarbonSwitchValue(popUpCarbonSwitches, item)}
          />
          <Column
            caption={t("carbExpProfileReport.a4")}
            dataField={`${item}_COST_A4`}
            visible={getCarbonSwitchValue(popUpCarbonSwitches, item)}
          />
          <Column
            caption={t("carbExpProfileReport.a5a")}
            dataField={`${item}_COST_A5A`}
            visible={getCarbonSwitchValue(popUpCarbonSwitches, item)}
          />
          <Column
            caption={t("carbExpProfileReport.a5w")}
            dataField={`${item}_COST_A5W`}
            visible={getCarbonSwitchValue(popUpCarbonSwitches, item)}
          />
          <Column
            caption={t("carbExpProfileReport.totala1a5")}
            dataType="number"
            dataField={`${item}_COST_TOTAL_A1_A5`}
            headerCellRender={(data) =>
              TotalCarbonHeaderCellRender({
                t,
                data,
                popUpCarbonSwitches,
                key: item,
              })
            }
          />
        </Column>
      ))}

      {/* Total */}
      {/* <Column alignment="center" caption={t("carbExpProfileReport.total")}>
        <Column />
        <Column />
      </Column> */}
    </DataGrid>
  )
}

export default memo(SummaryTable)
