import { useNavigate } from "react-router"
import Button from "devextreme-react/button"
import Toolbar, { Item } from "devextreme-react/toolbar"
import { Template } from "devextreme-react/core/template"
import UserPanel from "components/UserPanel"
import { HeaderProps } from "types/AppNav"
import titleLogo from "assets/icons/CCFTLogo.png"
import "./Header.scss"

const Header: React.FC<HeaderProps> = ({ menuToggleEnabled, title, toggleMenu }: HeaderProps) => {
  const navigate = useNavigate()

  const onTitleClick = () => navigate("/home")

  return (
    <header className="header-component">
      <Toolbar className="header-toolbar">
        <Item
          visible={menuToggleEnabled}
          location="before"
          widget="dxButton"
          cssClass="menu-button"
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location="before" cssClass="header-title" text={title} visible={!!title}>
          <Button stylingMode="text" onClick={onTitleClick}>
            <img src={titleLogo} alt="logo" />
            {title}
          </Button>
        </Item>
        <Item location="after" locateInMenu="auto" menuItemTemplate="userPanelTemplate">
          <Button
            className="user-button authorization"
            width={210}
            height="100%"
            stylingMode="text"
          >
            <UserPanel menuMode="context" />
          </Button>
        </Item>
        <Template name="userPanelTemplate">
          <UserPanel menuMode="list" />
        </Template>
      </Toolbar>
    </header>
  )
}

export default Header
