import { memo } from "react"
import { LoadPanel } from "devextreme-react"
import { useSelector } from "react-redux"
import { estimateLevelSelector } from "redux/estimate-level/estimateLevelSlice"
import { LoadingState } from "types/AppNav"

const EstimateLevelLoader = () => {
  const { loading } = useSelector(estimateLevelSelector)
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loading === LoadingState.LOADING}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(EstimateLevelLoader)
