import { Button } from "devextreme-react"
import { setSelectedDynamicCarbon, setShowEPDPopUp } from "redux/masters/dynamicCarbonLibrarySlice"
import { myUtils } from "utils/common-utils"

const renderViewEPD = (row: any) => {
  const { dispatch } = myUtils

  const onClick = () => {
    dispatch(setSelectedDynamicCarbon(row.data))
    dispatch(setShowEPDPopUp())
  }

  return <Button className="active" stylingMode="contained" text="View EPD" onClick={onClick} />
}

export default renderViewEPD
