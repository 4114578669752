import { get } from "lodash"
import { formatNumber } from "devextreme/localization"
import { IBOQItem } from "redux/projects/types"
import { config } from "utils/config"

export interface UpdateItemTableProps {
  popUpClose: () => void
  rowData?: IBOQItem
}

export const currencyConverter = (val: any) => {
  return formatNumber(val || 0, {
    currency: config.clientName === "HE" ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

export const onUpdateItemTableCellPrepared = (e: any) => {
  const arr = ["CODE", "DESCRIPTION", "UNIT_DESCRIPTION", "PROJECT_ITEM_RATE", "RATE"]
  if (e.rowType === "data" && arr.includes(e.column.dataField)) {
    e.cellElement.classList.add("bg-grey")
  }
}

export const tableUpdateKeys = [
  "PROJECT_ITEM_CARB_A1_A3_FACTOR",
  "PROJECT_ITEM_CARB_A4_FACTOR",
  "PROJECT_ITEM_CARB_A5A_FACTOR",
  "PROJECT_ITEM_CARB_A5W_FACTOR",
  "PROJECT_ITEM_CARB_WASTE_FACTOR",
  "PROJECT_ITEM_COST",
  "PROJECT_ITEM_ID",
  "PROJECT_ITEM_QTY",
]

export const renderUpdateItemTableRateCell = (item: any) => {
  const val =
    !!get(item, "data.PROJECT_ITEM_COST") && !!get(item, "data.PROJECT_ITEM_QTY")
      ? currencyConverter(
          Number(get(item, "data.PROJECT_ITEM_COST") / get(item, "data.PROJECT_ITEM_QTY"))
        )
      : null
  return val
}

export const renderUpdateItemTableCostCell = (item: any) => {
  return get(item, "data.PROJECT_ITEM_COST")
    ? currencyConverter(Number(get(item, "data.PROJECT_ITEM_COST")))
    : null
}
