import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { SelectBox } from "devextreme-react"
import {
  rolePermissionSelector,
  updateRolePermission,
} from "redux/role-permission/rolePermissionSlice"
import { fetchRolePermissionAction } from "redux/role-permission/middleware"

function RollPermissionHeader() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { roleList, permission } = useSelector(rolePermissionSelector)

  useEffect(() => {
    if (!roleList || !roleList.length) {
      dispatch(fetchRolePermissionAction())
    }
  }, [roleList])

  return (
    <div className="estimateLevelHeader dx-card">
      <div className="dropdown">
        <label>{t("rolePermission.headerDropdown1Label")}</label>
        {roleList && permission ? (
          <SelectBox
            dataSource={roleList}
            valueExpr="ROLE_ID"
            displayExpr="ROLE_NAME"
            value={permission.ROLE_ID}
            onValueChanged={({ value }) => dispatch(updateRolePermission(value))}
          />
        ) : null}
      </div>
    </div>
  )
}

export default RollPermissionHeader
