import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { ErrorResponse } from "services/SuccessResponse"
import {
  getJourneyLegValuesAsync,
  postJourneyLegValuesAsync,
  deleteJourneyLegValuesAsync,
} from "./services"
import { ITransportAssumptions } from "./types"

export const getJourneyLegValuesAction = createAsyncThunk<any>(
  "getJourneyLegValuesAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getJourneyLegValuesAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const postJourneyLegValuesAction = createAsyncThunk<any, ITransportAssumptions[]>(
  "postJourneyLegValuesAsync",
  async (request: ITransportAssumptions[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await postJourneyLegValuesAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Journey Leg updated successfully", "success", 2000)
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const deleteJourneyLegValuesAction = createAsyncThunk<any, number[]>(
  "delteJourneyLegValuesAsync",
  async (request: number[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await deleteJourneyLegValuesAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Journey Leg data deleted successfully", "success", 2000)
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
