/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createContext, useContext, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import notify from "devextreme/ui/notify"
import { profileSelector } from "redux/profile/profileSlice"
import type { NavigationContextType } from "types/AppNav"
import { identifyPermissions } from "utils/common-utils"

const NavigationContext = createContext<NavigationContextType>({} as NavigationContextType)
const useNavigation = () => useContext(NavigationContext)

const NavigationProvider = (props: React.PropsWithChildren<unknown>) => {
  const [navigationData, setNavigationData] = useState({ currentPath: "" })
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />
  )
}

function withNavigationWatcher(
  Component: React.ElementType,
  path: string,
  defaultProps?: Record<string, any>
) {
  const WrappedComponent = (props: Record<string, any>) => {
    const { setNavigationData } = useNavigation()
    const { role = [], permissionLevel = 0, accessType = 0 } = props

    const { t } = useTranslation()
    const routeNavigate = useNavigate()
    const { userRole } = useSelector(profileSelector)

    useEffect(() => {
      setNavigationData?.({ currentPath: path })
    }, [setNavigationData, path])

    useEffect(() => {
      if (userRole && role.length && permissionLevel && accessType) {
        if (
          !identifyPermissions(
            userRole,
            accessType as number,
            permissionLevel as number | number[],
            role as number[]
          )
        ) {
          notify(t("permissionDenied"), "error", 2000)
          routeNavigate("/home", { replace: true })
        }
      }
    }, [userRole])

    return <Component {...props} />
  }
  return <WrappedComponent {...defaultProps} />
}

export { NavigationProvider, useNavigation, withNavigationWatcher }
