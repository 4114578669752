import { memo } from "react"
import { LoadPanel } from "devextreme-react"
import { useSelector } from "react-redux"
import { dynamicCarbonSelector } from "redux/masters/dynamicCarbonLibrarySlice"
import { LoadingState } from "types/AppNav"

const DynamicCarbonLoader = () => {
  const { loading } = useSelector(dynamicCarbonSelector)
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loading === LoadingState.LOADING}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(DynamicCarbonLoader)
