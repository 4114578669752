import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"
import BreadCrumb from "components/bread-crumb"
import { useAppDispatch } from "redux/store"
import { updateRolePermission } from "redux/role-permission/rolePermissionSlice"
import RollPermissionTable from "./components/rollPermissionTable"
import RollPermissionHeader from "./components/rolePermissionHeader"
import rolePermissionBreadCrumb from "./utils"

const RolePermission: React.FC = (): JSX.Element => {
  useDocumentTitle("Role & Permissions | Cost and Carbon Forecasting tool")

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateRolePermission(1))
  }, [])

  return (
    <div className="estimateLevelMain">
      <BreadCrumb data={rolePermissionBreadCrumb(t)} />
      <RollPermissionHeader />
      <RollPermissionTable />
    </div>
  )
}

export default RolePermission
