import { TFunction } from "i18next"
import { get } from "lodash"
import { custom } from "devextreme/ui/dialog"
import { formatNumber } from "devextreme/localization"
import SubItemIcon from "assets/icons/SubItem.png"
import ItemTextIcon from "assets/icons/ItemText.png"
import ResourceItemIcon from "assets/icons/Resource.png"
import NormalItemIcon from "assets/icons/NormalItem.png"
import ItemVariableIcon from "assets/icons/ItemVariable.png"
import CompositeItemIcon from "assets/icons/CompositeItem.png"
import CompositeTotalIcon from "assets/icons/CompositeTotal.png"
import { UserRole } from "redux/profile/types"
import { IBOQItem, IProject, ISection } from "redux/projects/types"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { addClass, allRoles, camelToKebab, identifyPermissions } from "utils/common-utils"
import { config } from "utils/config"

export const boqBreadCrum = (
  t: TFunction<"translation", undefined>,
  project: IProject | undefined,
  section: ISection | undefined
) => {
  const projectId = get(project, "Id")

  return [
    {
      name: t("boq.estimate"),
      link: "/projects",
      title: project?.Title,
    },
    {
      name: t("boq.estimateSections"),
      link: `/project/${projectId}/sections`,
      title: section?.PROJECT_SECTION_DESC,
    },
  ]
}

export const boqSummaryChipsGenerator = (
  t: TFunction<"translation", undefined>,
  boqItemsColumnWiseTotal: any,
  userRole: UserRole | null,
  estimatorID: number | undefined
) => {
  const permsnCheck = identifyPermissions(
    userRole,
    AccessType.SHOW_COST_DATA,
    AccessLevel.YES,
    allRoles
  )
  const contractorCondition = userRole?.ESTIMATOR_ID === estimatorID
  const currencySignCheck = config.clientName === "HE"
  const currency = currencySignCheck ? "(£)" : "($)"
  const val = formatNumber(boqItemsColumnWiseTotal?.BOQItemTotalCost, {
    currency: currencySignCheck ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })

  return [
    {
      key: 1,
      label: `${t("boq.totalCost")} ${currency}`, // Rendering (&#163;) will give (£)
      value:
        userRole?.ROLE_ID !== RoleType.CONTRACTOR
          ? permsnCheck && val
          : contractorCondition && permsnCheck && val,
    },
    {
      key: 2,
      label: t("boq.totalCarbonLabel"),
      value: formatNumber(boqItemsColumnWiseTotal?.BOQItemTotalCarbonCost, {
        precision: 2,
        type: "fixedPoint",
      }),
    },
  ]
}

export const onBOQRowPrepared = (e: any, addedIds: any[], editedIds: any[]) => {
  if (e && e.rowType === "data") {
    const { rowElement, data } = e
    if (data.PROJECT_ITEM_COMPOSITETYPE) {
      addClass(rowElement, camelToKebab(data.PROJECT_ITEM_COMPOSITETYPE))
    }
    if (editedIds.includes(data.PROJECT_RESOURCE_ID)) addClass(rowElement, "bg-added")
    if (addedIds.includes(data.ID) || addedIds.includes(data.Parent_ID)) {
      addClass(rowElement, "bg-added")
    }
  }
}

const cellDescriptionProps = (compositeItemType: string) => {
  let obj = {
    icon: ItemTextIcon,
    alt: "",
    title: "Text Item",
    labelStyles: {
      color: "",
      fontWeight: "bold",
      fontStyle: "",
    },
  }
  switch (compositeItemType) {
    case "CompositeTotal":
      obj = {
        icon: CompositeTotalIcon,
        alt: "Composite Total",
        title: "Composite Total",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "NormalItem":
      obj = {
        icon: NormalItemIcon,
        alt: "Normal Item",
        title: "Normal Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "CompositeItem":
      obj = {
        icon: CompositeItemIcon,
        alt: "Composite Item",
        title: "Composite Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ItemVariable":
      obj = {
        icon: ItemVariableIcon,
        alt: "Item Variable",
        title: "Item Variable",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ResourceItem":
      obj = {
        icon: ResourceItemIcon,
        alt: "Resource Item",
        title: "Resource",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "SubItem":
      obj = {
        icon: SubItemIcon,
        alt: "Sub Item",
        title: "Sub Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    default:
      break
  }
  return obj
}

export const boqCellDescriptionRender = (row: any) => {
  const { icon, alt, title, labelStyles } = cellDescriptionProps(
    row.data.PROJECT_ITEM_COMPOSITETYPE
  )
  return (
    <div className="cell-description">
      <img src={icon} alt={alt} title={title} />
      <label style={labelStyles} title={row.value}>
        {row.value}
      </label>
    </div>
  )
}

export const boqConfirmationDialog = (msg: string) => {
  return custom({
    showTitle: false,
    messageHtml: `<div class="p-2">Are you sure you want to remove this ${msg}?</div>`,
    buttons: [
      {
        text: "Yes",
        onClick: () => true,
      },
      {
        text: "No",
        onClick: () => false,
      },
    ],
  })
}

export const getMultiInstance = (row: any, boqItems: IBOQItem[]) => {
  const filteredData = boqItems.filter(
    (item: any) => item.PROJECT_ITEM_COMPOSITETYPE === "SubItem" && item.CODE === row?.CODE
  )
  return filteredData
}

export const zeroNullCarbCnfrmDialog = (t: TFunction<"translation", undefined>) => {
  return custom({
    showTitle: false,
    messageHtml: `<div class="p-2">${t("boq.null0msg")}</div>`,
    buttons: [
      {
        text: "Yes",
        onClick: () => true,
      },
      {
        text: "No",
        onClick: () => false,
      },
    ],
  })
}
