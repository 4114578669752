/* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import {
  dynamicCarbonSelector,
  setSelectedDynamicCarbon,
  setShowEPDPopUp,
} from "redux/masters/dynamicCarbonLibrarySlice"
import EPD from "components/EPDPopup"

const ViewEPDPopUp = () => {
  const dispatch = useAppDispatch()
  const { selectedDynamicCarbon, showEPDPopUp } = useSelector(dynamicCarbonSelector)

  const onClose = () => {
    dispatch(setSelectedDynamicCarbon({}))
    dispatch(setShowEPDPopUp())
  }

  return (
    <EPD
      showEPDPopup={showEPDPopUp}
      epdPopupCallback={() => {}}
      setShowEPDPopup={(data) => onClose()}
      epdId={selectedDynamicCarbon?.EPD_ID}
      allowUpdate={false}
    />
  )
}

export default ViewEPDPopUp
