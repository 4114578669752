import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { getProjectByIdAction } from "redux/projects/middleware"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { reset, setIftRoleBasedPermissions } from "redux/ift/iftSlice"
import { getEstimateRevisionByIdAction } from "redux/estimate-revision-management/middleware"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { allRoles, identifyPermissions } from "utils/common-utils"
import useDocumentTitle from "hooks/useDocumentTitle"
import IFTContent from "./components/iftBody"
import IFTHeaderTabs from "./components/iftHeaderTabs"
import { iftBreadCrumb } from "./utils"
import "./ift.scss"

const IFTScreen: React.FC = (): JSX.Element => {
  useDocumentTitle("IFT | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectId, estimateId } = useParams()

  const { project } = useSelector(projectSelector)
  const { userRole } = useSelector(profileSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)

  useEffect(() => {
    if (userRole) {
      const permission = {
        deleteResourcesOrItems: identifyPermissions(
          userRole,
          AccessType.DELETE_ITEMS_RESOURCES,
          AccessLevel.YES,
          allRoles
        ),
        editResources: identifyPermissions(
          userRole,
          AccessType.EDIT_RESOURCES_FROM_REVISION,
          AccessLevel.YES,
          allRoles
        ),
        editItems: identifyPermissions(
          userRole,
          AccessType.EDIT_ITEMS_FROM_REVISION,
          AccessLevel.YES,
          allRoles
        ),
        addResourcesOrItems: identifyPermissions(
          userRole,
          AccessType.ADD_ITEMS_RESOURCES,
          AccessLevel.YES,
          allRoles
        ),
      }
      dispatch(setIftRoleBasedPermissions(permission))
    }
  }, [userRole])

  useEffect(() => {
    if (projectId || estimateId) {
      dispatch(
        reset({ projectId: Number(projectId), estimateId: estimateId ? Number(estimateId) : 0 })
      )
      if (!project) dispatch(getProjectByIdAction(projectId))
      if (!selectedRevision && !!estimateId)
        dispatch(getEstimateRevisionByIdAction({ projectId: estimateId }))
    }
  }, [projectId, estimateId])

  return (
    <div className="iftMain">
      <BreadCrumb data={iftBreadCrumb(t, project, selectedRevision)} />
      <IFTHeaderTabs />
      <IFTContent />
    </div>
  )
}

export default IFTScreen
