import { TFunction } from "i18next"
import { config } from "utils/config"

export const iftLevelBreadCrumb = (t: TFunction<"translation", undefined>) => [
  {
    name: t("iftComparatorMain.breadCrumbHeading"),
  },
]

export const nominatedRevisionConfirmationPopUPProps = {
  title: "Confirmation",
  showTitle: true,
  messageHtml: "Are you sure you want Nominate this as Preferred Revision?",
  buttons: [
    {
      text: "Yes",
      onClick: () => true,
    },
    {
      text: "No",
      onClick: () => false,
    },
  ],
}

export const BEHAVIOURS: any = {
  NEUTRAL: "#ffffc5",
  NEGATIVE: "#f9a1a1",
  POSITIVE: "#d0ffd0",
}

const currencySignCheck = config.clientName === "HE"
export const compCurrencySign = currencySignCheck ? "£" : "$"
