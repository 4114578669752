import axiosInstance, { isAxiosError } from "services/api"

export const fetchAssignInflationIndexAsync = async () => {
  try {
    const response = await axiosInstance.get(`/admin/inflation-sheet-index-access`)
    return response.data as any[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateAssignInflationIndexAsync = async (request: any) => {
  try {
    const response = await axiosInstance.post(`/admin/inflation-sheet-index-access`, {
      bulk: request,
    })
    return response.data as any[]
  } catch (err) {
    return isAxiosError(err)
  }
}
