/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { TFunction } from "i18next"

export const dynamicCarbonBreadCrumb = (t: TFunction<"translation", undefined>) => {
  return [
    {
      name: t("dynamicCarbonLibrary.breadCrumbHeading"),
    },
  ]
}
