import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"
import BreadCrumb from "components/bread-crumb"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { getExpenditureProfileProjectsAction } from "redux/expenditure-profile/middleware"
import ExpenseProfileTable from "./components/expenditureProfileTable"
import ExpenseProfileLoader from "./components/expenditureProfileLoader"
import { expenditureProfileBreadCrumb } from "./utils"
import "./expenditure-profile.scss"

const CarbonExpenseProfile: React.FC = (): JSX.Element => {
  useDocumentTitle("Cost and Carbon Expenditure Profile | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { expenditureProfileProjects } = useSelector(expenditureProfileSelector)

  useEffect(() => {
    if (!expenditureProfileProjects.length) dispatch(getExpenditureProfileProjectsAction())
  }, [])

  return (
    <div className="expenditureProfileMain">
      <ExpenseProfileLoader />
      <BreadCrumb data={expenditureProfileBreadCrumb(t)} />
      <ExpenseProfileTable />
    </div>
  )
}

export default CarbonExpenseProfile
