import { exportDataGrid } from "devextreme/excel_exporter"
import { formatNumber } from "devextreme/localization"
import dxDataGrid, { CellPreparedEvent } from "devextreme/ui/data_grid"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import { config } from "utils/config"

export const exportPreviousEstimate = (gridInstance?: dxDataGrid<any, any>) => {
  if (!gridInstance) {
    return
  }
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("PreviousEstimate")
  worksheet.protect("1234", {})
  exportDataGrid({
    component: gridInstance,
    worksheet,
    customizeCell({ gridCell, excelCell }) {
      if (gridCell?.rowType === "header") {
        excelCell.alignment = { wrapText: true }
        excelCell.font = { color: { argb: "FFFFFF" }, bold: true }
        excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "30363D" } }
      } else if (gridCell?.rowType === "data") {
        excelCell.alignment = { horizontal: "center", wrapText: true }
        // for formating....
        if (gridCell.data.ROW_TYPE === "SECTION_HEADER") {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } }
          excelCell.font = { bold: true }
        } else if (gridCell.data.ROW_TYPE === "ORG_TOTAL") {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A9A9A9" } }
          excelCell.font = { bold: true }
        } else if (gridCell.data.ROW_TYPE === "BASE_COST_ESTIMATE") {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "466D1D" } }
          excelCell.font = { bold: true }
        } else if (gridCell.data.ROW_TYPE === "THIRD_PARTY_CONTRIBUTION_TOTAL") {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "F8DE7E" } }
        } else if (gridCell.data.ROW_TYPE === "NON_RECOVERABLE_VAT") {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFDD0" } }
        } else if (
          gridCell.data.ROW_TYPE === "PERCENTAGE" &&
          gridCell.column?.dataField === "BASE_ML" &&
          (gridCell.data.TITLE === "VAT" ||
            gridCell.data.TITLE === "Contractor Fee" ||
            gridCell.data.TITLE === "NR VAT")
        ) {
          excelCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FDA50F" } }
        }

        if (gridCell.data.ROW_TYPE === "SECTION_HEADER" && gridCell.column?.dataField !== "TITLE") {
          excelCell.value = ""
        } else if (gridCell.data.ROW_TYPE === "PERCENTAGE") {
          if (gridCell.value) {
            excelCell.value = formatNumber(gridCell.value || 0, { type: "percent" })
          }
        } else if (
          gridCell.column?.dataField === "TITLE" ||
          gridCell.column?.dataField === "COMMENT"
        ) {
          excelCell.value = gridCell.value ? gridCell.value : ""
        } else {
          excelCell.value = formatNumber(Number(gridCell.value) || 0, {
            currency: config.clientName === "HE" ? "GBP" : "USD",
            precision: 4,
            type: "currency",
          })
        }
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), `PreviousEstimate.xlsx`)
    })
  })
}

export const renderPreviousEstimateDataCell = (cellInfo: any) => {
  if (cellInfo.data.ROW_TYPE === "SECTION_HEADER") return null

  if (cellInfo.data.ROW_TYPE === "PERCENTAGE") {
    return formatNumber(cellInfo.value, { type: "percent" })
  }

  return formatNumber(cellInfo.value || 0, {
    currency: config.clientName === "HE" ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

export const onCellPrepared = (e: CellPreparedEvent) => {
  if (e.rowType === "data") {
    if (e.data.ROW_TYPE === "SECTION_HEADER") {
      e.cellElement.classList.add("headerCellBg")
    } else if (e.data.ROW_TYPE === "ORG_TOTAL") {
      e.cellElement.classList.add("orgTotalBg")
    } else if (e.data.ROW_TYPE === "BASE_COST_ESTIMATE") {
      e.cellElement.classList.add("baseCostEstimageBg")
    } else if (e.data.ROW_TYPE === "THIRD_PARTY_CONTRIBUTION_TOTAL") {
      e.cellElement.classList.add("thirdPartyContributionBg")
    } else if (e.data.ROW_TYPE === "NON_RECOVERABLE_VAT") {
      e.cellElement.classList.add("nonRecoverableVatBg")
    } else if (
      e.data.ROW_TYPE === "PERCENTAGE" &&
      e.column.dataField === "BASE_ML" &&
      (e.data.TITLE === "VAT" || e.data.TITLE === "Contractor Fee" || e.data.TITLE === "NR VAT")
    ) {
      e.cellElement.classList.add("vatBg")
    }
  }
}
