export const tableHeaderArr = [
  "Resource Carbon ID",
  "Resource Library ID",
  "Code",
  "Description",
  "Unit",
  "Weight (kg)",
  "A1-A3 GWP KgCO2e/unit (ML)",
  // "Transport Assumption",
  "A4 GWP KgCO2e/unit (ML)",
  "A5w GWP KgCO2e/unit (ML)",
  "A5a GWP KgCO2e/unit (ML)",
  "Total A1-A5",
  "Waste Factor (%)",
  "Notes",
  "Resource Category",
  "Resource Group",
  "Resource Type",
]

export const rowDataGeneratorForResourceTable = (dataArr: any, transportAssumptions: any) => {
  const item = {
    RES_CARB_ID: null,
    RESOURCE_LIBRARY_ID: null,
    RESOURCE_LIBRARY_CODE: "",
    RESOURCE_LIBRARY_DESC: "",
    UNIT_DESC: "",
    RES_CARB_WEIGHTPERUNIT: 0,
    RES_CARB_A1_A3: 0,
    RES_CARB_JOURNEY_LEG: "",
    RES_CARB_A4: 0,
    RES_CARB_A5W: 0,
    RES_CARB_A5A: 0,
    TOTAL_CARBON: 0,
    RES_CARB_WASTEFACTOR: 0,
    RES_CARB_NOTE: "",
    RESOURCE_CATEGORY_DESC: "",
    RESOURCE_GROUP_DESC: "",
    RESOURCE_TYPE_DESC: "",
  }
  const numberFields = [
    "RES_CARB_A1_A3",
    "RES_CARB_A4",
    "RES_CARB_A5W",
    "TOTAL_CARBON",
    "RES_CARB_WEIGHTPERUNIT",
    "RES_CARB_WASTEFACTOR",
  ]
  const unparsableData: any = []
  const data = Object.keys(item).reduce((acc, curr, idx) => {
    let value: any = parseFloat(dataArr[idx])
    if (value || value === 0) value = parseFloat(dataArr[idx])
    else if (numberFields.includes(curr)) value = 0
    else value = dataArr[idx]

    if (dataArr[idx] && numberFields.includes(curr) && Number.isNaN(Number(dataArr[idx]))) {
      unparsableData.push(dataArr[idx])
    }

    if (curr === "RES_CARB_JOURNEY_LEG" && dataArr[idx]) {
      value =
        transportAssumptions?.find(
          (item: any) => item.TRANSPORT_CATEGORY_NAME.trim() === dataArr[idx]?.toString()?.trim()
        )?.JOURNEY_LEG_CARBON_ID || ""
    }

    return { ...acc, [curr]: value }
  }, item)
  return {
    data,
    unparsableData,
  }
}

export const itemEmissionTableHeaders = [
  "Item Library ID",
  "Code",
  "Description",
  "Unit",
  "Weight (kg)",
  "A1-A3 GWP KgCO2e/unit (ML)",
  "Transport Assumption",
  "A4 GWP KgCO2e/unit (ML)",
  "A5w GWP KgCO2e/unit (ML)",
  "Total A1-A5",
  "Waste Factor (%)",
  "Notes",
  "Item Group",
]

export const rowDataGeneratorForItemEmissionTable = (dataArr: any, transportAssumptions: any) => {
  const item = {
    ITEM_LIBRARY_ID: null,
    ITEM_LIBAGGR_CODE: "",
    ITEM_LIBAGGR_DESC: "",
    UNIT_DESC: "",
    ITEM_LIBRARY_CARB_WEIGHTPERUNIT: 0,
    ITEM_LIBRARY_CARB_A1_A3: 0,
    ITEM_LIBRARY_CARB_JOURNEY_LEG: "",
    ITEM_LIBRARY_CARB_A4: 0,
    ITEM_LIBRARY_CARB_A5W: 0,
    TOTAL_CARBON: 0,
    ITEM_LIBRARY_CARB_WASTEFACTOR: 0,
    ITEM_LIBRARY_CARB_NOTE: "",
    ITEM_GROUP_DESC: "",
  }
  return Object.keys(item).reduce((acc, curr, idx) => {
    let value: any = parseFloat(dataArr[idx])
    if (value || value === 0) value = parseFloat(dataArr[idx])
    else {
      const numberFields = [
        "ITEM_LIBRARY_CARB_A1_A3",
        "ITEM_LIBRARY_CARB_A4",
        "ITEM_LIBRARY_CARB_A5W",
        "TOTAL_CARBON",
        "ITEM_LIBRARY_CARB_WEIGHTPERUNIT",
        "ITEM_LIBRARY_CARB_WASTEFACTOR",
      ]
      if (numberFields.includes(curr)) value = 0
      else value = dataArr[idx]
    }
    if (curr === "ITEM_LIBRARY_CARB_JOURNEY_LEG" && dataArr[idx]) {
      value =
        transportAssumptions?.find(
          (item: any) => item.TRANSPORT_CATEGORY_NAME.trim() === dataArr[idx]?.toString()?.trim()
        )?.JOURNEY_LEG_CARBON_ID || ""
    }

    return { ...acc, [curr]: value }
  }, item)
}
