import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { ErrorResponse } from "services/SuccessResponse"
import {
  getExpenditureProfileSummaryTableAsync,
  getExpenditureProfileProjectByIdAsync,
  getExpenditureProfileProjectDataAsync,
  getExpenditureProfileProjectsAsync,
} from "./services"

export const getExpenditureProfileProjectsAction = createAsyncThunk<any>(
  "getExpenditureProfileProjectsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getExpenditureProfileProjectsAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getExpenditureProfileProjectByIdAction = createAsyncThunk<any, string | undefined>( // any1
  "estimates/getExpenditureProfileProjectByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getExpenditureProfileProjectByIdAsync(id) // any1
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data as any // any1
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getExpenditureProfileProjectDataAction = createAsyncThunk<any, string | undefined>( // any2
  "estimates/getExpenditureProfileProjectDataAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getExpenditureProfileProjectDataAsync(id) // any2
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data as any // any2
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getExpenditureProfileSummaryTableAction = createAsyncThunk<any, string | undefined>( // any3
  "estimates/getExpenditureProfileSummaryTableAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getExpenditureProfileSummaryTableAsync(id) // any3
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data as any // any3
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
