import { createAsyncThunk } from "@reduxjs/toolkit"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { fetchCodeItemsByCategoryAsync } from "redux/masters/services"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { v4 as uuidv4 } from "uuid"
import { LoadingState } from "types/AppNav"
import notify from "devextreme/ui/notify"
import { setLoading } from "redux/auth/authSlice"
import { CarbonCodesCategoryItem, CodeItems } from "redux/masters/types"
import { extractResponseData } from "utils/common-utils"
import { RootState } from "redux/store"
import isNaN from "lodash/isNaN"
import {
  fetchCreateCodeUpdateActionAsync,
  fetchFuelTypesWithValueAsync,
  fetchUnitAsync,
} from "./services"
import { CarbonFuelItem, FuelItem, UpdateFuelTypeRequest } from "./types"

const fuelTypeUnits = ["kWh", "litre", "tonne", "day", "hr", "week", "room/night"]

export const fetchAllFuelTypeAction = createAsyncThunk<FuelItem[], CarbonCodesCategoryItem>(
  "fuelType/fetchAllFuelTypeActions",
  async (carbonCodeCategory: CarbonCodesCategoryItem, { rejectWithValue }) => {
    const data: FuelItem[] = []
    try {
      // First fetch all fuel types codes....
      const fuelTypeCodesResponse: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(carbonCodeCategory?.CUSTOM_LIST_NAME || "")
      const fuelTypeCodesErrorRes = fuelTypeCodesResponse as ErrorResponse
      if (fuelTypeCodesErrorRes.code) {
        return rejectWithValue(fuelTypeCodesErrorRes.message)
      }
      const fuelTypeCodes: CodeItems[] = extractResponseData(
        fuelTypeCodesResponse as BenchmarkDataContainer[]
      )

      const unitDataResponse: BenchmarkDataContainer[] | ErrorResponse = await fetchUnitAsync(
        "Unit"
      )
      const unitDataErr = unitDataResponse as ErrorResponse
      if (unitDataErr.code) {
        return rejectWithValue(unitDataErr.message)
      }

      const unitItems = extractResponseData(unitDataResponse as BenchmarkDataContainer[]).filter(
        (item: CodeItems) =>
          fuelTypeUnits.find((str: string) => str.toLowerCase() === item.Name?.toLocaleLowerCase())
      )

      if (fuelTypeCodes.length > 0 && unitItems.length > 0) {
        fuelTypeCodes.forEach((fuelData: CodeItems) => {
          unitItems.forEach((unitData: CodeItems) => {
            data.push({
              id: uuidv4(),
              fuelType: fuelData,
              unit: unitData,
            })
          })
        })
      }

      // fetch existing data for the fuel type entries.
      const fuelItemsWithValuesResponse: SuccessResponse<CarbonFuelItem[]> | ErrorResponse =
        await fetchFuelTypesWithValueAsync(Number(carbonCodeCategory.CUSTOM_LIST_ID))
      const errRes = fuelItemsWithValuesResponse as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes)
      }

      const carbonFuelItems: CarbonFuelItem[] =
        (fuelItemsWithValuesResponse as SuccessResponse<CarbonFuelItem[]>).data || []

      const finalFuelItems: FuelItem[] = data.map((item: FuelItem) => {
        const fuelItemFound = carbonFuelItems.find(
          (carbonVal: CarbonFuelItem) =>
            Number(item.unit?.Id) === carbonVal.UNIT_ID &&
            Number(item.fuelType?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
        )

        if (fuelItemFound) {
          return {
            ...item,
            id: fuelItemFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
            value: fuelItemFound.CARBON_VALUE,
          }
        }
        return item
      })
      return finalFuelItems
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateFuelItemValueAction = createAsyncThunk<FuelItem[], UpdateFuelTypeRequest[]>(
  "fetchCreateCodeUpdateAction",
  async (request: UpdateFuelTypeRequest[], { dispatch, rejectWithValue, getState }) => {
    try {
      const fuelState = (getState() as RootState).FuelTypes || []

      const requestFuelItems: FuelItem[] =
        request
          .map((item: UpdateFuelTypeRequest) => {
            const fuelObj = fuelState.fuelItems.find(
              (fuleItem: FuelItem) => fuleItem.id === item.key
            )
            if (fuelObj) {
              item.data = { ...fuelObj, value: item.data.value }
              return item
            }
            return item
          })
          .map((item: UpdateFuelTypeRequest) => item.data) || []

      const finalRequest: CarbonFuelItem[] = requestFuelItems.map((item: FuelItem) => {
        const carbonFuelItem: CarbonFuelItem = {
          CUSTOM_LIST_ID: fuelState.carbonCodeItem.CUSTOM_LIST_ID || 0,
          UNIT_ID: Number(item.unit?.Id || 0),
          CUSTOM_LIST_CONTENT_ID: Number(item.fuelType?.Id || 0),
          CARBON_VALUE: Number(item.value),
        }

        if (!isNaN(Number(item.id))) {
          carbonFuelItem.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID = Number(item.id)
        }

        return carbonFuelItem
      })

      dispatch(setLoading(LoadingState.LOADING))
      const response: SuccessResponse<CarbonFuelItem[]> | ErrorResponse =
        await fetchCreateCodeUpdateActionAsync(finalRequest)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      // once api success.
      if ((response as SuccessResponse<CarbonFuelItem[]>).statusCode === 200) {
        const fuelItemsWithValuesResponse: SuccessResponse<CarbonFuelItem[]> | ErrorResponse =
          await fetchFuelTypesWithValueAsync(Number(fuelState.carbonCodeItem.CUSTOM_LIST_ID))
        const errRes = fuelItemsWithValuesResponse as ErrorResponse
        if (errRes.code) {
          return rejectWithValue(errRes)
        }

        const carbonFuelItems: CarbonFuelItem[] =
          (fuelItemsWithValuesResponse as SuccessResponse<CarbonFuelItem[]>).data || []

        const finalFuelItems: FuelItem[] = fuelState.fuelItems.map((item: FuelItem) => {
          const fuelItemFound = carbonFuelItems.find(
            (carbonVal: CarbonFuelItem) =>
              Number(item.unit?.Id) === carbonVal.UNIT_ID &&
              Number(item.fuelType?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
          )

          if (fuelItemFound) {
            return {
              ...item,
              id: fuelItemFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
              value: fuelItemFound.CARBON_VALUE,
            }
          }
          return item
        })

        return finalFuelItems
      }

      return []
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
