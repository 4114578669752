import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import DataGrid, {
  Column,
  Editing,
  FilterRow,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"
import EPD from "components/EPDPopup"
import { useAppDispatch } from "redux/store"
import {
  postBulkEstimatesLibrary,
  putBulkEstimatesLibrary,
} from "redux/project-estimate-library-setup/middleware"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, RoleType } from "redux/role-permission/types"
import { EstimateLibraryItem, Supplier } from "redux/project-estimate-library-setup/types"
import { estimateLibrarySelector } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import { allowedPageSizes } from "utils/config"
import { handleResetFilter, identifyPermissions } from "utils/common-utils"
import { badgeRender, estimateLibFullAccess, getSupplierCode, tempModel } from "../../utils"
import SupplierRatesPopUp from "../SupplierRatesPopup"
import {
  ModeOfTransportationEditor,
  onContractorSpecificCellPrepared,
  renderSupplierCell,
  renderUpdateCarbonFactorCell,
} from "./utils"
import "./contractor-specific-table.scss"

const ContractorSpecificTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const {
    estimateLibraryItems,
    modesOfTransport,
    // fuelLaborType,
    // wrapCategory,
    assuredLibraryContent,
    selectedEstimateLibrary,
  } = useSelector(estimateLibrarySelector)

  const dataGridRef = useRef<DataGrid>(null)
  const [epdId, setEPDId] = useState<number>()
  const [changes, setChanges] = useState<Array<any>>([])
  const [showEPDPopup, setShowEPDPopup] = useState<boolean>(false)
  const [gridData, setGridData] = useState<EstimateLibraryItem[]>()
  const [showSupplierRatesPopup, setShowSupplierRatesPopup] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<EstimateLibraryItem | undefined>(undefined)
  const [selectedRowKey, setSelectedRowKey] = useState<EstimateLibraryItem | undefined>(undefined)

  useEffect(() => {
    if (estimateLibraryItems) {
      setGridData(
        estimateLibraryItems.map((k) => ({
          ...tempModel,
          ...k,
          SUBCONTRATOR_CODE: getSupplierCode(k),
          WASTE_FACTOR_PERCENTAGE: k.WASTE_FACTOR_PERCENTAGE || 0,
        }))
      )
    }
  }, [estimateLibraryItems])

  const onChangesChange = useCallback((newChanges: any) => {
    setChanges(newChanges)
  }, [])

  const handleSaveClick = () => {
    const dataForSave: EstimateLibraryItem[] = []

    if (changes && changes.length > 0) {
      changes.forEach((ch) => {
        if (ch.type === "update") {
          const obj: any = {
            ...ch.key,
            CONTRACTOR_ESTIMATE_LIBRARY_ID: selectedEstimateLibrary,
            ...ch.data,
          }
          delete obj.SUPPLIER
          if (ch?.data?.CARB_EPD_GWP_A1_A3) {
            obj.CARB_A1_A3 = ch?.data?.CARB_EPD_GWP_A1_A3
          }
          if (ch?.data?.CARB_EPD_GWP_A5) {
            obj.CARB_A5A = ch?.data?.CARB_EPD_GWP_A5
          }
          if (ch?.data?.CARB_EPD_ID) {
            obj.EPD_ID = ch?.data?.CARB_EPD_ID
          }
          dataForSave.push(obj)
        }
      })
    }

    if (dataForSave && dataForSave.length > 0) {
      for (const row of dataForSave) {
        if (
          (row?.MODE_OF_TRANSPORTATION1 && !row?.DISTANCE1) ||
          (row?.MODE_OF_TRANSPORTATION2 && !row?.DISTANCE2) ||
          (row?.MODE_OF_TRANSPORTATION3 && !row?.DISTANCE3) ||
          (row?.MODE_OF_TRANSPORTATION4 && !row?.DISTANCE4)
        ) {
          notify(t("contractorEstimateLibrary.emptyDistanceErr"), "error", 3000)
          return
        }
      }
    }

    if (dataForSave[0].CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID) {
      dispatch(putBulkEstimatesLibrary(dataForSave))
        .then((res: any) => {
          if (res?.meta?.requestStatus === "fulfilled") {
            dataGridRef.current?.instance.cancelEditData()
          }
          if (res?.meta?.requestStatus === "rejected" && res?.payload) {
            notify(res?.payload, "error", 3000)
          }
        })
        .catch((error: any) => {
          console.log(error)
        })
    } else {
      dispatch(postBulkEstimatesLibrary(dataForSave))
        .then((res: any) => {
          if (res?.meta?.requestStatus === "fulfilled") {
            dataGridRef.current?.instance.cancelEditData()
          }
          if (res?.meta?.requestStatus === "rejected" && res?.payload) {
            notify(res?.payload, "error", 3000)
          }
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  const onApplyRatesClick = useCallback(
    (supplierData: Supplier) => {
      const rowChanges = {
        type: "update",
        data: {
          SUPPLIER_RATE: supplierData.RESOURCE_LIBRARY_RATE,
          SUBCONTRATOR_CODE: supplierData.SUBCONTRATOR_CODE,
          SUBCONTRACTOR_ID: supplierData.SUBCONTRACTOR_ID,
        },
        key: selectedItem,
      }

      setChanges([rowChanges])
      setShowSupplierRatesPopup(false)
      setSelectedItem(undefined)
    },
    [selectedItem]
  )

  const epdPopupCallback = useCallback(
    (data: any) => {
      const rowChanges = {
        type: "update",
        data: {
          CARB_EPD_DESC: data?.CARB_EPD_DESC,
          CARB_EPD_CODE: data?.CARB_EPD_CODE,
          CARB_EPD_ISSUE_DATE: data?.CARB_EPD_ISSUE_DATE,
          CARB_EPD_EXP_DATE: data?.CARB_EPD_EXP_DATE,
          CARB_EPD_A1_A3_ISINCL: data?.CARB_EPD_A1_A3_ISINCL,
          CARB_EPD_GWP_A1_A3: data?.CARB_EPD_GWP_A1_A3,
          CARB_EPD_A4_ISINCL: data?.CARB_EPD_A4_ISINCL,
          CARB_EPD_GWP_A5: data?.CARB_EPD_GWP_A5,
          CARB_EPD_A5_ISINCL: data?.CARB_EPD_A5_ISINCL,
          CARB_A1_A3: data?.CARB_EPD_GWP_A1_A3,
          CARB_A5A: data?.CARB_EPD_GWP_A5,
          CARB_EPD_ID: data?.CARB_EPD_ID,
          EPD_ID: data?.CARB_EPD_ID,
        },
        key: selectedRowKey,
      }

      setChanges([rowChanges])
      setShowEPDPopup(false)
    },
    [selectedRowKey]
  )

  const libraryFullAccess = useMemo(() => estimateLibFullAccess(userRole), [userRole])

  const hideColNotContractor = useMemo(
    () => identifyPermissions(userRole, -1, AccessLevel.FULL_ACCESS, [RoleType.CONTRACTOR]),
    [userRole]
  )

  const colNotContractor = useMemo(
    () =>
      identifyPermissions(userRole, -1, AccessLevel.FULL_ACCESS, [
        RoleType.ADMINISTRATORS,
        RoleType.AUTHORITY_ESTIMATOR,
      ]),
    [userRole]
  )

  return (
    <div className="contractor-specific-table-wrapper">
      {showSupplierRatesPopup && (
        <SupplierRatesPopUp
          applyRate={onApplyRatesClick}
          suppliers={selectedItem?.SUPPLIER}
          onClose={() => setShowSupplierRatesPopup(false)}
        />
      )}
      {showEPDPopup && (
        <EPD
          showEPDPopup={showEPDPopup}
          epdPopupCallback={epdPopupCallback}
          setShowEPDPopup={setShowEPDPopup}
          epdId={epdId}
          allowUpdate={libraryFullAccess}
        />
      )}
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={gridData}
        height={windowHeight - 148}
        highlightChanges
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        showColumnLines
        onCellPrepared={onContractorSpecificCellPrepared}
      >
        <Editing
          allowUpdating
          allowAdding={false}
          allowDeleting={false}
          mode="batch"
          changes={changes}
          onChangesChange={onChangesChange}
        />
        <FilterRow visible />
        <Paging defaultPageSize={10} />
        <SearchPanel visible width={240} />
        <Scrolling columnRenderingMode="virtual" />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Toolbar>
          <Item location="after">
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => handleResetFilter(dataGridRef)}
              />
            </span>
          </Item>
          <Item name="revertButton" />
          <Item location="after" disabled={!changes.length}>
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Column
          width="72"
          alignment="center"
          cellRender={(row: any) =>
            badgeRender({ row, assuredLibraryContent, dataGridRef, setChanges, t })
          }
          allowEditing={false}
          fixed
        />
        <Column dataField="CODE" caption={t("code")} allowEditing={false} fixed />
        <Column
          dataField="DESCRIPTION"
          caption={t("contractorEstimateLibrary.description")}
          allowEditing={false}
          width={400}
          fixed
        />
        <Column
          dataField="SUBCONTRATOR_CODE"
          caption={t("contractorEstimateLibrary.supplier")}
          allowEditing={false}
          alignment="center"
          cellRender={(row: any) =>
            renderSupplierCell({
              row,
              dataField: "SUBCONTRATOR_CODE",
              disableSupplierCell: !libraryFullAccess,
              setSelectedItem,
              setShowSupplierRatesPopup,
              t,
            })
          }
        />
        <Column
          dataField="SUPPLIER_RATE"
          caption={t("estimateLibrarySetup.rate")}
          allowEditing={libraryFullAccess}
        />
        <Column
          dataField="CONTRACTOR_CODE"
          caption={t("contractorEstimateLibrary.contractorCode")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_REGION"
          caption={t("contractorEstimateLibrary.region")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_UNIT"
          caption={t("contractorEstimateLibrary.unit")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_WEIGHT"
          caption={t("contractorEstimateLibrary.weightKg")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_CATEGORY"
          caption={t("contractorEstimateLibrary.category")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_GROUP"
          caption={t("contractorEstimateLibrary.group")}
          allowEditing={false}
        />
        <Column
          dataField="RESOURCE_TYPE"
          caption={t("contractorEstimateLibrary.type")}
          allowEditing={false}
        />
        <Column
          dataField="MODE_OF_TRANSPORTATION1"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.modeOfTransportation1")}
          editCellRender={(e) =>
            ModeOfTransportationEditor({
              t,
              modesOfTransport,
              disableModeOfTransport: !libraryFullAccess,
              cellInfo: e,
            })
          }
          width={300}
          showEditorAlways
        />
        <Column
          dataField="DISTANCE1"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.distance1")}
          allowEditing={libraryFullAccess}
        />
        <Column
          dataField="MODE_OF_TRANSPORTATION2"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.modeOfTransportation2")}
          editCellRender={(e) =>
            ModeOfTransportationEditor({
              t,
              modesOfTransport,
              disableModeOfTransport: !libraryFullAccess,
              cellInfo: e,
            })
          }
          width={300}
          showEditorAlways
        />
        <Column
          dataField="DISTANCE2"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.distance2")}
          allowEditing={libraryFullAccess}
        />
        <Column
          dataField="MODE_OF_TRANSPORTATION3"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.modeOfTransportation3")}
          editCellRender={(e) =>
            ModeOfTransportationEditor({
              t,
              modesOfTransport,
              disableModeOfTransport: !libraryFullAccess,
              cellInfo: e,
            })
          }
          width={300}
          showEditorAlways
        />
        <Column
          dataField="DISTANCE3"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.distance3")}
          allowEditing={libraryFullAccess}
        />
        <Column
          dataField="MODE_OF_TRANSPORTATION4"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.modeOfTransportation4")}
          editCellRender={(e) =>
            ModeOfTransportationEditor({
              t,
              modesOfTransport,
              disableModeOfTransport: !libraryFullAccess,
              cellInfo: e,
            })
          }
          width={300}
          showEditorAlways
        />
        <Column
          dataField="DISTANCE4"
          visible={hideColNotContractor}
          caption={t("contractorEstimateLibrary.distance4")}
          allowEditing={libraryFullAccess}
        />
        <Column dataField="CARB_A1_A3" caption={t("contractorEstimateLibrary.a1a3")} allowEditing />
        {/* <Column
          dataField="RES_CARB_JOURNEY_LEG"
          caption={t("contractorEstimateLibrary.transportAssumption")}
          visible={colNotContractor}
          lookup={{
            dataSource: modesOfTransport,
            displayExpr: "name",
            valueExpr: "id",
            allowClearing: true,
          }}
          allowEditing
        /> */}
        <Column
          dataField="CARB_A4"
          visible={colNotContractor}
          caption={t("contractorEstimateLibrary.a4")}
          allowEditing
        />
        {/* <Column
          dataField="RES_CARB_FUEL_LABOUR"
          visible={colNotContractor}
          caption={t("contractorEstimateLibrary.fuelOrLabor")}
          lookup={{
            dataSource: fuelLaborType,
            displayExpr: "name",
            valueExpr: "id",
            allowClearing: true,
          }}
          allowEditing
        /> */}
        <Column
          dataField="CARB_A5A"
          // visible={colNotContractor}
          caption={t("contractorEstimateLibrary.a5a")}
          allowEditing
        />
        {/* <Column
          dataField="RES_CARB_WASTE_CATEGORY"
          visible={colNotContractor}
          caption={t("contractorEstimateLibrary.wasteCategoryAssumption")}
          lookup={{
            dataSource: wrapCategory,
            displayExpr: "name",
            valueExpr: "id",
            allowClearing: true,
          }}
          allowEditing
        /> */}
        <Column
          dataField="WASTE_FACTOR_PERCENTAGE"
          caption={t("contractorEstimateLibrary.wasteFactorPer")}
          // allowEditing={colNotContractor}
          allowEditing={libraryFullAccess}
        />
        <Column
          caption={t("contractorEstimateLibrary.updateCarbonFactors")}
          allowEditing={false}
          visible={hideColNotContractor}
          cellRender={(e) =>
            renderUpdateCarbonFactorCell(e, { t, setSelectedRowKey, setEPDId, setShowEPDPopup })
          }
        />
      </DataGrid>
    </div>
  )
}

export default ContractorSpecificTable
