import { LoadIndicator, Popup } from "devextreme-react"
import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from "devextreme-react/form"
import notify from "devextreme/ui/notify"
import { useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { postContractorEstimateLibrary } from "redux/project-estimate-library-setup/middleware"
import {
  estimateLibrarySelector,
  setLoading,
  setShowAddNewLibrary,
} from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import { useAppDispatch } from "redux/store"
import { LoadingState } from "types/AppNav"

const AddNewContactorEstimateLibrary = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const formData = useRef({ libraryName: "" })
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)
  const { loading, contractorEstimateLibraryContent } = useSelector(estimateLibrarySelector)

  const onClose = () => dispatch(setShowAddNewLibrary(false))

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    dispatch(setLoading(LoadingState.LOADING))

    const { Id } = selectedRevision || {}
    const { libraryName } = formData.current
    const arr = contractorEstimateLibraryContent.map((item) => item.TITLE)
    const duplicateTitle = arr.includes(libraryName.trim())

    if (duplicateTitle) {
      notify(t("contractorEstimateLibrary.duplicateLibraryNameErr"), "error", 3000)
      dispatch(setLoading(LoadingState.DEFAULT))
    } else if (Id) {
      dispatch(postContractorEstimateLibrary({ projectId: Id, title: libraryName }))
      onClose()
    }
  }, [])

  return (
    <Popup
      visible
      width={450}
      height="auto"
      showCloseButton
      onHiding={onClose}
      dragEnabled={false}
      title={t("contractorEstimateLibrary.popUpHeadingAddLibrary")}
    >
      <form onSubmit={onSubmit} className="p-3">
        <Form formData={formData.current} disabled={loading === LoadingState.LOADING}>
          <Item
            dataField="libraryName"
            editorType="dxTextBox"
            editorOptions={{
              mode: "text",
              stylingMode: "filled",
              placeholder: t("contractorEstimateLibrary.enterLibraryName"),
            }}
          >
            <RequiredRule message={t("contractorEstimateLibrary.emptyLibraryName")} />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              width="100%"
              type="default"
              useSubmitBehavior
              elementAttr={{ class: "submit-button" }}
            >
              <span className="dx-button-text">
                {loading === LoadingState.LOADING ? (
                  <LoadIndicator width="24px" height="24px" visible />
                ) : (
                  t("toolbarActions.save")
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </Popup>
  )
}

export default AddNewContactorEstimateLibrary
