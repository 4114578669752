import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse } from "services/SuccessResponse"
import { LoadingState } from "types/AppNav"
import notify from "devextreme/ui/notify"
import { setLoading } from "./controlPanelSlice"
import { fetchAssignInflationIndexAsync, updateAssignInflationIndexAsync } from "./services"

export const fetchAssignInflationIndexAction = createAsyncThunk<any[]>(
  "fetchAssignInflationIndexAction",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      const response: any = await fetchAssignInflationIndexAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      const finalRes =
        response?.data?.map((item: any) => ({
          ...item,
          HAS_ACCESS: item.HAS_ACCESS === "Y" ? 1 : 0,
        })) || []

      return finalRes
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateAssignInflationIndexAction = createAsyncThunk<any[], any[]>(
  "updateAssignInflationIndexAction",
  async (request: any[], { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      const response: any = await updateAssignInflationIndexAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Access updated successfully.", "success", 2000)
      return response?.data as any[]
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
