import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Tabs } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { tabsOptions } from "screens/ift/utils"
import { iftSelector, setSelectedTab } from "redux/ift/iftSlice"
import { profileSelector } from "redux/profile/profileSlice"

function IFTHeaderTabs() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { selectedTab } = useSelector(iftSelector)
  const { userRole } = useSelector(profileSelector)

  return (
    <div className="tabs">
      <Tabs
        dataSource={tabsOptions(t, userRole)}
        selectedIndex={selectedTab}
        onItemClick={(args) => dispatch(setSelectedTab(args?.itemData?.id || 0))}
        scrollingEnabled
        scrollByContent
      />
    </div>
  )
}

export default IFTHeaderTabs
