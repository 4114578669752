/* eslint-disable security/detect-object-injection */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { sortBy } from "lodash"
import { LoadingState } from "types/AppNav"
import { CofficentState, ICarbonResource, IItemResource, ITransportAssumption } from "./types"
import {
  fetchResourcesAction,
  fetchTransportAssumptionAction,
  updateResourcesAction,
  fetchItemsAction,
  updateItemsAction,
} from "./middleware"

const INITIAL_STATE: CofficentState = {
  loading: LoadingState.DEFAULT,
  resources: [],
  items: [],
  transportAssumptions: [],
}

const emissionFactorSlice = createSlice({
  name: "EmissionFactor",
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setResources: (state, action) => {
      state.resources = action.payload
    },
    updateCoeffecientData: (
      state: CofficentState,
      { payload }: PayloadAction<{ key: string; value: ICarbonResource }>
    ) => {
      const { key, value } = payload
      const resources = [...state.resources]
      const idx: number = resources.findIndex((item) => item.RESOURCE_LIBRARY_CODE === key)
      if (idx >= 0) {
        resources[idx] = { ...resources[idx], ...value }
      }
      return { ...state, resources }
    },
    pushChangesToReduxStore: (
      state: CofficentState,
      { payload }: PayloadAction<{ changes: ICarbonResource[] }>
    ) => {
      const { changes } = payload
      const resources = [...state.resources]
      if (changes.length > 0) {
        changes.forEach((change) => {
          const idx: number = resources.findIndex(
            (item) => item.RESOURCE_LIBRARY_ID === change.RESOURCE_LIBRARY_ID
          )
          if (idx >= 0) {
            resources[idx] = { ...resources[idx], ...change }
          }
        })
      }
      return { ...state, resources }
    },
    pushItemsChangesToReduxStore: (
      state: CofficentState,
      { payload }: PayloadAction<{ changes: IItemResource[] }>
    ) => {
      const { changes } = payload
      const items = [...state.items]
      if (changes.length > 0) {
        changes.forEach((change) => {
          const idx: number = items.findIndex(
            (item) => item.ITEM_LIBRARY_ID === change.ITEM_LIBRARY_ID
          )
          if (idx >= 0) {
            items[idx] = { ...items[idx], ...change }
          }
        })
      }
      return { ...state, items }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchResourcesAction.fulfilled,
      (state, { payload }: PayloadAction<ICarbonResource[]>) => ({
        ...state,
        resources: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchResourcesAction.pending, (state) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateResourcesAction.pending, (state) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateResourcesAction.fulfilled, (state) => ({
      ...state,
      isSaveDisabled: true,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateResourcesAction.rejected, (state) => ({
      ...state,
      isSaveDisabled: true,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(
      fetchTransportAssumptionAction.fulfilled,
      (state, { payload }: PayloadAction<ITransportAssumption[]>) => ({
        ...state,
        transportAssumptions: sortBy(payload, ["TRANSPORT_CATEGORY_NAME"]),
      })
    )
    builder.addCase(
      fetchItemsAction.fulfilled,
      (state, { payload }: PayloadAction<IItemResource[]>) => ({
        ...state,
        items: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(fetchItemsAction.pending, (state) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateItemsAction.pending, (state) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(updateItemsAction.fulfilled, (state) => ({
      ...state,
      isSaveDisabled: true,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(updateItemsAction.rejected, (state) => ({
      ...state,
      isSaveDisabled: true,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const {
  updateCoeffecientData,
  pushChangesToReduxStore,
  pushItemsChangesToReduxStore,
  setLoading,
  setResources,
} = emissionFactorSlice.actions

export const emissionfactorSelector = (state: RootState) => state.EmissionFactor

export default emissionFactorSlice.reducer
