import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import notify from "devextreme/ui/notify"
import { Button, TextBox } from "devextreme-react"
import {
  estmteRvsnMgmntSelector,
  setSelectedUpdateRevision,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { putRevisionDetailsAction } from "redux/estimate-revision-management/middleware"
import { minCharCnfrmDialog } from "screens/estimate-revision-management/utils"

const RevisionEditForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { selectedUpdateRevision } = useSelector(estmteRvsnMgmntSelector)

  const [item, setItem] = useState<any>({
    projectId: selectedUpdateRevision?.Id || 0,
    PROJECT_TITLE: selectedUpdateRevision?.Title || "",
  })

  const onValueChanged = useCallback(
    (key: string, value: any) => {
      const v: any = {
        ...item,
        [key]: value,
      }
      setItem(v)
    },
    [item]
  )

  // eslint-disable-next-line consistent-return
  const handleSubmit = useCallback(() => {
    const charLength = get(item, "PROJECT_TITLE").length
    if (!charLength) {
      return notify(t("estimateRevisionManagement.minCharErr"), "error", 2000)
    }
    if (charLength > 200) return minCharCnfrmDialog(t).show()

    dispatch(putRevisionDetailsAction(item)).then(() => dispatch(setSelectedUpdateRevision()))
  }, [item])

  return (
    <form onSubmit={handleSubmit} className="editRevisionPopUp">
      <TextBox
        defaultValue={item.PROJECT_TITLE}
        label={t("estimateRevisionManagement.estimateDesc")}
        onValueChange={(value) => onValueChanged("PROJECT_TITLE", value)}
      />
      <div className="row save-btn">
        <Button
          className="active"
          text={t("toolbarActions.save")}
          type="default"
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default RevisionEditForm
