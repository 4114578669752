/* eslint-disable no-plusplus */
import { get } from "lodash"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import { UserRole } from "redux/profile/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, identifyPermissions } from "./common-utils"

function getHeader(columns: string | any[]) {
  const header = []
  for (let i = 0; i < columns.length; i++) {
    header[i] = columns[i].caption
  }
  return header
}

function getRow(node: any, columns: string | any[]) {
  const row = []
  for (let i = 0; i < columns.length; i++) {
    let cell = node.data[columns[i].dataField]
    if (i === 0) cell = "   ".repeat(node.level) + cell

    row[i] = cell
  }
  return row
}

function getData(node: { level: number; children: string | any[] }, columns: any) {
  const rows = []
  if (node.level >= 0) {
    rows.push(getRow(node, columns))
  }
  for (let i = 0; i < node.children.length; i++) {
    getData(node.children[i], columns).map((row) => rows.push(row))
  }
  return rows
}

export default function ExportToFile(
  fileName: string | undefined,
  tree: any,
  userRole?: UserRole | null
) {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("Estimate Level")

  const columnParent = get(tree, "_controllers.columns._columns") || []
  const columns = columnParent
    .filter((c: { dataField: string }) => c.dataField)
    .filter((c: { visible: boolean }) => c.visible)

  const header = getHeader(columns)
  worksheet.addRow(header)

  let data = getData(tree.getRootNode(), columns)
  if (userRole && fileName?.includes("Cost and Carbon Report")) {
    const permsnCheck = identifyPermissions(
      userRole,
      AccessType.SHOW_COST_DATA,
      AccessLevel.YES,
      allRoles
    )
    data = permsnCheck ? data : data.map((item) => item.slice(0, -1))
  }
  data.map((row) => worksheet.addRow(row))

  workbook.csv.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${fileName}.csv`)
  })
}
