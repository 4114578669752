import { useCallback, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import CustomStore from "devextreme/data/custom_store"
import { DataGrid, SelectBox } from "devextreme-react"
import { Column, Scrolling } from "devextreme-react/data-grid"
import useDocumentTitle from "hooks/useDocumentTitle"
import {
  fetchRoleAccessListAction,
  updatePermissionLevelForRoleAction,
} from "redux/role-permission/middleware"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { fetchUserRoleAndPermissionsAction } from "redux/profile/middleware"
import { rolePermissionSelector } from "redux/role-permission/rolePermissionSlice"
import { getAccessLevelList } from "./tableConst"

const RollPermissionTable = () => {
  useDocumentTitle("Role Permissions | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataGridRef = useRef<DataGrid>(null)

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { rolePermissionList, roleAccessLevelList, permission } =
    useSelector(rolePermissionSelector)

  const store = new CustomStore({
    load: () => rolePermissionList,
    key: "ROLEACCESS_ID",
  })

  useEffect(() => {
    if (permission) {
      dispatch(fetchRoleAccessListAction(permission.ROLE_ID))
    }
  }, [permission])

  const renderAccessLevelDropdown = useCallback(
    (rowData: any) => {
      return (
        <SelectBox
          disabled={rowData.data.IS_DISABLED === "Y"}
          dataSource={getAccessLevelList(
            rowData.data?.ROLEACCESS_ACCESSID?.ACCESS_NAME,
            roleAccessLevelList
          )}
          valueExpr="ACCESS_LEVEL_ID"
          displayExpr="ACCESS_LEVEL_NAME"
          defaultValue={rowData.data.ROLEACCESS_ACCESSLEVELID.ACCESS_LEVEL_ID}
          onValueChanged={({ value }) => {
            dispatch(
              updatePermissionLevelForRoleAction({
                currentRoleItem: rowData.data,
                updatedLevel: value,
              })
            ).then(() => {
              if (permission?.ROLE_ID === userRole?.ROLE_ID) {
                dispatch(fetchUserRoleAndPermissionsAction())
              }
            })
          }}
        />
      )
    },
    [roleAccessLevelList]
  )

  return (
    <DataGrid
      id="gridContainer"
      ref={dataGridRef}
      dataSource={store}
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      showBorders
      showColumnLines
      height={windowHeight - 248}
    >
      <Scrolling columnRenderingMode="virtual" />
      <Column
        dataField="ROLEACCESS_ACCESSID.ACCESS_NAME"
        caption={t("rolePermission.tableCol1Head")}
      />
      <Column
        caption={t("rolePermission.tableCol2Head")}
        dataField="ROLEACCESS_ACCESSLEVELID"
        cellRender={renderAccessLevelDropdown}
        allowSorting={false}
      />
    </DataGrid>
  )
}

export default RollPermissionTable
