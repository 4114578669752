import { addClass } from "utils/common-utils"

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const expenditureTableHeight = (windowHeight: number) => {
  return "min-content"
  // if (windowHeight > 1094) return "min-content"
  // if (windowHeight <= 972) return windowHeight - 240
  // if (windowHeight <= 1094) return windowHeight - 520
  // // if (windowHeight > 1094) return windowHeight - 720

  // return windowHeight - 220
}

export const onExpenditureReportRowPrepared = (e: any) => {
  const { rowElement, level } = e
  switch (level) {
    case 0:
      addClass(rowElement, "bg-org")
      break
    case 1:
      addClass(rowElement, "bg-section")
      break
    // case 2:
    //   addClass(rowElement, "bg-composite-total")
    //   break
    default:
      break
  }
}
