import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { useAppDispatch } from "redux/store"
import { fetchPowerBIAccessTokenAction } from "redux/powerBI/middleware"
import { reset } from "redux/powerBI/powerBISlice"
import PowerBILoader from "./powerBILoader"
import PowerBIDashboard from "./powerBIDashboard"
import { powerBIBreadCrumb } from "./utils"
import "./powerBI.scss"

const PowerBI: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const id = "123"
    dispatch(fetchPowerBIAccessTokenAction(id))
    return () => {
      dispatch(reset())
    }
  }, [])

  return (
    <div className="powerBIMain">
      <BreadCrumb data={powerBIBreadCrumb(t)} />
      <PowerBILoader />
      <PowerBIDashboard />
    </div>
  )
}

export default PowerBI
