import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import useDocumentTitle from "hooks/useDocumentTitle"
import { getExpenditureProfileProjectsAction } from "redux/expenditure-profile/middleware"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import CostEstimateLoader from "./components/costEstimateLoader"
import CostEstimateListTable from "./components/costEstimateListTable"
import { costEstimateSummaryBreadCrumb } from "./utils"
import "./cost-estimate-summary.scss"

const CostEstimateSummary: React.FC = (): JSX.Element => {
  useDocumentTitle(
    "Cost and Carbon Estimate Summary Sheet (CCESS) | Cost and Carbon Forecasting tool"
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { expenditureProfileProjects } = useSelector(expenditureProfileSelector)

  useEffect(() => {
    if (!expenditureProfileProjects.length) dispatch(getExpenditureProfileProjectsAction())
  }, [])

  return (
    <div className="costEstimateSummaryMain">
      <CostEstimateLoader />
      <BreadCrumb data={costEstimateSummaryBreadCrumb(t)} />
      <CostEstimateListTable />
    </div>
  )
}

export default CostEstimateSummary
