import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import {
  EstimateRevisionAPIData,
  GetEstimateContractorsAPIData,
  UpdateContractorsAPIData,
} from "./types"

export const getEstimateRevisionsAsync = async (data: EstimateRevisionAPIData) => {
  const { projectId } = data
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/estimate-revisions/${projectId}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const getEstimateRevisionAsync = async (data: EstimateRevisionAPIData) => {
  const { projectId } = data
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/estimate-revision/${projectId}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const postEstimateRevisionsAsync = async (data: EstimateRevisionAPIData) => {
  const { projectId } = data
  // EstimateNum is a hard-coded value,
  // estimate number naming has a certain rule and needs to be done in backend
  const finalData = {
    ProjectId: projectId,
    EstimateNum: "TESTQUOTEAPI23",
  }
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/estimate-revision`,
      finalData
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const putRevisionDetailsAsync = async (item: {
  PROJECT_TITLE: string
  projectId: number
}) => {
  try {
    const response = await axiosInstance.put<SuccessResponse<any> | ErrorResponse>(
      `/project/rename/${item?.projectId}`,
      item
    )

    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteEstimateAsync = async (projectId: string | number) => {
  try {
    const response = await axiosInstance.delete<string>(`/project/${projectId}`)
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getEstimateContractors = async (data: GetEstimateContractorsAPIData) => {
  const { Id, ParentProjId } = data
  const finalData = {
    REVISION_ID: Id,
    PARENT_PROJ_ID: ParentProjId,
  }
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/revisionContractors`,
      finalData
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const postRevisionContractors = async (data: UpdateContractorsAPIData) => {
  const { Id, ParentProjId, Contractors } = data
  const finalData = {
    REVISION_ID: Id,
    PARENT_PROJ_ID: ParentProjId,
    CONTRACTORS: Contractors,
  }
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/assignRevisionContractors`,
      finalData
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
