import axiosInstance, { isAxiosError } from "services/api"
import { SuccessResponse } from "services/SuccessResponse"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import {
  CarbonCodesCategoryItem,
  DynamicCarbonSingleData,
  EditCodeRequest,
  UpdateDynamicCarbonLibraryRequest,
} from "./types"

export const fetchCarbonCodeCategoriesAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<CarbonCodesCategoryItem[]>>(`/codes`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchCodeItemsByCategoryAsync = async (category: string) => {
  try {
    const response = await axiosInstance.get<BenchmarkDataContainer[]>(`/bes/usercodes/${category}`)
    return response.data as BenchmarkDataContainer[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteCodeAsync = async (id: string) => {
  try {
    const response = await axiosInstance.delete<string>(`/bes/usercodes/${id}`)
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}

export const deleteCodeJourneyLegAsync = async (id: string) => {
  try {
    const response = await axiosInstance.delete<string>(`/journey-leg/transport-category?ids=${id}`)
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchEditCodeForCategoryActionAsync = async (data: EditCodeRequest) => {
  try {
    const formData = new FormData()
    formData.append("Name", data.Name || "")
    formData.append("Description", data.Name || "")
    formData.append("UserCodeName", data.category || "")
    const response = await axiosInstance.put<string>(`/bes/usercodes/${data.Id}`, formData, {
      responseType: "text",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    return response.data as string
  } catch (err) {
    return isAxiosError(err)
  }
}

export const createCodeAsync = async (request: EditCodeRequest) => {
  try {
    const formData = new FormData()
    formData.append("Name", request.Name || "")
    formData.append("Description", request.Name || "")
    formData.append("UserCodeName", request.category || "")
    const response = await axiosInstance.post<string>(
      `/bes/usercodes/${request.category}`,
      formData,
      {
        responseType: "text",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getDynamicCarbonLibraryAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<DynamicCarbonSingleData[]>>(
      `/contract-estimate-library/content`
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateDynamicCarbonLibraryAsync = async (
  data: UpdateDynamicCarbonLibraryRequest[]
) => {
  try {
    const response = await axiosInstance.put<SuccessResponse<any>>(
      `/contract-estimate-library/content/assured`,
      data
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
