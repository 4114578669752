/* eslint-disable import/prefer-default-export */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios"
import { get } from "lodash"
import axiosInstance from "services/api"
import { logout } from "redux/auth/authSlice"
import { navUtil, myUtils } from "utils/common-utils"
import { getLocalAccessToken, getLocalRefreshToken, updateLocalAccessToken } from "services/token"

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = getLocalAccessToken()
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error)

const onResponse = (response: AxiosResponse): AxiosResponse => response

const onTokenExpiry = () => {
  myUtils.dispatch(logout())
  localStorage.clear()
  navUtil.navigate("/login", { replace: true })
}

const onResponseError = async (error: AxiosError) => {
  let retry = false

  const originalConfig: AxiosRequestConfig = error.config!!
  if (originalConfig.url !== "/auth/login" && error.response) {
    if (error.response.status === 401 && !retry) {
      retry = true
      const refreshToken = getLocalRefreshToken()
      try {
        if (refreshToken) {
          const rs = await axiosInstance.post("/auth/getToken", {
            refreshToken,
          })

          const { data } = rs.data

          // store.dispatch(refreshToken(data.accessToken, data.refreshToken));
          updateLocalAccessToken(data.accessToken, data.refreshToken)

          return axiosInstance(originalConfig)
        }

        onTokenExpiry()
      } catch (_error) {
        return Promise.reject(_error)
      }
    }
  }
  const TokenExpiredError = [get(error, "response.data.error.name"), error.name].includes(
    "TokenExpiredError"
  )

  if (originalConfig.url !== "/auth/login" && TokenExpiredError) onTokenExpiry()

  return Promise.reject(error)
}

export const setupInterceptorsTo = (axiosObj: AxiosInstance): AxiosInstance => {
  axiosObj.interceptors.request.use(onRequest, onRequestError)
  axiosObj.interceptors.response.use(onResponse, onResponseError)
  return axiosObj
}
