import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { LoadPanel } from "devextreme-react"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { LoadingState } from "types/AppNav"

const CostEstimateLoader = () => {
  const { loading } = useSelector(expenditureProfileSelector)
  const { loading: expenditureProfileLoading } = useSelector(expenditureProfileSelector)

  const loadingCheck = useMemo(
    () => [loading, expenditureProfileLoading].includes(LoadingState.LOADING),
    [loading, expenditureProfileLoading]
  )

  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loadingCheck}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(CostEstimateLoader)
