import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import List from "devextreme-react/list"
import ContextMenu, { Position } from "devextreme-react/context-menu"
import type { UserPanelProps } from "types/AppNav"
import { useAppDispatch } from "redux/store"
import { authSelector, logout } from "redux/auth/authSlice"
import { profileSelector } from "redux/profile/profileSlice"
import "./user-panel.scss"

export default function UserPanel({ menuMode }: UserPanelProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { currentUser } = useSelector(authSelector)
  const { userRole } = useSelector(profileSelector)

  const menuItems = useMemo(
    () => [
      {
        text: `Role: ${userRole?.ROLE_NAME || "No Role"}`,
        icon: "card",
      },
      // {
      //   text: t("profile"),
      //   icon: "user",
      //   onClick: () => navigate("/profile"),
      // },
      {
        text: t("logout"),
        icon: "runner",
        onClick: () => dispatch(logout()),
      },
    ],
    [userRole]
  )

  return (
    <div className="user-panel">
      <div className="user-info">
        <div className="image-container">
          <div
            style={{
              background: `url(${currentUser?.avatarUrl || `/user.png`}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className="user-image"
          />
        </div>
        <div className="user-name">{currentUser?.username}</div>
      </div>
      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target=".user-button"
          showEvent="dxclick"
          width={210}
          cssClass="user-menu"
        >
          <Position my="top" at="bottom" />
        </ContextMenu>
      )}
      {menuMode === "list" && <List className="dx-toolbar-menu-action" items={menuItems} />}
    </div>
  )
}
