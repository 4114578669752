import { memo, useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Popup } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { getScopeResourceDetailsAction } from "redux/ift/middleware"
import { iftSelector, setIFTScopeEditResource } from "redux/ift/iftSlice"
import EditResourceForm from "./EditResourceForm"
import { EditResourcePopupProps } from "../scopeTableUtils"

const EditResourcePopup = (props: EditResourcePopupProps) => {
  const { tableRef } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { iftScopeEditResource } = useSelector(iftSelector)

  useEffect(() => {
    if (iftScopeEditResource?.PROJECT_RESOURCE_ID)
      dispatch(getScopeResourceDetailsAction(iftScopeEditResource?.PROJECT_RESOURCE_ID))
  }, [])

  const onClose = useCallback(() => {
    dispatch(setIFTScopeEditResource(undefined))
    tableRef.current?.instance.cancelEditData()
  }, [])

  const popUpTitle = useMemo(
    () => `${t("iftScope.resourceDetails")} (${iftScopeEditResource?.CODE})`,
    [iftScopeEditResource]
  )

  return (
    <Popup
      visible
      showCloseButton
      enableBodyScroll
      width={620}
      maxHeight={560}
      onHiding={onClose}
      title={popUpTitle}
      dragEnabled={false}
    >
      <EditResourceForm popUpClose={onClose} />
    </Popup>
  )
}

export default memo(EditResourcePopup)
