export const ACCESS_NAME_WITH_YES_NO = [
  "IFT",
  "Scope",
  "Edit Items from Revision",
  "Edit Resources from Revision",
  "Add Items / Resources",
  "Assign Inflation Index",
  "Create Revision Estimates",
  "Add a revision to Unauthorised Estimate",
  "Delete Items / Resources",
  "Reports",
  "Show Cost Data",
]

export function getAccessLevelList(accessName: string, roleAccessLevelList: any[]) {
  const filteredAccessLevels = roleAccessLevelList.filter((k: { ACCESS_LEVEL_NAME: string }) =>
    accessName && ACCESS_NAME_WITH_YES_NO.includes(accessName)
      ? ["Yes", "No"].includes(k.ACCESS_LEVEL_NAME)
      : !["Yes", "No"].includes(k.ACCESS_LEVEL_NAME)
  )
  return filteredAccessLevels
}
