import { memo, useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Popup } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { getScopeItemDetailsAction } from "redux/ift/middleware"
import { iftSelector, setIFTScopeEditItem } from "redux/ift/iftSlice"
import { EditItemPopupProps } from "../scopeTableUtils"
import EditItemTable from "./EditItemTable"

const EditItemPopup = (props: EditItemPopupProps) => {
  const { tableRef } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { iftScopeEditItem } = useSelector(iftSelector)

  useEffect(() => {
    if (iftScopeEditItem?.PROJECT_ITEM_ID)
      dispatch(getScopeItemDetailsAction(iftScopeEditItem?.PROJECT_ITEM_ID))
  }, [])

  const onClose = useCallback(() => {
    dispatch(setIFTScopeEditItem(undefined))
    tableRef.current?.instance.cancelEditData()
  }, [])

  const popUpTitle = useMemo(
    () =>
      `${t("iftScope.provisionalSum")} (${iftScopeEditItem?.CODE} - ${
        iftScopeEditItem?.DESCRIPTION
      })`,
    [iftScopeEditItem]
  )

  return (
    <Popup
      visible
      showCloseButton
      enableBodyScroll
      // width={800}
      maxHeight={400}
      onHiding={onClose}
      title={popUpTitle}
      dragEnabled={false}
    >
      <EditItemTable popUpClose={onClose} />
    </Popup>
  )
}

export default memo(EditItemPopup)
