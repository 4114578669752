import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import notify from "devextreme/ui/notify"
import {
  getProjectsAsync,
  getProjectByIdAsync,
  getSectionByIdAsync,
  recalculateCarbonAsync,
  getEstimateLevelsAsync,
  updateResourcesAsync,
  updateItemsAsync,
  getItemsDetailsAsync,
  deleteBOQProjectItemAsync,
  deleteBOQProjectResourceAsync,
  getResourceDetailsAsync,
  synchronizeCarbonAsync,
} from "./services"
import {
  IProject,
  ISection,
  EstimateLevelAPIData,
  IResource,
  IItem,
  RecalculateThunkRequest,
} from "./types"

export const getProjectsAction = createAsyncThunk<IProject[]>(
  "estimates/getProjectsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: Array<IProject> | ErrorResponse = await getProjectsAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response as Array<IProject>
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getProjectByIdAction = createAsyncThunk<IProject, string | undefined>(
  "estimates/getProjectByIdAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: IProject | ErrorResponse = await getProjectByIdAsync(projectId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response as IProject
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getSectionByIdAction = createAsyncThunk<ISection, string | undefined>(
  "estimates/getSectionByIdAsync",
  async (sectionId, { rejectWithValue }) => {
    try {
      const response: ISection | ErrorResponse = await getSectionByIdAsync(sectionId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response as ISection
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const recalculateCarbonAction = createAsyncThunk<IProject, RecalculateThunkRequest>(
  "estimates/recalculateCarbonAsync",
  async (recalculateThunkRequest, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await recalculateCarbonAsync(recalculateThunkRequest)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const synchronizeCarbonAction = createAsyncThunk<IProject, string | undefined>(
  "estimates/synchronizeCarbonAsync",
  async (projectId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await synchronizeCarbonAsync(projectId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getEstimateLevelsAction = createAsyncThunk<any, EstimateLevelAPIData>(
  "estimates/getEstimateLevelsAsync",
  async (request: EstimateLevelAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateLevelsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      const finalRes =
        response?.data?.map((item: any) => ({
          ...item,
          PROJECT_SECTION_COMPLETED: item.PROJECT_SECTION_COMPLETED === "Y" ? 1 : 0,
        })) || []

      return finalRes
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getBOQItemsAndResourcesAction = createAsyncThunk<any, EstimateLevelAPIData>(
  "estimates/getBOQItemsAndResourcesAsync",
  async (request: EstimateLevelAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateLevelsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getItemDetailsAction = createAsyncThunk<IProject, number | string | undefined>(
  "estimates/getItemsDetailsAsync",
  async (itemId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getItemsDetailsAsync(itemId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getResourceDetailsAction = createAsyncThunk<IProject, number | string | undefined>(
  "estimates/getResourceDetailsAsync",
  async (resourceId, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getResourceDetailsAsync(resourceId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateResourcesAction = createAsyncThunk<any, Array<IResource>>(
  "estimates/updateResourcesAction",
  async (resources: Array<IResource>, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateResourcesAsync(resources)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("Resource Details updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateItemsAction = createAsyncThunk<any, Array<IItem>>(
  "estimates/updateItemsAction",
  async (items: Array<IItem>, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateItemsAsync(items)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      notify("Item data updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const deleteBOQProjectItemAction = createAsyncThunk<
  SuccessResponse<any>,
  { resourceId: number; projectId: number }
>("estimates/deleteBOQProjectItemAsync", async ({ resourceId, projectId }, { rejectWithValue }) => {
  const response: SuccessResponse<any> | ErrorResponse = await deleteBOQProjectItemAsync(
    resourceId,
    projectId
  )
  const errRes = (response as ErrorResponse)?.message

  if (errRes) {
    notify(errRes, "error", 2000)
    return rejectWithValue(errRes)
  }

  notify("Project Item deleted successfully.", "success", 2000)
  return response
})

export const deleteBOQProjectResourceAction = createAsyncThunk<
  SuccessResponse<any>,
  { resourceId: number; projectId: number }
>(
  "estimates/deleteBOQProjectResourceAsync",
  async ({ resourceId, projectId }, { rejectWithValue }) => {
    const response: SuccessResponse<any> | ErrorResponse = await deleteBOQProjectResourceAsync(
      resourceId,
      projectId
    )
    const errRes = (response as ErrorResponse)?.message

    if (errRes) {
      notify(errRes, "error", 2000)
      return rejectWithValue(errRes)
    }

    notify("Project Resource deleted successfully.", "success", 2000)
    return response
  }
)
