import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { get, isNumber } from "lodash"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { getCostEstimateTableDataAction } from "./middleware"
import { CostEstimateSummaryState, CostSummaryData } from "./types"

const initialState: CostEstimateSummaryState = {
  loading: LoadingState.DEFAULT,
  costEstimateReportData: [],
  showPortfolioRisk: false,
  showInflation: false,
  carbSwitches: {
    showMLCarb: false,
    showPortfolioCarb: false,
    showProjectCarb: false,
    showInflationCarb: false,
  },
}

const costEstimateSummarySlice = createSlice({
  name: "costEstimateSummary",
  initialState,
  reducers: {
    setLoading: (state: CostEstimateSummaryState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setPortfolioRisk: (state: CostEstimateSummaryState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showPortfolioRisk: payload,
    }),
    setInflation: (state: CostEstimateSummaryState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showInflation: payload,
    }),
    setCostReportCarbonSwitches: (
      state: CostEstimateSummaryState,
      { payload }: PayloadAction<{ key: string; value: boolean }>
    ) => {
      const { carbSwitches } = state
      const { key, value } = payload
      const obj = () => {
        switch (key) {
          case "ML":
            return { ...carbSwitches, showMLCarb: value }
          case "project":
            return { ...carbSwitches, showProjectCarb: value }
          case "portfolio":
            return { ...carbSwitches, showPortfolioCarb: value }
          case "inflation":
            return { ...carbSwitches, showInflationCarb: value }
          default:
            return carbSwitches
        }
      }

      return {
        ...state,
        carbSwitches: obj(),
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCostEstimateTableDataAction.pending, (state: CostEstimateSummaryState) => ({
      ...state,
      loading: LoadingState.LOADING,
      costEstimateReportData: [],
    }))
    builder.addCase(
      getCostEstimateTableDataAction.fulfilled,
      (state: CostEstimateSummaryState, { payload }: PayloadAction<CostSummaryData[]>) => {
        const costEstimateReportData = payload
          .map((item) => {
            const updatedData = Object.keys(item).reduce((acc, curr) => {
              const val = get(item, curr)
              const carbonKeys = ["A1_A3", "A4", "A5A", "A5W", "TOTAL_A1_A5"]
              const subArr = carbonKeys.filter((str) => curr.includes(str))
              if (isNumber(val) || subArr.length) {
                return { ...acc, [curr]: Math.round((val || 0) * 100) / 100 || 0 }
              }

              return { ...acc, [curr]: val }
            }, {})
            return { ...item, ...updatedData }
          })
          .map((item) => {
            const TOTAL_COST =
              Math.round(
                ((get(item, "BASE_ML") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK") || 0) +
                  (get(item, "PORTFOLIO_RISK_COST") || 0) +
                  (get(item, "TOTAL_INFLATION") || 0)) *
                  100
              ) / 100
            const TOTAL_CARBON =
              Math.round(
                ((get(item, "TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK_TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_PORTFOLIO_RISK_TOTAL_A1_A5") || 0) +
                  (get(item, "INFLATION_TOTAL_A1_A5") || 0)) *
                  100
              ) / 100

            const TOTAL_COST_PROJECTRISK_PORTFOLIO =
              Math.round(
                ((get(item, "BASE_ML") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK") || 0) +
                  (get(item, "PORTFOLIO_RISK_COST") || 0)) *
                  100
              ) / 100
            const TOTAL_CARBON_PROJECTRISK_PORTFOLIO =
              Math.round(
                ((get(item, "TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK_TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_PORTFOLIO_RISK_TOTAL_A1_A5") || 0)) *
                  100
              ) / 100

            const TOTAL_COST_PROJECTRISK_INFLATION =
              Math.round(
                ((get(item, "BASE_ML") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK") || 0) +
                  (get(item, "TOTAL_INFLATION") || 0)) *
                  100
              ) / 100
            const TOTAL_CARBON_PROJECTRISK_INFLATION =
              Math.round(
                ((get(item, "TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_PROJECT_RISK_TOTAL_A1_A5") || 0) +
                  (get(item, "INFLATION_TOTAL_A1_A5") || 0)) *
                  100
              ) / 100

            const TOTAL_ML_COST =
              Math.round(
                ((get(item, "TOTAL_PROJECT_RISK") || 0) + (get(item, "BASE_ML") || 0)) * 100
              ) / 100
            const TOTAL_ML_CARBON =
              Math.round(
                ((get(item, "TOTAL_PROJECT_RISK_TOTAL_A1_A5") || 0) +
                  (get(item, "TOTAL_A1_A5") || 0)) *
                  100
              ) / 100

            return {
              ...item,
              TOTAL_COST,
              TOTAL_CARBON,
              TOTAL_ML_COST,
              TOTAL_ML_CARBON,
              TOTAL_COST_PROJECTRISK_PORTFOLIO,
              TOTAL_CARBON_PROJECTRISK_PORTFOLIO,
              TOTAL_COST_PROJECTRISK_INFLATION,
              TOTAL_CARBON_PROJECTRISK_INFLATION,
            }
          })

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          costEstimateReportData,
          showPortfolioRisk: false,
          showInflation: false,
          carbSwitches: {
            showMLCarb: false,
            showPortfolioCarb: false,
            showProjectCarb: false,
            showInflationCarb: false,
          },
        }
      }
    )
    builder.addCase(getCostEstimateTableDataAction.rejected, (state: CostEstimateSummaryState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const { setLoading, setPortfolioRisk, setInflation, setCostReportCarbonSwitches, reset } =
  costEstimateSummarySlice.actions

export const costEstimateSummarySelector = (state: RootState) => state.CostEstimateSummary

export default costEstimateSummarySlice.reducer
