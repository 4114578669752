/* eslint-disable import/prefer-default-export */
import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { ProfileInfo, UserRole } from "./types"

export const fetchProfileAsync = async () => {
  try {
    const response = await axiosInstance.get<ProfileInfo>(`/bes/profile`)
    return response.data as ProfileInfo
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchUserRolePermissionAsync = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<UserRole> | ErrorResponse>(
      `/currentUserInfo`
    )
    return response.data as SuccessResponse<UserRole>
  } catch (err) {
    return isAxiosError(err)
  }
}
