import { useRef, useCallback, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form"
import LoadIndicator from "devextreme-react/load-indicator"
import "./signup-form.scss"
import { useSelector } from "react-redux"
import { authSelector, setLoading } from "redux/auth/authSlice"
import { useAppDispatch } from "redux/store"
import { LoadingState } from "types/AppNav"
import { createAccountAction } from "redux/auth/middleware"
import { Trans, useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"

const SignUpForm = () => {
  useDocumentTitle("Signup | Cost and Carbon Forecasting tool")
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading } = useSelector(authSelector)
  const dispatch = useAppDispatch()
  const formData = useRef({ username: "", email: "", password: "" })

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { username, email, password } = formData.current
      dispatch(setLoading(LoadingState.LOADING))
      dispatch(createAccountAction({ username, email, password }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (loading === LoadingState.SUCCESS) {
      navigate("/login")
      dispatch(setLoading(LoadingState.DEFAULT))
    }
  }, [loading])

  const confirmPassword = ({ value }: any) => value === formData.current.password

  return (
    <form className="create-account-form" onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading === LoadingState.LOADING}>
        <Item
          dataField="username"
          editorType="dxTextBox"
          editorOptions={{ stylingMode: "filled", placeholder: t("enterUserName"), mode: "text" }}
        >
          <RequiredRule message={t("emptyUserName").toString()} />
          <Label visible={false} />
        </Item>
        <Item
          dataField="email"
          editorType="dxTextBox"
          editorOptions={{ stylingMode: "filled", placeholder: t("enterEmail"), mode: "email" }}
        >
          <RequiredRule message={t("emptyEmail").toString()} />
          <EmailRule message={t("invalidEmail").toString()} />
          <Label visible={false} />
        </Item>
        <Item
          dataField="password"
          editorType="dxTextBox"
          editorOptions={{
            stylingMode: "filled",
            placeholder: t("enterPassword"),
            mode: "password",
          }}
        >
          <RequiredRule message={t("emptyPassword").toString()} />
          <Label visible={false} />
        </Item>
        <Item
          dataField="confirmedPassword"
          editorType="dxTextBox"
          editorOptions={{
            stylingMode: "filled",
            placeholder: t("confirmPassword"),
            mode: "password",
          }}
        >
          <RequiredRule message={t("emptyPassword").toString()} />
          <CustomRule
            message={t("passwordMatch").toString()}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item>
          <div className="policy-info">
            <Trans
              t={t}
              i18nKey="signUpTermsText"
              components={[
                <Link key={0} to="/terms-of-service" />,
                <Link key={1} to="/privacy-policy" />,
              ]}
            />
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions width="100%" type="default" useSubmitBehavior>
            <span className="dx-button-text">
              {loading === LoadingState.LOADING ? (
                <LoadIndicator width="24px" height="24px" visible />
              ) : (
                t("createAccount")
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className="login-link">
            <Trans t={t} i18nKey="haveAnAccount" components={[<Link key={0} to="/login" />]} />
          </div>
        </Item>
      </Form>
    </form>
  )
}

export default SignUpForm
