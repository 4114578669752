import { useTranslation } from "react-i18next"
import RangeEstimateDistTable from "./rangeEstimateDistTable"

const KeyDataBody = () => {
  const { t } = useTranslation()

  return (
    <div className="keyDataBody">
      <h6>{t("keyData.rangeEstDist")}</h6>
      <RangeEstimateDistTable />
    </div>
  )
}

export default KeyDataBody
