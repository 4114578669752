import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { fetchCodeItemsByCategoryAsync } from "redux/masters/services"
import { CodeItems } from "redux/masters/types"
import { ErrorResponse } from "services/SuccessResponse"
import {
  getContractorEstimateLibraryAsync,
  getResourceEpdAsync,
  getSpecificLibraryDetailsAsync,
  postBulkEstimatesLibraryAsync,
  postContractorEstimateLibraryAsync,
  putBulkEstimatesLibraryAsync,
  createResourceEpdAsync,
  updateResourceEpdAsync,
  uploadResourceEpdAsync,
  applyEstimateLibraryAsync,
  getEPDFileAsync,
  getAssuredLibraryData,
} from "./services"
import {
  ContractorEstimateLibraryAPIData,
  EstimateLibraryItem,
  IContractorEstimateLibrary,
  IEpd,
  SpecificLibraryAPIData,
} from "./types"

export const getContractorEstimateLibrary = createAsyncThunk<any, ContractorEstimateLibraryAPIData>(
  "getContractorEstimateLibrary",
  async (request: ContractorEstimateLibraryAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getContractorEstimateLibraryAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const postContractorEstimateLibrary = createAsyncThunk<
  any,
  ContractorEstimateLibraryAPIData
>(
  "postContractorEstimateLibrary",
  async (request: ContractorEstimateLibraryAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await postContractorEstimateLibraryAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      const listRes: any | ErrorResponse = await getContractorEstimateLibraryAsync(request)
      const listResError = response as ErrorResponse
      if (listResError?.code) {
        notify(listResError.message, "error", 2000)
        return rejectWithValue(listResError.message)
      }
      notify("New Library added successfully.", "success", 2000)
      return listRes.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getSpecificLibraryDetails = createAsyncThunk<any, SpecificLibraryAPIData>(
  "getSpecificLibraryDetails",
  async (request: SpecificLibraryAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getSpecificLibraryDetailsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const postBulkEstimatesLibrary = createAsyncThunk<any, EstimateLibraryItem[]>(
  "postBulkEstimatesLibrary",
  async (request: EstimateLibraryItem[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await postBulkEstimatesLibraryAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Bulk estimates library added successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const putBulkEstimatesLibrary = createAsyncThunk<any, EstimateLibraryItem[]>(
  "putBulkEstimatesLibrary",
  async (request: EstimateLibraryItem[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await putBulkEstimatesLibraryAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      notify("Bulk estimates library updated successfully.", "success", 2000)
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const fetchCodesByCategoryAction = createAsyncThunk<CodeItems[], string>(
  "master/fetchCodesByCategory",
  async (category: string, { rejectWithValue }) => {
    try {
      const response: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(category)
      const errRes = response as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes.message)
      }
      const codeItems: CodeItems[] = (response as BenchmarkDataContainer[]).map(
        (container: BenchmarkDataContainer) =>
          ({
            name: container.Properties[1].Data,
            description: container.Properties[2].Data,
            id: container.Properties[0].Data,
          } as unknown as CodeItems)
      )
      return codeItems
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchWrapCategoryAction = createAsyncThunk<CodeItems[]>(
  "master/fetchWrapCategoryAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync("WRAP Category")
      const errRes = response as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes.message)
      }
      const codeItems: CodeItems[] = (response as BenchmarkDataContainer[]).map(
        (container: BenchmarkDataContainer) =>
          ({
            name: container.Properties[1].Data,
            description: container.Properties[2].Data,
            id: container.Properties[0].Data,
          } as unknown as CodeItems)
      )
      return codeItems
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchFuelLaborCategoryAction = createAsyncThunk<CodeItems[]>(
  "master/fetchFuelLaborCategoryAction",
  async (_, { rejectWithValue }) => {
    try {
      const response: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync("Fuel or Labour Type")
      const errRes = response as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes.message)
      }
      const codeItems: CodeItems[] = (response as BenchmarkDataContainer[]).map(
        (container: BenchmarkDataContainer) =>
          ({
            name: container.Properties[1].Data,
            description: container.Properties[2].Data,
            id: container.Properties[0].Data,
          } as unknown as CodeItems)
      )
      return codeItems
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getResourceEpdAction = createAsyncThunk<IEpd[], number>(
  "estimateLibrary/getResourceEpd",
  async (epdId: number, { rejectWithValue }) => {
    try {
      const response: IEpd[] | ErrorResponse = await getResourceEpdAsync(epdId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response as IEpd[]
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const createResourceEpdAction = createAsyncThunk<any, IEpd>(
  "estimateLibrary/createResourceEpd",
  async (epd: IEpd, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await createResourceEpdAsync(epd)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateResourceEpdAction = createAsyncThunk<any, IEpd>(
  "estimateLibrary/updateResourceEpd",
  async (epd: IEpd, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateResourceEpdAsync(epd)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const uploadResourceEpdAction = createAsyncThunk<any, Blob>(
  "estimateLibrary/uploadResourceEpd",
  async (file: Blob, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await uploadResourceEpdAsync(file)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const applyEstimateLibraryAction = createAsyncThunk<any, IContractorEstimateLibrary>(
  "estimateLibrary/applyEstimateLibrary",
  async (lib: IContractorEstimateLibrary, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await applyEstimateLibraryAsync(lib)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getEpdFileAction = createAsyncThunk<string, number>(
  "estimateLibrary/getEpdFileAction",
  async (epdId: number, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEPDFileAsync(epdId)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response as string
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getAssuredLibraryDataAction = createAsyncThunk<string>(
  "estimateLibrary/getAssuredLibraryData",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getAssuredLibraryData()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
