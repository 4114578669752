/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { get, isEqual, uniqBy } from "lodash"
import { UserRole } from "redux/profile/types"
import { ResourceType } from "redux/projects/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import {
  allRoles,
  addClass,
  camelToKebab,
  renderCurrencyCell,
  identifyPermissions,
  numberFormatterConfig,
  DataGridColumnDataTypeProps,
} from "utils/common-utils"
import { aggregateOptions } from "../../utils"

export const estimateLevelColumnsGenerator = (userRole: UserRole | null) => {
  const permsnCheck = identifyPermissions(
    userRole,
    AccessType.SHOW_COST_DATA,
    AccessLevel.YES,
    allRoles
  )

  return [
    {
      key: 1,
      dataField: "",
      caption: "nil",
      visible: (aggregateBy: any) => true,
      allowSorting: true,
    },
    {
      key: 2,
      dataField: "PROJECT_SECTION_NUMBER",
      caption: "estimateLevel.tableCol1Head",
      visible: (aggregateBy: any) => true,
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 3,
      dataField: "PROJECT_SECTION_DESC",
      caption: "estimateLevel.tableCol2Head",
      width: 380,
      visible: (aggregateBy: any) => true,
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 4,
      dataField: "PROJECT_ITEMAGGR_CODE",
      caption: "estimateLevel.tableCol3Head",
      visible: (aggregateBy: any) => !isEqual(aggregateOptions[0], aggregateBy),
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 5,
      dataField: "PROJECT_ITEMAGGR_DESC",
      caption: "estimateLevel.tableCol4Head",
      width: 360,
      visible: (aggregateBy: any) => !isEqual(aggregateOptions[0], aggregateBy),
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 6,
      dataField: "PROJECT_RESOURCE_CODE",
      caption: "estimateLevel.tableCol5Head",
      visible: (aggregateBy: any) => isEqual(aggregateOptions[2], aggregateBy),
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 7,
      dataField: "PROJECT_RESAGGR_DESC",
      caption: "estimateLevel.tableCol6Head",
      visible: (aggregateBy: any) => isEqual(aggregateOptions[2], aggregateBy),
      cssClass: "tree-grid-column-bold",
    },
    {
      key: 8,
      dataField: "a1a3",
      caption: "estimateLevel.tableCol7Head",
      visible: (aggregateBy: any) => true,
      dataType: "number" as DataGridColumnDataTypeProps,
      format: numberFormatterConfig,
    },
    {
      key: 9,
      dataField: "a4",
      caption: "estimateLevel.tableCol8Head",
      visible: (aggregateBy: any) => true,
      dataType: "number" as DataGridColumnDataTypeProps,
      format: numberFormatterConfig,
    },
    {
      key: 10,
      dataField: "a5a",
      caption: "estimateLevel.tableCol9Head",
      visible: (aggregateBy: any) => true,
      dataType: "number" as DataGridColumnDataTypeProps,
      format: numberFormatterConfig,
    },
    {
      key: 11,
      dataField: "a5w",
      caption: "estimateLevel.tableCol10Head",
      visible: (aggregateBy: any) => true,
      dataType: "number" as DataGridColumnDataTypeProps,
      format: numberFormatterConfig,
    },
    {
      key: 12,
      dataField: "totala1a5",
      caption: "estimateLevel.tableCol11Head",
      visible: (aggregateBy: any) => true,
      dataType: "number" as DataGridColumnDataTypeProps,
      format: numberFormatterConfig,
    },
    {
      key: 13,
      dataField: "totalCost",
      caption: "estimateLevel.tableCol12Head",
      visible: (aggregateBy: any) => true,
      cellRender: (row: any) => (permsnCheck ? renderCurrencyCell(row.data.totalCost) : null),
    },
  ]
}

const getParentIdArr = (item: string | null) => {
  const ParentIDArr = item && JSON.parse(item.replaceAll("][", ","))

  return ParentIDArr || []
}

const getParentId = (item: string | null) => {
  const ParentIDArr = getParentIdArr(item)
  const ParentID = ParentIDArr.length ? ParentIDArr.pop() : 0

  return ParentID
}

export const filterDataSourceFn = (dataSource: any[], aggregateBy: string) => {
  let processedData = dataSource || []

  if (dataSource.length) {
    switch (aggregateBy) {
      case "RESOURCE":
        processedData = dataSource.map((item) => {
          return {
            ...item,
            id: item.PROJECT_RESOURCE_ID || item.PROJECT_ITEM_ID,
            Parent_ID: getParentId(item.PROJECT_ITEMAGGR_HIERARCHY),
            a1a3: item.PROJECT_RESOURCE_CARB_A1_A3 || item.PROJECT_ITEM_CARB_A1_A3 || 0.0,
            a4: item.PROJECT_RESOURCE_CARB_A4 || item.PROJECT_ITEM_CARB_A4 || 0.0,
            a5a: item.PROJECT_RESOURCE_CARB_A5A || item.PROJECT_ITEM_CARB_A5A || 0.0,
            a5w: item.PROJECT_RESOURCE_CARB_A5W || item.PROJECT_ITEM_CARB_A5W || 0.0,
            totala1a5:
              item.PROJECT_RESOURCE_CARB_TOTAL_A1_A5 || item.PROJECT_ITEM_CARB_TOTAL_A1_A5 || 0.0,
            totalCost: item.PROJECT_RESOURCE_COST || item.PROJECT_ITEM_COST || 0.0,
          }
        })
        break
      case "ITEM":
        processedData = dataSource.map((item) => {
          return {
            ...item,
            id: item.PROJECT_ITEM_ID,
            Parent_ID: getParentId(item.PROJECT_ITEMAGGR_HIERARCHY),
            a1a3: item.PROJECT_ITEM_CARB_A1_A3 || 0.0,
            a4: item.PROJECT_ITEM_CARB_A4 || 0.0,
            a5a: item.PROJECT_ITEM_CARB_A5A || 0.0,
            a5w: item.PROJECT_ITEM_CARB_A5W || 0.0,
            totala1a5: item.PROJECT_ITEM_CARB_TOTAL_A1_A5 || 0.0,
            totalCost: item.PROJECT_ITEM_COST || 0.0,
          }
        })
        break
      default:
        processedData = dataSource.map((item) => ({
          ...item,
          id: item.PROJECT_SECTION_ID,
          Parent_ID: 0,
          a1a3: item.PROJECT_SECTION_CARB_A1_A3 || 0.0,
          a4: item.PROJECT_SECTION_CARB_A4 || 0.0,
          a5a: item.PROJECT_SECTION_CARB_A5A || 0.0,
          a5w: item.PROJECT_SECTION_CARB_A5W || 0.0,
          totala1a5: item.PROJECT_SECTION_CARB_TOTAL_A1_A5 || 0.0,
          totalCost: item.PROJECT_SECTION_COST || 0.0,
        }))
    }

    processedData = uniqBy(
      processedData.sort((a, b) => a.PROJECT_SECTION_NUMBER - b.PROJECT_SECTION_NUMBER),
      "id"
    )
  }

  return processedData
}

export const onRowPrepared = (e: any) => {
  if (e && e.rowType === "data") {
    const { rowElement, node } = e

    if (typeof get(node, "data.PROJECT_ITEM_COMPOSITETYPE") === "number")
      addClass(rowElement, camelToKebab(ResourceType[get(node, "data.PROJECT_ITEM_COMPOSITETYPE")]))
  }
}
