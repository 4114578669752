import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import isNaN from "lodash/isNaN"
import DataGrid, {
  AsyncRule,
  Column,
  Editing,
  FilterRow,
  Grouping,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import Button from "devextreme-react/button"
import CustomStore from "devextreme/data/custom_store"
import BreadCrumb from "components/bread-crumb"
import {
  fetchAllFuelTypeAction,
  updateFuelItemValueAction,
} from "redux/basline-assumptions/fuel-type/middleware"
import {
  fuelTypeSelector,
  setCarbonCodeForFuelType,
  updateFuelTypeData,
} from "redux/basline-assumptions/fuel-type/fuelTypeSlice"
import { MasterState } from "redux/masters/types"
import { sharedSelector } from "redux/shared/sharedSlice"
import { masterSelector } from "redux/masters/masterSlice"
import { fetchCarbonCodeCategoriesAction } from "redux/masters/middleware"
import { FuelTypeState, UpdateFuelTypeRequest } from "redux/basline-assumptions/fuel-type/types"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import "./fuel-type.scss"
import useDocumentTitle from "hooks/useDocumentTitle"

const FuelType: React.FC = (): JSX.Element => {
  useDocumentTitle("Fuel Type | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const dataGridRef = useRef<DataGrid>(null)
  const [isEditableColumn, setEditableColumn] = useState<boolean>(false)

  const { windowHeight } = useSelector(sharedSelector)
  const { carbonCodeCategory }: MasterState = useSelector(masterSelector)
  const { fuelItems, carbonCodeItem }: FuelTypeState = useSelector(fuelTypeSelector)

  const setSaveRevertToolbar = (value: boolean) => {
    dataGridRef.current?.instance.option("toolbar.items[1].disabled", value) // save
    dataGridRef.current?.instance.option("toolbar.items[2].disabled", value) // revert
  }

  const store = useMemo(
    () =>
      new CustomStore({
        load: () => fuelItems,
        key: "id",
        update: () => {
          setEditableColumn(false)
          dataGridRef.current?.instance.option("toolbar.items[0].disabled", false)
          setSaveRevertToolbar(true)
          return Promise.resolve()
        },
      }),
    [fuelItems]
  )

  useEffect(() => {
    if (!carbonCodeItem && carbonCodeCategory.length === 0) {
      dispatch(fetchCarbonCodeCategoriesAction())
    } else if (carbonCodeCategory.length > 0 && !carbonCodeItem) {
      dispatch(setCarbonCodeForFuelType(carbonCodeCategory))
    } else if (carbonCodeItem) {
      dispatch(fetchAllFuelTypeAction(carbonCodeItem))
    }
    setSaveRevertToolbar(true)
  }, [carbonCodeItem, carbonCodeCategory])

  const handleSaveClick = () => dataGridRef.current?.instance.saveEditData()

  const handleEditClick = () => {
    setEditableColumn(!isEditableColumn)
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
  }

  const handleInitNewRowOrStartEditing = () => setSaveRevertToolbar(false)

  const handleRevertAll = () => {
    dataGridRef.current?.instance.cancelEditData()
    setSaveRevertToolbar(true)
  }

  const handleValueValidation = (params: any) => {
    if (isNaN(Number(params.value))) {
      return Promise.reject()
    }
    return Promise.resolve(true)
  }

  return (
    <div className="fuel-type-container">
      <BreadCrumb
        data={[
          {
            name: t("menu.fuelType"),
          },
        ]}
      />
      <DataGrid
        id="fuel-type-grid"
        dataSource={store}
        ref={dataGridRef}
        showBorders
        columnAutoWidth
        highlightChanges
        repaintChangesOnly
        showColumnLines
        allowColumnReordering
        noDataText={t("noRecords")}
        onEditingStart={handleInitNewRowOrStartEditing}
        onSaving={(elements) => {
          dispatch(updateFuelTypeData(elements.changes as UpdateFuelTypeRequest[]))
          dispatch(updateFuelItemValueAction(elements.changes as UpdateFuelTypeRequest[]))
        }}
        height={windowHeight - 120}
      >
        <FilterRow visible />
        <HeaderFilter visible />
        <Grouping autoExpandAll />
        <SearchPanel visible width={340} />
        <Editing mode="batch" allowUpdating />
        <Paging defaultPageSize={defaultPageSize} />
        <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.edit")}>
              <Button icon="edit" stylingMode="text" text="" onClick={handleEditClick} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" text="" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item location="after">
            <span title={t("toolbarActions.undoAll")}>
              <Button icon="revert" stylingMode="text" text="" onClick={handleRevertAll} />
            </span>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item name="groupPanel" location="after" />
        </Toolbar>
        <Column
          dataField="fuelType.Name"
          caption={t("fuelTypeScreen.fuelType")}
          dataType="string"
          allowHiding
          allowEditing={false}
          defaultGroupIndex={0}
          defaultSortOrder="asc"
          allowHeaderFiltering={false}
        />
        <Column
          dataField="unit.Name"
          caption={t("fuelTypeScreen.fuelUnits")}
          allowHeaderFiltering
          allowEditing={false}
        />
        <Column
          dataField="value"
          caption={t("fuelTypeScreen.carbonFactor")}
          alignment="left"
          dataType="number"
          allowHeaderFiltering
          allowEditing={isEditableColumn}
        >
          <AsyncRule
            ignoreEmptyValue
            validationCallback={handleValueValidation}
            message={t("fuelTypeScreen.carbonFactorValidation")}
          />
        </Column>
      </DataGrid>
    </div>
  )
}

export default FuelType
