// import AuthorityEstimateLibrary from "./authorityEstimateLibrary"
import useDocumentTitle from "hooks/useDocumentTitle"
import ContractorEstimateLibrary from "./contractorEstimateLibrary"
import "./project-estimate-library-setup.scss"

const EstimateLibrarySetup: React.FC = (): JSX.Element => {
  useDocumentTitle("Estimate Library Setup | Cost and Carbon Forecasting tool")
  return (
    <>
      {/* <AuthorityEstimateLibrary /> */}
      <ContractorEstimateLibrary />
    </>
  )
}
export default EstimateLibrarySetup
