import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import isNaN from "lodash/isNaN"
import { setLoading } from "redux/auth/authSlice"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { fetchCodeItemsByCategoryAsync } from "redux/masters/services"
import { CarbonCodesCategoryItem, CodeItems } from "redux/masters/types"
import { RootState } from "redux/store"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { LoadingState } from "types/AppNav"
import { extractResponseData } from "utils/common-utils"
import { v4 as uuidv4 } from "uuid"
import {
  createCarbonValueForWasteFactorAsync,
  fetchWasteFactorizationValuesAsync,
} from "./services"
import { CarbonWasteFactorItem, UpdateWasteFactorRequest, WasteFactorItem } from "./types"

export const fetchAllWasteFactorizationAction = createAsyncThunk<
  WasteFactorItem[],
  CarbonCodesCategoryItem
>(
  "wasteFactor/fetchAllWasteFactorActions",
  async (carbonCodeCategory: CarbonCodesCategoryItem, { rejectWithValue }) => {
    const data: WasteFactorItem[] = []
    try {
      // First fetch all fuel types codes....
      const codesResponse: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(carbonCodeCategory?.CUSTOM_LIST_NAME || "")
      const codesErrorRes = codesResponse as ErrorResponse
      if (codesErrorRes.code) {
        return rejectWithValue(codesErrorRes.message)
      }
      const wasteFactorCodes: CodeItems[] = extractResponseData(
        codesResponse as BenchmarkDataContainer[]
      )

      if (wasteFactorCodes.length > 0) {
        wasteFactorCodes.forEach((code: CodeItems) => {
          data.push({
            id: uuidv4(),
            code,
          })
        })
      }

      // fetch existing data for the fuel type entries.
      const WasteFactorWithValuesResponse:
        | SuccessResponse<CarbonWasteFactorItem[]>
        | ErrorResponse = await fetchWasteFactorizationValuesAsync(
        Number(carbonCodeCategory.CUSTOM_LIST_ID)
      )
      const errRes = WasteFactorWithValuesResponse as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes)
      }

      const carbonResponseItem: CarbonWasteFactorItem[] =
        (WasteFactorWithValuesResponse as SuccessResponse<CarbonWasteFactorItem[]>).data || []

      const finalTransportItems: WasteFactorItem[] = data.map((item: WasteFactorItem) => {
        const transportFound = carbonResponseItem.find(
          (carbonVal: CarbonWasteFactorItem) =>
            Number(item.code?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
        )

        if (transportFound) {
          return {
            ...item,
            id: transportFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
            value: transportFound.CARBON_VALUE,
          }
        }
        return item
      })
      return finalTransportItems
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateTranportValueAction = createAsyncThunk<
  WasteFactorItem[],
  UpdateWasteFactorRequest[]
>(
  "updateTranportValueAction",
  async (request: UpdateWasteFactorRequest[], { dispatch, rejectWithValue, getState }) => {
    try {
      const wasteFactorState = (getState() as RootState).WasteFactor || []

      const requestFuelItems: WasteFactorItem[] =
        request
          .map((item: UpdateWasteFactorRequest) => {
            const transportObj = wasteFactorState.wasteFactor.find(
              (tranportItem: WasteFactorItem) => tranportItem.id === item.key
            )
            if (transportObj) {
              item.data = { ...transportObj, value: item.data.value }
              return item
            }
            return item
          })
          .map((item: UpdateWasteFactorRequest) => item.data) || []

      const finalRequest: CarbonWasteFactorItem[] = requestFuelItems.map(
        (item: WasteFactorItem) => {
          const carbonTransportItem: CarbonWasteFactorItem = {
            CUSTOM_LIST_ID: wasteFactorState.carbonCodeItem?.CUSTOM_LIST_ID || 0,
            CUSTOM_LIST_CONTENT_ID: Number(item.code?.Id || 0),
            CARBON_VALUE: Number(item.value),
          }

          if (!isNaN(Number(item.id))) {
            carbonTransportItem.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID = Number(item.id)
          }

          return carbonTransportItem
        }
      )

      dispatch(setLoading(LoadingState.LOADING))
      const response: SuccessResponse<CarbonWasteFactorItem[]> | ErrorResponse =
        await createCarbonValueForWasteFactorAsync(finalRequest)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }

      // once api success.
      if ((response as SuccessResponse<CarbonWasteFactorItem[]>).statusCode === 200) {
        const fuelItemsWithValuesResponse:
          | SuccessResponse<CarbonWasteFactorItem[]>
          | ErrorResponse = await fetchWasteFactorizationValuesAsync(
          Number(wasteFactorState.carbonCodeItem?.CUSTOM_LIST_ID)
        )
        const errRes = fuelItemsWithValuesResponse as ErrorResponse
        if (errRes.code) {
          return rejectWithValue(errRes)
        }

        const carbonFuelItems: CarbonWasteFactorItem[] =
          (fuelItemsWithValuesResponse as SuccessResponse<CarbonWasteFactorItem[]>).data || []

        const finalFuelItems: WasteFactorItem[] = wasteFactorState.wasteFactor.map(
          (item: WasteFactorItem) => {
            const fuelItemFound = carbonFuelItems.find(
              (carbonVal: CarbonWasteFactorItem) =>
                Number(item.code?.Id) === carbonVal.CUSTOM_LIST_CONTENT_ID
            )

            if (fuelItemFound) {
              return {
                ...item,
                id: fuelItemFound.CUSTOM_LIST_CONTENT_UNIT_AGGR_ID?.toString(),
                value: fuelItemFound.CARBON_VALUE,
              }
            }
            return item
          }
        )

        return finalFuelItems
      }

      return []
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
