import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import axiosInstance, { isAxiosError } from "services/api"
import {
  ContractorEstimateLibraryAPIData,
  EstimateLibraryItem,
  IContractorEstimateLibrary,
  IEpd,
  SpecificLibraryAPIData,
} from "./types"

export const getContractorEstimateLibraryAsync = async (data: ContractorEstimateLibraryAPIData) => {
  const { projectId: estimateId } = data
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/contract-estimate-library/project/${estimateId}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const postContractorEstimateLibraryAsync = async (
  data: ContractorEstimateLibraryAPIData
) => {
  const { projectId, title } = data
  const finalData = {
    PROJECT_ID: projectId,
    TITLE: title,
  }
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/contract-estimate-library`,
      finalData
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getSpecificLibraryDetailsAsync = async (data: SpecificLibraryAPIData) => {
  const { projectId, contractorEstimateId } = data
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/contract-estimate-library/project/${projectId}/library/${contractorEstimateId}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const postBulkEstimatesLibraryAsync = async (items: EstimateLibraryItem[]) => {
  try {
    const response = await axiosInstance.post<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/contract-estimate-library/content/bulk`,
      { bulk: items }
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const putBulkEstimatesLibraryAsync = async (items: EstimateLibraryItem[]) => {
  try {
    const response = await axiosInstance.put<SuccessResponse<{ OK: number }> | ErrorResponse>(
      `/contract-estimate-library/content/bulk`,
      { bulk: items }
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getResourceEpdAsync = async (epdId: number) => {
  try {
    const response = await axiosInstance.get(`/resourceEpd/${epdId}`)
    const mappedRes = response.data.data.map((epd: IEpd) => ({
      CARB_EPD_ID: epd.CARB_EPD_ID || null,
      CARB_EPD_A1_A3_ISINCL: epd.CARB_EPD_A1_A3_ISINCL,
      CARB_EPD_A4_ISINCL: epd.CARB_EPD_A4_ISINCL,
      CARB_EPD_A5_ISINCL: epd.CARB_EPD_A5_ISINCL,
      CARB_EPD_CODE: epd.CARB_EPD_CODE,
      CARB_EPD_DESC: epd.CARB_EPD_DESC,
      CARB_EPD_EXP_DATE: epd.CARB_EPD_EXP_DATE,
      CARB_EPD_GWP_A1_A3: epd.CARB_EPD_GWP_A1_A3,
      CARB_EPD_GWP_A4: epd.CARB_EPD_GWP_A4,
      CARB_EPD_GWP_A5: epd.CARB_EPD_GWP_A5,
      CARB_EPD_ISSUE_DATE: epd.CARB_EPD_ISSUE_DATE,
      CARB_EPD_NOTE: epd.CARB_EPD_NOTE,
      UPLOAD_ID: epd.UPLOAD_ID,
      PATH: epd.PATH,
      MIMETYPE: epd.MIMETYPE,
      ORIGINAL_NAME: epd.ORIGINAL_NAME,
      BUCKET: epd.BUCKET,
    }))
    return mappedRes as IEpd[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const createResourceEpdAsync = async (epd: IEpd) => {
  try {
    const response = await axiosInstance.post(`/resourceEpd`, epd)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateResourceEpdAsync = async (epd: IEpd) => {
  try {
    const response = await axiosInstance.put(`/resourceEpd`, epd)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const uploadResourceEpdAsync = async (file: Blob) => {
  try {
    const response = await axiosInstance.post(
      `/file`,
      { file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*",
        },
      }
    )
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const applyEstimateLibraryAsync = async (lib: IContractorEstimateLibrary) => {
  try {
    const response = await axiosInstance.post(`/apply-estimate-library`, lib)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getEPDFileAsync = async (uploadId: number) => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/file/url/${uploadId}`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}

export const getAssuredLibraryData = async () => {
  try {
    const response = await axiosInstance.get<SuccessResponse<any> | ErrorResponse>(
      `/contract-estimate-library/content/assured`
    )
    return response.data
  } catch (error) {
    return isAxiosError(error)
  }
}
