import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { iftSelector } from "redux/ift/iftSlice"
import {
  fetchBaselineIFTScopeAction,
  fetchRevisionIFTScopeAction,
  fetchItemGroupIFTScopeAction,
  fetchSubItemGroupIFTScopeAction,
  fetchResourceGroupIFTScopeAction,
} from "redux/ift/middleware"
import useDocumentTitle from "hooks/useDocumentTitle"
import { getContractorEstimateLibrary } from "redux/project-estimate-library-setup/middleware"
import ScopeLoader from "./ScopeLoader"
import ScopeHeader from "./ScopeHeader"
import ScopeTable from "./ScopeTable"

const Scope: React.FC = (): JSX.Element => {
  useDocumentTitle("IFT Scope | Cost and Carbon Forecasting tool")
  const dispatch = useAppDispatch()
  const { projectId, estimateId, iftBaselineScopeData } = useSelector(iftSelector)

  useEffect(() => {
    if (!iftBaselineScopeData.length && projectId) {
      dispatch(fetchItemGroupIFTScopeAction())
      dispatch(fetchSubItemGroupIFTScopeAction())
      dispatch(fetchResourceGroupIFTScopeAction())
      dispatch(fetchBaselineIFTScopeAction({ projectId, estimateId }))
      if (estimateId) {
        dispatch(getContractorEstimateLibrary({ projectId: estimateId }))
        dispatch(fetchRevisionIFTScopeAction(estimateId))
      }
    }
  }, [projectId])

  return (
    <div className="scopeMain">
      <ScopeLoader />
      <ScopeHeader />
      <ScopeTable />
    </div>
  )
}

export default Scope
