import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PieChart, {
  Legend,
  Series,
  Tooltip,
  Label,
  Connector,
  Size,
} from "devextreme-react/pie-chart"
import { formatNumber } from "devextreme/localization"
import { sharedSelector } from "redux/shared/sharedSlice"
import { ICarbonEmissionDoughnutChartProps } from "redux/projects/types"
import "./carbon-emission-doughnut-chart.scss"

const CarbonEmissionDoughnutChart: React.FC<ICarbonEmissionDoughnutChartProps> = ({
  showPopUp,
  isPieChartPopUpVisible,
  data,
}: any) => {
  const { t } = useTranslation()
  const { windowHeight } = useSelector(sharedSelector)

  const onPointClick = (e: any) => {
    const point = e.target
    if (point.isSelected()) {
      point.clearSelection()
      document.body.classList.remove("pie-chart")
    } else {
      document.body.classList.add("pie-chart")
      point.select()
      if (showPopUp) showPopUp("visible")
    }
  }

  return (
    <div className="carbon-emission-doughnut-chart-wrapper">
      <PieChart
        id="pie"
        type="doughnut"
        title={t("sections.carbonEmissions")}
        palette="Ocean"
        onPointClick={onPointClick}
        dataSource={data}
        tooltip={{
          enabled: true,
          zIndex: 999999,
        }}
      >
        <Series argumentField="region">
          <Label visible format="percent" position="inside">
            <Connector visible />
          </Label>
        </Series>
        <Legend margin={20} horizontalAlignment="right" verticalAlignment="top" />
        <Tooltip
          enabled
          format={{
            formatter: (value: any) =>
              formatNumber(value, {
                precision: 2,
                type: "percent",
              }),
          }}
        />
        {isPieChartPopUpVisible && <Size height={windowHeight - 250} />}
      </PieChart>
    </div>
  )
}

export default CarbonEmissionDoughnutChart
