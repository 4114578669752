import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BreadCrumb from "components/bread-crumb"
import { Button, LoadPanel } from "devextreme-react"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { getEstimateRevisionByIdAction } from "redux/estimate-revision-management/middleware"
import {
  fetchCodesByCategoryAction,
  getAssuredLibraryDataAction,
  // fetchFuelLaborCategoryAction,
  // fetchWrapCategoryAction,
  getContractorEstimateLibrary,
  getSpecificLibraryDetails,
} from "redux/project-estimate-library-setup/middleware"
import {
  clearEstimateLibContent,
  estimateLibrarySelector,
  setSelectedEstimateLibrary,
} from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import { getProjectByIdAction } from "redux/projects/middleware"
import { projectSelector } from "redux/projects/projectSlice"
import { useAppDispatch } from "redux/store"
import { LoadingState } from "types/AppNav"
import useDocumentTitle from "hooks/useDocumentTitle"
import { config } from "utils/config"
import ContractorSpecificLibrary from "./contractorSpecificLibrary"
import LibraryManagementScreen from "./libraryManagementScreen"
import { estimateLibraryBreadCrumb } from "./utils"

const ContractorEstimateLibrary = () => {
  useDocumentTitle("Contractor Estimate Library | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { project } = useSelector(projectSelector)
  const { selectedRevision } = useSelector(estmteRvsnMgmntSelector)
  const { selectedEstimateLibrary, loading } = useSelector(estimateLibrarySelector)

  const { projectId, estimateId } = useParams()
  const [libraryApplied, setLibraryApplied] = useState(false)
  const [showContractorLibrary, setShowContactorLibrary] = useState(false)

  useEffect(() => {
    // dispatch(fetchWrapCategoryAction())
    // dispatch(fetchFuelLaborCategoryAction())
    dispatch(fetchCodesByCategoryAction("Mode of Transportation"))
    dispatch(getAssuredLibraryDataAction())
    if (!project) dispatch(getProjectByIdAction(projectId))
    if (!selectedRevision) dispatch(getEstimateRevisionByIdAction({ projectId: estimateId }))
  }, [])

  useEffect(() => {
    const { Id, LibraryApplied } = selectedRevision || {}
    if (Id && !LibraryApplied) dispatch(getContractorEstimateLibrary({ projectId: Id }))

    if (LibraryApplied && Id) {
      setLibraryApplied(true)
      clearEstimateLibContent([])
      dispatch(setSelectedEstimateLibrary(LibraryApplied))
      dispatch(
        getSpecificLibraryDetails({
          projectId: Id,
          contractorEstimateId: LibraryApplied,
        })
      )
    }
  }, [selectedRevision])

  const handleBackButtonClick = () => {
    dispatch(setSelectedEstimateLibrary(null))
    setShowContactorLibrary(false) // Set the showContractorLibrary to false when the back button is clicked
  }

  useEffect(() => {
    if (selectedEstimateLibrary) {
      const { Id } = selectedRevision || {}
      if (Id) {
        setShowContactorLibrary(true)
        clearEstimateLibContent([])
        dispatch(
          getSpecificLibraryDetails({
            projectId: Id,
            contractorEstimateId: selectedEstimateLibrary,
          })
        )
      } else {
        setShowContactorLibrary(false)
      }
    }
  }, [selectedEstimateLibrary])

  const proj = useMemo(() => (config.clientName === "HE" ? "Estimate" : "Project"), [])

  return (
    <div className="contractorLibrarySetupMain">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position="center"
        visible={loading === LoadingState.LOADING}
        showIndicator
        shading
        showPane
      />
      <BreadCrumb data={estimateLibraryBreadCrumb(project, selectedRevision)} />
      {libraryApplied ? (
        <>
          <br />
          <ContractorSpecificLibrary />
        </>
      ) : (
        <>
          {!showContractorLibrary && <LibraryManagementScreen />}
          {showContractorLibrary && (
            <>
              <Button
                onClick={handleBackButtonClick}
                icon="back"
                stylingMode="text"
                text={t("contractorEstimateLibrary.btnTextBackToLib", { proj })}
                type="default"
              />
              <ContractorSpecificLibrary />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ContractorEstimateLibrary
