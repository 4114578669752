import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { aggregateOptions } from "screens/reports/estimate-level/utils"
import { getEstimateLevelsAction, getProjectIDsAction } from "./middleware"
import { EstimateLevelResponse, EstimateLevelState } from "./types"

const initialState: EstimateLevelState = {
  loading: LoadingState.DEFAULT,
  aggregateBy: aggregateOptions[0],
  estimateNumber: 0,
  estimateName: "",
  estimateDesc: "",
  estimateLevels: [],
  projectIDs: [],
}

const estimateLevelSlice = createSlice({
  name: "EstimateLevel",
  initialState,
  reducers: {
    setLoading: (state: EstimateLevelState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setAggregateBy: (state, action) => {
      state.aggregateBy = action.payload
    },
    setEstimateNumber: (state, action) => {
      state.estimateNumber = action.payload?.PROJECT_ID
      state.estimateName = action.payload?.PROJECT_QUOTE_NO
      state.estimateDesc = action.payload?.PROJECT_TITLE
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectIDsAction.pending, (state: EstimateLevelState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getProjectIDsAction.fulfilled,
      (state: EstimateLevelState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        projectIDs: payload,
        estimateNumber: payload[0]?.PROJECT_ID,
        estimateName: payload[0]?.PROJECT_QUOTE_NO,
        estimateDesc: payload[0]?.PROJECT_TITLE,
      })
    )
    builder.addCase(getProjectIDsAction.rejected, (state: EstimateLevelState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getEstimateLevelsAction.pending, (state: EstimateLevelState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getEstimateLevelsAction.fulfilled,
      (state: EstimateLevelState, { payload }: PayloadAction<EstimateLevelResponse[]>) => ({
        ...state,
        estimateLevels: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(getEstimateLevelsAction.rejected, (state: EstimateLevelState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const { setLoading, setAggregateBy, setEstimateNumber, reset } = estimateLevelSlice.actions

export const estimateLevelSelector = (state: RootState) => state.EstimateLevel

export default estimateLevelSlice.reducer
