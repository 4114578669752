import { memo } from "react"
import { Popup } from "devextreme-react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import {
  expenditureProfileSelector,
  setShowSummaryPopUp,
} from "redux/expenditure-profile/expenditureProfileSlice"
import SummaryTable from "./summaryTable"

const ExpenditureProfileSummaryPopUp = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { showSummaryPopUp } = useSelector(expenditureProfileSelector)

  return (
    <Popup
      visible={showSummaryPopUp}
      showCloseButton
      enableBodyScroll
      height={440}
      onHiding={() => dispatch(setShowSummaryPopUp(!showSummaryPopUp))}
      title={t("carbExpProfileReport.summary")}
      dragEnabled={false}
    >
      <SummaryTable />
    </Popup>
  )
}

export default memo(ExpenditureProfileSummaryPopUp)
