import { memo, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Button, DataGrid } from "devextreme-react"
import { Column, FilterRow, Item, SearchPanel, Toolbar } from "devextreme-react/data-grid"
import { useSelector } from "react-redux"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { comparatorSelector } from "redux/comparator/iftComparatorSlice"
import {
  allRoles,
  handleResetFilter,
  identifyPermissions,
  numberFormatterConfig,
  renderCurrencyCell,
} from "utils/common-utils"
import { compCurrencySign } from "../../utils"

const EstimateTotalsTable = () => {
  const { t } = useTranslation()
  const dataGridRef = useRef<DataGrid>(null)

  const { userRole } = useSelector(profileSelector)
  const { comparedEstimateTotals } = useSelector(comparatorSelector)

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  return (
    <DataGrid
      id="comparator-est-tools-container"
      ref={dataGridRef}
      dataSource={comparedEstimateTotals}
      showBorders
      showColumnLines
      columnAutoWidth
      wordWrapEnabled
      hoverStateEnabled
      allowColumnResizing
    >
      <FilterRow visible />
      <SearchPanel visible width={340} />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column caption={t("iftComparatorMain.estimateNumb")} dataField="PROJECT_QUOTE_NO" />
      <Column caption={t("iftComparatorMain.estimate")} dataField="PROJECT_TITLE" />
      <Column
        caption={t("iftComparatorMain.totalCostwithMeasure", { compCurrencySign })}
        dataField="PROJECT_COST"
        cellRender={(row) => (permsnCheck ? renderCurrencyCell(row.data.PROJECT_COST || 0) : null)}
        format={numberFormatterConfig}
      />
      <Column
        caption={t("iftComparatorMain.totalA1A5withMeasure")}
        dataField="PROJECT_CARB_TOTAL_A1_A5"
        format={numberFormatterConfig}
      />
    </DataGrid>
  )
}

export default memo(EstimateTotalsTable)
