/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import moment from "moment"
import { get } from "lodash"
import { addClass } from "utils/common-utils"
import { iftScheduleItem } from "redux/ift/types"
import { formatDate } from "devextreme/localization"

export const handleScheduleDateValidation = (params: any) => {
  return moment(params.value, "DD/MM/YYYY", true).isValid() ? Promise.resolve() : Promise.reject()
}

export const handleScheduleStartEndDateValidation = (params: any) => {
  const { MostLikelyStartDateValue, MostLikelyEndDateValue } = params.data
  const d1 = new Date(MostLikelyStartDateValue).getTime()
  const d2 = new Date(MostLikelyEndDateValue).getTime()

  return d1 > d2 ? Promise.reject() : Promise.resolve()
}

export const renderEstimateEndCell = (row: any) => {
  const { displayValue, data } = row || {}
  const { MostLikelyEndDateValue, BaselineMostLikelyEndDateValue } = data

  const date1 = moment(BaselineMostLikelyEndDateValue)
  const date2 = moment(MostLikelyEndDateValue)

  const duration = moment.duration(date2.diff(date1))

  const diffYearVal = Math.abs(get(duration, "_data.years") || 0)
  const diffMonVal = Math.abs(get(duration, "_data.months") || 0)
  const diffDayVal = Math.abs(get(duration, "_data.days") || 0)

  const diffYearText = diffYearVal
    ? ` ${diffYearVal} ${diffYearVal === 1 ? "year" : "years"} ${diffMonVal ? "and" : ""}`
    : ""
  const diffMonText = diffMonVal
    ? ` ${diffMonVal} ${diffMonVal === 1 ? "month" : "months"} ${diffDayVal ? "and" : ""}`
    : ""
  const diffDayText = diffDayVal ? ` ${diffDayVal} ${diffDayVal === 1 ? "day" : "days"}` : ""

  const finalDiffText = `Moved by${diffYearText}${diffMonText}${diffDayText}`

  return <span title={finalDiffText}>{formatDate(displayValue, "dd/MM/yyyy")}</span>
}

const getParentIdArr = (item: string | null) => {
  const ParentIDArr = item && JSON.parse(item.replaceAll("][", ","))

  return ParentIDArr || []
}

const getParentId = (item: string | null) => {
  const ParentIDArr = getParentIdArr(item)
  const ParentID = ParentIDArr.length ? ParentIDArr.pop() : 0

  return ParentID
}

export const filterIFTScheduleData = (data: {
  baselineDataSource: iftScheduleItem[]
  revisionDataSource: iftScheduleItem[]
  estimateId: number
  projectId: number
}) => {
  const { baselineDataSource, revisionDataSource, estimateId, projectId } = data
  let processedData = revisionDataSource.length ? revisionDataSource : baselineDataSource || []

  if (baselineDataSource.length) {
    processedData = processedData.map((item) => {
      const baselineFound = baselineDataSource.find(
        (baseFound) => baseFound.StoreTag === item.StoreTag
      )
      const revisionFound = revisionDataSource.find(
        (revFound) => revFound.StoreTag === item.StoreTag
      )
      const ProjectId = revisionDataSource.length ? item.ProjectId : estimateId || projectId
      const reqrdData = revisionFound || item

      return {
        ...reqrdData,
        ProjectId,
        // Parent_ID: getParentId(item.iftScheduleHierarchy),
        Parent_ID: 0,
        BaselineMostLikelyStartDateValue: get(baselineFound, "MostLikelyStartDateValue"),
        BaselineMostLikelyEndDateValue: get(baselineFound, "MostLikelyEndDateValue"),
        MostLikelyStartDateValue:
          get(revisionFound, "MostLikelyStartDateValue") ||
          get(baselineFound, "MostLikelyStartDateValue"),
        MostLikelyEndDateValue:
          get(revisionFound, "MostLikelyEndDateValue") ||
          get(baselineFound, "MostLikelyEndDateValue"),
      }
    })
  }

  return processedData
}

export const onRowPrepared = (e: any) => {
  if (e && e.rowType === "data") {
    const { rowElement, node } = e

    if (node.hasChildren) addClass(rowElement, "bg-green")
  }
}

const removableKeys = [
  "Parent_ID",
  "BaselineMostLikelyStartDateValue",
  "BaselineMostLikelyEndDateValue",
]
const reassignCheck = [
  {
    reassignKey: "MinStartDateValue",
    reassignValue: "MostLikelyStartDateValue",
  },
  {
    reassignKey: "MaxStartDateValue",
    reassignValue: "MostLikelyStartDateValue",
  },
  {
    reassignKey: "MinEndDateValue",
    reassignValue: "MostLikelyEndDateValue",
  },
  {
    reassignKey: "MaxEndDateValue",
    reassignValue: "MostLikelyEndDateValue",
  },
]
export const convertToAPIReqrdStructure = (dataSource: iftScheduleItem[]) => {
  return dataSource.reduce((a: any[], b: iftScheduleItem) => {
    const Properties = Object.entries(b)
      .map(([key, value]) => {
        const reassignData = reassignCheck.find((item) => item.reassignKey === key)

        if (reassignData)
          return {
            Name: key,
            Data: get(b, reassignData?.reassignValue),
            PropertyType: "FixedField",
          }
        if (removableKeys.includes(key)) return null
        return {
          Name: key,
          Data: value,
          PropertyType: "FixedField",
        }
      })
      .filter(Boolean)
    const obj = { DataTypeName: "ExpenseForecastScheduleXmlDataStore", Properties }

    return [...a, obj]
  }, [])
}
