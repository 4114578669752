import { memo, useCallback, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { get } from "lodash"
import { Button, DataGrid } from "devextreme-react"
import {
  Item,
  Pager,
  Paging,
  Column,
  Toolbar,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import {
  expenditureProfileSelector,
  setSelectedExpenditureProject,
  setSelectedExpenditureProjectData,
} from "redux/expenditure-profile/expenditureProfileSlice"
import { costEstimateSummarySelector } from "redux/cost-estimate-summary/costEstimateSummarySlice"
import { costEstimateListDateCellVal, onCostEstimateListCellPrepared } from "./utils"

const CostEstimateListTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const dataGridRef = useRef<DataGrid>(null)

  const { costEstimateReportData } = useSelector(costEstimateSummarySelector)
  const { expenditureProfileProjects, selectedExpenditureProjectData } = useSelector(
    expenditureProfileSelector
  )

  const onRowClick = useCallback(
    (e: any) => {
      const estimate = get(e, "data")
      const estimateID = get(e, "data.PROJECT_ID")
      const selectedEstimateID = get(selectedExpenditureProjectData, "PROJECT_ID")
      const reportGenerated = get(e, "data.PROJECT_REPORT_GENERATED") === "Y"

      if (reportGenerated) {
        if (selectedEstimateID !== estimateID || !costEstimateReportData.length) {
          dispatch(setSelectedExpenditureProjectData())
        }

        dispatch(setSelectedExpenditureProject(estimate))
        navigate(`/costEstimateSummary/${estimateID}/report`)
      }
    },
    [selectedExpenditureProjectData]
  )

  return (
    <DataGrid
      id="cost-estimate-container"
      ref={dataGridRef}
      dataSource={expenditureProfileProjects}
      showBorders
      showColumnLines
      columnAutoWidth
      hoverStateEnabled
      allowColumnResizing
      allowColumnReordering
      onRowClick={onRowClick}
      onCellPrepared={(e) => onCostEstimateListCellPrepared(e, t)}
      stateStoring={{
        enabled: true,
        type: "localStorage",
        storageKey: "cost-estimate-grid-state",
        savingTimeout: 0,
      }}
    >
      <FilterRow visible />
      <Paging defaultPageSize={10} />
      <SearchPanel visible width={240} />
      <Pager showPageSizeSelector showInfo visible />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAll")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => dataGridRef.current?.instance.state(null)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      <Column
        caption={t("costEstimateSummary.schemeTitle")}
        dataField="PROJ_COMP_DESC"
        dataType="string"
      />
      <Column
        caption={t("costEstimateSummary.estimateNumb")}
        dataField="PROJECT_QUOTE_NO"
        dataType="string"
      />
      <Column
        caption={t("costEstimateSummary.estimateDesc")}
        dataField="PROJECT_TITLE"
        dataType="string"
      />
      <Column
        caption={t("costEstimateSummary.estimateStage")}
        dataField="PROJECT_EST_STAGE"
        dataType="string"
      />
      <Column
        caption={t("costEstimateSummary.estimateDate")}
        dataField="PROJECT_DATE"
        dataType="string"
        calculateCellValue={costEstimateListDateCellVal}
      />
      <Column
        caption={t("costEstimateSummary.estimator")}
        dataField="ESTIMATOR_NAME"
        dataType="string"
      />
    </DataGrid>
  )
}

export default memo(CostEstimateListTable)
