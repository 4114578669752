import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import CustomStore from "devextreme/data/custom_store"
import { Button, Popup, ScrollView } from "devextreme-react"
import DataGrid, { Item, Column, Toolbar, Editing, FilterRow } from "devextreme-react/data-grid"
import { v4 as uuidv4 } from "uuid"
import {
  addResourceIFTScopeAction,
  fetchResourceLibraryContractorIFTScopeAction,
  fetchResourceLibraryIFTScopeAction,
  fetchRevisionIFTScopeAction,
} from "redux/ift/middleware"
import { profileSelector } from "redux/profile/profileSlice"
import { iftSelector, scopePopUpsInit } from "redux/ift/iftSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, handleResetFilter, identifyPermissions } from "utils/common-utils"
import { ResourceModalProps } from "./resourceModalUtils"
import { currencyConverter, dupSubItemCnfrmDialog } from "../ScopeTable/scopeTableUtils"
import "./resource-modal.scss"

const ResourceModal: React.FC<ResourceModalProps> = ({
  visible,
  onHide,
  rowData,
  isBeingAddedOnDuplicateSubItem,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { userRole } = useSelector(profileSelector)
  const { resourceGroups, resourceLibraries, resourceLibraryContractors, estimateId } =
    useSelector(iftSelector)

  const tableData = useRef<any[]>([])
  const dataGridRef = useRef<DataGrid>(null)
  const dataGridResourceGroupRef = useRef<DataGrid>(null)
  const dataGridResourceLibraryRef = useRef<DataGrid>(null)
  const dataGridResourceLibraryContractorRef = useRef<DataGrid>(null)

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  useEffect(() => {
    if (visible) {
      dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
    }
    return () => {
      dispatch(scopePopUpsInit())
      tableData.current = []
    }
  }, [visible])

  const onResourceGroupSelectionChanged = useCallback(
    (e: any) => {
      const selRow = e.currentSelectedRowKeys?.[0]
      if (selRow) {
        dataGridResourceLibraryRef.current?.instance?.beginCustomLoading("")
        dispatch(fetchResourceLibraryIFTScopeAction(selRow?.RESOURCE_GROUP_ID)).finally(() => {
          dataGridResourceLibraryRef.current?.instance?.endCustomLoading()
        })
      }
    },
    [dataGridResourceLibraryRef]
  )

  const onResourceLibrarySelectionChanged = useCallback((e: any) => {
    const selRow = e.currentSelectedRowKeys?.[0]
    if (selRow) {
      dataGridResourceLibraryContractorRef.current?.instance?.beginCustomLoading("")
      dispatch(fetchResourceLibraryContractorIFTScopeAction(selRow?.RESOURCE_LIBRARY_CODE)).finally(
        () => {
          dataGridResourceLibraryContractorRef.current?.instance?.endCustomLoading()
        }
      )
    }
  }, [])

  const onResourceLibraryContractorSelectionChanged = useCallback((keys: string[]) => {
    if (!keys.length) return
    dataGridRef.current?.instance?.addRow()
  }, [])

  const store = useMemo(
    () =>
      new CustomStore({
        key: "ROW_ID",
        load: () => tableData.current,
        insert: (values: any) => {
          tableData.current = [{ ...values }, ...tableData.current]
          return Promise.resolve(values)
        },
        update: (key: any, value: any) => {
          const tableItems = [...tableData.current]
          const idx = tableItems.findIndex((item: any) => item.ROW_ID === key)
          if (idx >= 0) {
            tableItems[idx] = { ...tableItems[idx], ...value }
          }
          tableData.current = tableItems
          return Promise.resolve(tableItems)
        },
        remove: (rowIds: string[]) => {
          const tableItems = [...tableData.current]
          const filteredData = tableItems.filter((item: any) => !rowIds.includes(item.ROW_ID || ""))
          tableData.current = filteredData
          return Promise.resolve()
        },
      }),
    [tableData.current]
  )

  const handleEditedChanges = () => {
    dataGridRef?.current?.instance?.saveEditData()
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", !tableData.current.length)
  }

  const handleSaveClick = () => {
    dataGridRef.current?.instance.saveEditData()?.then(() => {
      dispatch(addResourceIFTScopeAction(tableData?.current))
        .then(() => {
          if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
        })
        .finally(() => {
          dataGridResourceGroupRef?.current?.instance?.clearSelection()
          tableData.current = []
          onHide?.()
        })
    })
  }

  const resourceSynchronisation = () => {
    if (!isBeingAddedOnDuplicateSubItem) handleSaveClick()
    else {
      dupSubItemCnfrmDialog(rowData)
        .show()
        .then((dialogResult: boolean) => dialogResult && handleSaveClick())
    }
  }

  const onInitNewRow = (e: any) => {
    const selectedResourceGroup =
      dataGridResourceGroupRef?.current?.instance?.getSelectedRowsData()?.[0]
    const selectedResourceLibrary =
      dataGridResourceLibraryRef?.current?.instance?.getSelectedRowsData()?.[0]
    const selectedResourceLibraryContactor =
      dataGridResourceLibraryContractorRef?.current?.instance?.getSelectedRowsData()?.[0]

    e.data = {
      ROW_ID: uuidv4(),
      UNIT_ID: selectedResourceLibrary?.UNIT_ID,
      UNIT_DESC: selectedResourceLibrary?.UNIT_DESC,
      PROJECT_RESOURCE_CODE: selectedResourceLibrary?.RESOURCE_LIBRARY_CODE,
      PROJECT_RESAGGR_DESC: selectedResourceLibrary?.RESOURCE_LIBRARY_DESC,
      PROJECT_RESOURCE_CATEGORY: selectedResourceLibrary?.RESOURCE_CATEGORY_ID,
      PROJECT_ITEM_ID: rowData.PROJECT_ITEM_ID,
      PROJECT_RESOURCE_RATE: selectedResourceLibraryContactor?.RESOURCE_LIBRARY_RATE,
      PROJECT_RESOURCE_QTY: 0,
      RESOURCE_LIBRARY_ID: selectedResourceLibrary?.RESOURCE_LIBRARY_ID,
      RESOURCE_GROUP_ID: selectedResourceGroup?.RESOURCE_GROUP_ID,
      SUBCONTRACTOR_ID: selectedResourceLibraryContactor?.SUBCONTRACTOR_ID,
      PROJECT_RESOURCE_CATEGORY_DESC: selectedResourceLibrary?.RESOURCE_CATEGORY_DESC,
      SUBCONTRACTOR_DESC: selectedResourceLibraryContactor?.SUBCONTRACTOR_COMPANY,
    }
  }

  return (
    <Popup
      visible={visible}
      onHiding={() => {
        onHide?.()
        dataGridResourceGroupRef.current?.instance.deselectAll()
      }}
      dragEnabled={false}
      defaultVisible={false}
      hideOnOutsideClick={false}
      showCloseButton
      showTitle
      title={t("scopeResource.addResource")}
      container=".dx-viewport"
      width="90%"
      height="90%"
      enableBodyScroll
    >
      <ScrollView className="resource-modal-pop-up">
        <div className="resource-group-top-row">
          <DataGrid
            dataSource={resourceGroups}
            className="ift-scope-item"
            ref={dataGridResourceGroupRef}
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="20%"
            paging={{ enabled: false }}
            onSelectionChanged={onResourceGroupSelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridResourceGroupRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="RESOURCE_GROUP_DESC"
              caption={t("scopeResource.resourceGroups")}
              alignment="center"
            />
          </DataGrid>

          <DataGrid
            dataSource={resourceLibraries}
            className="ift-scope-item"
            ref={dataGridResourceLibraryRef}
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="40%"
            paging={{ enabled: false }}
            onSelectionChanged={onResourceLibrarySelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridResourceLibraryRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="RESOURCE_LIBRARY_CODE"
              caption={t("scopeResource.code")}
              alignment="center"
            />
            <Column
              dataField="RESOURCE_LIBRARY_DESC"
              caption={t("scopeResource.description")}
              alignment="center"
            />
            <Column
              dataField="RESOURCE_CATEGORY_DESC"
              caption={t("scopeResource.category")}
              alignment="center"
            />
            <Column dataField="UNIT_DESC" caption={t("scopeResource.unit")} alignment="center" />
          </DataGrid>
          <DataGrid
            dataSource={resourceLibraryContractors}
            className="ift-scope-item"
            ref={dataGridResourceLibraryContractorRef}
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            selection={{ mode: "single" }}
            height={400}
            width="40%"
            paging={{ enabled: false }}
            onSelectedRowKeysChange={onResourceLibraryContractorSelectionChanged}
          >
            <FilterRow visible />
            <Toolbar>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridResourceLibraryContractorRef)}
                  />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="SUBCONTRATOR_CODE"
              caption={t("scopeResource.code")}
              alignment="center"
            />
            <Column
              dataField="SUBCONTRACTOR_COMPANY"
              caption={t("scopeResource.subContractor")}
              alignment="center"
            />
            <Column
              dataField="RESOURCE_LIBRARY_RATE"
              caption={t("scopeResource.rate")}
              alignment="center"
              cellRender={(row) =>
                permsnCheck ? currencyConverter(get(row, "data.RESOURCE_LIBRARY_RATE")) : null
              }
            />
          </DataGrid>
        </div>

        <div className="resource-group-bottom-row">
          <DataGrid
            dataSource={store}
            className="ift-provisional-sum"
            ref={dataGridRef}
            highlightChanges
            allowColumnResizing
            repaintChangesOnly
            columnAutoWidth
            showBorders
            showColumnLines
            showRowLines
            scrolling={{ mode: "standard" }}
            paging={{ enabled: false }}
            onInitNewRow={onInitNewRow}
            width="100%"
          >
            <FilterRow visible />
            <Editing
              mode="batch"
              allowAdding
              allowUpdating
              allowDeleting
              onChangesChange={handleEditedChanges}
              refreshMode="full"
            />
            <Toolbar visible>
              <Item location="after">
                <span title={t("toolbarActions.resetAllFilters")}>
                  <Button
                    icon="refresh"
                    stylingMode="text"
                    onClick={() => handleResetFilter(dataGridResourceGroupRef)}
                  />
                </span>
              </Item>
              <Item name="saveButton" location="after">
                <span title={t("toolbarActions.save")}>
                  <Button icon="save" stylingMode="text" onClick={resourceSynchronisation} />
                </span>
              </Item>
            </Toolbar>
            <Column
              dataField="PROJECT_RESOURCE_CODE"
              caption={t("scopeResource.code")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_RESAGGR_DESC"
              caption={t("scopeResource.description")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="SUBCONTRACTOR_DESC"
              caption={t("scopeResource.subContractor")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_RESOURCE_CATEGORY_DESC"
              caption={t("scopeResource.category")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_RESOURCE_RATE"
              caption={t("scopeResource.rate")}
              alignment="center"
              allowEditing={false}
              cellRender={(row) => (permsnCheck ? get(row, "data.PROJECT_RESOURCE_RATE") : null)}
            />
            <Column
              dataField="UNIT_DESC"
              caption={t("scopeResource.unit")}
              alignment="center"
              allowEditing={false}
            />
            <Column
              dataField="PROJECT_RESOURCE_QTY"
              caption={t("scopeResource.qty")}
              alignment="center"
              allowEditing
            />
          </DataGrid>
        </div>
      </ScrollView>
    </Popup>
  )
}

export default ResourceModal
