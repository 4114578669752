import { CheckBox } from "devextreme-react"
import { setEditedDynamicCarbonData } from "redux/masters/dynamicCarbonLibrarySlice"
import { myUtils } from "utils/common-utils"

function renderCheckboxCell(data: any, disabled: boolean) {
  const { dispatch } = myUtils
  const { CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID, IS_ASSURED } = data

  const onChange = (e: any) => {
    const data = {
      CONTRACTOR_ESTIMATE_LIBRARY_CONTENT_ID,
      IS_ASSURED: e.value ? 1 : 0,
    }
    dispatch(setEditedDynamicCarbonData(data))
  }

  return (
    <div className="cell-content-center">
      <CheckBox disabled={disabled} defaultValue={!!IS_ASSURED} onValueChanged={onChange} />
    </div>
  )
}

export default renderCheckboxCell
