import { useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, Pager, Paging, Selection } from "devextreme-react/data-grid"
import { sharedSelector } from "redux/shared/sharedSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons"
import { estmteRvsnMgmntSelector } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { updateEstimateContractorsAction } from "redux/estimate-revision-management/middleware"
import { pageSizeContractorTable } from "screens/estimate-revision-management/utils"

const ContractorsTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const assignedDataGridRef = useRef<DataGrid>(null)
  const unAssignedDataGridRef = useRef<DataGrid>(null)

  const { windowHeight } = useSelector(sharedSelector)
  const { selectedRevision, revisionContractors } = useSelector(estmteRvsnMgmntSelector)

  const assignedDataStore = useMemo(
    () =>
      new CustomStore({
        load: () => revisionContractors.filter((item) => item.IS_ASSIGNED),
        key: "ESTIMATOR_ID",
      }),
    [revisionContractors]
  )
  const unAssignedDataStore = useMemo(
    () =>
      new CustomStore({
        load: () => revisionContractors.filter((item) => !item.IS_ASSIGNED),
        key: "ESTIMATOR_ID",
      }),
    [revisionContractors]
  )

  const handleButtonClickLeft = useCallback(async () => {
    const selectedItems = await unAssignedDataGridRef.current?.instance.getSelectedRowsData()

    const { Id, ParentProjId } = selectedRevision || {}
    const selectedContractors = selectedItems ? selectedItems.map((item) => item.ESTIMATOR_ID) : []
    const existingContractors = revisionContractors
      .filter((item) => item.IS_ASSIGNED)
      .map((item) => item.ESTIMATOR_ID)
    const Contractors = [...selectedContractors, ...existingContractors]
    const data = { Id, ParentProjId, Contractors }

    if (Contractors?.length) {
      dispatch(updateEstimateContractorsAction(data))
    }
    unAssignedDataGridRef.current?.instance.clearSelection()
  }, [revisionContractors, selectedRevision, assignedDataGridRef])

  const handleButtonClickRight = useCallback(async () => {
    const selectedItems = await assignedDataGridRef.current?.instance.getSelectedRowsData()

    const { Id, ParentProjId } = selectedRevision || {}
    const selectedContractors = selectedItems ? selectedItems.map((item) => item.ESTIMATOR_ID) : []

    const Contractors = revisionContractors
      .filter((item) => item.IS_ASSIGNED)
      .filter((item) => !selectedContractors.includes(item.ESTIMATOR_ID))
      .map((item) => item.ESTIMATOR_ID)

    const data = { Id, ParentProjId, Contractors }

    if (selectedItems?.length) {
      dispatch(updateEstimateContractorsAction(data))
    }
    assignedDataGridRef.current?.instance.clearSelection()
  }, [revisionContractors, selectedRevision, assignedDataGridRef])

  const onSelectionChanged = useCallback((e: any) => {
    const selRowsLength = e.currentSelectedRowKeys.length
    const deselRowsLength = e.currentDeselectedRowKeys.length

    if (selRowsLength === 1) {
      e.component.selectRows([e.currentSelectedRowKeys[0], ...e.currentDeselectedRowKeys], true)
    } else if (!selRowsLength && deselRowsLength > 1) {
      e.component.selectRows([...e.currentDeselectedRowKeys])
    }
  }, [])

  return (
    <div className="contractorsTable">
      <DataGrid
        id="gridContainer"
        ref={assignedDataGridRef}
        dataSource={assignedDataStore}
        showBorders
        columnAutoWidth
        showColumnLines
        allowColumnResizing
        allowColumnReordering
        selection={{
          mode: "multiple",
          showCheckBoxesMode: "none",
        }}
        height={windowHeight - 356}
        onSelectionChanged={onSelectionChanged}
      >
        <Paging enabled defaultPageSize={10} />
        <Selection mode="multiple" deferred showCheckBoxesMode="none" selectByClick />
        <Pager showPageSizeSelector showInfo allowedPageSizes={pageSizeContractorTable} visible />
        <Column
          key="ESTIMATOR_ID"
          dataField="ESTIMATOR_NAME"
          caption={t("estimateRevisionManagement.popUpCol1Heading")}
        />
      </DataGrid>
      <div className="arrowIcons">
        <FontAwesomeIcon
          size="2x"
          color="green"
          cursor="pointer"
          icon={faCircleRight}
          onClick={handleButtonClickRight}
        />
        <FontAwesomeIcon
          size="2x"
          color="green"
          cursor="pointer"
          icon={faCircleLeft}
          onClick={handleButtonClickLeft}
        />
      </div>
      <DataGrid
        id="gridContainer"
        ref={unAssignedDataGridRef}
        dataSource={unAssignedDataStore}
        showBorders
        showColumnLines
        columnAutoWidth
        allowColumnResizing
        allowColumnReordering
        selection={{
          mode: "multiple",
          showCheckBoxesMode: "none",
        }}
        height={windowHeight - 356}
        onSelectionChanged={onSelectionChanged}
      >
        <Paging enabled defaultPageSize={10} />
        <Selection mode="multiple" deferred showCheckBoxesMode="none" selectByClick />
        <Pager showPageSizeSelector showInfo allowedPageSizes={pageSizeContractorTable} visible />
        <Column
          key="ESTIMATOR_ID"
          dataField="ESTIMATOR_NAME"
          caption={t("estimateRevisionManagement.popUpCol2Heading")}
        />
      </DataGrid>
    </div>
  )
}

export default ContractorsTable
