import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { LoadingState } from "types/AppNav"
import { LoadPanel } from "devextreme-react"
import { iftSelector } from "redux/ift/iftSlice"
import { projectSelector } from "redux/projects/projectSlice"

const ScopeLoader = () => {
  const { loading } = useSelector(iftSelector)
  const { loading: projectLoading } = useSelector(projectSelector)

  const loadingCheck = useMemo(
    () => [loading, projectLoading].includes(LoadingState.LOADING),
    [loading, projectLoading]
  )

  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loadingCheck}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(ScopeLoader)
