/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { get } from "lodash"
import BreadCrum from "components/bread-crumb"
import {
  isColumnHidden,
  expandHiddenColumns,
  prepareCustomContextMenu,
} from "controllers/grid-controller"
import DataGrid, {
  Item,
  Pager,
  Paging,
  Column,
  Toolbar,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import { formatDate } from "devextreme/localization"
import { LoadPanel } from "devextreme-react/load-panel"
import { ContextMenuPreparingEvent } from "devextreme/ui/data_grid"
import useDocumentTitle from "hooks/useDocumentTitle"
import { useAppDispatch } from "redux/store"
import { IHiddenColumn } from "redux/projects/types"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { getProjectsAction } from "redux/projects/middleware"
import { projectSelector, selectProject } from "redux/projects/projectSlice"
import { LoadingState } from "types/AppNav"
import { handleDateStringSorting } from "utils/common-utils"
import { config } from "utils/config"
import {
  projBreadCrumb,
  renderCheckboxCell,
  handleEstimateNoSort,
  renderContextMenuCell,
} from "./projUtils"
import "./projects.scss"

const Projects: React.FC = () => {
  useDocumentTitle("Estimate Browser | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { projects, loading } = useSelector(projectSelector)

  const dataGridRef = useRef<DataGrid>(null)
  const TFNSWCheck = useMemo(() => config.clientName === "TFNSW", [])
  const [hiddenColumns, setHiddenColumns] = useState<Array<IHiddenColumn | undefined>>([])

  useEffect(() => {
    if (!projects.length) dispatch(getProjectsAction())
  }, [])

  const renderHeaderCell = useCallback(
    (data: any) => {
      const val = get(data, "column.caption")
      return (
        <>
          {expandHiddenColumns(data, hiddenColumns, setHiddenColumns, dataGridRef)}
          <span className="eb-header-cell" title={val}>
            {val}
          </span>
        </>
      )
    },
    [hiddenColumns, dataGridRef]
  )

  const prepareContextMenu = useCallback(
    (e: ContextMenuPreparingEvent) => {
      prepareCustomContextMenu(e, hiddenColumns, setHiddenColumns, dataGridRef, t, userRole)

      if (e?.row?.rowType === "data") e?.component?.selectRowsByIndexes([e.rowIndex])
      else e?.component?.deselectAll()
    },
    [hiddenColumns, userRole]
  )

  const onRowClick = useCallback(
    (e: any) => {
      const rowData = get(e, "data")
      const rowID = get(e, "data.Id")
      const checkContextMenuCell = (get(e, "event.target.className") || "").includes("dx-icon")
      if (checkContextMenuCell) return

      dataGridRef.current?.instance.deselectAll()
      navigate(`/project/${rowID}/sections`)
      dispatch(selectProject(rowData))
    },
    [dataGridRef]
  )

  const onOutSideClick = useCallback(
    () => dataGridRef.current?.instance.deselectAll(),
    [dataGridRef]
  )

  return (
    <div data-testid="project" className="estimate-browser-wrapper" onClick={onOutSideClick}>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position="center"
        visible={loading === LoadingState.LOADING}
        showIndicator
        shading
        showPane
      />
      <BreadCrum data={projBreadCrumb(t)} />
      <DataGrid
        id="project-grid-container"
        keyExpr="Id"
        ref={dataGridRef}
        dataSource={projects}
        showBorders
        showColumnLines
        columnAutoWidth
        hoverStateEnabled
        columnWidth="auto"
        allowColumnResizing
        rowAlternationEnabled
        headerFilter={{ visible: true }}
        onContextMenuPreparing={prepareContextMenu}
        height={windowHeight - 124}
        onRowClick={onRowClick}
        className="project-grid-container"
        stateStoring={{
          enabled: true,
          type: "localStorage",
          storageKey: "estimate-browser-grid-state",
          savingTimeout: 0,
        }}
      >
        <FilterRow visible />
        <Paging defaultPageSize={10} />
        <SearchPanel visible width={240} />
        <Pager showPageSizeSelector showInfo visible />
        <Toolbar>
          <Item location="before">
            <span title={t("toolbarActions.resetAll")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => dataGridRef.current?.instance.state(null)}
              />
            </span>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Column
          dataField="SchemaDesc"
          caption={t("projectBrowser.schemeTitle")}
          visible={!TFNSWCheck && isColumnHidden(0, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering={false}
          headerCellRender={renderHeaderCell}
          fixed
        />
        <Column
          dataField="QuoteNo"
          caption={t("projectBrowser.estimateNo")}
          visible={isColumnHidden(1, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering={false}
          headerCellRender={renderHeaderCell}
          fixed
          minWidth={136}
          sortingMethod={handleEstimateNoSort}
        />
        <Column
          dataField="Title"
          caption={t("projectBrowser.estimateDescription")}
          visible={isColumnHidden(2, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering={false}
          headerCellRender={renderHeaderCell}
          fixed
          width={400}
        />
        <Column
          caption={t("projectBrowser.action")}
          visible={isColumnHidden(3, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering={false}
          headerCellRender={renderHeaderCell}
          cellRender={(row) => renderContextMenuCell(row, dataGridRef)}
          fixed
          minWidth={76}
        />
        <Column
          dataField="BaselineEstimate"
          caption={t("projectBrowser.baselineEstimate")}
          visible={isColumnHidden(4, hiddenColumns)}
          dataType="string"
          cellRender={(row) => renderCheckboxCell(row.data.BaselineEstimate)}
          allowFiltering={false}
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          minWidth={150}
        />
        <Column
          dataField="EstimateStage"
          caption={t("projectBrowser.estimateStage")}
          visible={isColumnHidden(5, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          minWidth={200}
        />
        <Column
          dataField="UnixDate"
          caption={t("projectBrowser.estimateDate")}
          visible={isColumnHidden(6, hiddenColumns)}
          dataType="string"
          calculateCellValue={(rowData: { UnixDate: string | number | Date }) =>
            formatDate(new Date(rowData.UnixDate), "dd/MM/yyyy")
          }
          sortingMethod={handleDateStringSorting}
          allowHeaderFiltering={false}
          headerCellRender={renderHeaderCell}
          minWidth={130}
        />
        <Column
          dataField="CompletedCaption"
          caption={t("projectBrowser.completed")}
          visible={isColumnHidden(7, hiddenColumns)}
          dataType="string"
          allowFiltering={false}
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          cellRender={(row) => renderCheckboxCell(row.data.Completed)}
          minWidth={150}
        />
        <Column
          dataField="AuthorisedCaption"
          caption={t("projectBrowser.authorised")}
          visible={isColumnHidden(8, hiddenColumns)}
          dataType="string"
          allowFiltering={false}
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          cellRender={(row) => renderCheckboxCell(row.data.Authorised)}
          minWidth={150}
        />
        <Column
          dataField="Estimator"
          caption={t("projectBrowser.estimator")}
          visible={isColumnHidden(9, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          minWidth={225}
        />
        <Column
          dataField="Region"
          caption={t("projectBrowser.region")}
          visible={isColumnHidden(10, hiddenColumns)}
          dataType="string"
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          minWidth={110}
        />
        <Column
          dataField="PreferredEstimate"
          caption={t("projectBrowser.preferredEstimate")}
          visible={!TFNSWCheck && isColumnHidden(11, hiddenColumns)}
          dataType="string"
          allowFiltering={false}
          allowHeaderFiltering
          headerCellRender={renderHeaderCell}
          cellRender={(row) => renderCheckboxCell(row.data.PreferredEstimate)}
          minWidth={180}
        />
      </DataGrid>
    </div>
  )
}

export default Projects
