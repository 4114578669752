/* eslint-disable react/no-array-index-key */
import { Link } from "react-router-dom"
import "./bread-crumb.scss"

const BreadCrumb = ({ data }: any) => {
  return (
    <div className="custom-breadcrum">
      <div className="content-block">
        {data.map((item: any, index: number) =>
          item.link ? (
            <Link replace key={`bread-crumb-${index}`} to={item.link}>
              <span title={item.title}>
                &nbsp;
                <label title={item.name}>{item.name}</label>
                {item.title && <label>&nbsp;{item.title || ""}</label>}
                <i className="arrow">&nbsp;{index === data.length - 1 ? "" : ">"}</i>
              </span>
            </Link>
          ) : (
            <span key={`bread-crumb-${index}`} title={item.title}>
              &nbsp;
              <label title={item.name}>{item.name}</label>
              <label>&nbsp;{item.title || ""}</label>
              <i className="arrow">&nbsp; {index === data.length - 1 ? "" : ">"}</i>
            </span>
          )
        )}
      </div>
      {/* {data.length > 1 && (
                <Button className="button" text='Back' type='default' icon='back' onClick={() => goBack()}></Button>
            )} */}
    </div>
  )
}

export default BreadCrumb
