import { get } from "lodash"
import { TFunction } from "i18next"
import { custom } from "devextreme/ui/dialog"
import { UserRole } from "redux/profile/types"
import { IProject } from "redux/projects/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { EstimateRevisionManagementItem } from "redux/estimate-revision-management/types"
import { allRoles, identifyPermissions } from "utils/common-utils"

export const iftBreadCrumb = (
  t: TFunction<"translation", undefined>,
  project: IProject | undefined,
  selectedRevision: EstimateRevisionManagementItem | undefined
) => {
  const projectId = get(project, "Id") || get(project, "Identity.Value")

  let breadCrumbArr = [
    {
      key: "estimate",
      name: t("ift.estimate"),
      link: "/projects",
      title: project?.Title,
    },
    {
      key: "revision",
      name: t("ift.revision"),
      link: `/project/${projectId}/estimate-revision-management`,
      title: selectedRevision?.Title,
    },
    {
      key: "ift",
      name: t("ift.ift"),
    },
  ]

  if (!window.location.hash.includes("erm")) {
    breadCrumbArr = breadCrumbArr.filter((item) => item.key !== "revision")
  }

  return breadCrumbArr
}

export const tabsOptions = (t: TFunction<"translation", undefined>, userRole: UserRole | null) => {
  const roleBasedScopePermission = identifyPermissions(
    userRole,
    AccessType.SCOPE,
    AccessLevel.YES,
    allRoles
  )
  return [
    {
      key: "keyData",
      text: t("ift.keyData"),
    },
    {
      key: "previousEstimate",
      text: t("ift.previousEstimate"),
    },
    {
      key: "schedule",
      text: t("ift.schedule"),
    },
    {
      key: "scope",
      text: t("ift.scope"),
      visible: roleBasedScopePermission,
    },
    {
      key: "riskRegister",
      text: t("ift.riskRegister"),
    },
    {
      key: "inflationAndSpend",
      text: t("ift.inflationAndSpend"),
    },
  ].map((item, idx) => ({ ...item, id: idx }))
}

export const zeroNullCarbCnfrmDialog = (t: TFunction<"translation", undefined>) => {
  return custom({
    showTitle: false,
    messageHtml: `<div class="p-2">${t("iftScope.null0msg")}</div>`,
    buttons: [
      {
        text: "Yes",
        onClick: () => true,
      },
      {
        text: "No",
        onClick: () => false,
      },
    ],
  })
}
