import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, Route, Routes } from "react-router-dom"
import LoginForm from "screens/auth/login-form"
import SingleCard from "layouts/single-card"
import ResetPasswordForm from "screens/auth/reset-password"
import ChangePasswordForm from "screens/auth/change-password"
import SignUpForm from "screens/auth/signup-form"

const AuthNavigator: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SingleCard title={t("loginFormTitle")}>
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path="/create-account"
        element={
          <SingleCard title={t("signup")}>
            <SignUpForm />
          </SingleCard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <SingleCard title={t("resetPassword")} description={t("resetPasswordDescription")}>
            <ResetPasswordForm />
          </SingleCard>
        }
      />

      <Route
        path="/change-password/:recoveryCode"
        element={
          <SingleCard title={t("changePassword")}>
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

export default AuthNavigator
