import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import notify from "devextreme/ui/notify"
import { exportDataGrid } from "devextreme/excel_exporter"
import SubItemIcon from "assets/icons/SubItem.png"
import ItemTextIcon from "assets/icons/ItemText.png"
import ResourceItemIcon from "assets/icons/Resource.png"
import NormalItemIcon from "assets/icons/NormalItem.png"
import ItemVariableIcon from "assets/icons/ItemVariable.png"
import CompositeItemIcon from "assets/icons/CompositeItem.png"
import CompositeTotalIcon from "assets/icons/CompositeTotal.png"
import { ResourceType } from "redux/projects/types"
import { BEHAVIOURS } from "../../utils"

export const onComparatorCellPrepared = (e: any) => {
  if (e && e.rowType === "data") {
    let bgColor = "white"
    const behaviour = e.data[`${e.column.dataField}_BEHAVIOUR`]
    if (behaviour) {
      bgColor = BEHAVIOURS[behaviour]
    }
    e.cellElement.style.backgroundColor = bgColor
  }
}

export const comparatorEditorOptions = { format: "#0.00" }

export const ExportToFile = async (e: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet("Main sheet")
  const { component } = e
  const totalCount = component?.getDataSource()?.totalCount()

  if (totalCount) {
    await exportDataGrid({
      component,
      worksheet,
      customizeCell: (options) => {
        options.excelCell.font = { name: "Arial", size: 12 }
        options.excelCell.alignment = { horizontal: "left" }
      },
    })
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Cost Element Comparator.xlsx")
  } else {
    notify("No data to export.", "info", 2500)
  }
}

const cellDescriptionProps = (compositeItemType: string) => {
  let obj = {
    icon: ItemTextIcon,
    alt: "",
    title: "Text Item",
    labelStyles: {
      color: "",
      // fontWeight: "bold",
      fontStyle: "",
    },
  }
  switch (compositeItemType) {
    case "CompositeTotal":
      obj = {
        icon: CompositeTotalIcon,
        alt: "Composite Total",
        title: "Composite Total",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "NormalItem":
      obj = {
        icon: NormalItemIcon,
        alt: "Normal Item",
        title: "Normal Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "CompositeItem":
      obj = {
        icon: CompositeItemIcon,
        alt: "Composite Item",
        title: "Composite Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ItemVariable":
      obj = {
        icon: ItemVariableIcon,
        alt: "Item Variable",
        title: "Item Variable",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ResourceItem":
      obj = {
        icon: ResourceItemIcon,
        alt: "Resource Item",
        title: "Resource",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "SubItem":
      obj = {
        icon: SubItemIcon,
        alt: "Sub Item",
        title: "Sub Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    default:
      break
  }
  return obj
}

export const comparatorCellDescriptionRender = (row: any) => {
  const subItemCheck =
    row.data.SUBITEM === "Y" && row.data.PROJECT_ITEM_COMPOSITETYPE === 0
      ? "SubItem"
      : ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]
  const { icon, alt, title, labelStyles } = cellDescriptionProps(subItemCheck)
  const sectionCheck = ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]

  return (
    <div className="cell-description">
      {sectionCheck && <img src={icon} alt={alt} title={title} />}
      <label style={labelStyles} title={row.value}>
        {row.value}
      </label>
    </div>
  )
}
