/* eslint-disable no-return-assign */
// @ts-nocheck
import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { useSelector } from "react-redux"
import { get } from "lodash"
import DataGrid, {
  Item,
  Pager,
  Paging,
  Lookup,
  Column,
  Toolbar,
  FilterRow,
  AsyncRule,
  SearchPanel,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import Button from "devextreme-react/button"
import { DataChange } from "devextreme/common/grids"
import CustomStore from "devextreme/data/custom_store"
import useDocumentTitle from "hooks/useDocumentTitle"
import {
  deleteRiskRegisterAction,
  fetchCostElementsForRiskAction,
  createOrUpdateRiskRegisterAction,
  fetchRiskRegistersByProjectAction,
} from "redux/ift/middleware"
import { sharedSelector } from "redux/shared/sharedSlice"
import { RiskAllocationItem, RiskRegisterItem } from "redux/ift/types"
import { deleteRiskRegister, iftSelector, updateRiskRegister } from "redux/ift/iftSlice"
import { currencyFormatterConfig, handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import {
  prcntVldte,
  costImpactVal,
  prcntCellRender,
  renderDropdownCell,
  riskRegNumValidate,
  riskDelCnfrmDialog,
  requiredFieldsCheck,
  handleRiskRevertAll,
  summationCheckCellVal,
  summationCheckValidate,
  setRiskSaveRevertToolbar,
  RiskContractualAllocation,
  onRiskRegisterCellPrepared,
} from "./riskRegisterUtils"
import "./risk-register.scss"

const RiskRegister: React.FC = (): JSX.Element => {
  useDocumentTitle("Risk Register | Cost and Carbon Forecasting tool")
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { windowHeight } = useSelector(sharedSelector)
  const { riskRegisters, projectId, estimateId, riskCostElements } = useSelector(iftSelector)

  const dataGridRef = useRef<DataGrid>(null)

  const store = useMemo(
    () =>
      new CustomStore({
        load: () => riskRegisters,
        key: "RISK_REGISTER_ID",
        update: (key: string, value: RiskRegisterItem) => {
          let obj = { ...value }
          if (Object.keys(value).includes("RISK_REGISTER_PROBABILITY")) {
            obj = { ...obj, RISK_REGISTER_PROBABILITY: value.RISK_REGISTER_PROBABILITY * 100 }
          }
          dispatch(updateRiskRegister(obj))
          return Promise.resolve(riskRegisters)
        },
        insert: (values: RiskRegisterItem) => {
          const newRecord = { ...values }
          return Promise.resolve(newRecord)
        },
        remove: (key: number[]) => {
          dispatch(deleteRiskRegister(key))
          dispatch(deleteRiskRegisterAction(key))

          return Promise.resolve(riskRegisters)
        },
      }),
    [riskRegisters]
  )

  useEffect(() => {
    if (dataGridRef.current && projectId && !riskRegisters.length && !riskCostElements.length) {
      dispatch(fetchCostElementsForRiskAction())
      dispatch(fetchRiskRegistersByProjectAction(projectId))
      dataGridRef.current.instance.beginUpdate()
      setRiskSaveRevertToolbar(dataGridRef, true)
      if (estimateId) {
        dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
      }
      dataGridRef.current.instance.endUpdate()
      setTimeout(() => {
        if (estimateId) {
          const selectedRows = dataGridRef.current?.instance.getSelectedRowsData()
          dataGridRef.current.instance.beginUpdate()
          dataGridRef.current.instance.option("toolbar.items[0].disabled", !selectedRows.length)
          dataGridRef.current.instance.endUpdate()
        }
      }, 1000)
    }
  }, [projectId])

  const handleSaveClick = () => dataGridRef.current?.instance.saveEditData()

  const handleAddClick = async () => {
    dataGridRef.current?.instance.cancelEditData()
    dataGridRef.current?.instance?.addRow()
  }

  const handleDeleteClick = () => {
    const selectedRows = dataGridRef.current?.instance.getSelectedRowsData()
    const selectedRowIds = selectedRows.map((row) => row.RISK_REGISTER_ID)

    if (selectedRowIds.length > 0) {
      riskDelCnfrmDialog(t)
        .show()
        .then((dialogResult) => {
          if (dialogResult) {
            dataGridRef.current?.instance.option("toolbar.items[0].disabled", true)
            store.remove(selectedRowIds)
            dataGridRef.current?.instance.option("toolbar.items[0].disabled", !selectedRows?.length)
          }
        })
    }
  }

  const handleRowSelection = (e: any) => {
    const selectedRowIds = e.selectedRowKeys as string[]
    dataGridRef.current?.instance.option("toolbar.items[0].disabled", !selectedRowIds.length)
  }

  const handleInitNewRowOrStartEditing = (e: any) => {
    const editCheck =
      get(e, "data.RISK_CONTRACTUALALLOCATION") === 0 &&
      get(e, "column.dataField") === "RISK_REGISTER_PROBABILITY"
    if (editCheck) {
      e.cancel = true
    }
    setRiskSaveRevertToolbar(dataGridRef, false)
  }

  const onInitNewRow = (e) => {
    setRiskSaveRevertToolbar(dataGridRef, false)
    const newElement: RiskRegisterItem = { PROJECT_ID: projectId, RISK_REGISTER_ID: e.key }
    riskCostElements.forEach((costElement: RiskAllocationItem) => {
      newElement[costElement.KEY] = costElement
    })
    e.data = newElement
  }

  const onSavingTableData = (changes: DataChange<any, any>[]) => {
    setRiskSaveRevertToolbar(dataGridRef, true)
    let isValid = true
    let finalItemsToPush: RiskRegisterItem[] = []
    for (const change of changes) {
      if (change.type === "update") {
        const riskItem = riskRegisters.find(
          (risk: RiskRegisterItem) => risk.RISK_REGISTER_ID === change.key
        )
        if (riskItem) {
          const object = {}
          Object.keys(change?.data || {}).forEach((key) => {
            const item = Object.keys(riskItem).includes(key)
            if (item) {
              const { VALUE } = change.data[key]
              if (typeof riskItem[key] !== "object") {
                object[key] = change.data[key]
                if (key === "RISK_REGISTER_BASEDATE") object[key] = change.data[key]
                if (key === "RISK_REGISTER_PROBABILITY") object[key] = change.data[key] / 100
                if (key === "RISK_CONTRACTUALALLOCATION") object[key] = change.data[key]
              } else object[key] = { ...item, VALUE }
            }
          })
          const updatedItem = { ...riskItem, ...object }
          if (requiredFieldsCheck(updatedItem)) {
            notify(t("riskRegisters.requireFields"), "error", 2000)
            isValid = false
            break
          }

          store.update(updatedItem.RISK_REGISTER_ID, updatedItem)
          finalItemsToPush.push(updatedItem)
        }
      } else if (change.type === "insert") {
        if (requiredFieldsCheck(change.data)) {
          notify(t("riskRegisters.requireFields"), "error", 2000)
          isValid = false
          break
        }

        store.insert({ ...change.data, RISK_REGISTER_ID: change.key })
        finalItemsToPush.push({ ...change.data })
      }
    }

    finalItemsToPush = finalItemsToPush.map((item) => {
      let obj = {}
      if (item.RISK_REGISTER_PROBABILITY > 1) {
        obj = { RISK_REGISTER_PROBABILITY: item.RISK_REGISTER_PROBABILITY / 100 }
      }
      if (item.RISK_CONTRACTUALALLOCATION === 0) {
        // Need 0 because by error this can become null or {} or undefined
        obj = { RISK_REGISTER_PROBABILITY: 1 }
      }
      return { ...item, ...obj }
    })

    if (isValid) dispatch(createOrUpdateRiskRegisterAction(finalItemsToPush))
  }

  return (
    <div className="risk-register-container">
      <DataGrid
        id="risk-grid"
        dataSource={store}
        ref={dataGridRef}
        showBorders
        showRowLines
        showColumnLines
        columnAutoWidth
        highlightChanges
        repaintChangesOnly
        allowColumnResizing
        scrolling={{ mode: "standard" }}
        editing={{
          mode: "batch",
          refreshMode: "repaint",
          allowAdding: !!estimateId,
          allowUpdating: !!estimateId,
        }}
        noDataText={t("noRecords")}
        selection={{ mode: estimateId ? "multiple" : "none" }}
        height={windowHeight - 188}
        onInitNewRow={onInitNewRow}
        onSelectionChanged={handleRowSelection}
        onCellPrepared={onRiskRegisterCellPrepared}
        onEditingStart={handleInitNewRowOrStartEditing}
        onSaving={(elements) => {
          dataGridRef?.current?.instance?.beginUpdate()
          onSavingTableData(elements.changes)
          dataGridRef?.current?.instance?.endUpdate()
        }}
      >
        <FilterRow visible />
        <SearchPanel visible width={340} />
        <Paging enabled defaultPageSize={defaultPageSize} />
        <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Toolbar visible>
          <Item name="deleteRecords" location="before" visible={!!estimateId}>
            <span title={t("toolbarActions.delete")}>
              <Button icon="trash" stylingMode="text" onClick={handleDeleteClick} />
            </span>
          </Item>
          <Item name="resetFilters" location="before" visible={!!estimateId}>
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button
                icon="refresh"
                stylingMode="text"
                onClick={() => handleResetFilter(dataGridRef)}
              />
            </span>
          </Item>
          <Item name="addRecord" location="after" visible={!!estimateId}>
            <span title={t("toolbarActions.add")}>
              <Button icon="add" stylingMode="text" onClick={handleAddClick} />
            </span>
          </Item>
          <Item name="saveRecord" location="after" visible={!!estimateId}>
            <span title={t("toolbarActions.save")}>
              <Button icon="save" stylingMode="text" onClick={handleSaveClick} />
            </span>
          </Item>
          <Item name="revertAll" location="after" visible={!!estimateId}>
            <span title={t("toolbarActions.undoAll")}>
              <Button
                icon="revert"
                stylingMode="text"
                onClick={() => handleRiskRevertAll(dataGridRef)}
              />
            </span>
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
        <Column
          caption={t("riskRegisters.mainTitle")}
          alignment="center"
          fixedPosition="left"
          cssClass="parent-wrapper"
          fixed
        >
          <Column
            dataField="RISK_REGISTER_NUMBER"
            caption={t("riskRegisters.riskNumber")}
            alignment="left"
            cssClass="border-class"
            width={90}
          >
            <AsyncRule
              message=""
              ignoreEmptyValue
              validationCallback={(param) => riskRegNumValidate(param, riskRegisters, t)}
            />
          </Column>
          <Column
            dataField="RISK_REGISTER_CAUSE"
            caption={t("riskRegisters.cause")}
            alignment="left"
            dataType="string"
            cssClass="border-class"
            width={210}
          />
          <Column
            dataField="RISK_REGISTER_EVENT"
            caption={t("riskRegisters.event")}
            alignment="left"
            dataType="string"
            cssClass="border-class"
            width={210}
          />
          <Column
            dataField="RISK_REGISTER_IMPACT"
            caption={t("riskRegisters.impact")}
            alignment="left"
            dataType="string"
            cssClass="border-class"
            width={210}
          />
          <Column
            dataField="RISK_REGISTER_BASEDATE"
            caption={t("riskRegisters.baseDate")}
            alignment="left"
            dataType="date"
            format="QQQ, yyyy"
            width={200}
          />
        </Column>
        <Column
          dataField="RISK_CONTRACTUALALLOCATION"
          caption={t("riskRegisters.contractualAllocation")}
          alignment="left"
          cssClass="border-class"
          editCellRender={renderDropdownCell}
        >
          <Lookup dataSource={RiskContractualAllocation} displayExpr="name" valueExpr="id" />
        </Column>
        <Column
          caption={t("riskRegisters.preMitigationValue")}
          alignment="center"
          cssClass="parent-wrapper"
        >
          <Column
            dataField="RISK_REGISTER_PROBABILITY"
            caption={t("riskRegisters.likelyhood")}
            alignment="left"
            dataType="number"
            format="percent"
            cellRender={(rowData) => prcntCellRender(rowData, "RISK_REGISTER_PROBABILITY")}
            width={180}
          >
            <AsyncRule
              message=""
              ignoreEmptyValue
              validationCallback={(param) => prcntVldte(param, "RISK_REGISTER_PROBABILITY", t)}
            />
          </Column>
          <Column
            dataField="RISK_REGISTER_COSTMIN"
            caption={t("riskRegisters.minimum")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
          <Column
            dataField="RISK_REGISTER_COSTML"
            caption={t("riskRegisters.mostLikely")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
          <Column
            dataField="RISK_REGISTER_COSTMAX"
            caption={t("riskRegisters.maximum")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
        </Column>
        <Column
          caption={t("riskRegisters.postMitigationValue")}
          alignment="center"
          cssClass="parent-wrapper"
        >
          <Column
            dataField="RISK_REGISTER_POSTMTGTPROB"
            caption={t("riskRegisters.likelyhood")}
            alignment="left"
            dataType="number"
            format="percent"
            setCellValue={(newData, value) => (newData.RISK_REGISTER_POSTMTGTPROB = value / 100)}
            cellRender={(rowData) => prcntCellRender(rowData, "RISK_REGISTER_POSTMTGTPROB")}
            width={180}
          >
            <AsyncRule
              message=""
              ignoreEmptyValue
              validationCallback={(param) => prcntVldte(param, "RISK_REGISTER_POSTMTGTPROB", t)}
            />
          </Column>
          <Column
            dataField="RISK_REGISTER_POSTMTGTMINCOST"
            caption={t("riskRegisters.minimum")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
          <Column
            dataField="RISK_REGISTER_POSTMTGTMLCOST"
            caption={t("riskRegisters.mostLikely")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
          <Column
            dataField="RISK_REGISTER_POSTMTGTMAXCOST"
            caption={t("riskRegisters.maximum")}
            alignment="right"
            dataType="number"
            format={currencyFormatterConfig}
            width={110}
          />
        </Column>
        <Column
          dataField="RISK_REGISTER_COSTIMPACTEMV"
          caption={t("riskRegisters.costImpact")}
          format={currencyFormatterConfig}
          dataType="number"
          width={180}
          allowEditing={false}
          calculateCellValue={costImpactVal}
        />
        <Column
          dataField="RISK_REGISTER_POSTMTGTEMV"
          caption={t("riskRegisters.mitigationCost")}
          format={currencyFormatterConfig}
          dataType="number"
          width={180}
        />
        <Column
          caption={t("riskRegisters.allocationBreakdown")}
          alignment="center"
          cssClass="parent-wrapper"
        >
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_1.VALUE"
            caption={t("riskRegisters.stage1")}
            alignment="right"
            dataType="number"
            width={120}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_2.VALUE"
            caption={t("riskRegisters.stage2")}
            alignment="right"
            dataType="number"
            width={120}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_3.VALUE"
            caption={t("riskRegisters.stage3")}
            alignment="right"
            dataType="number"
            width={120}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_4.VALUE"
            caption={t("riskRegisters.stage4")}
            alignment="right"
            dataType="number"
            width={120}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_5.VALUE"
            caption={t("riskRegisters.stage5")}
            alignment="right"
            dataType="number"
            width={120}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_6_IN_DEVELOPMENT.VALUE"
            caption={t("riskRegisters.stage6Dev")}
            alignment="right"
            dataType="number"
            width={240}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STAGE_6_IN_CONSTRUCTION.VALUE"
            caption={t("riskRegisters.stage6Construction")}
            alignment="right"
            dataType="number"
            width={240}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_LANDS_BLIGHT.VALUE"
            caption={t("riskRegisters.landsBlight")}
            alignment="right"
            dataType="number"
            width={160}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_LANDS_ACQUISITION.VALUE"
            caption={t("riskRegisters.landsAcquisition")}
            alignment="right"
            dataType="number"
            width={200}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_LANDS_PART_1_CLAIMS.VALUE"
            caption={t("riskRegisters.landspart1claim")}
            alignment="right"
            dataType="number"
            width={240}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_LANDS_INDEXATION_LANDS_RISK_GENERAL.VALUE"
            caption={t("riskRegisters.landsIndex")}
            alignment="right"
            dataType="number"
            width={280}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_CESS_STATUTORY_UNDERTAKERS.VALUE"
            caption={t("riskRegisters.statutoryUndertaker")}
            alignment="right"
            dataType="number"
            width={280}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_AUTHORITY_COSTS_RAIL_ENV_LOCAL_ETC.VALUE"
            caption={t("riskRegisters.authorityCost")}
            alignment="right"
            dataType="number"
            width={280}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_HIGHWAYS_ENGLAND_EMP_AGENT_STAGE_6.VALUE"
            caption={t("riskRegisters.highwayStage6")}
            alignment="right"
            dataType="number"
            width={280}
          />
          <Column
            dataField="RISK_ALLOCATION_COLUMN_HIGHWAYS_ENGLAND_EMP_AGENT_STAGE_7.VALUE"
            caption={t("riskRegisters.highwayStage7")}
            alignment="right"
            dataType="number"
            width={280}
          />
          <Column
            dataField="SUMMATION_CHECK"
            caption={t("riskRegisters.summationCheck")}
            alignment="right"
            allowEditing={false}
            calculateCellValue={summationCheckCellVal}
            width={160}
          >
            <AsyncRule
              message=""
              ignoreEmptyValue
              validationCallback={(param) => summationCheckValidate(param, t)}
            />
          </Column>
        </Column>
      </DataGrid>
    </div>
  )
}

export default RiskRegister
