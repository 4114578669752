import { get } from "lodash"
import { formatNumber } from "devextreme/localization"
import { ISpend } from "redux/ift/types"
import { UserRole } from "redux/profile/types"
import { RoleType } from "redux/role-permission/types"
import { addClass, identifyPermissions } from "utils/common-utils"
import { config } from "utils/config"

export const onSpendCellPrepared = (e: any) => {
  if (e.rowType === "data" && e.column.command === "edit" && e.data?.RowType === "SECTION") {
    const links = e.cellElement.querySelectorAll("a")
    links.forEach((link: { textContent: string | string[]; remove: () => void }) => {
      link?.remove()
    })
  }
}

const findDuplicateObjectsByProperty = (arr: any[], property: string) => {
  const { duplicateIndices } = arr.reduce(
    (acc, obj, index) => {
      const key = obj[property]
      if (acc.seen[key] !== undefined && acc.duplicateIndices.indexOf(acc.seen[key]) === -1) {
        acc.duplicateIndices.push(acc.seen[key])
      }
      acc.seen[key] = index

      return acc
    },
    { seen: {}, duplicateIndices: [] as any[] }
  )

  return duplicateIndices || []
}

export const onSpendRowPrepared = (e: any, iftSpend: ISpend[]) => {
  if (e && e.rowType === "data") {
    const { rowElement, data, rowIndex } = e

    if (data.RowType === "SECTION") addClass(rowElement, "heading-row")

    const sum = data.Values.reduce((acc: number, curr: any) => {
      const index = data.Values.findIndex((item: any) => item === curr)
      return acc + Number(data[`V${index}`])
    }, 0)

    if (data.RowType !== "SECTION" && sum.toFixed(2) !== data.TotalCost.toFixed(2))
      addClass(rowElement, "invalid-data-row")

    const iftSpendIndices = findDuplicateObjectsByProperty(iftSpend, "CeCode")
    if (data.RowType !== "SECTION" && iftSpendIndices.includes(rowIndex))
      addClass(rowElement, "duplicate-data-row")
  }
}

const contractorNoPerm = (userRole: UserRole | null) => {
  return identifyPermissions(userRole, -1, -1, [
    RoleType.ADMINISTRATORS,
    RoleType.AUTHORITY_ESTIMATOR,
  ])
}

export const calculateTotalProfiledCost = (row: any, field: string) => {
  return row.RowType === "SECTION"
    ? ""
    : row[field].reduce((acc: number, curr: number) => acc + row.TotalCost * curr, 0)
}

export const currencyConverter = (val: any) => {
  const currencySignCheck = config.clientName === "HE"

  return formatNumber(val || 0, {
    currency: currencySignCheck ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}

const checkContractorRevisionApplied = (userRoleId: number | null, libraryContent?: any) => {
  return (libraryContent || []).find(
    (item: any) => item.CONTRACTOR_ID === userRoleId && item.IS_APPLIED === 1
  )
}

export const renderTableCostCell = (
  cellInfo: any,
  userRole: UserRole | null,
  libraryContent?: any
) => {
  return contractorNoPerm(userRole) ||
    checkContractorRevisionApplied(userRole?.ESTIMATOR_ID ?? null, libraryContent)
    ? currencyConverter(cellInfo.value)
    : null
}

export const calculateTotalProfileCost = (
  newData: any,
  value: any,
  currentRowData: any,
  index: number
) => {
  currentRowData[`V${index}`] = Number(value) || 0
  const sum = currentRowData?.Values?.reduce((acc: number, curr: any, index: number) => {
    return acc + Number(currentRowData[`V${index}`])
  }, 0)

  newData.TotalProfiledCost = sum
  newData[`V${index}`] = value
}

export const spendTitleRender = (row: any) => {
  const val = get(row, "data.Title")
  const sectionCheck = get(row, "data.RowType") === "SECTION"

  return <label className={`${sectionCheck ? "fw-semibold" : ""}`}>{val}</label>
}

export const spendValueRender = (row: any, fieldName: string) => {
  const value = get(row, `data.${fieldName}`)

  return row?.data?.RowType === "SECTION" ? (
    <label className="fw-semibold">{value}</label>
  ) : (
    <div className="time-division-cell">
      <div className="value">{`${Number(value || 0).toFixed(2)}`}</div>
      <div className="separator" />
      <div className="value">{`${((value / row.data.TotalCost || 0) * 100).toFixed(2)}%`}</div>
    </div>
  )
}
