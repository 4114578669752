import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { fetchPowerBIAccessTokenAction } from "./middleware"
import { PowerBIState } from "./types"

const initialState: PowerBIState = {
  loading: LoadingState.DEFAULT,
  powerBIData: {},
}

const powerBISlice = createSlice({
  name: "powerBI",
  initialState,
  reducers: {
    setLoading: (state: PowerBIState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPowerBIAccessTokenAction.pending, (state: PowerBIState) => ({
      ...state,
      loading: LoadingState.LOADING,
      powerBIData: {},
    }))
    builder.addCase(
      fetchPowerBIAccessTokenAction.fulfilled,
      (state: PowerBIState, { payload }: PayloadAction<any>) => {
        return {
          ...state,
          loading: LoadingState.DEFAULT,
          powerBIData: payload,
        }
      }
    )
    builder.addCase(fetchPowerBIAccessTokenAction.rejected, (state: PowerBIState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const { setLoading, reset } = powerBISlice.actions

export const powerBISelector = (state: RootState) => state.PowerBI

export default powerBISlice.reducer
