import { memo } from "react"
import { useSelector } from "react-redux"
import { LoadingState } from "types/AppNav"
import { LoadPanel } from "devextreme-react"
import { iftSelector } from "redux/ift/iftSlice"

const ScheduleLoader = () => {
  const { loading } = useSelector(iftSelector)
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position="center"
      visible={loading === LoadingState.LOADING}
      showIndicator
      shading
      showPane
    />
  )
}

export default memo(ScheduleLoader)
