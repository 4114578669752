export default {
  translation: {
    appTitle: "Cost and Carbon Forecasting Tool",
    loginPageTitle: "Login",
    loginFormTitle: "Log in to your account",
    enterUserName: "Enter Username",
    emptyUserName: "Username is required",
    enterEmail: "Enter Email",
    emptyEmail: "Email is required",
    invalidEmail: "Email is invalid",
    enterPassword: "Enter Password",
    emptyPassword: "Password is required",
    forgotPassword: "Forgot password?",
    resetPassword: "Reset Password",
    changePassword: "Change Password",
    confirmPassword: "Confirm Password",
    passwordNotMatch: "Passwords do not match",
    passwordMatch: "Both the passwords match",
    pwdReqTitle: "Password Requirements:",
    pwdMinLngthCheck: "Be at least 8 characters long",
    pwdUpperCaseCheck: "Contain at least 1 upper case letter",
    pwdLowerCaseCheck: "Contain at least 1 lower case letter",
    pwdNumCheck: "Contain at least 1 numeric character",
    pwdSpecialCharCheck: "Contain at least 1 special character *()!@#$%^&",
    haveAnAccount: "Have an account? <0>Sign In</0>",
    returnToSignIn: "Return to <0>Sign In</0>",
    signUpTermsText:
      "By creating an account, you agree to the <0>Terms of Service</0> and <1>Privacy Policy</1>",
    createAccount: "Create a new account",
    continue: "Continue",
    signup: "Sign Up",
    resetPasswordDescription:
      "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
    profile: "Profile",
    logout: "Logout",
    sessionLogoutMsg: "You have been logged out due to inactivity.",
    uploadEpdTitle: "Upload EPD for following Resource Code(s):",
    btnOk: "ok",
    code: "Code",
    description: "Description",
    unit: "Unit",
    weightPerUnitKg: "Weight (kg)",
    a1a3: "A1-A3",
    a4: "A4",
    a5w: "A5w",
    a5a: "A5a",
    total: "Total",
    transportAssumption: "Transport Assumption",
    resourceCategory: "Resource Category",
    resourceGroup: "Resource Group",
    resourceType: "Resource Type",
    onlyPdfAllow: "Only pdf files are allowed to be uploaded",
    fileSizeLimit: "File size should not be more than 10 MB.",
    viewFile: "View File",
    wasteFactor: "Waste Factor",
    notes: "Notes",
    attachViewEpd: "Attach/View EPD",
    noRecords: "No records found",
    welcomeHome: "Welcome to Cost and Carbon Forecasting Tool!",
    areYouSure: "Are you sure?",
    confirmChanges: "Confirm changes",
    projectItemHeading: "Items and Resources in the Section",
    nil: "",
    permissionDenied: "You don't have access to visit this page.",
    estimate: "Estimate",
    menu: {
      home: "Home",
      projectBrowser: "Estimate Browser",
      forecasting: "Forecasting",
      keydata: "Key Data",
      billquantities: "Bill of Quantities",
      schedule: "Schedule",
      riskRegister: "Risk Register",
      scheduleOfOtherCost: "Schedule of Other Costs",
      previousEstimate: "Previous Estimate",
      reports: "Reports",
      estimateLevelCostAndCarbon: "Estimate - Level",
      iftComparator: "Comparator",
      iftComp: "Cost - Element Comparator",
      carbExpProfile: "Cost and Carbon Expenditure Profile",
      costEstimateSummary: "Cost and Carbon Estimate Summary Sheet (CCESS)",
      admin: "Admin",
      rolebasedaction: "Role Based Access",
      rolepermissions: "Role Permissions",
      assignestimatorsroles: "Assign Estimators to Roles",
      controlPanel: "Control Panel",
      assignInflationIndex: "Assign Inflation Index",
      masterData: "Master Data",
      codes: "Codes",
      dynamicCarbonLibrary: "Dynamic Carbon Library",
      baselineAssumptions: "Baseline Assumptions",
      __modesOfTransport: "Mode of Transport",
      transport: "Transport",
      transportAssumptions: "Transport (Journey Leg) Assumptions",
      modesOfTransport: "Mode of Transport",
      wasteFactor: "Waste Factor",
      fuelType: "Fuel / Labour Type",
      emissionFactor: "Emission Factors",
      resourceImport: "Base Carbon Library",
      resourceUpdate: "Resource Update",
      itemImport: "Item Import",
      itemUpdate: "Item Update",
      powerBi: "Power BI",
    },
    codesCategory: {
      modeOfTransportation: "Mode of Transportation",
      transportCategory: "Transport Category",
      wrapCategory: "WRAP Category",
      sourceReference: "Source Reference",
      fuelType: "Fuel / Labour Type",
      emptyCodeDescription: "Code description required",
      values: "Values",
    },
    estimateLibrarySetup: {
      title: "Estimate Library Setup",
      dwnldTemplt: "Download Template",
      upload: "Upload",
      // Table Column Headers //
      resourceLibraryID: "Resource Library ID",
      estimateNo: "Estimate No.",
      code: "Code",
      desc: "Description",
      unit: "Unit",
      rate: "Rate",
      contractorCode: "Contractor Code",
      region: "Region",
      weight: "Weight (kg) / unit",
      category: "Category",
      group: "Group",
      type: "Type",
      a1a3: "A1-A3",
      transportAssumption: "Transport Assumption (Transport Category)",
      a4: "A4",
      a5a: "A5a",
      a5w: "A5w",
      totalA1A5: "Total A1-A5",
      notes: "Notes",
    },
    epd: {
      importErr: "Only PDF files can be imported",
      expDateErr: "Please select a valid expiry date greater than issue date",
      saveSucs: "EPD saved successfully",
      title: "Environmental Product Declaration (EPD)",
      fileSizeErr: "Upload EPD of maximum 20mb file size",
      uploadErr: "File uploadded successfully",
      uploadFail: "File upload failed. Try again",
      fileTypeErr: "Only PDF file upload allowed",
      uploadAbortMsg: "File upload aborted",
      uploadSucsMsg: "File Uploaded",
      viewEPD: "View EPD File",
      code: "EPD Code",
      desc: "EPD Description",
      issuDate: "Issue Date",
      expDate: "Expiry Date",
      carbFactors: "Carbon Factors",
      a1a3Scope: "A1-A3 Scope?",
      a1a3Label: "Enter A1-A3 carbon factor",
      a1a3GWP: "A1-A3 GWP KgCO2e",
      a4Scope: "A4 Scope?",
      a4desc:
        "This is a calculated value derived from specific product weight, quantity, transport distance and modes.",
      a5aScope: "A5a Scope?",
      fuelCarbDesc: "Enter fuel carbon factor",
      a5aGWP: "A5a GWP KgCO2e",
      a5wScope: "A5w Scope?",
      a1a4Desc: "This value is derived from A1-A3, A4 and Waste Factor components.",
      save: "Save",
      addMore: "Add More",
    },
    projectDetails: {
      summary: "Project Summary",
      estimateNo: "Estimate No.",
      totalCost: "Total Cost",
      schemeTitle: "Scheme Title",
      totalCarbon: "Total Carbon (KgCO2e)",
      sectionsInEstimate: "Sections in the Estimate",
      manageRevisions: "Manage Revisions",
      ift: "View IFT",
    },
    sections: {
      scheme: "Scheme: ",
      estimate: "Estimate: ",
      estimateSections: "Estimate Sections",
      carbonEmissions: "Carbon Emissions (Estimate Level)",
      sectionsInEstimate: "Sections in the Estimate",
      baselineBOQ: "Baseline BOQ",
      recalculatingMsg: "Recalculating carbon values...",
      recalculateCarbon: "This will lookup the latest Cost and Carbon data for the components.",
      recalculate: "Recalculate",
      sync: "Sync",
      syncDesc:
        "This will look up and recalculate Carbon for all the components in the Project, including unlinked Resources.",
      syncMsg: "Carbon data synchronisation is in progress",
      number: "Number",
      desc: "Description",
      a1a3: "A1-A3 (KgCO2e)",
      a4: "A4 (KgCO2e)",
      a5a: "A5a (KgCO2e)",
      a5w: "A5w (KgCO2e)",
      totalA1A5: "Total A1-A5",
      totalCO2: "Total CO2e (tonnes)",
      totalCost: "Total Cost",
      completed: "Completed?",
    },
    boq: {
      scheme: "Scheme: ",
      estimate: "Estimate: ",
      estimateSections: "Estimate Sections: ",
      boq: "BOQ",
      carbonEmissions: "Carbon Emissions (Estimate Level)",
      totalCarbonLabel: "Total Carbon (KgCO2e)",
      baselineBOQ: "Baseline BOQ",
      recalculatingMsg: "Recalculating carbon values...",
      recalculateCarbon: "This will lookup the latest Cost and Carbon data for the components.",
      recalculate: "Recalculate",
      infoText: "Page {0} of {1} ({2} BOQ Components)",
      code: "Code",
      desc: "Description",
      rate: "Rate",
      qty: "Quantity",
      unit: "Unit",
      a1a3: "A1-A3 (KgCO2e)",
      a4: "A4 (KgCO2e)",
      a5a: "A5a (KgCO2e)",
      a5w: "A5w (KgCO2e)",
      totalA1A5: "Total A1-A5",
      totalCost: "Total Cost",
      resourceDetails: "Resource Details",
      updateSubItemQty: "Update SubItem Quantity",
      SubItemItemUpdateMsg: "SubItem quantity updated successfully.",
      comingSoon: "Coming Soon...",
      pas2080A1A5: "PAS2080 A1-A5",
      pas2080B1B8: "PAS2080 B1-B8",
      pas2080C1C4: "PAS2080 C1-C4",
      updatePopUpa1a3: "A1-A3 KgCO2e/unit",
      updatePopUpa4: "A4 KgCO2e/unit",
      updatePopUpa5a: "A5A KgCO2e/unit",
      updatePopUpWasteFactor: "Waste Factor %",
      resourceManualUpdateMsg: "NOTE: This row has a Resource Carbon value set manually.",
      null0msg:
        "A faulty value like null (empty field), or zero was detected. Any such value will undergo a check with the base carbon library and values will be updated accordingly. Are you sure to proceed?",
    },
    toolbarActions: {
      undoPrev: "Undo the most recent change",
      undoAll: "Undo All",
      resetAllFilters: "Reset all filters",
      resetAll: "Reset all",
      exportCSV: "Export to CSV",
      exportExcel: "Export to Excel",
      save: "Save",
      saveAllChanges: "Save all changes",
      delete: "Delete",
      undo: "Undo",
      add: "Add",
      edit: "Edit",
      export: "Export",
    },
    transportAssumptions: {
      parentColHead: "Journey Leg and Distances",
      tableCol1Head: "Transport Category",
      tableCol2Head: "Mode of Transportation - 1",
      tableCol3Head: "Distance (kms) - 1",
      tableCol4Head: "Mode of Transportation - 2",
      tableCol5Head: "Distance (kms) - 2",
      emptyRowErrMsg: "Empty rows cannot be added.",
    },
    fuelTypeScreen: {
      fuelType: "Fuel / Labour Type",
      carbonFactor: "Carbon Factor",
      fuelUnits: "Units",
      carbonFactorValidation: "Carbon factor must be number.",
    },
    modeOfTransportationScreen: {
      columnHeader: "Mode of Transportation and Carbon",
      modeOfTransportation: "Mode of Transportation",
      carbonFactor: "Carbon Factor (kgCO2e/tonne.km)",
      carbonFactorValidation: "Carbon factor must be number.",
    },
    assignInflationIndexScreen: {
      inflationIndex: "Inflation Index",
      contractorAccess: "Contractor Access",
    },
    wasteFactorScreen: {
      columnHeader: "Waste Factor and Carbon",
      wasteFactor: "Wastage Category",
      carbonFactor: "Waste%",
      carbonFactorValidation: "Waste% must be number.",
    },
    estimatorRoles: {
      assignRoles: "Assign Estimators to Roles",
      tableCol1Head: "Estimators not assigned to",
      tableCol2Head: "Estimators assigned to",
    },
    estimateLevel: {
      breadCrumbHeading: "Estimate - Level Cost and Carbon",
      headerDropdown1Label: "Estimate No.",
      headerDropdown2Label: "Aggregate By",
      grandTotal: "Grand Total",
      estimateDesc: "Estimate Description: ",
      tableCol1Head: "Section No",
      tableCol2Head: "Section Description",
      tableCol3Head: "Item Code",
      tableCol4Head: "Item Description",
      tableCol5Head: "Resource Code",
      tableCol6Head: "Resource Description",
      tableCol7Head: "A1-A3 KgCO2e",
      tableCol8Head: "A4 KgCO2e",
      tableCol9Head: "A5a KgCO2e",
      tableCol10Head: "A5w KgCO2e",
      tableCol11Head: "Total A1-A5",
      tableCol12Head: "Total Cost",
      aggregateOption1: "Section",
      aggregateOption2: "Item",
      aggregateOption3: "Resource",
    },
    iftComparatorMain: {
      breadCrumbHeading: "Comparator",
      baseEstimate: "Baseline Estimate",
      revisions: "Revision",
      nominatePreferredRevision: "Nominate Preferred Revision",
      selectPreferredRevision: "Please Select Preferred Revision",
      baseline: "Baseline",
      code: "Code",
      desc: "Description",
      totalA1A5: "Total A1-A5",
      totalCost: "Total Cost",
      baseCost: "Cost Variance",
      carbon: "Carbon Variance",
      inflation: "Inflation",
      risk: "Risk",
      reportNotGen: "CESS Report not generated.",
      estimateTotals: "Estimate Totals",
      estimateNumb: "Estimate No",
      estimate: "Estimate",
      totalA1A5withMeasure: "Total A1-A5 (KgCO2e)",
      totalCostwithMeasure: "Total Cost ({{compCurrencySign}})",
    },
    iftCostComparatorMain: {
      breadCrumbHeading: "IFT Cost Element Comparator",
      baseEstimate: "Base Estimate",
    },
    costEstimateSummary: {
      breadCrumbHeading: "Cost and Carbon Estimate Summary Sheet",
      breadCrumbHeading2: "CCESS Report",
      noReportText: "Cost and Carbon Estimate Summary Sheet is not available for this estimate.",
      portfolioRisk: "Portfolio Risk",
      inflation: "Inflation",
      schemeNo: "Scheme Number",
      schemeTitle: "Scheme Title",
      schemeType: "Scheme Type",
      estimateNo: "Estimate Number",
      estimateTitle: "Estimate Title",
      estimateReleaseDate: "Estimate Release Date",
      clientProjMngr: "Client Project Manager",
      estimateType: "Estimate Type",
      estimator: "Estimator",
      estimateBaseDate: "Estimate Base Date",
      portfolio: "Portfolio",
      programme: "Programme",
      estimateNumb: "Estimate No",
      estimateDesc: "Estimate Description",
      estimateStage: "Estimate Stage",
      estimateDate: "Estimate Date",
      sectionCostElement: "Section / Cost Element",
      totalBaseEstimate: "Total Base Estimate (Including UIA)",
      MLCost: "ML Cost ({{costSummaryReportCurrencySign}})",
      a1a3: "A1-A3 KgCO2e",
      a4: "A4 KgCO2e",
      a5a: "A5a KgCO2e",
      a5w: "A5w KgCO2e",
      totala1a5: "Total A1-A5 KgCO2e",
      projRiskOppUncertCost: "Project Risk, Opportunity & Uncertainty Cost",
      MLEmployerRisk: "ML Employer Risk ({{costSummaryReportCurrencySign}})",
      MLContractDlvryPartnrRsk:
        "ML Contractor / Delivery Partner Risk ({{costSummaryReportCurrencySign}})",
      uncertainty: "Uncertainty",
      MLUncertainity: "ML Uncertainty ({{costSummaryReportCurrencySign}})",
      totalProjRiskAndUncertinty: "Total Project Risk and Uncertainty",
      MLtotalProjRiskAndUncertintyCurr:
        "ML Total Project Risk and Uncertainty ({{costSummaryReportCurrencySign}})",
      projRiskOpprtntyUncertCarb: "Project Risk, Opportunity & Uncertainty Carbon",
      totalCarbProjRiskUncertAllwnce: "Total A1-A5 KgCO2e Project Risk and Uncertainty Allowance",
      portfolioRiskCost: "Portfolio Risk - Cost",
      MLPortfolioRisk: "ML Portfolio Risk ({{costSummaryReportCurrencySign}})",
      portfolioRiskCarb: "Portfolio Risk - Carbon",
      totalCarbPortfolioRiskAllwnce: "Total A1-A5 KgCO2e Portfolio Risk Allowance",
      showCarbVals: "Show Carbon Values",
      hideCarbVals: "Hide Carbon Values",
      costBaseDate: "Cost Base Date",
      totalCostInflation: "Total Cost Inflation",
      carbInflation: "Carbon Inflation",
      totalCostProj: "Total Base, Project Risk - Cost",
      totalCarbProj: "Total Base, Project Risk - Carbon",
      totalCostProjAndPortfolio: "Total Base, Project Risk & Portfolio Risk - Cost",
      totalCarbProjAndPortfolio: "Total Base, Project Risk & Portfolio Risk - Carbon",
      totalCostProjAndInflation: "Total Base, Project Risk & Inflation - Cost",
      totalCarbProjAndInflation: "Total Base, Project Risk & Inflation - Carbon",
      totalCost: "Total Base, Project Risk, Portfolio Risk & Inflation - Cost",
      totalCarb: "Total Base, Project Risk, Portfolio Risk & Inflation - Carbon",
    },
    carbExpProfile: {
      breadCrumbHeading: "Cost and Carbon Expenditure Profile",
      breadCrumbHeading2: "Estimated Cost and Carbon Expenditure Profile",
      schemeTitle: "Scheme Title",
      estimateNo: "Estimate No",
      estimateDesc: "Estimate Description",
      estimateStage: "Estimate Stage",
      estimateDate: "Estimate Date",
      estimator: "Estimator",
      noReportText: "Cost expenditure profile is not available for this estimate.",
    },
    carbExpProfileReport: {
      schemeType: "Scheme Type:",
      scheme: "Scheme:",
      nationalHighways: "National Highways",
      commercialServcsDiv: "Commercial Services Division",
      sasHeading: "Scheme Analysis Sheet(SAS) - Expenditure Profile - Most Likely Cost",
      fundingDirectorate: "Funding Directorate",
      projSchemeName: "Project / Scheme Name",
      optionName: "Option Name",
      estReleaseDate: "Estimate Release Date",
      currPCFStage: "Current PCF Stage",
      typeOfEstimate: "Type of Estimate",
      leadCostEngineer: "Lead Cost Engineer",
      showCarbVals: "Show Carbon Values",
      hideCarbVals: "Hide Carbon Values",
      sectionCostElement: "Section / Cost Element",
      nil: "",
      historicCosts: "Historic Costs",
      cost: "Cost (£)",
      a1a3: "A1-A3 KgCO2e",
      a4: "A4 KgCO2e",
      a5a: "A5a KgCO2e",
      a5w: "A5w KgCO2e",
      totala1a5: "Total A1-A5 KgCO2e",
      total: "Total",
      summary: "Summary",
    },
    rolePermission: {
      breadCrumb: "Role Permissions",
      headerDropdown1Label: "Select Role",
      roleOption1: "Administrators",
      roleOption2: "Authority Estimators",
      roleOption3: "Contractors",
      roleOption4: "Blank(Default)",
      tableCol1Head: "Permission",
      tableCol2Head: "Access Level",
    },
    estimateRevisionManagement: {
      breadCrumbHeading1: "Estimate: ",
      breadCrumbHeading2: "Estimate Revisions Management",
      addRevision: "Add Revision",
      tableCol1Head: "Scheme Title",
      tableCol2Head: "Estimate Number",
      tableCol3Head: "Estimate Description",
      tableCol4Head: "Action",
      tableCol5Head: "Estimate Stage",
      tableCol6Head: "Estimate Date",
      tableCol7Head: "Estimator",
      tableCol8Head: "Region",
      tableCol9Head: "Total Cost",
      tableCol10Head: "Total Carbon (kgCO2e)",
      popUpHeading: "Manage Contractors",
      role: "Role",
      contractors: "Contractors",
      popUpCol1Heading: "Assigned to",
      popUpCol2Heading: "Un-Assigned From",
      hide: "Hide",
      manageContractors: "Manage Contractors",
      applyEstimateLibrary: "Apply Estimate-Specific Library",
      ift: "View IFT",
      editHeading: "Editing Revision",
      estimateDesc: "Estimate Description",
      delCnfMsg: "Deleted revisions cannot be recovered. Are you sure you want to proceed?",
      delCancelMsg:
        "This revision has been assigned to one or more Contractors. You cannot delete it.",
      delCancelMsg1:
        "Failed to delete resource, as revision has been assigned to one or more Contractors.",
      minCharErr: "Please enter a value in the description field.",
      maxCharErr: "This Description exceeds the 200 character limit. Please update.",
    },
    contractorEstimateLibrary: {
      popUpHeadingAddLibrary: "Add New Library",
      addLibrary: "Add Library",
      enterLibraryName: "Enter Library Name",
      emptyLibraryName: "Library Name is Required",
      duplicateLibraryNameErr: "A library with this name already exists, please try a new one.",
      adminAssurance: "The admin has Assured this Resource.",
      assuredDataInfo:
        "An Assured Carbon factor exists in the Contractor's Dynamic Carbon Library for this Resource.",
      applyAssuredConfirmMsg:
        "This Resource has carbon factors assured by the Authority. Would you like to use these factors?",
      yes: "Yes",
      no: "No",
      assured: "Assured",
      popUpHeadingApplyRate: "Apply Supplier Rate",
      supplierRate: "Supplier Rate",
      supplierName: "Supplier Name",
      btnTextBackToLib: "Back to {{ proj }} Specific Libraries",
      creationDate: "Creation Date",
      title: "Title",
      creator: "Creator",
      description: "Description",
      supplier: "Supplier",
      contractorCode: "User",
      loginCode: "Login Code",
      region: "Region",
      category: "Category",
      unit: "Unit",
      weightKg: "Weight (kg)",
      type: "Type",
      group: "Group",
      modeOfTransportation1: "Mode of Transportation 1",
      modeOfTransportation2: "Mode of Transportation 2",
      modeOfTransportation3: "Mode of Transportation 3",
      modeOfTransportation4: "Mode of Transportation 4",
      modeOfTransportation5: "Mode of Transportation 5",
      distance1: "Distance 1",
      distance2: "Distance 2",
      distance3: "Distance 3",
      distance4: "Distance 4",
      distance5: "Distance 5",
      addNewThenApply: "Add a new Estimate Revision and then apply this Library",
      applyEstimateLibrarySuccess: "Successfully applied to estimate",
      selectSupplier: "Select Supplier",
      addOrUpdate: "Add / Update",
      viewOrUpdate: "View / Update",
      viewEPD: "View EPD",
      emptyDistanceErr: "Please enter a distance value against the selected Mode Of Transportation",
      a1a3: "A1-A3 GWP KgCO2e/unit (ML)",
      transportAssumption: "Transport Assumption",
      a4: "A4 GWP KgCO2e/unit (ML)",
      fuelOrLabor: "Fuel/Labour Assumption",
      a5a: "A5A GWP KgCO2e/unit (ML)",
      wasteCategoryAssumption: "Waste Category Assumption",
      wasteFactorPer: "Waste Factor (%)",
      updateCarbonFactors: "Update Carbon Factors",
      applyToEstimateBtnText: "Apply To Estimate",
      select: "Select",
    },
    dynamicCarbonLibrary: {
      breadCrumbHeading: "Dynamic Carbon Library",
      popUpHeading: "Code: ",
      tableCol1Head: "Code",
      tableCol2Head: "Description",
      tableCol3Head: "Unit",
      tableCol4Head: "Contractor Code",
      tableCol5Head: "Supplier",
      tableCol6Head: "Rate",
      tableCol7Head: "Region",
      tableCol8Head: "Weight (kg)",
      tableCol9Head: "Category",
      tableCol10Head: "Group",
      tableCol11Head: "Type",
      tableCol12Head: "View EPD",
      tableCol13Head: "A1-A3",
      tableCol14Head: "A5a",
      tableCol15Head: "Waste Factor (%)",
      tableCol16Head: "Assured",
    },
    ift: {
      estimate: "Estimate: ",
      revision: "Revision: ",
      ift: "IFT",
      keyData: "Key Data",
      previousEstimate: "Previous Estimate",
      schedule: "Schedule",
      scope: "Scope",
      riskRegister: "Risk Register",
      inflationAndSpend: "Inflation And Spend",
      inflationSwitchTxt: "Inflation allocation",
      spendSwitchTxt: "Spend allocation",
      stage: "Stage",
      baseline: "Baseline",
      startDate: "Start Date",
      endDate: "End Date",
      iftScheduleDateErr: "Please enter a valid date of the format DD/MM/YYYY",
      scheduleStartDateValidation: "Start date should be lesser than End date",
      scheduleEndDateValidation: "End date should be greater than Start date",
    },
    projectBrowser: {
      estimates: "Estimates",
      schemeTitle: "Scheme Title",
      estimateNo: "Estimate No",
      estimateDescription: "Estimate Description",
      action: "Action",
      baselineEstimate: "Baseline Estimate",
      estimateStage: "Estimate Stage",
      estimateDate: "Estimate Date",
      completed: "Completed?",
      authorised: "Authorised?",
      estimator: "Estimator",
      region: "Region",
      preferredEstimate: "Preferred Estimate",
    },
    keyData: {
      projKeyData: "Project Key Data",
      projName: "Project Name:",
      projPin: "Project Pin:",
      optionName: "Option Name: ",
      estimateReleaseDate: "Estimate Release Date:",
      estimateBaseDate: "Estimate Base Date (CY):",
      historicCostDate: "Historic Cost Date:",
      AGCodeDIP: "Agreement Code for DIP:",
      AGCodePC: "Agreement Code for Project Control:",
      AGCode: "Agreement Code (null):",
      AGCodeTA: "Agreement Code for TA:",
      contractorFee: "Contractor Fee (%): ",
      soFa: "SoFA ({{ 0 }}):",
      vat: "VAT (%):",
      nrvat: "NRVAT (%):",
      rangeEstDist: "Range Estimate Distribution",
      incPortRisk: "Including Portfolio Risk ({{ 0 }})",
      excPortRisk: "Excluding Portfolio Risk ({{ 0 }})",
    },
    riskRegisters: {
      mainTitle: "Risk Register Quantification Details",
      riskNumber: "Risk No",
      cause: "Risk Cause",
      event: "Event",
      impact: "Impact",
      baseDate: "Base Date of Risk Values",
      contractualAllocation: "Contractual Allocation",
      preMitigationValue: "Pre-Mitigation Risk Values",
      likelyhood: "Likelihood Most Likely",
      minimum: "Minimum",
      mostLikely: "Most Likely",
      maximum: "Maximum",
      postMitigationValue: "Post-Mitigation Risk Values",
      costImpact: "Cost Impact of Risk",
      mitigationCost: "Mitigation Action Cost",
      allocationBreakdown: "% Risk Allocation to CESS Breakdown Cost Headings",
      stage1: "CESS Stage 1",
      stage2: "CESS Stage 2",
      stage3: "CESS Stage 3",
      stage4: "CESS Stage 4",
      stage5: "CESS Stage 5",
      stage6Dev: "CESS Stage 6 - In Development",
      stage6Construction: "CESS Stage 6 - In Construction",
      landsBlight: "CESS Lands - Blight",
      landsAcquisition: "CESS Lands - Acquisition",
      landspart1claim: "CESS Lands - Part 1 Claims",
      landsIndex: "Lands Indexation/ Lands Risk (General)",
      statutoryUndertaker: "CESS Statutory Undertakers",
      authorityCost: "Authority Costs (Rail/Env/Local, etc)",
      highwayStage6: "Highways England/Emp Agent Stage 6",
      highwayStage7: "Highways England/Emp Agent Stage 7",
      summationCheck: "Summation Check",
      summationError:
        "Sum of % Risk Allocation to CESS Breakdown Cost Headings values more than 100",
      requireFields: "Please fill all required fields",
      riskRegisterNumberError: "Entered Risk Register Number already exist",
      minMaxValidation: "Value must be between 0 to 100",
      summationNotEqual100:
        "Sum of % Risk Allocation to CESS Breakdown Cost Headings values must be equal to 100",
      delConfirmation: "Are you sure you want to delete this risk register record?",
    },
    iftScope: {
      showCarbon: "Show Carbon",
      showBaseline: "Show Baseline",
      baseline: "Baseline",
      code: "Code",
      desc: "Description",
      unit: "Unit",
      a1a3: "A1-A3",
      a4: "A4",
      a5a: "A5a",
      a5w: "A5w",
      totalA1A5: "Total A1-A5",
      qty: "Quantity",
      rate: "Rate",
      cost: "Cost",
      reva1a3: "Revision A1-A3",
      reva4: "Revision A4",
      reva5a: "Revision A5a",
      reva5w: "Revision A5w",
      revTotalA1A5: "Revision Total A1-A5",
      revQty: "Revision Quantity",
      revRate: "Revision Rate",
      revCost: "Revision Cost",
      provisionalSum: "Provisional Sum",
      carbon: "Carbon",
      a1a3kg: "A1-A3 KgCO2e/unit",
      a4kg: "A4 KgCO2e/unit",
      a5akg: "A5a KgCO2e/unit",
      wasteFactor: "Waste factor %",
      recalculate: "Recalculate",
      recalculateCarbon: "This will lookup the latest Cost and Carbon data for the components.",
      recalculatingMsg: "Recalculating carbon values (This might take some time)...",
      recalculatingAftrMsg: "Fetching data after recalculation",
      SubItemItemUpdateMsg: "SubItem quantity updated successfully.",
      SubItemItemDelMsg: "Project SubItem deleted successfully.",
      resourceDetails: "Resource Details",
      comingSoon: "Coming Soon...",
      pas2080A1A5: "PAS2080 A1-A5",
      pas2080B1B8: "PAS2080 B1-B8",
      pas2080C1C4: "PAS2080 C1-C4",
      updatePopUpa1a3: "A1-A3 KgCO2e/unit",
      updatePopUpa4: "A4 KgCO2e/unit",
      updatePopUpa5a: "A5A KgCO2e/unit",
      updatePopUpWasteFactor: "Waste Factor %",
      null0msg:
        "A faulty value like null (empty field), or zero was detected. Any such value will undergo a check with the base carbon library and values will be updated accordingly. Are you sure to proceed?",
    },
    previousEstimate: {
      projectName: "Project Name",
      schemeType: "Scheme Type",
      costEstimateSummarySheet: "Cost Estimate Summary Sheet (CESS)",
      totalBaseEstimate: "Total Base Estimate (including UIA)",
      mostLikely: "Most Likely",
      cumulativeMostLikely: "Cumulative Most Likely",
      mostLikelyFinal: "Final Most-Likely",
      riskAllocation: "Risk Allocation",
      contractorRisk: "Contractor/Delivery Partner Risk",
      employerRisk: "Employer Risk",
      mostLikelyRisk: "Most Likely Risk",
      totalRiskAdjustment: "Total Risk Adjustment",
      baseEstimateIncRisk:
        "Total Base Estimate (including Risk) note: Project risk in construction phase not disaggregated in individual lines",
      uncertainty: "Uncertainty",
      estimateTotal:
        "Estimate Total - note project risk in construction phase not disaggregated in individual lines",
      comments: "Comments",
    },
    provisionalSum: {
      addProvisionalSum: "Add Provisional Item",
      code: "Code",
      description: "Description",
      unit: "Unit",
      qty: "Qty",
      rate: "Rate",
      cost: "Cost",
      a1_a3: "A1-A3 KgCO2e/unit",
      a4: "A4 KgCO2e/unit",
      a5a: "A5a KgCO2e/unit",
      wasteFactor: "Waste factor %",
    },
    scopeResource: {
      addResource: "Add Resource",
      resourceGroups: "Resource Groups",
      addItem: "Add Item",
      itemGroups: "Item Groups",
      code: "Code",
      category: "Category",
      subContractor: "Subcontractor/Supplier",
      description: "Description",
      unit: "Unit",
      qty: "Qty",
      rate: "Rate",
      cost: "Cost",
      a1_a3: "A1-A3 KgCO2e/unit",
      a4: "A4 KgCO2e/unit",
      a5a: "A5a KgCO2e/unit",
      wasteFactor: "Waste factor %",
      selectedItem: "Selected Item(s)",
      addSelectedItem: "Add Selected Item",
    },
    scopeItem: {
      addItem: "Add Item",
      itemGroups: "Item Groups",
      code: "Code",
      description: "Description",
      addSelectedItem: "Add Selected Item",
      unit: "Unit",
      qty: "Quantity",
      rate: "Rate",
      cost: "Cost",
      selectedItem: "Selected Items",
      qty0Check: "Quantity must be greater than zero",
    },
    scopeSubItem: {
      addSubItem: "Add Sub Item",
      groups: "Groups",
      code: "Code",
      description: "Description",
      category: "Category",
      unit: "Unit",
      subContractor: "Subcontractor/Supplier",
      rate: "Rate",
      qty: "Quantity",
      cost: "Cost",
    },
    spend: {
      viewRes: "View Result",
      description: "Description",
      cashFlowForcast: "CASH FLOW FORECAST - DEVELOPMENT PHASE",
      costBaseDate: "Cost Base Date",
      totalCost: "Total Cost",
      totalProfiledCost: "Total Profiled Cost",
      totalProfiledCostWInflation: "Total Profiled Cost with Inflation",
      timeDivisions: "Time Divisions",
      spendResPopUpHead: "IFT Spend Result",
      saveSumErrMsg: "Sum of time divisions should be equal to 100% for",
      changeSaveSCS: "Changes saved successfully",
      changeSaveErr: "Error while saving changes",
    },
    inflation: {
      applyToSelRows: "Apply Inflation Sheet to selected rows",
      description: "Description",
      costBaseDate: "Cost Base Date",
      inflationIdx: "Inflation Index",
      errSaving: "Error while saving changes",
      changeSuccess: "Changes saved successfully",
      select1Idx: "Please select one index sheet from option",
    },
  },
}
