import { useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "devextreme-react"
import DataGrid, { Column } from "devextreme-react/data-grid"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, RoleType } from "redux/role-permission/types"
import { identifyPermissions } from "utils/common-utils"

const SupplierRatesTable = (props: any) => {
  const { t } = useTranslation()
  const { applyRate, suppliers } = props

  const gridRef = useRef<DataGrid>(null)
  const { userRole } = useSelector(profileSelector)

  const contractorCheck = useMemo(
    () => identifyPermissions(userRole, -1, AccessLevel.NO_ACCESS, [RoleType.CONTRACTOR]),
    [userRole]
  )

  const suppliersTabledata = useMemo(
    () =>
      contractorCheck ? (suppliers || []).filter((item: any) => item?.SUBCONTRACTOR_ID) : suppliers,
    [suppliers, contractorCheck]
  )

  const applySupplyRateCell = useCallback(
    (row: any) => {
      return (
        <div className="d-flex justify-content-center">
          <Button
            className="active supplier-apply-btn"
            stylingMode="contained"
            text="Apply"
            onClick={() => applyRate(row.data)}
          />
        </div>
      )
    },
    [applyRate]
  )

  return (
    <div className="supplier-rates-table p-3">
      <DataGrid
        id="gridContainer"
        ref={gridRef}
        dataSource={suppliersTabledata || []}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        showColumnLines
        selection={{
          mode: "multiple",
          showCheckBoxesMode: "none",
        }}
        height={490}
      >
        <Column
          key="SUBCONTRATOR_CODE"
          caption={t("contractorEstimateLibrary.supplierName")}
          dataField="SUBCONTRATOR_CODE"
        />
        <Column
          key="RESOURCE_LIBRARY_RATE"
          caption={t("contractorEstimateLibrary.supplierRate")}
          dataField="RESOURCE_LIBRARY_RATE"
        />
        <Column width={150} fixed fixedPosition="right" cellRender={applySupplyRateCell} />
      </DataGrid>
    </div>
  )
}

export default SupplierRatesTable
