import { TFunction } from "i18next"
import { get } from "lodash"
import { ContextMenuPreparingEvent } from "devextreme/ui/data_grid"
import {
  setSelectedRevision,
  setshowManageContractorsPopUp,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { UserRole } from "redux/profile/types"
import { setSelectedTab } from "redux/ift/iftSlice"
import { AccessLevel, AccessType, RoleType } from "redux/role-permission/types"
import { setSelectedEstimateLibrary } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"
import { allRoles, navUtil, identifyPermissions } from "utils/common-utils"

export const prepareRvsnMgmntContextMenu = (data: {
  e: ContextMenuPreparingEvent
  t: TFunction<"translation", undefined>
  userRole: UserRole | null
  dispatch: any
}) => {
  const { e, t, dispatch, userRole } = data
  if (
    e.target === "content" &&
    e.row?.rowType === "data" &&
    window.location.hash.includes("/estimate-revision-management") &&
    get(e, "event.button") === 0 // The value can be 0/2/undefined & functionality is needed only on 0 hence 0 check
  ) {
    let manageContractorPermission = identifyPermissions(userRole, -1, AccessLevel.FULL_ACCESS, [
      RoleType.ADMINISTRATORS,
      RoleType.AUTHORITY_ESTIMATOR,
    ])
    const roleBasedIftPermission = identifyPermissions(
      userRole,
      AccessType.IFT,
      AccessLevel.YES,
      allRoles
    )
    const iftPermission =
      get(userRole, "tenantSettings.IFT") === "Y" &&
      get(e, "row.data.ShowIFT") &&
      roleBasedIftPermission
    const estimateSpecificLibraryPermission = identifyPermissions(
      userRole,
      AccessType.PROJECT_SPECIFIC_LIBRARY,
      [AccessLevel.READ_ONLY, AccessLevel.FULL_ACCESS],
      allRoles
    )

    // If the specific entry in table is created by a contractor,
    // Manage Contractors should not be visible, Hence the following code
    if (get(e, "row.data.RoleId") === RoleType.CONTRACTOR) {
      manageContractorPermission = false
    }

    if (!e.items) {
      e.items = [
        {
          text: t("estimateRevisionManagement.manageContractors"),
          onItemClick: () => {
            dispatch(setshowManageContractorsPopUp(true))
            dispatch(setSelectedRevision(e?.row?.data))
          },
          visible: manageContractorPermission,
        },
        {
          text: t("estimateRevisionManagement.applyEstimateLibrary"),
          onItemClick: () => {
            dispatch(setSelectedRevision(e?.row?.data))
            dispatch(setSelectedEstimateLibrary(null))
            navUtil.navigate(
              `/project/${get(e, "row.data.ParentProjId")}/estimate-library-setup/${get(
                e,
                "row.data.Id"
              )}`
            )
          },
          visible: estimateSpecificLibraryPermission,
        },
        {
          text: t("estimateRevisionManagement.ift"),
          onItemClick: () => {
            dispatch(setSelectedRevision(e?.row?.data))
            dispatch(setSelectedTab(0))
            navUtil.navigate(
              `/project/${get(e, "row.data.ParentProjId")}/erm/${get(e, "row.data.Id")}/ift`
            )
          },
          visible: iftPermission,
        },
      ]
    }
  }
}

export const onERMContentReady = (e: {
  component: { columnOption: (arg0: string, arg1: string, arg2: number) => void }
}) => {
  e.component.columnOption("command:edit", "visibleIndex", -1)
}
