import { LoadingState } from "types/AppNav"

export type SelectRole = {
  ROLE_ID: number
  ROLE_NAME: string
  ROLE_IS_EXTERNAL: string
  EXTERNAL_CLIENT_ID: null | any
  ROLE_TIMESTAMP: null | any
  ROLE_RECORDFLAG: null | any
  HASHCODE: null | any
}

export type SelectRoleData = {
  statusCode: number
  data: SelectRole[]
}
export type RoleAccessList = {
  ACCESS_ID: number
  ACCESS_NAME: string
  ACCESS_TIMESTAMP: null
  ACCESS_RECORDFLAG: null
  HASHCODE: null
}

export type AccessLevelItem = {
  ACCESS_LEVEL_NAME: string
  ACCESS_LEVEL_ID: number
}

export type RolePermissionLevelItem = {
  ROLEACCESS_ID: number
  ROLEACCESS_ROLEID: SelectRole | number
  ROLEACCESS_ACCESSID: RoleAccessList | number
  ROLEACCESS_ACCESSLEVELID: AccessLevelItem | number
  ROLEACCESS_TIMESTAMP: string | null
  ROLEACCESS_RECORDFLAG: boolean | null
  HASHCODE: string | null
  IS_DISABLED: string
}

export type RoleAccessLevelItem = {
  rolePermissionList: RolePermissionLevelItem[]
  accessList: RoleAccessList[]
  roleAccessLevelList: AccessLevelItem[]
}

export type RolePermissionState = {
  loading: LoadingState
  roleList: SelectRole[]
  rolePermissionList: RolePermissionLevelItem[]
  accessList: RoleAccessList[]
  roleAccessLevelList: AccessLevelItem[]
  permission: SelectRole | null
}

export type UpdatePermissionLevelForRoleRequest = {
  currentRoleItem: RolePermissionLevelItem
  updatedLevel: number
}

export enum RoleType {
  ADMINISTRATORS = 1,
  AUTHORITY_ESTIMATOR = 2,
  CONTRACTOR = 3,
}

export enum AccessLevel {
  NO_ACCESS = 1,
  READ_ONLY = 2,
  FULL_ACCESS = 3,
  YES = 4,
  NO = 5,
}

export enum AccessType {
  CARBON_RATE_A1_A3 = 1,
  CARBON_RATE_A4 = 2,
  CARBON_RATE_A5A = 3,
  CARBON_RATE_A5W = 4,
  PROJECTS = 5,
  TEMPLATE_PROJECT_LIBRARY = 6,
  REPORTS = 7,
  CARBON_LIBRARY = 8,
  PROJECT_SPECIFIC_LIBRARY = 9,
  COST_BASELINE_ESTIMATE_ITEM_LEVEL = 10,
  COST_BASELINE_ESTIMATE_COMPOSITE_ITEM_LEVEL = 11,
  GRANT_RIGHTS = 12,
  MANAGE_USERS = 13,
  CREATE_REVISION_ESTIMATE = 14,
  DYNAMIC_CARBON_LIBRARY = 15,
  IFT = 16,
  SCOPE = 17,
  EDIT_ITEMS_FROM_REVISION = 18,
  EDIT_RESOURCES_FROM_REVISION = 19,
  ADD_ITEMS_RESOURCES = 20,
  ASSIGN_INFLATION_INDEX = 21,
  ADD_A_REVISION_TO_UNAUTHORISED_ESTIMATE = 22,
  DELETE_ITEMS_RESOURCES = 23,
  SHOW_COST_DATA = 24,
}
