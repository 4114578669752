/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { TFunction } from "i18next"
import { get, sortBy } from "lodash"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ContextMenuPreparingEvent } from "devextreme/ui/data_grid"
import { UserRole } from "redux/profile/types"
import { setSelectedTab } from "redux/ift/iftSlice"
import { IHiddenColumn } from "redux/projects/types"
import { selectProject } from "redux/projects/projectSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, navUtil, identifyPermissions, myUtils } from "utils/common-utils"

export const prepareCustomContextMenu = (
  e: ContextMenuPreparingEvent,
  hiddenColumns: Array<IHiddenColumn | undefined>,
  setHiddenColumns: Function,
  dataGridRef: any,
  t: TFunction<"translation", undefined>,
  userRole: UserRole | null
) => {
  if (e.target === "header" && e.row?.rowType === "header") {
    // e.items can be undefined
    if (!e.items) e.items = []
    // Add a custom menu item
    e.items.push({
      text: "Hide",
      onItemClick: () => {
        const columns: Array<IHiddenColumn | undefined> = [
          ...hiddenColumns,
          {
            Caption: e?.column?.caption,
            VisibleIndex: e?.column?.visibleIndex,
          },
        ]
        setHiddenColumns(sortBy(columns, ["VisibleIndex"]))
        setTimeout(() => dataGridRef.current?.instance.repaint(), 1000)
      },
    })
  }
  if (
    e.target === "content" &&
    e.row?.rowType === "data" &&
    window.location.hash === "#/projects" &&
    get(e, "event.button") === 0 // The value can be 0/2/undefined & functionality is needed only on 0 hence 0 check
  ) {
    const roleBasedIftPermission = identifyPermissions(
      userRole,
      AccessType.IFT,
      AccessLevel.YES,
      allRoles
    )
    const iftPermission =
      get(userRole, "tenantSettings.IFT") === "Y" &&
      get(e, "row.data.ShowIFT") &&
      roleBasedIftPermission

    if (!e.items) {
      e.items = [
        {
          text: t("projectDetails.manageRevisions"),
          onItemClick: (ev: any) => {
            myUtils.dispatch(selectProject(get(e, "row.data")))
            navUtil.navigate(`/project/${get(e, "row.data.Id")}/estimate-revision-management`)
          },
        },
        {
          text: t("projectDetails.ift"),
          onItemClick: () => {
            myUtils.dispatch(selectProject(get(e, "row.data")))
            myUtils.dispatch(setSelectedTab(0))
            navUtil.navigate(`/project/${get(e, "row.data.Id")}/ift`)
          },
          visible: iftPermission,
        },
      ]
    }
  }
}

export const expandHiddenColumns = (
  data: any,
  hiddenColumns: Array<IHiddenColumn | undefined>,
  setHiddenColumns: Function,
  dataGridRef: any
) => {
  let expandIcon = <span id="icon" />
  for (const hiddenColumn of hiddenColumns) {
    if (hiddenColumn?.VisibleIndex && hiddenColumn.VisibleIndex + 1 === data.column.visibleIndex) {
      expandIcon = (
        <FontAwesomeIcon
          icon={faPlus}
          size="lg"
          color="grey"
          style={{ marginRight: "10px" }}
          onClick={() => {
            const columns = hiddenColumns.filter(
              (hc) => hc?.VisibleIndex && hc.VisibleIndex > data.column.visibleIndex
            )
            setHiddenColumns(sortBy(columns, ["VisibleIndex"]))
            setTimeout(() => dataGridRef.current?.instance.repaint(), 1000)
          }}
        />
      )
    }
  }
  return expandIcon
}

export const isColumnHidden = (
  columnIndex: number,
  hiddenColumns: Array<IHiddenColumn | undefined>
) => {
  let isHidden = true
  hiddenColumns.forEach((hiddenColumn: IHiddenColumn | undefined) => {
    if (hiddenColumn?.VisibleIndex === columnIndex) {
      isHidden = false
    }
  })
  return isHidden
}
