/* eslint-disable no-nested-ternary, import/prefer-default-export */
export const revisionResponseTransformer = (dataArr: any[]) => {
  return dataArr.reduce((acc: {}[], curr: { Properties: any[] }) => {
    let obj = {}
    curr.Properties.forEach(({ Name, Data }: { Name: string; Data: any }) => {
      const parsedData = Data === "True" ? true : Data === "False" ? false : Data
      obj = { ...obj, [Name]: parsedData }
    })
    return [...acc, obj]
  }, [])
}
