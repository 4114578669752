import useDocumentTitle from "hooks/useDocumentTitle"
import ContractorSpecificTable from "./ContractorSpecificTable"

const ContractorSpecificLibrary = () => {
  useDocumentTitle("Contractor Specific Library | Cost and Carbon Forecasting tool")
  return (
    <div>
      <ContractorSpecificTable />
    </div>
  )
}

export default ContractorSpecificLibrary
