import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  applyEstimateLibraryAction,
  createResourceEpdAction,
  fetchCodesByCategoryAction,
  fetchFuelLaborCategoryAction,
  fetchWrapCategoryAction,
  getAssuredLibraryDataAction,
  getContractorEstimateLibrary,
  getResourceEpdAction,
  getSpecificLibraryDetails,
  postBulkEstimatesLibrary,
  postContractorEstimateLibrary,
  putBulkEstimatesLibrary,
  updateResourceEpdAction,
  uploadResourceEpdAction,
} from "./middleware"
import { ProjectEstimateLibraryState } from "./types"

const initialState: ProjectEstimateLibraryState = {
  loading: LoadingState.DEFAULT,
  contractorEstimateLibraryContent: [],
  estimateLibraryItems: [],
  showAddNewLibrary: false,
  selectedEstimateLibrary: null,
  modesOfTransport: [],
  fuelLaborType: [],
  wrapCategory: [],
  assuredLibraryContent: [],
}

const projectEstimateLibrarySlice = createSlice({
  name: "ProjectEstimateLibrary",
  initialState,
  reducers: {
    setLoading: (state: ProjectEstimateLibraryState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setShowAddNewLibrary: (
      state: ProjectEstimateLibraryState,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      showAddNewLibrary: payload,
    }),
    setSelectedEstimateLibrary: (
      state: ProjectEstimateLibraryState,
      { payload }: PayloadAction<number | null>
    ) => ({
      ...state,
      selectedEstimateLibrary: payload,
    }),
    clearEstimateLibContent: (
      state: ProjectEstimateLibraryState,
      { payload }: PayloadAction<any>
    ) => ({
      ...state,
      contractorEstimateLibraryContent: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractorEstimateLibrary.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        getContractorEstimateLibrary.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          contractorEstimateLibraryContent: payload,
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(getContractorEstimateLibrary.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(postContractorEstimateLibrary.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        postContractorEstimateLibrary.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          contractorEstimateLibraryContent: [...payload],
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(postContractorEstimateLibrary.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(getSpecificLibraryDetails.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        getSpecificLibraryDetails.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          estimateLibraryItems: payload,
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(getSpecificLibraryDetails.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(postBulkEstimatesLibrary.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        postBulkEstimatesLibrary.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          estimateLibraryItems: [...payload],
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(postBulkEstimatesLibrary.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(putBulkEstimatesLibrary.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        putBulkEstimatesLibrary.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          estimateLibraryItems: [...payload],
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(putBulkEstimatesLibrary.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(fetchCodesByCategoryAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        fetchCodesByCategoryAction.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          modesOfTransport: payload,
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(fetchCodesByCategoryAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(fetchWrapCategoryAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        fetchWrapCategoryAction.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          wrapCategory: payload,
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(fetchWrapCategoryAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(fetchFuelLaborCategoryAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        fetchFuelLaborCategoryAction.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          fuelLaborType: payload,
          loading: LoadingState.DEFAULT,
        })
      )
      .addCase(fetchFuelLaborCategoryAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(getResourceEpdAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(getResourceEpdAction.fulfilled, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(getResourceEpdAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(updateResourceEpdAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(updateResourceEpdAction.fulfilled, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(updateResourceEpdAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(uploadResourceEpdAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(uploadResourceEpdAction.fulfilled, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(uploadResourceEpdAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(createResourceEpdAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(createResourceEpdAction.fulfilled, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(createResourceEpdAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(applyEstimateLibraryAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(applyEstimateLibraryAction.fulfilled, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
      .addCase(applyEstimateLibraryAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))

      .addCase(getAssuredLibraryDataAction.pending, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.LOADING,
      }))
      .addCase(
        getAssuredLibraryDataAction.fulfilled,
        (state: ProjectEstimateLibraryState, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: LoadingState.DEFAULT,
          assuredLibraryContent: payload,
        })
      )
      .addCase(getAssuredLibraryDataAction.rejected, (state: ProjectEstimateLibraryState) => ({
        ...state,
        loading: LoadingState.DEFAULT,
      }))
  },
})

export const {
  setLoading,
  setShowAddNewLibrary,
  setSelectedEstimateLibrary,
  clearEstimateLibContent,
} = projectEstimateLibrarySlice.actions

export const estimateLibrarySelector = (state: RootState) => state.ProjectEstimateLibrary

export default projectEstimateLibrarySlice.reducer
