import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { CarbonModeOfTransportItem } from "./types"

export const fetchModeOfTransportationValuesAsync = async (carbonCodeID: number) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<CarbonModeOfTransportItem[]> | ErrorResponse
    >(`/codetype-unit?filter=CUSTOM_LIST_ID||$eq||${carbonCodeID}`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const createCarbonValueForModeOfTranportAsync = async (
  request: CarbonModeOfTransportItem[]
) => {
  try {
    const response = await axiosInstance.post<
      SuccessResponse<CarbonModeOfTransportItem[]> | ErrorResponse
    >(`/codetype-unit/bulk`, { bulk: request })
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
