/* eslint-disable import/prefer-default-export */
export function filterEmptyItems(data: any[]) {
  return data.reduce((acc: any[], curr: { items: any }) => {
    if (curr.items && Array.isArray(curr.items)) {
      const filteredItems = filterEmptyItems(curr.items)
      if (filteredItems.length) {
        acc.push({ ...curr, items: filteredItems })
      }
    } else {
      acc.push(curr)
    }
    return acc
  }, [])
}
