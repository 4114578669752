/* eslint-disable react/jsx-props-no-spreading, no-unused-expressions */
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import DataGrid, {
  Item,
  Pager,
  Paging,
  Column,
  Editing,
  Toolbar,
  Scrolling,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"
import { custom } from "devextreme/ui/dialog"
import CustomStore from "devextreme/data/custom_store"
import { ContextMenuPreparingEvent } from "devextreme/ui/data_grid"
import { useAppDispatch } from "redux/store"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector, selectProject } from "redux/projects/projectSlice"
import {
  deleteEstimateAction,
  postEstimateRevisionsAction,
} from "redux/estimate-revision-management/middleware"
import {
  estmteRvsnMgmntSelector,
  setSelectedUpdateRevision,
} from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { allowedPageSizes } from "utils/config"
import { handleResetFilter } from "utils/common-utils"
import { onERMContentReady, prepareRvsnMgmntContextMenu } from "./tableFunctions"
import { addRevisionPermission, confirmationPopUPProps, tableColumnsGenerator } from "./constants"

const EstimateRevisionManagementTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { projectId } = useParams()
  const { project } = useSelector(projectSelector)

  const dataGridRef = useRef<DataGrid>(null)

  const { userRole } = useSelector(profileSelector)
  const { estimateRevisions } = useSelector(estmteRvsnMgmntSelector)

  const addRevisionBtnDisabled = useMemo(
    () => !addRevisionPermission(userRole, project),
    [userRole, project]
  )

  const tableColumns = useMemo(
    () => tableColumnsGenerator({ userRole, dataGridRef }),
    [userRole, dataGridRef]
  )

  const tableData = useMemo(
    () =>
      new CustomStore({
        load: () => estimateRevisions,
        key: "Id",
        update: async () => Promise.resolve(),
        remove: (id: number) => {
          const found = estimateRevisions.find((item) => item.Id === id)
          const cancelDelPermsn = get(found, "IsContractorAssigned")
          if (cancelDelPermsn) {
            notify(t("estimateRevisionManagement.delCancelMsg"), "error", 2000)
            return Promise.resolve()
          }
          dispatch(deleteEstimateAction(id))
          return Promise.resolve()
        },
      }),
    [estimateRevisions]
  )

  const prepareContextMenu = useCallback(
    (e: ContextMenuPreparingEvent) => {
      const data = { e, t, dispatch, userRole }
      get(e, "row.rowType") === "data"
        ? e?.component?.selectRowsByIndexes([e.rowIndex])
        : e?.component?.deselectAll()
      prepareRvsnMgmntContextMenu(data)
    },
    [userRole]
  )

  const addRevisionConfirmDialog = useCallback(() => {
    const confirmationDialog = custom(confirmationPopUPProps)
    confirmationDialog.show().then((dialogResult: any) => {
      if (dialogResult) dispatch(postEstimateRevisionsAction({ projectId }))
    })
  }, [])

  const onRowClick = useCallback(
    (e: any) => {
      const columnIndex = get(e, "event.target.cellIndex")
      const estimate = get(e, "data")
      const rowID = get(e, "data.Id")
      const rowParentProjId = get(e, "data.ParentProjId")
      const checkContextMenuCell = (get(e, "event.target.className") || "").includes("dx-icon")
      if (checkContextMenuCell) return

      dispatch(selectProject(estimate))
      dataGridRef.current?.instance.deselectAll()
      if (![0, 4].includes(columnIndex)) {
        rowParentProjId
          ? navigate(`/project/${rowID}/sections`, {
              state: { ParentProjId: rowParentProjId },
            })
          : navigate(`/project/${rowID}/sections`)
      }
    },
    [dataGridRef]
  )

  const onEditingStart = useCallback((e: any) => {
    e.cancel = true
    const updateRevision = get(e, "data")
    dataGridRef.current?.instance.deselectAll()
    dispatch(setSelectedUpdateRevision(updateRevision))
  }, [])

  useEffect(() => {
    dataGridRef.current?.instance.deselectAll()
  }, [])

  return (
    <DataGrid
      id="gridContainer"
      ref={dataGridRef}
      dataSource={tableData}
      showBorders
      showColumnLines
      columnAutoWidth
      hoverStateEnabled
      allowColumnResizing
      allowColumnReordering
      rowAlternationEnabled
      onRowClick={onRowClick}
      onEditingStart={onEditingStart}
      onContentReady={onERMContentReady}
      onContextMenuPreparing={prepareContextMenu}
    >
      <FilterRow visible />
      <Paging defaultPageSize={10} />
      <SearchPanel visible width={240} />
      <Scrolling columnRenderingMode="virtual" />
      <Editing
        mode="row"
        allowUpdating
        allowDeleting
        texts={{
          confirmDeleteMessage: t("estimateRevisionManagement.delCnfMsg"),
        }}
      />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
      <Toolbar>
        <Item location="before">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
        <Item>
          <Button
            stylingMode="contained"
            className="addRevisionBtn"
            disabled={addRevisionBtnDisabled}
            onClick={addRevisionConfirmDialog}
            text={t("estimateRevisionManagement.addRevision")}
          />
        </Item>
      </Toolbar>
      {tableColumns.map(({ caption, ...rest }) => (
        <Column caption={t(caption)} allowSorting {...rest} />
      ))}
    </DataGrid>
  )
}

export default EstimateRevisionManagementTable
