import { TFunction } from "i18next"
import { Button, CheckBox } from "devextreme-react"

export const projBreadCrumb = (t: TFunction<"translation", undefined>) => {
  return [
    {
      name: t("projectBrowser.estimates"),
    },
  ]
}

export const renderContextMenuCell = (row: any, dataGridRef: any) => {
  const handleClick = (e: any) => {
    e.event.preventDefault()
    const dxContextMenuEvent = new MouseEvent("dxcontextmenu", e.event)
    e.event.currentTarget.dispatchEvent(dxContextMenuEvent)
    dataGridRef.current?.instance.selectRowsByIndexes([row.rowIndex])
  }

  return (
    <div className="eb-context-container">
      <Button
        id="eb-context-btn"
        className="eb-context-btn active"
        icon="spindown"
        onClick={handleClick}
      />
    </div>
  )
}

export const renderCheckboxCell = (value: boolean | null | undefined) => {
  return (
    <div className="cell-content-center">
      <CheckBox disabled value={value} />
    </div>
  )
}

// Function to extract numeric and non-numeric parts of a string
const extractParts = (str: string | number) => {
  const regex: RegExp = /\d+|\D+/g
  const result: string[] = []
  let match: RegExpExecArray | null

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(str as string)) !== null) {
    result.push(match[0])
  }
  return result
}
export function handleEstimateNoSort(a: string | number, b: string | number) {
  // Convert numeric parts to actual numbers for logical comparison
  const convertToNumber = (part: string): string | number =>
    Number.isNaN(Number(part)) ? part : Number(part)

  const partsA = extractParts(a)
  const partsB = extractParts(b)

  // Compare parts one by one
  for (let i = 0; i < Math.min(partsA.length, partsB.length); i += 1) {
    const valueA = convertToNumber(partsA[i])
    const valueB = convertToNumber(partsB[i])

    if (valueA !== valueB) {
      return valueA < valueB ? -1 : 1
    }
  }

  // If one string is a prefix of the other, shorter string comes first
  return partsA.length - partsB.length
}
