import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
// eslint-disable-next-line import/extensions
import { config } from "utils/config"
import * as defaultResources from "./resources/default-resources"
import * as tfnsw from "./resources/tfnsw"

const getResources = () => {
  switch (config.clientName) {
    case "TFNSW":
      return { ...tfnsw }
    default:
      return { ...defaultResources }
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ...getResources(),
    },
    fallbackLng: "en",
    // nonExplicitSupportedLngs: true,
    debug: true,

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
