/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { powerBILoginAsync } from "./services"
import { PowerBIErrorResponse } from "./types"

export const fetchPowerBIAccessTokenAction = createAsyncThunk<any, string>(
  "powerBILoginAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response: any | PowerBIErrorResponse = await powerBILoginAsync(id)
      const errorResponse = response as PowerBIErrorResponse
      if (errorResponse?.error_codes?.length) {
        notify(errorResponse.error, "error", 2000)
        return rejectWithValue(errorResponse.error)
      }

      return response.data as any
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
