import { createAsyncThunk } from "@reduxjs/toolkit"
import { BenchmarkDataContainer } from "redux/emissionfactor/types"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { LoadingState } from "types/AppNav"
import { HttpStatusCode } from "axios"
import { orderBy } from "lodash"
import notify from "devextreme/ui/notify"
import { extractResponseData } from "utils/common-utils"
import {
  createCodeAsync,
  deleteCodeAsync,
  deleteCodeJourneyLegAsync,
  getDynamicCarbonLibraryAsync,
  fetchCodeItemsByCategoryAsync,
  fetchCarbonCodeCategoriesAsync,
  updateDynamicCarbonLibraryAsync,
  fetchEditCodeForCategoryActionAsync,
} from "./services"
import {
  CarbonCodesCategoryItem,
  CodeItems,
  EditCodeRequest,
  UpdateDynamicCarbonLibraryRequest,
} from "./types"
import { setLoading } from "./masterSlice"

const masterCategoryItems = [
  "Mode of Transportation",
  "Transport Category",
  // "WRAP Category",
  "Source Reference",
  // "Fuel or Labour Type",
]
let multipleCodes: any = {}

export const fetchCarbonCodeCategoriesAction = createAsyncThunk<CarbonCodesCategoryItem[]>(
  "master/fetchCarbonCodeCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response: SuccessResponse<CarbonCodesCategoryItem[]> | ErrorResponse =
        await fetchCarbonCodeCategoriesAsync()
      if (
        (response as SuccessResponse<CarbonCodesCategoryItem[]>).statusCode === HttpStatusCode.Ok
      ) {
        let items: CarbonCodesCategoryItem[] =
          (response as SuccessResponse<CarbonCodesCategoryItem[]>).data?.filter(
            (categoryItem: CarbonCodesCategoryItem) =>
              categoryItem.CUSTOM_LIST_NAME &&
              masterCategoryItems.includes(categoryItem.CUSTOM_LIST_NAME)
          ) || []

        items = orderBy(items, ["CUSTOM_LIST_NAME"], ["asc"])

        return items
      }

      return rejectWithValue(response.message)
    } catch (exception) {
      return rejectWithValue(exception)
    }
  }
)

/**
 *  This function use to fetch codes by category from the BES API.
 */
export const fetchCodesByCategoryAction = createAsyncThunk<CodeItems[], string>(
  "master/fetchCodesByCategory",
  async (category: string, { rejectWithValue }) => {
    try {
      const response: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(category)
      const errRes = response as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes.message)
      }
      return extractResponseData(response as BenchmarkDataContainer[])
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchMultipleCodesActions = createAsyncThunk<any, any>(
  "masters/fetchCodesActions",
  async (codesData: any, { rejectWithValue }) => {
    try {
      let motResp: BenchmarkDataContainer[] | ErrorResponse = []
      let tcResp: BenchmarkDataContainer[] | ErrorResponse = []

      switch (codesData?.module) {
        case "Mode of Transportation":
          motResp = await fetchCodeItemsByCategoryAsync(codesData?.module)
          codesData.transportCodes = extractResponseData(motResp as BenchmarkDataContainer[])
          multipleCodes = { ...multipleCodes, transportCodes: codesData.transportCodes }
          break
        case "Transport Category":
          tcResp = await fetchCodeItemsByCategoryAsync(codesData?.module)
          codesData.categoryCodes = extractResponseData(tcResp as BenchmarkDataContainer[])
          multipleCodes = { ...multipleCodes, categoryCodes: codesData.categoryCodes }
          break
        default:
          break
      }

      return { ...multipleCodes }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createCodeForCategoryAction = createAsyncThunk<CodeItems[], EditCodeRequest>(
  "masters/createCodeForCategory",
  async (request: EditCodeRequest, { rejectWithValue, dispatch }) => {
    try {
      const response: string | ErrorResponse = await createCodeAsync(request)
      const errRes = response as ErrorResponse
      if (errRes.code) {
        return rejectWithValue(errRes.message)
      }

      const listResponse: BenchmarkDataContainer[] | ErrorResponse =
        await fetchCodeItemsByCategoryAsync(request.category)
      const listResponseError = listResponse as ErrorResponse
      if (listResponseError.code) {
        return rejectWithValue(errRes.message)
      }

      notify("Code created successfully", "success", 2000)
      return extractResponseData(listResponse as BenchmarkDataContainer[])
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(LoadingState.DEFAULT))
    }
  }
)

export const deleteCodesForCategoryAction = createAsyncThunk<any, { id: string }>(
  "masters/deleteCodesForCategory",
  async (request: { id: string }, { rejectWithValue }) => {
    try {
      const { id } = request
      const response: string | ErrorResponse = await deleteCodeAsync(id)
      const errRes = response as ErrorResponse
      if (errRes.error) {
        notify("Code delete failed.", "error", 2000)
        return rejectWithValue(errRes.error)
      }
      notify("Code deleted successfully", "success", 2000)
      return response
    } catch (error) {
      notify("Code delete failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)

export const deleteCodesJourneyLegAction = createAsyncThunk<any, { id: string }>(
  "masters/deleteCodesForCategory",
  async (request: { id: string }, { rejectWithValue }) => {
    try {
      const { id } = request
      const response: string | ErrorResponse = await deleteCodeJourneyLegAsync(id)
      const errRes = response as ErrorResponse
      if (errRes.error) {
        notify(errRes.message || "Code dependent Journey Leg delete failed.", "error", 2000)
        return rejectWithValue(errRes.error)
      }
      return response
    } catch (error) {
      notify("Code dependent Journey Leg delete failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)

export const editCodeForCategoryAction = createAsyncThunk<CodeItems, EditCodeRequest>(
  "masters/editCodeForCategory",
  async (request: EditCodeRequest, { rejectWithValue }) => {
    const codeItem: CodeItems = {
      Id: request.Id,
      Name: request.Name,
      Description: request.Description,
    }
    try {
      const response: string | ErrorResponse = await fetchEditCodeForCategoryActionAsync(request)
      const errRes = response as ErrorResponse
      if (errRes.code) {
        notify("Code update failed.", "error", 2000)
        return rejectWithValue(errRes.message)
      }
      notify("Code updated successfully", "success", 2000)
      return codeItem
    } catch (error) {
      notify("Code update failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)

// Dynamic Carbon Library from here
export const getDynamicCarbonLibraryAction = createAsyncThunk<any>(
  "master/getDynamicCarbonLibraryAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getDynamicCarbonLibraryAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const updateDynamicCarbonLibraryAction = createAsyncThunk<
  any,
  UpdateDynamicCarbonLibraryRequest[]
>(
  "masters/updateDynamicCarbonLibraryAsync",
  async (request: UpdateDynamicCarbonLibraryRequest[], { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await updateDynamicCarbonLibraryAsync(request)
      const errRes = response as ErrorResponse
      if (errRes.code) {
        notify("Dynamic Carbon Library update failed.", "error", 2000)
        return rejectWithValue(errRes.message)
      }
      notify("Dynamic Carbon Library updated successfully.", "success", 2000)
      return response.data
    } catch (error) {
      notify("Dynamic Carbon Library update failed.", "error", 2000)
      return rejectWithValue(error)
    }
  }
)
