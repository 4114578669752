import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/store"
import { Switch } from "devextreme-react"
import {
  iftSelector,
  setScopeCarbonSwitch,
  setScopeBaselineSwitch,
  scopeInit,
} from "redux/ift/iftSlice"

const ScopeHeader = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { estimateId } = useParams()
  const { scopeCarbonSwitch, scopeBaselineSwitch } = useSelector(iftSelector)

  useEffect(() => {
    if (scopeCarbonSwitch || scopeBaselineSwitch) {
      dispatch(scopeInit())
    }
  }, [])

  return (
    <div className="scopeHeader">
      <div className={`scopeHeaderSwitch ${scopeCarbonSwitch ? "active-label" : ""}`}>
        <Switch onValueChanged={(e) => dispatch(setScopeCarbonSwitch(e.value))} />
        <label>{t("iftScope.showCarbon")}</label>
      </div>
      {!!estimateId && (
        <div className={`scopeHeaderSwitch ${scopeBaselineSwitch ? "active-label" : ""}`}>
          <Switch onValueChanged={(e) => dispatch(setScopeBaselineSwitch(e.value))} />
          <label>{t("iftScope.showBaseline")}</label>
        </div>
      )}
    </div>
  )
}

export default ScopeHeader
