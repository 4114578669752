/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react"
import moment from "moment"
import { JSX } from "react/jsx-runtime"
import packageJson from "../package.json"

const buildDateGreaterThan = (latestDate: moment.MomentInput, currentDate: moment.MomentInput) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  return !!momLatestDateTime.isAfter(momCurrentDateTime)
}

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }
  // delete browser cache and hard reload
  window.location.reload()
}

function withClearCache(Component: typeof React.Component | React.FC<{}>) {
  function ClearCacheComponent(props: JSX.IntrinsicAttributes) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      const fetchData = async () => {
        fetch("/meta.json")
          .then((response) => response.json())
          .then((meta) => {
            const latestVersionDate = meta.buildDate
            const currentVersionDate = packageJson.buildDate

            const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate)
            if (shouldForceRefresh) {
              setIsLatestBuildDate(false)
              refreshCacheAndReload()
            } else {
              if (
                moment(packageJson.buildDate).isValid() &&
                !window.location.host.includes("localhost:300")
              ) {
                console.log(
                  `Latest Build on ${moment(packageJson.buildDate).format(
                    "dddd, MMMM Do, YYYY h:mm A"
                  )}`
                )
              }
              setIsLatestBuildDate(true)
            }
          })
      }
      fetchData()
      const pollingInterval = setInterval(fetchData, 150000)

      return () => {
        clearInterval(pollingInterval)
      }
    }, [])

    if (isLatestBuildDate) {
      return <span>{isLatestBuildDate ? <Component {...props} /> : null}</span>
    }
  }

  return ClearCacheComponent
}

export default withClearCache
