import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { LoadingState } from "types/AppNav"
import notify from "devextreme/ui/notify"
import { RootState } from "redux/store"
import {
  AccessLevelItem,
  RoleAccessLevelItem,
  RoleAccessList,
  RolePermissionLevelItem,
  SelectRole,
  UpdatePermissionLevelForRoleRequest,
} from "./types"
import { setLoading } from "./rolePermissionSlice"
import {
  fetchAccessListItemsAsync,
  fetchRoleAccessListAsync,
  fetchRoleAndPermissionByRoleIdAsync,
  fetchRolePermissionAsync,
  updatePermissionLevelForRoleAsync,
} from "./services"

export const fetchRolePermissionAction = createAsyncThunk<SelectRole[]>(
  "fetchRolePermissionAction",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      // SelectRole[] | ErrorResponse
      const response: any = await fetchRolePermissionAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response?.data as SelectRole[]
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchRoleAccessListAction = createAsyncThunk<RoleAccessLevelItem, number>(
  "fetchPermissionListAction",
  async (roleId: number, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setLoading(LoadingState.LOADING))
      const permissionState = (getState() as RootState).RolePermission

      // ROLE ACCESS LIST DATA.....
      const roleAccessListResponse: SuccessResponse<RoleAccessList[]> | ErrorResponse =
        await fetchRoleAccessListAsync()
      const errorResponse = roleAccessListResponse as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      const roleAccessListData = (roleAccessListResponse as SuccessResponse<RoleAccessList[]>).data

      // ROLE ACCESS LIST LEVEL DATA.....
      const accessListResponse: SuccessResponse<AccessLevelItem[]> | ErrorResponse =
        await fetchAccessListItemsAsync()
      const accessListErrorResponse = accessListResponse as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(accessListErrorResponse.message)
      }
      const roleAccessLevelListData = (accessListResponse as SuccessResponse<AccessLevelItem[]>)
        .data

      // find the access list of specific role
      const rolePermissionResponse: SuccessResponse<RolePermissionLevelItem[]> | ErrorResponse =
        await fetchRoleAndPermissionByRoleIdAsync(roleId)

      const rolePermissionErrorResponse = rolePermissionResponse as ErrorResponse
      if (errorResponse?.code) {
        notify("Something went wrong!!", "error", 2000)
        return rejectWithValue(rolePermissionErrorResponse.message)
      }
      let rolePermissionData = (
        rolePermissionResponse as SuccessResponse<RolePermissionLevelItem[]>
      ).data
      rolePermissionData = (rolePermissionData as RolePermissionLevelItem[]).map(
        (item: RolePermissionLevelItem) => {
          const roleItem: RolePermissionLevelItem = { ...item }
          roleItem.ROLEACCESS_ROLEID =
            permissionState.roleList.find(
              (item: SelectRole) => item.ROLE_ID === roleItem.ROLEACCESS_ROLEID
            ) || roleItem.ROLEACCESS_ROLEID

          roleItem.ROLEACCESS_ACCESSID =
            (roleAccessListData as RoleAccessList[]).find(
              (item: RoleAccessList) => item.ACCESS_ID === roleItem.ROLEACCESS_ACCESSID
            ) || roleItem.ROLEACCESS_ACCESSID

          roleItem.ROLEACCESS_ACCESSLEVELID =
            (roleAccessLevelListData as AccessLevelItem[]).find(
              (item: AccessLevelItem) => item.ACCESS_LEVEL_ID === roleItem.ROLEACCESS_ACCESSLEVELID
            ) || roleItem.ROLEACCESS_ACCESSLEVELID
          return roleItem
        }
      )

      const roleAccessLevelItem: RoleAccessLevelItem = {
        accessList: roleAccessListData as RoleAccessList[],
        roleAccessLevelList: roleAccessLevelListData as AccessLevelItem[],
        rolePermissionList: rolePermissionData as RolePermissionLevelItem[],
      }

      return roleAccessLevelItem
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updatePermissionLevelForRoleAction = createAsyncThunk<
  RolePermissionLevelItem,
  UpdatePermissionLevelForRoleRequest
>(
  "updatePermissionLevelForRoleAction",
  async (request: UpdatePermissionLevelForRoleRequest, { rejectWithValue, getState }) => {
    const { currentRoleItem, updatedLevel } = request
    const accessLevelList = (getState() as RootState).RolePermission.roleAccessLevelList

    const roleRequest = {
      ...currentRoleItem,
      ROLEACCESS_ROLEID: (currentRoleItem.ROLEACCESS_ROLEID as SelectRole).ROLE_ID,
      ROLEACCESS_ACCESSID: (currentRoleItem.ROLEACCESS_ACCESSID as RoleAccessList).ACCESS_ID,
      ROLEACCESS_ACCESSLEVELID: updatedLevel,
    }

    const response = await updatePermissionLevelForRoleAsync(roleRequest)
    const errorResponse = response as ErrorResponse
    if (errorResponse?.code) {
      notify("Something went wrong!!", "error", 2000)
      return rejectWithValue(errorResponse.message)
    }

    const updatedObj = {
      ...currentRoleItem,
      ROLEACCESS_ACCESSLEVELID:
        (accessLevelList as AccessLevelItem[]).find(
          (item: AccessLevelItem) => item.ACCESS_LEVEL_ID === updatedLevel
        ) || updatedLevel,
    }

    return updatedObj
  }
)
