import { get } from "lodash"
import { TFunction } from "i18next"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"
import { recalculateCarbonAction } from "redux/projects/middleware"
import { EstimateRevisionManagementItem } from "redux/estimate-revision-management/types"
import { applyEstimateLibraryAction } from "redux/project-estimate-library-setup/middleware"
import { setSelectedRevision } from "redux/estimate-revision-management/estimateRevisionManagementSlice"
import { setSelectedEstimateLibrary } from "redux/project-estimate-library-setup/projectEstimateLibrarySetupSlice"

export const renderRedirectCell = (row: any, dataField: string, dispatch: any) => {
  const onClick = () => {
    dispatch(setSelectedEstimateLibrary(get(row, "data.CONTRACTOR_ESTIMATE_LIBRARY_ID")))
  }

  return (
    <label className="link" title={get(row, `data.${dataField}`)} onClick={onClick}>
      {get(row, `data.${dataField}`)}
    </label>
  )
}

export const renderApplyEstimateCell = (props: {
  e: any
  t: TFunction<"translation", undefined>
  dataGridRef: any
  selectedRevision: EstimateRevisionManagementItem | undefined
  projectId: string | undefined
  libraryNoFullAccess: boolean
  dispatch: any
}) => {
  const { e, t, dataGridRef, libraryNoFullAccess, projectId, selectedRevision, dispatch } = props

  const onClick = async () => {
    if (e?.row?.data?.IS_APPLIED) {
      notify(t("contractorEstimateLibrary.addNewThenApply"), "error", 2000)
      return
    }
    await dispatch(
      applyEstimateLibraryAction({
        CONTRACTOR_ESTIMATE_LIBRARY_ID: e?.row?.data?.CONTRACTOR_ESTIMATE_LIBRARY_ID,
        PROJECT_ID: e?.row?.data?.PROJECT_ID,
      })
    ).then(async (data: { payload: { statusCode: number } }) => {
      if (data.payload.statusCode === 200) {
        notify(t("contractorEstimateLibrary.applyEstimateLibrarySuccess"), "success", 5000)
        dataGridRef.current?.instance?.refresh()
        const updatedRevision = {
          ...selectedRevision,
          LibraryApplied: get(e, "row.data.CONTRACTOR_ESTIMATE_LIBRARY_ID") || 0,
        }
        dispatch(setSelectedRevision(updatedRevision as EstimateRevisionManagementItem))
        await dispatch(recalculateCarbonAction({ projectId })).then((res: any) => {
          const resStatus = get(res, "meta.requestStatus")
          const resDataStatus = get(res, "payload.data.status")
          setTimeout(() => {
            notify(resDataStatus, resStatus === "fulfilled" ? "success" : "error", 3000)
          }, 3000)
        })
      }
    })
  }

  return (
    <Button
      stylingMode="contained"
      className="roundBorderGreenBtn"
      disabled={libraryNoFullAccess}
      text={t("contractorEstimateLibrary.applyToEstimateBtnText")}
      onClick={onClick}
    />
  )
}
