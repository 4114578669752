/* eslint-disable global-require, consistent-return, no-plusplus */
/* eslint-disable react/no-danger */
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BreadCrumb from "components/bread-crumb"
import Button from "devextreme-react/button"
import DataGrid, {
  Column,
  Editing,
  Export,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import { LoadPanel } from "devextreme-react/load-panel"
import { exportDataGrid } from "devextreme/excel_exporter"
import notify from "devextreme/ui/notify"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver-es"
import { isEqual, uniqWith } from "lodash"
import * as papa from "papaparse"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  emissionfactorSelector,
  pushItemsChangesToReduxStore,
  setLoading,
} from "redux/emissionfactor/emissionFactorSlice"
import {
  fetchItemsAction,
  updateItemsAction,
  fetchTransportAssumptionAction,
} from "redux/emissionfactor/middleware"
import { IItemResource, ITransportAssumption } from "redux/emissionfactor/types"
import { sharedSelector } from "redux/shared/sharedSlice"
import { useAppDispatch } from "redux/store"
import { LoadingState } from "types/AppNav"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { fetchCodesByCategoryAction } from "redux/masters/middleware"
import useDocumentTitle from "hooks/useDocumentTitle"
import { itemEmissionTableHeaders, rowDataGeneratorForItemEmissionTable } from "./utils"

const ItemEmission: React.FC = (): JSX.Element => {
  useDocumentTitle("Item Library | Cost and Carbon Forecasting tool")
  const dispatch = useAppDispatch()
  const { items, loading, transportAssumptions } = useSelector(emissionfactorSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { t } = useTranslation()

  const dataGridRef = useRef<DataGrid>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [changes, setChanges] = useState<Array<any>>([])
  const [imported, setImported] = useState<string>("")
  const [changesHistory, setChangesHistory] = useState<Array<any>>([])

  useEffect(() => {
    dispatch(fetchItemsAction())
    dispatch(fetchCodesByCategoryAction("Transport Category"))
    dispatch(fetchTransportAssumptionAction())
  }, [])

  const handleResetFilter = () => {
    dataGridRef.current?.instance.clearFilter()
    dataGridRef.current?.instance.getVisibleColumns().forEach((column) => {
      if (column.dataField != null) {
        dataGridRef.current?.instance.columnOption(
          column.dataField,
          "selectedFilterOperation",
          undefined
        )
      }
    })
  }

  /**
   * Get the last changes from history
   * @returns Returns last changes from history
   */
  const getLastChanges = useCallback(() => {
    return changesHistory[changesHistory.length - 1] || { changesOnPageNo: 0, changes: [] }
  }, [changes, changesHistory])

  /**
   * Handle cell value change event and store into history
   */
  const onChangesChange = useCallback((newChanges: any) => {
    const data = {
      changesOnPageNo: dataGridRef.current?.instance.pageIndex(),
      changes: newChanges,
    }
    setChanges(newChanges)
    setChangesHistory((prev) => {
      return [...prev, data]
    })
  }, [])

  const undoChanges = useCallback(() => {
    changesHistory.pop()
    const lastChanges = getLastChanges()
    dataGridRef.current?.instance.pageIndex(lastChanges.changesOnPageNo)
    setChanges(lastChanges.changes)
  }, [changes, changesHistory])

  const discardAllChanges = useCallback(() => {
    setChanges([])
    setChangesHistory([])
  }, [])

  const saveChanges = useCallback(async () => {
    try {
      if (changes.length === 1 && changes[0].type === "imported") {
        const res = await dispatch(updateItemsAction(items))
        if (res?.meta?.requestStatus === "fulfilled") {
          discardAllChanges()
          notify("Changes saved successfully", "success", 2000)
          dispatch(fetchItemsAction())
        }
      } else {
        const localChanges: IItemResource[] = []
        changesHistory.forEach((obj: { changesOnPageNo: number; changes: any[] }) => {
          obj.changes.forEach((change: any) => {
            if (change.type === "insert" || change.type === "update") {
              // merge object with the same id in javascript
              const index = localChanges.findIndex(
                (obj) => obj.ITEM_LIBRARY_ID === change.key.ITEM_LIBRARY_ID
              )
              if (index !== -1) {
                localChanges[index] = {
                  ...localChanges[index],
                  ...change.data,
                }
              } else {
                localChanges.push({
                  ITEM_LIBRARY_ID: change.key.ITEM_LIBRARY_ID,
                  ITEM_LIBRARY_CARB_WASTEFACTOR: change.key.ITEM_LIBRARY_CARB_WASTEFACTOR,
                  ...change.data,
                })
              }
            }
          })
        })
        dispatch(pushItemsChangesToReduxStore({ changes: localChanges }))
        const res = await dispatch(updateItemsAction(localChanges))
        if (res?.meta?.requestStatus === "fulfilled") {
          discardAllChanges()
          notify("Changes saved successfully", "success", 2000)
          dispatch(fetchItemsAction())
        }
      }
    } catch (error) {
      notify("Error while saving changes", "error", 2000)
    }
  }, [changes, changesHistory, items])

  const handleNumValSorting = (a: string, b: string) => {
    const val1 = parseFloat(a)
    const val2 = parseFloat(b)
    return val1 - val2
  }

  const handleStringValSorting = (a: string, b: string) => {
    const fa = (a || "")?.toLowerCase().trimStart()
    const fb = (b || "")?.toLowerCase().trimStart()
    // eslint-disable-next-line no-nested-ternary
    return fa < fb ? -1 : fa > fb ? 1 : 0
  }

  const handleButtonClick = () => fileInputRef.current && fileInputRef.current.click()

  const handleFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || !event.target.files[0].name.includes(".csv")) {
        notify("Only CSV files can be imported", "error", 2000)
        return
      }
      const file = event.target.files[0]
      papa.parse(file, {
        complete: (results: any) => {
          const source: any = []
          if (
            results.data &&
            results.data.length > 0 &&
            !isEqual(itemEmissionTableHeaders, results.data[0])
          ) {
            notify("Imported data and table header don't match", "error", 2000)
            dispatch(setLoading(LoadingState.DEFAULT))
            return
          }
          results.data.forEach((item: any, index: number) => {
            if (index > 0 && results.data.length > 0) {
              const parsedData = rowDataGeneratorForItemEmissionTable(item, transportAssumptions)
              if (
                (parsedData &&
                  parsedData?.ITEM_LIBRARY_CARB_A1_A3 !==
                    items[index - 1]?.ITEM_LIBRARY_CARB_A1_A3) ||
                parsedData?.ITEM_LIBRARY_CARB_A5W !== items[index - 1]?.ITEM_LIBRARY_CARB_A5W ||
                parsedData?.ITEM_LIBRARY_CARB_NOTE !== items[index - 1]?.ITEM_LIBRARY_CARB_NOTE ||
                parsedData?.ITEM_LIBRARY_CARB_JOURNEY_LEG !==
                  items[index - 1]?.ITEM_LIBRARY_CARB_JOURNEY_LEG
              ) {
                const updatedFields: any = {}
                if (
                  parsedData?.ITEM_LIBRARY_CARB_A1_A3 !== items[index - 1]?.ITEM_LIBRARY_CARB_A1_A3
                ) {
                  updatedFields.ITEM_LIBRARY_CARB_A1_A3 = parsedData?.ITEM_LIBRARY_CARB_A1_A3
                }
                if (
                  parsedData?.ITEM_LIBRARY_CARB_JOURNEY_LEG !==
                  items[index - 1]?.ITEM_LIBRARY_CARB_JOURNEY_LEG
                ) {
                  updatedFields.ITEM_LIBRARY_CARB_JOURNEY_LEG =
                    parsedData?.ITEM_LIBRARY_CARB_JOURNEY_LEG
                }
                if (parsedData?.ITEM_LIBRARY_CARB_A5W !== items[index - 1]?.ITEM_LIBRARY_CARB_A5W) {
                  updatedFields.ITEM_LIBRARY_CARB_A5W = parsedData?.ITEM_LIBRARY_CARB_A5W
                }
                if (
                  parsedData?.ITEM_LIBRARY_CARB_NOTE !== items[index - 1]?.ITEM_LIBRARY_CARB_NOTE
                ) {
                  updatedFields.ITEM_LIBRARY_CARB_NOTE = parsedData?.ITEM_LIBRARY_CARB_NOTE
                }
                const obj = {
                  type: "update",
                  data: updatedFields,
                  key: items[index - 1],
                }
                if (obj.key) {
                  source.push(obj)
                }
              }
            }
          })
          const importMsg = `${uniqWith(source, isEqual).length} out of ${
            results.data.length - 1
          } records has been imported`
          notify(importMsg, "success", 2000)
          setImported(importMsg)
          dispatch(setLoading(LoadingState.DEFAULT))
          setChanges(source)
          const tempObj = {
            changesOnPageNo: dataGridRef.current?.instance.pageIndex(),
            changes: source,
          }
          setChangesHistory([tempObj])
        },
      })

      if (fileInputRef.current) fileInputRef.current.value = ""
    },
    [changes, changesHistory, items, loading]
  )

  const onExporting = useCallback(async (e: any) => {
    e.component.beginUpdate()
    e.component.columnOption("ITEM_LIBRARY_ID", "visible", true)
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")
    const { component } = e
    await exportDataGrid({
      component,
      worksheet,
      // customizeCell: (options) => {
      //   const { gridCell } = options
      //   const { excelCell } = options
      //   if (gridCell?.rowType === "data") {
      //     if (gridCell?.column?.dataField === "ITEM_LIBRARY_CARB_JOURNEY_LEG") {
      //       excelCell.value = gridCell.value
      //     }
      //   }
      // },
    })
    const buffer = await workbook.csv.writeBuffer()
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Item-Emission-Template.csv")
    e.component.columnOption("ITEM_LIBRARY_ID", "visible", false)
    e.component.endUpdate()
  }, [])

  const formattedValue = (value: any) => (value ? parseFloat(value) : 0.0)

  const calculateTotalCarbon = useCallback((row: IItemResource) => {
    return (
      formattedValue(row.ITEM_LIBRARY_CARB_A1_A3) +
      formattedValue(row.ITEM_LIBRARY_CARB_A4) +
      formattedValue(row.ITEM_LIBRARY_CARB_A5W)
    )
  }, [])

  const calculateA4Carbon = (row: IItemResource) => {
    if (row?.ITEM_LIBRARY_CARB_JOURNEY_LEG) {
      const TA: ITransportAssumption | undefined = transportAssumptions.find(
        (item) => row?.ITEM_LIBRARY_CARB_JOURNEY_LEG === item.JOURNEY_LEG_CARBON_ID
      )
      if (TA) {
        return (
          (row.ITEM_CARB_WEIGHTPERUNIT / 1000) *
          (TA.DISTANCE_1 * TA.MODE_OF_TRANSPORT_1_CARBON +
            TA.DISTANCE_2 * TA.MODE_OF_TRANSPORT_2_CARBON)
        )
      }
      return row?.ITEM_LIBRARY_CARB_A4
    }
    return row?.ITEM_LIBRARY_CARB_A4
  }

  const cellValueFormatter = (value: number | Date) => Number(value).toFixed(4)

  const cellFormatterConfig = {
    type: "decimal",
    precision: 4,
    formatter: cellValueFormatter,
  }

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position="center"
        visible={loading === LoadingState.LOADING}
        showIndicator
        shading
        showPane
      />
      <BreadCrumb
        data={[
          {
            name: t("menu.itemImport"),
          },
        ]}
      />
      <input
        type="file"
        accept=".csv"
        onChange={handleFileInputChange}
        ref={fileInputRef}
        className="display-none"
      />
      <span className="ms-18">{imported}</span>
      <br />
      <br />
      <DataGrid
        dataSource={items}
        ref={dataGridRef}
        key="ITEM_LIBRARY_ID"
        highlightChanges
        repaintChangesOnly
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        noDataText={t("noRecords")}
        height={windowHeight - 170}
        onExporting={onExporting}
      >
        <FilterRow visible />
        <SearchPanel visible width={340} />
        <Column
          caption="Item Library ID"
          dataField="ITEM_LIBRARY_ID"
          fixed
          visible={false}
          allowEditing={false}
        />
        <Column
          caption={t("code")}
          width={110}
          fixed
          allowFixing
          dataField="ITEM_LIBAGGR_CODE"
          allowEditing={false}
          cssClass="border-class disabled row-height"
        />
        <Column
          dataField="ITEM_LIBAGGR_DESC"
          caption={t("description")}
          width={270}
          fixed
          allowFixing
          allowEditing={false}
          sortingMethod={handleStringValSorting}
          cssClass="border-class disabled row-height"
        />
        <Column
          dataField="UNIT_DESC"
          caption={t("unit")}
          allowEditing={false}
          width={80}
          fixed
          allowFixing
          cssClass="border-class disabled row-height"
        />
        <Column
          dataField="ITEM_CARB_WEIGHTPERUNIT"
          caption={t("weightPerUnitKg")}
          cssClass="border-class disabled row-height"
          sortingMethod={handleNumValSorting}
          fixed
          allowFixing
          allowEditing={false}
        />
        <Column
          dataField="ITEM_LIBRARY_CARB_A1_A3"
          caption="A1-A3 GWP KgCO2e/unit (ML)"
          editorOptions={{ format: "#0.0000" }}
          headerCellTemplate={
            "<div title='Carbon emissions at production stage including extraction, processing, transportation and manufacturing of materials and products up to the factory gate ready to be taken to site. The values per input unit are modelled from product weight, base material(s), compositions & ICE database embodied carbon factors. Unit of measurement is kgCO2e/unit.'>A1-A3 GWP KgCO2e/unit (ML)</div>"
          }
          cssClass="border-class"
          sortingMethod={handleNumValSorting}
          format={cellFormatterConfig}
        />
        <Column
          dataField="ITEM_LIBRARY_CARB_JOURNEY_LEG"
          caption={t("transportAssumption")}
          cssClass="border-class"
          lookup={{
            dataSource: transportAssumptions,
            displayExpr: "TRANSPORT_CATEGORY_NAME",
            valueExpr: "JOURNEY_LEG_CARBON_ID",
            allowClearing: true,
          }}
        />
        <Column
          dataField="ITEM_LIBRARY_CARB_A4"
          caption="A4 GWP KgCO2e/unit (ML)"
          allowEditing={false}
          editorOptions={{ format: "#0.0000" }}
          headerCellTemplate={
            "<div title='Carbon emissions from the transportation of materials and products from the factory gate to site. The values per unit are modelled from product weight and assumptions for transport type(s) and distance(s). Unit of measurement is kgCO2e/unit.'>A4 GWP KgCO2e/unit (ML)</div>"
          }
          cssClass="border-class disabled"
          sortingMethod={handleNumValSorting}
          format={cellFormatterConfig}
          calculateCellValue={calculateA4Carbon}
        />
        <Column
          dataField="ITEM_LIBRARY_CARB_A5W"
          caption="A5w GWP KgCO2e/unit (ML)"
          editorOptions={{ format: "#0.0000" }}
          headerCellTemplate={
            "<div title='Carbon emissions from the extraction, processing, manufacture, transportation and end-of-life processing associated with materials wasted on site. The values per unit are modelled from assumptions stated for Waste Factors applied to the A1-3 GWP values. Unit of measurement is kgCO2e/unit.'>A5w GWP KgCO2e/unit (ML)</div>"
          }
          cssClass="border-class"
          sortingMethod={handleNumValSorting}
          format={cellFormatterConfig}
        />
        <Column
          dataField="TOTAL_CARBON"
          caption={`${t("total")} A1-A5`}
          allowEditing={false}
          editorOptions={{ format: "#0.0000" }}
          cssClass="border-class disabled"
          sortingMethod={handleNumValSorting}
          calculateCellValue={calculateTotalCarbon}
          format={cellFormatterConfig}
        />
        <Column
          dataField="ITEM_LIBRARY_CARB_WASTEFACTOR"
          caption={`${t("wasteFactor")} (%)`}
          allowEditing={false}
          cssClass="border-class disabled"
          sortingMethod={handleNumValSorting}
          format={{
            formatter(value) {
              return `${value}%`
            },
          }}
        />
        <Column dataField="ITEM_LIBRARY_CARB_NOTE" caption={t("notes")} cssClass="border-class" />
        <Column
          dataField="ITEM_GROUP_DESC"
          caption="Item Group"
          allowEditing={false}
          cssClass="border-class disabled"
        />
        <Toolbar visible>
          <Item location="before">
            <span title={t("toolbarActions.resetAllFilters")}>
              <Button icon="refresh" stylingMode="text" text="" onClick={handleResetFilter} />
            </span>
          </Item>
          <Item location="before" disabled={changes.length === 0}>
            <span title={t("toolbarActions.undoAll")}>
              <FontAwesomeIcon
                aria-disabled={changes.length === 0}
                cursor="pointer"
                icon={faClockRotateLeft}
                size="1x"
                onClick={discardAllChanges}
                opacity={changes.length === 0 ? 0.3 : 1}
              />
            </span>
          </Item>
          <Item location="after" disabled={changes.length === 0}>
            <span title="Save all changes">
              <Button icon="save" stylingMode="text" onClick={saveChanges} />
            </span>
          </Item>
          <Item location="after" disabled={changes.length === 0}>
            <span title="Undo the most recent change">
              <Button icon="undo" stylingMode="text" onClick={undoChanges} />
            </span>
          </Item>
          <Item location="after">
            <span title="Upload">
              <Button icon="upload" stylingMode="text" onClick={handleButtonClick} />
            </span>
          </Item>
          <Item name="exportButton" />
          <Item name="searchPanel" />
        </Toolbar>
        <Export enabled />
        <Paging defaultPageSize={defaultPageSize} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} visible />
        <Editing mode="batch" allowUpdating changes={changes} onChangesChange={onChangesChange} />
      </DataGrid>
    </>
  )
}

export default ItemEmission
