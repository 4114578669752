/* eslint-disable consistent-return */
import { memo, useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { get, isEmpty } from "lodash"
import { Button } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, { Column, Editing, FilterRow, Item, Toolbar } from "devextreme-react/data-grid"
import { projectSelector, updateSelectedBOQItemForUpdate } from "redux/projects/projectSlice"
import { getBOQItemsAndResourcesAction, updateItemsAction } from "redux/projects/middleware"
import { allRoles, handleResetFilter, identifyPermissions } from "utils/common-utils"
import { profileSelector } from "redux/profile/profileSlice"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import {
  onUpdateItemTableCellPrepared,
  renderUpdateItemTableCostCell,
  renderUpdateItemTableRateCell,
  UpdateItemTableProps,
  tableUpdateKeys,
} from "./updateItemTableConst"

const UpdateItemTable = (props: UpdateItemTableProps) => {
  const { rowData, popUpClose } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const dataGridRef = useRef<DataGrid>(null)

  const { projectId, sectionId } = useParams()
  const { selectedBOQItemForUpdate } = useSelector(projectSelector)

  const { userRole } = useSelector(profileSelector)

  const permsnCheck = useMemo(
    () => identifyPermissions(userRole, AccessType.SHOW_COST_DATA, AccessLevel.YES, allRoles),
    [userRole]
  )

  const dataStore = useMemo(
    () =>
      new CustomStore({
        load: () => [{ ...rowData, ...selectedBOQItemForUpdate }],
        key: "ID",
        update: (a, b) => {
          const data = { ...rowData, ...selectedBOQItemForUpdate }
          const updatedData = { ...data, ...b }
          dispatch(updateSelectedBOQItemForUpdate(b))
          return Promise.resolve([updatedData])
        },
      }),
    [rowData, selectedBOQItemForUpdate]
  )

  const saveRecord = useCallback(() => dataGridRef.current?.instance.saveEditData(), [dataGridRef])

  const onSaving = useCallback(
    (e: { changes: any }) => {
      const { changes } = e
      const updatedValue = changes.reduce((a: any, b: { data: any }) => {
        const val = tableUpdateKeys.reduce((a1: any, b1: string) => {
          let dummy = {}
          if (Object.keys(b.data).includes(b1)) dummy = { [b1]: Number(b.data[b1]) }
          else dummy = { [b1]: get(selectedBOQItemForUpdate, b1) }
          return { ...a1, ...dummy }
        }, {})
        return { ...a, ...val }
      }, {})

      if (isEmpty(updatedValue)) return popUpClose()

      dispatch(updateItemsAction([updatedValue])).then(() => {
        const data = { aggregatedOn: "ITEM_RESOURCE_TREE", projectId, sectionId }
        dispatch(getBOQItemsAndResourcesAction(data)).then(() => {
          popUpClose()
        })
      })
    },
    [selectedBOQItemForUpdate, projectId]
  )

  return (
    <DataGrid
      id="gridContainer"
      className="editItemTable"
      ref={dataGridRef}
      dataSource={dataStore}
      showBorders
      showColumnLines
      columnAutoWidth
      allowColumnResizing
      allowColumnReordering
      onSaving={onSaving}
      errorRowEnabled={false}
      onCellPrepared={onUpdateItemTableCellPrepared}
    >
      <FilterRow visible />
      <Editing mode="batch" allowUpdating />
      <Toolbar>
        <Item location="after">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.undo")}>
            <Button
              icon="undo"
              stylingMode="text"
              onClick={() => dataGridRef.current?.instance.cancelEditData()}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.save")}>
            <Button icon="save" stylingMode="text" onClick={saveRecord} />
          </span>
        </Item>
      </Toolbar>
      <Column dataField="CODE" caption={t("iftScope.code")} allowEditing={false} />
      <Column dataField="DESCRIPTION" caption={t("iftScope.desc")} allowEditing={false} />
      <Column dataField="UNIT_DESCRIPTION" caption={t("iftScope.unit")} allowEditing={false} />
      <Column dataField="PROJECT_ITEM_QTY" caption={t("iftScope.qty")} />
      <Column
        dataField="PROJECT_ITEM_RATE"
        caption="Rate"
        allowEditing={false}
        cellRender={(item) => (permsnCheck ? renderUpdateItemTableRateCell(item) : null)}
      />
      <Column
        dataField="PROJECT_ITEM_COST"
        caption={t("iftScope.cost")}
        cellRender={(item) => (permsnCheck ? renderUpdateItemTableCostCell(item) : null)}
        allowEditing={permsnCheck}
      />
      <Column caption={t("iftScope.carbon")} alignment="center">
        <Column dataField="PROJECT_ITEM_CARB_A1_A3_FACTOR" caption={t("iftScope.a1a3kg")} />
        <Column dataField="PROJECT_ITEM_CARB_A4_FACTOR" caption={t("iftScope.a4kg")} />
        <Column dataField="PROJECT_ITEM_CARB_A5A_FACTOR" caption={t("iftScope.a5akg")} />
        <Column
          dataField="PROJECT_ITEM_CARB_WASTE_FACTOR"
          caption={t("iftScope.wasteFactor")}
          alignment="right"
        />
      </Column>
    </DataGrid>
  )
}

export default memo(UpdateItemTable)
