import axiosInstance, { isAxiosError } from "services/api"
import { ErrorResponse, SuccessResponse } from "services/SuccessResponse"
import { ExpenditureProfileProject, ExpenditureProfileProjectData } from "./types"

export const getExpenditureProfileProjectsAsync = async () => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<ExpenditureProfileProject[]> | ErrorResponse
    >(`/sas-report/project/list`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getExpenditureProfileProjectByIdAsync = async (id: string | undefined) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<ExpenditureProfileProjectData[]> | ErrorResponse
    >(`/sas-report/project/${id}`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getExpenditureProfileProjectDataAsync = async (id: string | undefined) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<ExpenditureProfileProject[]> | ErrorResponse
    >(`/sas-report/project/${id}/detail?format=true`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}

export const getExpenditureProfileSummaryTableAsync = async (id: string | undefined) => {
  try {
    const response = await axiosInstance.get<
      SuccessResponse<ExpenditureProfileProject[]> | ErrorResponse
    >(`sas-report/project/${id}/summary`)
    return response.data
  } catch (err) {
    return isAxiosError(err)
  }
}
