/* eslint-disable no-unused-expressions */
import { memo, useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import Accordion, { Item } from "devextreme-react/accordion"
import { Button, NumberBox, ScrollView } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { iftSelector } from "redux/ift/iftSlice"
import { fetchRevisionIFTScopeAction, updateScopeResourcesAction } from "redux/ift/middleware"
import { zeroNullCarbCnfrmDialog } from "screens/ift/utils"
import { EditItemTableProps } from "../scopeTableUtils"

const EditResourceForm = (props: EditItemTableProps) => {
  const { popUpClose } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { estimateId, iftScopeEditResource } = useSelector(iftSelector)

  const [quantity, setQuantity] = useState(iftScopeEditResource?.QUANTITY)
  const [item, setItem] = useState<any>({
    PROJECT_RESOURCE_CARB_A1_A3_FACTOR: 0,
    PROJECT_RESOURCE_CARB_A4_FACTOR: 0,
    PROJECT_RESOURCE_CARB_A5A_FACTOR: 0,
    PROJECT_RES_CARB_WASTEFACTOR: 0,
  })

  useEffect(() => {
    if (iftScopeEditResource) {
      setItem({
        PROJECT_RESOURCE_CARB_A1_A3_FACTOR:
          get(iftScopeEditResource, "PROJECT_RESOURCE_CARB_A1_A3_FACTOR") || null,
        PROJECT_RESOURCE_CARB_A4_FACTOR:
          get(iftScopeEditResource, "PROJECT_RESOURCE_CARB_A4_FACTOR") || null,
        PROJECT_RESOURCE_CARB_A5A_FACTOR:
          get(iftScopeEditResource, "PROJECT_RESOURCE_CARB_A5A_FACTOR") || null,
        PROJECT_RES_CARB_WASTEFACTOR:
          get(iftScopeEditResource, "PROJECT_RES_CARB_WASTEFACTOR") || null,
      })
    }
  }, [iftScopeEditResource])

  const onQuantityChange = useCallback((value: any) => setQuantity(value), [])
  const onValueChanged = useCallback(
    (key: string, value: any) => {
      const v: any = {
        ...item,
        [key]: value,
      }
      setItem(v)
    },
    [item]
  )

  const handleSave = useCallback(async () => {
    const resourceID = get(iftScopeEditResource, "PROJECT_RESOURCE_ID")
    const null0Check = Object.values(item).some((item) => !item)

    const data = [
      {
        PROJECT_RESOURCE_ID: resourceID,
        PROJECT_RESOURCE_QTY: quantity || 0,
        ...item,
      },
    ]
    const updateResourceFn = async () => {
      await dispatch(updateScopeResourcesAction(data)).then(() => {
        if (estimateId) dispatch(fetchRevisionIFTScopeAction(estimateId))
        popUpClose()
      })
    }

    null0Check
      ? zeroNullCarbCnfrmDialog(t)
          .show()
          .then((dialogResult: any) => {
            if (dialogResult) updateResourceFn()
          })
      : updateResourceFn()
  }, [estimateId, quantity, iftScopeEditResource, item])

  return (
    <ScrollView className="scope-update-resource-popup-wrapper">
      <div className="p-3">
        <NumberBox
          label={t("iftScope.qty")}
          labelMode="floating"
          value={quantity}
          onValueChange={onQuantityChange}
        />
      </div>
      <Accordion>
        <Item title={t("iftScope.pas2080A1A5")}>
          <NumberBox
            label={t("iftScope.updatePopUpa1a3")}
            labelMode="floating"
            value={item.PROJECT_RESOURCE_CARB_A1_A3_FACTOR}
            onValueChange={(value) => onValueChanged("PROJECT_RESOURCE_CARB_A1_A3_FACTOR", value)}
          />
          <NumberBox
            label={t("iftScope.updatePopUpa4")}
            labelMode="floating"
            value={item.PROJECT_RESOURCE_CARB_A4_FACTOR}
            onValueChange={(value) => onValueChanged("PROJECT_RESOURCE_CARB_A4_FACTOR", value)}
          />
          <NumberBox
            label={t("iftScope.updatePopUpa5a")}
            labelMode="floating"
            value={item.PROJECT_RESOURCE_CARB_A5A_FACTOR}
            onValueChange={(value) => onValueChanged("PROJECT_RESOURCE_CARB_A5A_FACTOR", value)}
          />
          <NumberBox
            label={t("iftScope.updatePopUpWasteFactor")}
            labelMode="floating"
            value={item.PROJECT_RES_CARB_WASTEFACTOR}
            onValueChange={(value) => onValueChanged("PROJECT_RES_CARB_WASTEFACTOR", value)}
            min={0}
            max={100}
          />
        </Item>
        <Item title={t("iftScope.pas2080B1B8")}>{t("iftScope.comingSoon")}</Item>
        <Item title={t("iftScope.pas2080C1C4")}>{t("iftScope.comingSoon")}</Item>
      </Accordion>
      <div className="row save-btn">
        <Button
          className="active"
          text={t("toolbarActions.save")}
          type="default"
          onClick={handleSave}
        />
      </div>
    </ScrollView>
  )
}

export default memo(EditResourceForm)
