// eslint-disable-next-line import/no-extraneous-dependencies
import React, { ElementType } from "react"
import { Provider as ReduxProvider, useSelector } from "react-redux"
import { HashRouter as Router, useNavigate } from "react-router-dom"
import { NavigationProvider } from "contexts/navigation"
import { AuthState } from "redux/auth/types"
import store, { useAppDispatch } from "redux/store"
import { authSelector } from "redux/auth/authSlice"
import AuthNavigator from "navigators/AuthNavigator"
import AppNavigator from "navigators/AppNavigator"
import { useScreenSizeClass } from "utils/media-query"
import { navUtil, myUtils } from "utils/common-utils"
import withClearCache from "ClearCache"
import "devextreme/dist/css/dx.common.css"
import "./themes/generated/theme.base.css"
import "./themes/generated/theme.additional.css"
import "./dx-styles.scss"

const App = () => {
  const authState: AuthState = useSelector(authSelector)
  const dispatch = useAppDispatch()
  navUtil.navigate = useNavigate()
  myUtils.dispatch = dispatch

  return authState.currentUser ? <AppNavigator /> : <AuthNavigator />
}

const Root: React.FC = () => {
  const screenSizeClass = useScreenSizeClass()

  return (
    <Router data-testid="app">
      <NavigationProvider>
        <ReduxProvider store={store}>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </ReduxProvider>
      </NavigationProvider>
    </Router>
  )
}

export default withClearCache(Root) as unknown as ElementType
