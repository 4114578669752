/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import TreeList, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Toolbar,
} from "devextreme-react/tree-list"
import { Button } from "devextreme-react"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { estimateLevelSelector } from "redux/estimate-level/estimateLevelSlice"
import ExportToFile from "utils/ExportTreeListToCSV"
import { handleResetFilter } from "utils/common-utils"
import { allowedPageSizes, defaultPageSize } from "utils/config"
import { estimateLevelColumnsGenerator, filterDataSourceFn, onRowPrepared } from "./tableConst"

function EstimateLevelTable() {
  const { t } = useTranslation()
  const treeListRef = useRef<TreeList>(null)

  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { aggregateBy, estimateName, estimateDesc, estimateLevels } =
    useSelector(estimateLevelSelector)

  const filteredDataSource = useMemo(
    () => filterDataSourceFn(estimateLevels, aggregateBy.name),
    [estimateLevels, aggregateBy]
  )

  const estimateLevelColumns = useMemo(() => estimateLevelColumnsGenerator(userRole), [userRole])

  const onExporting = useCallback(
    () =>
      ExportToFile(
        `${estimateName} Cost and Carbon Report`,
        treeListRef.current?.instance,
        userRole
      ),
    [estimateName, treeListRef, userRole]
  )

  return (
    <TreeList
      id="estimateLevelItems"
      className="compact-grid compact-grid-tree"
      ref={treeListRef}
      dataSource={filteredDataSource}
      showBorders
      showRowLines
      columnAutoWidth
      showColumnLines
      rowAlternationEnabled
      allowColumnResizing
      keyExpr="id"
      filterMode="matchOnly"
      parentIdExpr="Parent_ID"
      errorRowEnabled={false}
      height={windowHeight - 232}
      onRowPrepared={onRowPrepared}
    >
      <FilterRow visible />
      <SearchPanel visible width={340} />
      <Paging enabled defaultPageSize={defaultPageSize} />
      <Pager visible showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Toolbar>
        <Item location="before">
          <label>{t("estimateLevel.estimateDesc")}</label>
          {estimateDesc}
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(treeListRef)}
            />
          </span>
        </Item>
        <Item location="after">
          <span title={t("toolbarActions.exportCSV")}>
            <Button icon="export" onClick={onExporting} />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      {estimateLevelColumns.map(({ caption, visible, ...rest }) => (
        <Column caption={t(caption)} visible={visible(aggregateBy)} {...rest} />
      ))}
    </TreeList>
  )
}

export default EstimateLevelTable
