import { useRef, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form"
import LoadIndicator from "devextreme-react/load-indicator"
import { useAppDispatch } from "redux/store"
import { useSelector } from "react-redux"
import { authSelector, setLoading } from "redux/auth/authSlice"
import { LoadingState } from "types/AppNav"
import { changePasswordAction } from "redux/auth/middleware"
import { useTranslation } from "react-i18next"
import useDocumentTitle from "hooks/useDocumentTitle"
import { passwordRules } from "./util"
import "./change-password.scss"

const ChangePasswordForm = () => {
  useDocumentTitle("Change password | Cost and Carbon Forecasting tool")
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const formData = useRef({ password: "", confirmedPassword: "" })
  const { recoveryCode } = useParams()
  const [pwdRules, setPwdRules] = useState<Array<any>>(passwordRules)
  const [pwdMatch, setPwdMatch] = useState<Boolean>(false)

  const { loading } = useSelector(authSelector)

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { password } = formData.current
      dispatch(setLoading(LoadingState.LOADING))
      dispatch(changePasswordAction({ password, recoveryCode: recoveryCode || "" }))
    },
    [dispatch, recoveryCode]
  )

  useEffect(() => {
    if (loading === LoadingState.SUCCESS) {
      navigate("/login")
      dispatch(setLoading(LoadingState.DEFAULT))
    }
  }, [loading])

  const confirmPassword = ({ value }: any) => value === formData.current.password

  const onFieldDataChange = (e: any) => {
    if (e.dataField === "password") {
      const PWDRules = passwordRules.map(({ ruleFn, ...rest }) => ({
        ...rest,
        pass: ruleFn(e.value),
      }))
      setPwdRules(PWDRules)
    }
    if (formData.current.password && pwdRules.every((item) => item.pass)) {
      setPwdMatch(formData.current.password === formData.current.confirmedPassword)
    } else {
      setPwdMatch(false)
    }
  }

  return (
    <div className="changePwdMain">
      <form onSubmit={onSubmit}>
        <Form
          formData={formData.current}
          onFieldDataChanged={onFieldDataChange}
          disabled={loading === LoadingState.LOADING}
        >
          <Item
            dataField="password"
            editorType="dxTextBox"
            editorOptions={{
              stylingMode: "filled",
              placeholder: t("enterPassword"),
              mode: "password",
            }}
          >
            <RequiredRule message={t("emptyPassword").toString()} />
            <Label visible={false} />
          </Item>
          <Item
            dataField="confirmedPassword"
            editorType="dxTextBox"
            editorOptions={{
              stylingMode: "filled",
              placeholder: t("confirmPassword"),
              mode: "password",
            }}
          >
            <RequiredRule message={t("emptyPassword").toString()} />
            <CustomRule
              message={t("passwordNotMatch").toString()}
              validationCallback={confirmPassword}
            />
            <Label visible={false} />
          </Item>
          <Item>
            {pwdMatch && (
              <div className="pwdMatch">
                <i className="dx-icon-check" />
                {t("passwordMatch")}
              </div>
            )}
          </Item>
          <ButtonItem>
            <ButtonOptions width="100%" type="default" useSubmitBehavior>
              <span className="dx-button-text">
                {loading === LoadingState.LOADING ? (
                  <LoadIndicator width="24px" height="24px" visible />
                ) : (
                  t("continue")
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
      <div className="pwdReq">
        <div className="pwdReqTitle">{t("pwdReqTitle")}</div>
        <ul>
          {pwdRules.map((item) => (
            <li key={item.id} style={{ color: item.pass ? "#54bc0e" : "#f44336" }}>
              {t(item.rule)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ChangePasswordForm
