import { get } from "lodash"
import { TFunction } from "i18next"
import { KeyDataFieldsResponse } from "redux/ift/types"
import { config } from "utils/config"

interface keyDataFieldsType {
  t: TFunction<"translation", undefined>
  hideFields: boolean
  iftKeyDataFields: KeyDataFieldsResponse | {}
}

export interface FieldLabelProps {
  title: string
  field: string
  fieldType: string
  value: any
  fieldProps: any
}

const currencySignCheck = config.clientName === "HE"
export const keyDataCurrencySign = currencySignCheck ? "£" : "$"

export const keyDataFields = ({ t, hideFields, iftKeyDataFields }: keyDataFieldsType) => {
  const fields = [
    {
      title: t("keyData.projName"),
      field: "PROJECT_NAME",
      fieldType: "text",
      value: get(iftKeyDataFields, "PROJECT_NAME"),
      fieldProps: {
        maxLength: "200",
      },
    },
    {
      title: t("keyData.projPin"),
      field: "PROJECT_PIN",
      fieldType: "text",
      value: get(iftKeyDataFields, "PROJECT_PIN"),
      fieldProps: {},
    },
    {
      title: t("keyData.optionName"),
      field: "OPTION_NAME",
      fieldType: "text",
      value: get(iftKeyDataFields, "OPTION_NAME"),
      fieldProps: { maxLength: "200" },
    },
    {
      title: t("keyData.estimateReleaseDate"),
      field: "ESTIMATE_RELEASE_DATE",
      fieldType: "date",
      value: get(iftKeyDataFields, "ESTIMATE_RELEASE_DATE"),
      fieldProps: {},
    },
    {
      title: t("keyData.estimateBaseDate"),
      field: "ESTIMATE_BASE_DATE",
      fieldType: "text",
      value: get(iftKeyDataFields, "ESTIMATE_BASE_DATE"),
      fieldProps: {},
      filterForRevision: true,
    },
    {
      title: t("keyData.historicCostDate"),
      field: "HISTORIC_COST_DATE",
      fieldType: "text",
      value: get(iftKeyDataFields, "HISTORIC_COST_DATE"),
      fieldProps: {},
      filterForRevision: true,
    },
    {
      title: t("keyData.AGCodeDIP"),
      field: "AGGREMENT_CODE_DIP",
      fieldType: "number",
      value: get(iftKeyDataFields, "AGGREMENT_CODE_DIP"),
      fieldProps: { min: "0" },
    },
    {
      title: t("keyData.AGCodePC"),
      field: "AGGREMENT_CODE_PC",
      fieldType: "number",
      value: get(iftKeyDataFields, "AGGREMENT_CODE_PC"),
      fieldProps: { min: "0" },
    },
    {
      title: t("keyData.AGCode"),
      field: "AGGREMENT_CODE",
      fieldType: "number",
      value: get(iftKeyDataFields, "AGGREMENT_CODE"),
      fieldProps: { min: "0" },
    },
    {
      title: t("keyData.AGCodeTA"),
      field: "AGGREMENT_CODE_TA",
      fieldType: "number",
      value: get(iftKeyDataFields, "AGGREMENT_CODE_TA"),
      fieldProps: { min: "0" },
    },
    {
      title: t("keyData.contractorFee"),
      field: "CONTRACTOR_FEE_PERCENTAGE",
      fieldType: "number",
      value: get(iftKeyDataFields, "CONTRACTOR_FEE_PERCENTAGE"),
      fieldProps: {
        min: "0",
        max: "100",
        // onKeyUp: "if(this.value > 5) this.value = null;",
      },
      filterForRevision: true,
    },
    {
      title: t("keyData.soFa", { 0: keyDataCurrencySign }),
      field: "SOFA",
      fieldType: "text",
      value: get(iftKeyDataFields, "SOFA"),
      fieldProps: {},
      filterForRevision: true,
    },
    {
      title: t("keyData.vat"),
      field: "VAT_PERCENTAGE",
      fieldType: "number",
      value: get(iftKeyDataFields, "VAT_PERCENTAGE"),
      fieldProps: {
        min: "0",
        max: "100",
        // onKeyUp: "if(this.value > 5) this.value = null;"
      },
    },
    {
      title: t("keyData.nrvat"),
      field: "NRVAT_PERCENTAGE",
      fieldType: "number",
      value: get(iftKeyDataFields, "NRVAT_PERCENTAGE"),
      fieldProps: { min: "0", max: "100" },
      filterForRevision: true,
    },
  ]
  return hideFields ? fields.filter((item) => !item?.filterForRevision) : fields
}

export const keyDataTableHeight = (windowHeight: number) => {
  if (windowHeight > 1094) return "min-content"
  if (windowHeight === 1094) return windowHeight - 496
  if (windowHeight <= 811) return windowHeight - 320
  if (windowHeight <= 730) return windowHeight - 420

  return windowHeight - 480
}
