import { memo, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "redux/store"
import {
  fetchKeyDataFieldsDataAction,
  fetchKeyDataRangeDistTableAction,
} from "redux/ift/middleware"
import KeyDataHead from "./keyDataHead"
import KeyDataBody from "./keyDataBody"
import KeyDataLoader from "./keyDataLoader"
import "./keyData-style.scss"

const KeyData = () => {
  const dispatch = useAppDispatch()
  const { projectId, estimateId } = useParams()

  useEffect(() => {
    const obj = { projectId, estimateId }
    dispatch(fetchKeyDataFieldsDataAction(obj))
    if (!estimateId) dispatch(fetchKeyDataRangeDistTableAction(projectId))
  }, [])

  return (
    <div className="keyDataMain">
      <KeyDataLoader />
      <KeyDataHead />
      {!estimateId && <KeyDataBody />}
    </div>
  )
}

export default memo(KeyData)
