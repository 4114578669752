import { useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { custom } from "devextreme/ui/dialog"
import { Button, Popup, SelectBox } from "devextreme-react"
import { useAppDispatch } from "redux/store"
import { nominatedEstimateRevisionAction } from "redux/ift/middleware"
import { comparatorSelector } from "redux/comparator/iftComparatorSlice"
import { nominatedRevisionConfirmationPopUPProps } from "../../utils"

const NominatedRevision = ({ popupVisible, onClose }: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { estimateRevisions } = useSelector(comparatorSelector)
  const [nominatedRevision, setNominatedRevision] = useState<number | undefined>()

  const onSubmitNominatedRevision = () => {
    const confirmationDialog = custom(nominatedRevisionConfirmationPopUPProps)
    confirmationDialog.show().then((dialogResult: any) => {
      if (dialogResult) {
        dispatch(nominatedEstimateRevisionAction(nominatedRevision)).finally(() => {
          setNominatedRevision(undefined)
          onClose()
        })
      }
    })
  }

  return (
    <Popup
      visible={popupVisible}
      hideOnOutsideClick
      dragEnabled={false}
      showCloseButton
      width={500}
      maxHeight={400}
      onHiding={onClose}
      title={`${t("iftComparatorMain.nominatePreferredRevision")}`}
    >
      <div className="p-3">
        <div className="dropdown">
          <label className="mb-2">{t("iftComparatorMain.selectPreferredRevision")}</label>
          <SelectBox
            dataSource={estimateRevisions}
            valueExpr="Id"
            displayExpr="QuoteNo"
            className="margin-10"
            value={nominatedRevision}
            onValueChange={(v: number) => setNominatedRevision(v)}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            width={150}
            height={40}
            text="Save"
            className="button-success"
            onClick={onSubmitNominatedRevision}
            stylingMode="contained"
            disabled={!nominatedRevision}
          />
        </div>
      </div>
    </Popup>
  )
}

export default NominatedRevision
