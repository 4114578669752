import { get } from "lodash"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver-es"
import SubItemIcon from "assets/icons/SubItem.png"
import ItemTextIcon from "assets/icons/ItemText.png"
import ResourceItemIcon from "assets/icons/Resource.png"
import NormalItemIcon from "assets/icons/NormalItem.png"
import ItemVariableIcon from "assets/icons/ItemVariable.png"
import CompositeItemIcon from "assets/icons/CompositeItem.png"
import CompositeTotalIcon from "assets/icons/CompositeTotal.png"
import { UserRole } from "redux/profile/types"
import { ResourceType } from "redux/projects/types"
import { AccessLevel, AccessType } from "redux/role-permission/types"
import { allRoles, identifyPermissions, renderCurrencyCell } from "utils/common-utils"
import { BEHAVIOURS } from "../../utils"

export const onComparatorCellPrepared = (e: any) => {
  if (e && e.rowType === "data") {
    let bgColor = "white"
    const behaviour = e.data[`${e.column.dataField}_BEHAVIOUR`]
    if (behaviour) {
      bgColor = BEHAVIOURS[behaviour]
    }
    e.cellElement.style.backgroundColor = bgColor
  }
}

export const comparatorEditorOptions = { format: "#0.00" }

export const compCostVarCalCellVal = (row: any, index: number) => {
  const behaviour = get(row, `data.V${index + 1}_VARIANCE_COST_BEHAVIOUR`) === "NEGATIVE"
  const val = renderCurrencyCell(get(row, `data.V${index + 1}_VARIANCE_COST`) || 0)

  return behaviour ? `- ${val}` : val
}

export const compCarbVarCalCellVal = (row: any, index: number) => {
  const val = get(row, `V${index + 1}_VARIANCE_CARBON_COST`) || 0
  const behaviour = get(row, `V${index + 1}_VARIANCE_CARBON_COST_BEHAVIOUR`) === "NEGATIVE"

  return behaviour ? -val : val
}

const getHeader = (columns: Array<any>) => {
  const header: Array<string> = []
  columns.forEach((column: any) => {
    header.push(column.caption)
  })
  return header
}

const getRow = (node: any, columns: Array<any>) => {
  const row: Array<any> = []
  columns.forEach((column: any, index: number) => {
    let cell = node.data[column.dataField]
    cell = cell === null ? "" : cell // 0 values need to be printed but null needs to be cleared

    if (node.data[`${column.dataField}_BEHAVIOUR`] === "NEGATIVE") {
      cell *= -1
    }
    // if (index === 0) cell = "   ".repeat(node.level) + cell
    row[index] = cell
  })
  return row
}

const getData = (node: any, columns: Array<any>) => {
  const rows: Array<any> = []
  if (node.level >= 0) {
    rows.push(getRow(node, columns))
  }
  node?.children?.forEach((child: any) => {
    if (child) {
      getData(child, columns).map((row) => rows.push(row))
    }
  })
  return rows
}

export const ExportToFile = async (treeListRef: any, userRole: UserRole | null) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet("IFT Comparator")

  const columns: any = treeListRef.current?.instance
    .getVisibleColumns()
    .filter((c: any) => c.dataField)
  const header = getHeader(columns)

  worksheet.addRow(header)

  let data = getData(treeListRef.current?.instance.getRootNode(), columns)
  const permsnCheck = identifyPermissions(
    userRole,
    AccessType.SHOW_COST_DATA,
    AccessLevel.YES,
    allRoles
  )
  if (!permsnCheck) {
    data = data.map((item) => {
      const newArr = [...item]
      newArr[2] = null
      return newArr
    })
  }

  data.map((row) => worksheet.addRow(row))

  // worksheet.spliceColumns(1, 1) // Remove First Column to remove row expansion arrows

  const buffer = await workbook.csv.writeBuffer()
  saveAs(new Blob([buffer], { type: "application/octet-stream" }), "IFT-Comparator.csv")
}

const cellDescriptionProps = (compositeItemType: string) => {
  let obj = {
    icon: ItemTextIcon,
    alt: "",
    title: "Text Item",
    labelStyles: {
      color: "",
      // fontWeight: "bold",
      fontStyle: "",
    },
  }
  switch (compositeItemType) {
    case "CompositeTotal":
      obj = {
        icon: CompositeTotalIcon,
        alt: "Composite Total",
        title: "Composite Total",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "NormalItem":
      obj = {
        icon: NormalItemIcon,
        alt: "Normal Item",
        title: "Normal Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "CompositeItem":
      obj = {
        icon: CompositeItemIcon,
        alt: "Composite Item",
        title: "Composite Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ItemVariable":
      obj = {
        icon: ItemVariableIcon,
        alt: "Item Variable",
        title: "Item Variable",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "ResourceItem":
      obj = {
        icon: ResourceItemIcon,
        alt: "Resource Item",
        title: "Resource",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    case "SubItem":
      obj = {
        icon: SubItemIcon,
        alt: "Sub Item",
        title: "Sub Item",
        labelStyles: {
          ...obj.labelStyles,
          color: "",
        },
      }
      break
    default:
      break
  }
  return obj
}

export const comparatorCellDescriptionRender = (row: any) => {
  const subItemCheck =
    row.data.SUBITEM === "Y" && row.data.PROJECT_ITEM_COMPOSITETYPE === 0
      ? "SubItem"
      : ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]
  const { icon, alt, title, labelStyles } = cellDescriptionProps(subItemCheck)
  const sectionCheck = ResourceType[row.data.PROJECT_ITEM_COMPOSITETYPE]

  return (
    <div className="cell-description">
      {sectionCheck && <img src={icon} alt={alt} title={title} />}
      <label style={labelStyles} title={row.value}>
        {row.value}
      </label>
    </div>
  )
}
