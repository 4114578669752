import { get } from "lodash"
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import {
  updateEstimateContractorsAction,
  getEstimateRevisionByIdAction,
  getEstimateContractorsAction,
  postEstimateRevisionsAction,
  getEstimateRevisionsAction,
  putRevisionDetailsAction,
  deleteEstimateAction,
} from "./middleware"
import { ContractorsList, EstimateRevisionManagementItem, EstimateRevisionsState } from "./types"

const initialState: EstimateRevisionsState = {
  loading: LoadingState.DEFAULT,
  estimateRevisions: [],
  showManageContractorsPopUp: false,
  selectedRevision: undefined,
  selectedUpdateRevision: undefined,
  revisionContractors: [],
}

const estimateRevisionManagementSlice = createSlice({
  name: "EstimateRevisionManagement",
  initialState,
  reducers: {
    setLoading: (state: EstimateRevisionsState, { payload }: PayloadAction<LoadingState>) => ({
      ...state,
      loading: payload,
    }),
    setshowManageContractorsPopUp: (
      state: EstimateRevisionsState,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      showManageContractorsPopUp: payload,
    }),
    clearRevisionContractors: (state) => ({
      ...state,
      revisionContractors: [],
    }),
    setSelectedRevision: (
      state: EstimateRevisionsState,
      { payload }: PayloadAction<EstimateRevisionManagementItem | undefined>
    ) => ({
      ...state,
      selectedRevision: payload,
    }),
    setSelectedUpdateRevision: (
      state: EstimateRevisionsState,
      { payload }: PayloadAction<EstimateRevisionManagementItem | undefined>
    ) => ({
      ...state,
      selectedUpdateRevision: payload,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getEstimateRevisionsAction.pending, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getEstimateRevisionsAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<any>) => {
        const estimateRevisions = payload.map((item: any) => ({
          ...item,
          ProjectCost: +Number(item.ProjectCost).toFixed(2),
          TotalCarbonValue: +Number(item.TotalCarbonValue).toFixed(2),
        }))

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          estimateRevisions,
        }
      }
    )
    builder.addCase(getEstimateRevisionsAction.rejected, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    builder.addCase(getEstimateRevisionByIdAction.pending, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      getEstimateRevisionByIdAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        selectedRevision: payload[0] ? payload[0] : undefined,
      })
    )
    builder.addCase(getEstimateRevisionByIdAction.rejected, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
    // builder.addCase(postEstimateRevisionsAction.pending, (state: EstimateRevisionsState) => ({
    //   ...state,
    //   loading: LoadingState.LOADING,
    // }))
    builder.addCase(
      postEstimateRevisionsAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<any>) => {
        const newRevision = payload.map((item: any) => ({
          ...item,
          ProjectCost: +Number(item.ProjectCost).toFixed(2),
          TotalCarbonValue: +Number(item.TotalCarbonValue).toFixed(2),
        }))

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          estimateRevisions: [...state.estimateRevisions, ...newRevision],
        }
      }
    )
    builder.addCase(postEstimateRevisionsAction.rejected, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(putRevisionDetailsAction.pending, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      putRevisionDetailsAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<any>) => {
        const { estimateRevisions } = current(state)
        const updatedRevID = payload[0].PROJECT_ID
        let found = estimateRevisions.find((item) => item.Id === updatedRevID)
        found = { ...found, Title: payload[0].PROJECT_TITLE } as EstimateRevisionManagementItem
        const newEstimateRevisions = [
          ...estimateRevisions.filter((item) => item.Id !== updatedRevID),
          found,
        ]

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          estimateRevisions: newEstimateRevisions,
        }
      }
    )
    builder.addCase(putRevisionDetailsAction.rejected, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(deleteEstimateAction.pending, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.LOADING,
    }))
    builder.addCase(
      deleteEstimateAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<any>) => {
        const { estimateRevisions } = current(state)
        const newEstimateRevisions = estimateRevisions.filter((item) => item.Id !== payload)

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          estimateRevisions: newEstimateRevisions,
        }
      }
    )
    builder.addCase(deleteEstimateAction.rejected, (state: EstimateRevisionsState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))

    builder.addCase(
      getEstimateContractorsAction.fulfilled,
      (state: EstimateRevisionsState, { payload }: PayloadAction<ContractorsList[]>) => ({
        ...state,
        loading: LoadingState.DEFAULT,
        revisionContractors: payload,
      })
    )
    builder.addCase(
      updateEstimateContractorsAction.fulfilled,
      (state: EstimateRevisionsState, { payload, ...rest }: PayloadAction<ContractorsList[]>) => {
        const revId = get(rest, "meta.arg.Id")
        const { estimateRevisions } = current(state)

        const found = estimateRevisions.find((item) => item.Id === revId)
        let newEstimateRevisions: any[] = estimateRevisions
        const checkContAssigned = payload.some((item) => item.IS_ASSIGNED)
        newEstimateRevisions = [
          ...estimateRevisions.filter((item) => item.Id !== revId),
          { ...found, IsContractorAssigned: checkContAssigned },
        ]

        return {
          ...state,
          loading: LoadingState.DEFAULT,
          revisionContractors: payload,
          estimateRevisions: newEstimateRevisions,
        }
      }
    )
  },
})

export const {
  reset,
  setLoading,
  setSelectedRevision,
  clearRevisionContractors,
  setSelectedUpdateRevision,
  setshowManageContractorsPopUp,
} = estimateRevisionManagementSlice.actions

export const estmteRvsnMgmntSelector = (state: RootState) => state.EstimateRevisionManagement

export default estimateRevisionManagementSlice.reducer
