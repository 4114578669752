import axiosInstance, { isAxiosError } from "services/api"
import notify from "devextreme/ui/notify"
import { ICarbonResource, IItemResource, ITransportAssumption } from "./types"

export const fetchResourcesAsync = async () => {
  try {
    const response = await axiosInstance.get(`/resource-library-carbon`)
    const mappedRes = response.data.data.map((resource: ICarbonResource) => ({
      RESOURCE_LIBRARY_ID: resource.RESOURCE_LIBRARY_ID || null,
      RESOURCE_LIBRARY_CODE: resource.RESOURCE_LIBRARY_CODE || "",
      RESOURCE_LIBRARY_DESC: resource.RESOURCE_LIBRARY_DESC || "",
      // RESOURCE_LIBRARY_NOTES: resource.RESOURCE_LIBRARY_NOTES || '',  // TODO: Upcoming in future
      RES_CARB_ID: resource.RES_CARB_ID || null,
      RES_CARB_A1_A3: resource.RES_CARB_A1_A3 || 0,
      RES_CARB_A4: resource.RES_CARB_A4 || 0,
      RES_CARB_A5A: resource.RES_CARB_A5A || 0,
      RES_CARB_A5W: resource.RES_CARB_A5W || 0,
      TOTAL_CARBON:
        resource.RES_CARB_A1_A3 + resource.RES_CARB_A4 + resource.RES_CARB_A5W ||
        0 + resource.RES_CARB_A5A ||
        0,
      RES_CARB_WEIGHTPERUNIT: resource.RES_CARB_WEIGHTPERUNIT || 0,
      RES_CARB_WASTEFACTOR: resource.RES_CARB_WASTEFACTOR || 0,
      RES_CARB_NOTE: resource.RES_CARB_NOTE || "",
      RESOURCE_CATEGORY_DESC: resource.RESOURCE_CATEGORY_DESC || "",
      RESOURCE_GROUP_DESC: resource.RESOURCE_GROUP_DESC || "",
      RESOURCE_TYPE_DESC: resource.RESOURCE_TYPE_DESC || "",
      UNIT_DESC: resource.UNIT_DESC || "",
      RES_CARB_JOURNEY_LEG: resource.RES_CARB_JOURNEY_LEG || "",
    }))
    return mappedRes as ICarbonResource[]
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateResourcesAsync = async (resources: ICarbonResource[]) => {
  try {
    const response = await axiosInstance.put(`/resource-library-carbon/bulk`, { bulk: resources })
    if (response?.data) {
      const resourceLength = resources.length === 1 ? `1 Resource` : `${resources.length} Resources`
      notify(`${resourceLength} updated successfully.`, "success", 2000)
      return resources
    }

    return resources
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchTransportAssumptionAsync = async () => {
  try {
    const response = await axiosInstance.get(`/transport-assumption`)
    return response.data.data as Array<ITransportAssumption>
  } catch (err) {
    return isAxiosError(err)
  }
}

export const fetchItemsAsync = async () => {
  try {
    const response = await axiosInstance.get(`/item-library/carbon`)
    if (response && response.data && response.data.data) {
      const mappedRes = response.data.data.map((resource: IItemResource) => ({
        ITEM_LIBRARY_ID: resource.ITEM_LIBRARY_ID || null,
        ITEM_LIBAGGR_CODE: resource.ITEM_LIBAGGR_CODE || "",
        ITEM_LIBAGGR_DESC: resource.ITEM_LIBAGGR_DESC || "",
        ITEM_LIBRARY_CARB_A1_A3: resource.ITEM_LIBRARY_CARB_A1_A3 || 0,
        ITEM_LIBRARY_CARB_A4: resource.ITEM_LIBRARY_CARB_A4 || 0,
        ITEM_LIBRARY_CARB_A5W: resource.ITEM_LIBRARY_CARB_A5W || 0,
        TOTAL_CARBON:
          resource.ITEM_LIBRARY_CARB_A1_A3 +
            resource.ITEM_LIBRARY_CARB_A4 +
            resource.ITEM_LIBRARY_CARB_A5W || 0,
        ITEM_CARB_WEIGHTPERUNIT: resource.ITEM_CARB_WEIGHTPERUNIT || 0,
        ITEM_LIBRARY_CARB_WASTEFACTOR: resource.ITEM_LIBRARY_CARB_WASTEFACTOR || 0,
        ITEM_LIBRARY_CARB_NOTE: resource.ITEM_LIBRARY_CARB_NOTE || "",
        ITEM_GROUP_DESC: resource.ITEM_GROUP_DESC || "",
        UNIT_DESC: resource.UNIT_DESC || "",
        ITEM_LIBRARY_CARB_JOURNEY_LEG: resource.ITEM_LIBRARY_CARB_JOURNEY_LEG || "",
      }))
      return mappedRes as IItemResource[]
    }
    return []
  } catch (err) {
    return isAxiosError(err)
  }
}

export const updateItemsAsync = async (resources: IItemResource[]) => {
  try {
    const response = await axiosInstance.put(`/item-library/carbon/bulk`, { bulk: resources })
    if (response?.data) {
      const resourceLength = resources.length === 1 ? `1 Item` : `${resources.length} Items`
      notify(`${resourceLength} updated successfully.`, "success", 2000)
      return resources
    }

    return resources
  } catch (err) {
    return isAxiosError(err)
  }
}
