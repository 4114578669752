/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "redux/store"
import { useTranslation } from "react-i18next"
import { sharedSelector } from "redux/shared/sharedSlice"
import { profileSelector } from "redux/profile/profileSlice"
import { updateDynamicCarbonLibraryAction } from "redux/masters/middleware"
import { dynamicCarbonSelector } from "redux/masters/dynamicCarbonLibrarySlice"
import DataGrid, {
  Column,
  Export,
  FilterRow,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid"
import { Button } from "devextreme-react"
import { handleResetFilter } from "utils/common-utils"
import { onExporting, tableColumnsGenerator } from "./tableConst"

const DynamicCarbonTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { userRole } = useSelector(profileSelector)
  const { windowHeight } = useSelector(sharedSelector)
  const { dynamicCarbonData, editedDynamicCarbonData } = useSelector(dynamicCarbonSelector)

  const dataGridRef = useRef<DataGrid>(null)

  const tableColumns = useMemo(() => tableColumnsGenerator(userRole), [userRole])

  const onSave = () => dispatch(updateDynamicCarbonLibraryAction(editedDynamicCarbonData))

  return (
    <DataGrid
      id="gridContainer"
      ref={dataGridRef}
      dataSource={dynamicCarbonData}
      allowColumnResizing
      columnAutoWidth
      showBorders
      showColumnLines
      onExporting={onExporting}
      height={windowHeight - 148}
    >
      <Pager showPageSizeSelector showInfo visible />
      <Scrolling columnRenderingMode="virtual" />
      <SearchPanel visible width={340} />
      <Paging defaultPageSize={10} />
      <FilterRow visible />
      <Export enabled />
      <Toolbar>
        <Item name="exportButton" />
        <Item location="after">
          <span title={t("toolbarActions.resetAllFilters")}>
            <Button
              icon="refresh"
              stylingMode="text"
              onClick={() => handleResetFilter(dataGridRef)}
            />
          </span>
        </Item>
        <Item>
          <span title={t("toolbarActions.save")}>
            <Button
              icon="check"
              type="success"
              onClick={onSave}
              disabled={!editedDynamicCarbonData.length}
            />
          </span>
        </Item>
        <Item name="searchPanel" />
      </Toolbar>
      {tableColumns.map(({ caption, ...rest }) => (
        <Column caption={t(caption)} {...rest} />
      ))}
    </DataGrid>
  )
}

export default DynamicCarbonTable
