import { Popup } from "devextreme-react"
import { useTranslation } from "react-i18next"
import SupplierRatesTable from "./SupplierRatesTable"

const SupplierRatesPopUp = (props: any) => {
  const { t } = useTranslation()
  const { onClose, applyRate, suppliers } = props

  return (
    <Popup
      visible
      width={600}
      maxHeight={600}
      title={`${t("contractorEstimateLibrary.popUpHeadingApplyRate")} `}
      showCloseButton
      onHiding={onClose}
      dragEnabled={false}
      enableBodyScroll
    >
      <SupplierRatesTable applyRate={applyRate} suppliers={suppliers} />
    </Popup>
  )
}

export default SupplierRatesPopUp
