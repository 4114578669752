// @ts-nocheck
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { LoadingState } from "types/AppNav"
import { CarbonCodesCategoryItem } from "redux/masters/types"
import { FuelItem, FuelTypeState, UpdateFuelTypeRequest } from "./types"
import { fetchAllFuelTypeAction, updateFuelItemValueAction } from "./middleware"

const INITIAL_STATE: FuelTypeState = {
  loading: LoadingState.DEFAULT,
  fuelItems: [],
  carbonCodeItem: null,
}

const fuelTypeSlice = createSlice({
  name: "FuelTypes",
  initialState: INITIAL_STATE,
  reducers: {
    setCarbonCodeForFuelType: (
      state: FuelTypeState,
      { payload }: PayloadAction<CarbonCodesCategoryItem[]>
    ) => {
      const item = payload.find(
        (item: CarbonCodesCategoryItem) =>
          item.CUSTOM_LIST_NAME?.toLowerCase() === "fuel or labour type"
      )
      if (item) {
        return { ...state, carbonCodeItem: item }
      }

      return { ...state }
    },

    updateFuelTypeData: (
      state: FuelTypeState,
      { payload }: PayloadAction<UpdateFuelTypeRequest[]>
    ) => {
      const fuelTypes = [...state.fuelItems]
      payload.forEach((request: UpdateFuelTypeRequest) => {
        const idx: number = fuelTypes.findIndex((item) => item.id === request.key)
        if (idx >= 0) {
          const updatedItem = { ...fuelTypes[idx], ...request.data }
          fuelTypes[idx] = updatedItem
        }
      })

      return { ...state, fuelItems: fuelTypes }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllFuelTypeAction.fulfilled,
      (state: FuelTypeState, { payload }: PayloadAction<FuelItem[]>) => ({
        ...state,
        fuelItems: payload,
        loading: LoadingState.DEFAULT,
      })
    )
    builder.addCase(
      updateFuelItemValueAction.fulfilled,
      (state: FuelTypeState, { payload }: PayloadAction<FuelItem[]>) => ({
        ...state,
        fuelItems: payload,
        loading: LoadingState.DEFAULT,
      })
    )

    builder.addCase(fetchAllFuelTypeAction.rejected, (state: FuelTypeState) => ({
      ...state,
      loading: LoadingState.DEFAULT,
    }))
  },
})

export const { updateFuelTypeData, setCarbonCodeForFuelType } = fuelTypeSlice.actions

export const fuelTypeSelector = (state: RootState) => state.FuelTypes

export default fuelTypeSlice.reducer
