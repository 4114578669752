import { createAsyncThunk } from "@reduxjs/toolkit"
import notify from "devextreme/ui/notify"
import { ErrorResponse } from "services/SuccessResponse"
import { getEstimateLevelsAsync, getProjectIDsAsync } from "./services"
import { EstimateLevelAPIData } from "./types"

export const getProjectIDsAction = createAsyncThunk<any>(
  "getProjectIDsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getProjectIDsAsync()
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data || []
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)

export const getEstimateLevelsAction = createAsyncThunk<any, EstimateLevelAPIData>(
  "getEstimateLevelsAsync",
  async (request: EstimateLevelAPIData, { rejectWithValue }) => {
    try {
      const response: any | ErrorResponse = await getEstimateLevelsAsync(request)
      const errorResponse = response as ErrorResponse
      if (errorResponse?.code) {
        notify(errorResponse.message, "error", 2000)
        return rejectWithValue(errorResponse.message)
      }
      return response.data
    } catch (error: unknown) {
      return rejectWithValue(error)
    }
  }
)
