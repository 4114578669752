import { useRef, useCallback, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from "devextreme-react/form"
import LoadIndicator from "devextreme-react/load-indicator"
import "./reset-password.scss"
import { useAppDispatch } from "redux/store"
import { resetPasswordAction } from "redux/auth/middleware"
import { useSelector } from "react-redux"
import { authSelector, setLoading } from "redux/auth/authSlice"
import { Trans, useTranslation } from "react-i18next"
import { LoadingState } from "types/AppNav"
import useDocumentTitle from "hooks/useDocumentTitle"

const ResetPasswordForm = () => {
  useDocumentTitle("Reset Password | Cost and Carbon Forecasting tool")
  const navigate = useNavigate()
  const { t } = useTranslation()
  const formData = useRef({ username: "" })
  const { loading } = useSelector(authSelector)
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { username } = formData.current
      dispatch(setLoading(LoadingState.LOADING))
      dispatch(resetPasswordAction({ username }))
    },
    [navigate]
  )

  useEffect(() => {
    if (loading === LoadingState.SUCCESS) {
      dispatch(setLoading(LoadingState.DEFAULT))
      navigate("/login")
    }
  }, [loading])

  return (
    <form className="reset-password-form" onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading === LoadingState.LOADING}>
        <Item
          dataField="username"
          editorType="dxTextBox"
          editorOptions={{ stylingMode: "filled", placeholder: t("enterUserName"), mode: "text" }}
        >
          <RequiredRule message={t("emptyUserName").toString()} />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={{ class: "submit-button" }}
            width="100%"
            type="default"
            useSubmitBehavior
          >
            <span className="dx-button-text">
              {loading === LoadingState.LOADING ? (
                <LoadIndicator width="24px" height="24px" visible />
              ) : (
                t("resetPassword")
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className="login-link">
            {/* {t("returnTo", { test: <Link to="/login" /> })} */}
            <Trans t={t} i18nKey="returnToSignIn" components={[<Link key={0} to="/login" />]} />
          </div>
        </Item>
      </Form>
    </form>
  )
}

export default ResetPasswordForm
