import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { profileSelector } from "redux/profile/profileSlice"
import { projectSelector } from "redux/projects/projectSlice"
import { summaryChipsGenerator } from "../utils"

const SectionSummaryCard = () => {
  const { t } = useTranslation()

  const { userRole } = useSelector(profileSelector)
  const { project, estimatorID, sectionColumnWiseTotal } = useSelector(projectSelector)

  const data = useMemo(
    () => summaryChipsGenerator(t, project, sectionColumnWiseTotal, userRole, estimatorID),
    [project, sectionColumnWiseTotal, userRole, estimatorID]
  )

  return (
    <div className="summary-container dx-card">
      <label className="summary-title">{t("projectDetails.summary")}</label>
      <div className="summary-chip-parent">
        {data.map(({ label, value, key, hide }) => (
          <div key={key} className={`summary-chip ${hide ? "d-none" : ""}`}>
            <label className="summary-chip-label">{label}</label>
            <label className="summary-chip-value">{value}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(SectionSummaryCard)
