import { get } from "lodash"
import { TFunction } from "i18next"
import { formatDate, formatNumber } from "devextreme/localization"
import { config } from "utils/config"

export const costEstimateSummaryReportBreadCrumb = (t: TFunction<"translation", undefined>) => [
  {
    name: t("costEstimateSummary.breadCrumbHeading"),
    link: "/costEstimateSummary",
  },
  {
    name: t("costEstimateSummary.breadCrumbHeading2"),
  },
]

export const dataSetGenerator = (t: TFunction<"translation", undefined>, dataSourceObj: any) => [
  {
    label: t("costEstimateSummary.schemeNo"),
    value: get(dataSourceObj, "SCHEME_TITLE") || "",
  },
  {
    label: t("costEstimateSummary.schemeTitle"),
    value: get(dataSourceObj, "PROJ_COMP_DESC") || "",
  },
  {
    label: t("costEstimateSummary.schemeType"),
    value: get(dataSourceObj, "SCHEME_TYPE") || "",
  },
  {
    label: t("costEstimateSummary.estimateNo"),
    value: get(dataSourceObj, "PROJECT_QUOTE_NO") || "",
  },
  {
    label: t("costEstimateSummary.estimateTitle"),
    value: get(dataSourceObj, "PROJECT_TITLE") || "",
  },
  {
    label: t("costEstimateSummary.estimateReleaseDate"),
    value: get(dataSourceObj, "ESTIMATE_RELEASE_DATE")
      ? formatDate(new Date(get(dataSourceObj, "ESTIMATE_RELEASE_DATE") || ""), "dd/MM/yyyy")
      : "",
  },
  {
    label: t("costEstimateSummary.clientProjMngr"),
    value: get(dataSourceObj, "PROJECT_MANAGER") || "",
  },

  {
    label: t("costEstimateSummary.estimateType"),
    value: get(dataSourceObj, "LEVEL_OF_ESTIMATE_DESCRIPTION") || "",
  },
  {
    label: t("costEstimateSummary.estimator"),
    value: get(dataSourceObj, "ESTIMATOR_NAME") || "",
  },
  {
    label: t("costEstimateSummary.estimateBaseDate"),
    value: get(dataSourceObj, "COST_BASE_DATE")
      ? formatDate(new Date(get(dataSourceObj, "COST_BASE_DATE") || ""), "dd/MM/yyyy")
      : "",
  },
  {
    label: t("costEstimateSummary.portfolio"),
    value: get(dataSourceObj, "PORTFOLIO_NAME") || "",
  },
  {
    label: t("costEstimateSummary.programme"),
    value: get(dataSourceObj, "PARENT_PROJ_COMP_DESC") || "",
  },
]

const currencySignCheck = config.clientName === "HE"
export const costSummaryReportCurrencySign = currencySignCheck ? "£" : "$"

export const renderCostSummaryReportCurrency = (row: any) => {
  const val = get(row, "value")

  return formatNumber(val, {
    currency: currencySignCheck ? "GBP" : "USD",
    precision: 2,
    type: "currency",
  })
}
