import { memo, useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { expenditureProfileSelector } from "redux/expenditure-profile/expenditureProfileSlice"
import { detailsTableDataGenerator } from "../../utils"

const DetailsTable = () => {
  const { t } = useTranslation()
  const { selectedExpenditureProjectData } = useSelector(expenditureProfileSelector)

  const data = useMemo(
    () => detailsTableDataGenerator(t, selectedExpenditureProjectData),
    [selectedExpenditureProjectData]
  )

  return (
    <table className="details-table">
      <tbody>
        {data.map(({ key, value }) => (
          <tr key={key}>
            <td className="fw-bold">{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default memo(DetailsTable)
